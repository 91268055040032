"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchant = require("@/api/merchant");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["id"],
  data: function data() {
    return {
      form: {
        company: '',
        companyid: '',
        faname: '',
        idcard: '',
        ant_organization_id: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _merchant.getMerchantInfo)(this.id).then(function (res) {
        _this.form = res;
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;
      var data = this.form;
      data.id = this.id;
      if (data.company == '' || data.companyid == '' || data.faname == '' || data.idcard == '') {
        this.$message.error('参数都必填');
        return false;
      }
      (0, _merchant.maYiPlatformCreate)(data).then(function (res) {
        _this2.getList();
      });
    }
  }
};