"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _xcx = require("@/api/xcx");
var _compForm = _interopRequireDefault(require("./components/compForm"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    compForm: _compForm.default
  },
  data: function data() {
    return {
      id: 0,
      activeNum: 1,
      configData: (0, _defineProperty2.default)({
        first_level: {
          goods: [],
          main_title: ''
        },
        second_level: {
          goods: [],
          main_title: ''
        },
        third_level: {
          goods: [],
          main_title: ''
        }
      }, "third_level", {
        goods: [],
        main_title: ''
      }),
      pageType: 'default',
      compFormData: {},
      imgWidth: 750,
      imgHeight: 320
    };
  },
  computed: {
    sectionList: function sectionList() {
      var sectionList = [];
      switch (this.pageType) {
        default:
          sectionList = [{
            title: "商品（2）推荐位",
            key: 'first_level',
            imgName: '10.jpg',
            num: 2
          }, {
            title: "商品（9）推荐位",
            key: 'second_level',
            imgName: '3.jpg',
            num: 9
          }, {
            title: "商品（2）推荐位",
            key: 'third_level',
            imgName: '10.jpg',
            num: 2
          }, {
            title: "商品（9）推荐位",
            key: 'fourth_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
      }
      return sectionList;
    },
    pageTitle: function pageTitle() {
      var title = this.configData.first_level.main_title ? this.configData.first_level.main_title : '';
      return title;
    }
  },
  watch: {
    activeNum: function activeNum(val) {
      if (val) {
        this.setData(val);
      }
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      // console.log('this.$route', this.$route)
      this.id = this.$route.params.id;
      if (this.id > 0) {
        this.$route.meta.title = '编辑 - 活动页';
        this.getData();
      } else {
        this.$route.meta.title = '添加 - 活动页';
      }
    },
    getData: function getData() {
      var _this = this;
      (0, _xcx.getEventInfo)(this.id).then(function (result) {
        var data = result && result.details ? JSON.parse(result.details) : false;
        console.log('getEventInfo', data);
        _this.setConfigData(data);
        _this.setData(_this.activeNum);
      });
    },
    setData: function setData(pos) {
      var key = this.sectionList[pos - 1].key;
      var data = _objectSpread({}, this.configData[key]);
      data.goods = data.goods ? data.goods : [];
      if (data.goods.length > 0) {
        data.goods.forEach(function (item) {
          item.id = item.id ? item.id : item.goods_id;
          item.goods_id = item.goods_id ? item.goods_id : item.id;
        });
      }
      this.compFormData = data;
      // this.$nextTick(() => {
      // 	this.$refs.compForm.formData = data;
      // })
    },
    setConfigData: function setConfigData() {
      var _this2 = this;
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.sectionList.forEach(function (item, index) {
        var key = 'first_level';
        switch (index + 1) {
          case 1:
            key = 'first_level';
            break;
          case 2:
            key = 'second_level';
            break;
          case 3:
            key = 'third_level';
            break;
          case 4:
            key = 'fourth_level';
            break;
        }
        _this2.configData[key] = data && data[key] ? data[key] : {
          goods: [],
          main_title: ''
        };
      });
    },
    // 保存
    save: function save() {
      var _this3 = this;
      var params = this.$refs.compForm.getSubmitData();
      console.log('save', params);
      if (params) {
        (0, _xcx.editEventPage)(_objectSpread({
          id: this.id,
          page_title: this.pageTitle
        }, params)).then(function (res) {
          if (_this3.id == 0) {
            _this3.id = res;
          }
          _this3.getData();
        });
      }
    },
    // 发布
    submit: function submit() {
      (0, _xcx.releaseEventPage)(this.id).then(function (res) {});
    }
  }
};