"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _linkDialog = _interopRequireDefault(require("./linkDialog"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default,
    linkDialog: _linkDialog.default
  },
  props: {
    itemIndex: {
      type: Number
    },
    showTool: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: {}
    },
    type: {
      // type: String
    }
  },
  computed: {
    linkVal: function linkVal() {
      return this.formData.jumpType == 1 ? this.formData.jumpUrl : this.formData.goods.title;
    }
  },
  data: function data() {
    return {
      editItem: null
    };
  },
  methods: {
    deleteLink: function deleteLink() {
      this.formData.jumpUrl = '';
      this.formData.goods = {};
    },
    setVal: function setVal(_ref) {
      var jumpType = _ref.jumpType,
        jumpUrl = _ref.jumpUrl,
        goods = _ref.goods;
      this.formData.jumpType = jumpType;
      this.formData.jumpUrl = jumpUrl;
      this.formData.goods = jumpType == 2 ? goods : {};
    },
    addLink: function addLink() {
      this.editItem = _objectSpread({}, this.formData);
      this.$refs.linkDialog.dialogVisible = true;
    }
  }
};