"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _auth = require("./utils/auth");
var imgBaseUrl = (0, _auth.getImgBaseUrl)();
var host = window.location.host;
var protocol = window.location.protocol;
function getUrl(hostname) {
  if (host.indexOf(hostname) != -1) {
    var hostArr = host.split('.');
    hostArr[0] = 'shop';
    host = hostArr.join('.');
  }
  // console.log('window.location',window.location)
  var url = "".concat(protocol, "//").concat(host, "/login");
  // console.log('url',url)
  return url;
}
var globalObj = {
  host: "".concat(protocol, "//").concat(host),
  title: '供应商后台管理系统',
  // 系统名称
  imgApiUrl: process.env.VUE_APP_BASE_API + '/',
  // 图片api接口地址
  defaultImgUrl: 'https://images.shshzu.com/',
  // 默认图片阿里云地址
  imgBaseUrl: imgBaseUrl ? imgBaseUrl : 'https://images.shshzu.com/',
  // 阿里云图片地址 https://xxx.com/
  merchantUrl: getUrl('supplier') // 商户后台地址 http://xxx.com
};
var _default = exports.default = globalObj;