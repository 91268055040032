"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      loading: true,
      filesList: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      } else {
        this.filesList = [];
      }
    }
  },
  methods: {
    init: function init() {
      this.getEsignContract();
    },
    getEsignContract: function getEsignContract() {
      var _this = this;
      this.loading = true;
      (0, _order.getEsignContract)({
        id: this.editItem.id
      }).then(function (res) {
        // console.log('getEsignContract', res)
        // console.log('res.files.downloadUrl', res.files[0].downloadUrl)
        _this.filesList = res.files;
        _this.loading = false;
      });
    },
    openFile: function openFile(item) {
      if (item.downloadUrl) {
        window.open(item.downloadUrl);
      }
    }
  }
};