"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id'],
  data: function data() {
    return {
      loading: true,
      options: [{
        value: 0,
        label: '无风控'
      }, {
        value: 1,
        label: '风控一'
      }, {
        value: 2,
        label: '风控二'
      }],
      value: 0
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getConfig();
    },
    getConfig: function getConfig() {
      var _this = this;
      (0, _finance.shopIsCanPayType)().then(function (res) {
        _this.value = res;
        _this.loading = false;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.loading = true;
      (0, _finance.editShopIsCanPayType)({
        type: this.value
      }).then(function (res) {
        _this2.$message.success("操作成功");
        _this2.getConfig();
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};