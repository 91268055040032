var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "backgroundImg" },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 },
          }),
          _vm._m(0),
        ],
        1
      ),
      _vm._l(_vm.bannerList, function (item, index) {
        return [
          _c("banner-form", {
            key: index,
            staticStyle: { "margin-top": "10px" },
            attrs: {
              "item-index": index,
              "show-tool": true,
              "form-data": item,
              type: "backgroundImg",
            },
            on: {
              upSortItem: function ($event) {
                return _vm.upSortItem(index)
              },
              downSortItem: function ($event) {
                return _vm.downSortItem(index)
              },
              deleteItem: function ($event) {
                return _vm.deleteItem(index)
              },
            },
          }),
        ]
      }),
      _vm.bannerList.length > 0 && _vm.bannerList.length < 1
        ? _c("el-divider")
        : _vm._e(),
      _vm.bannerList.length < 1
        ? _c("div", { staticClass: "add-div", on: { click: _vm.addItem } }, [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v("添加图片(" + _vm._s(_vm.bannerList.length) + "/1)\n  "),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("图片宽度750px，高度255px。"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }