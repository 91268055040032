var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "800px",
        title: "增值服务",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticStyle: { width: "100%", height: "160px" },
      }),
      !_vm.loading
        ? _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "选择服务" } },
                _vm._l(_vm.serviceList, function (item) {
                  return _c(
                    "div",
                    { staticClass: "setmeal-radio" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: {
                            label: item.id,
                            border: "",
                            disabled:
                              _vm.disabledServiceIds.indexOf(item.id) > -1,
                          },
                          model: {
                            value: _vm.form.service_ids,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "service_ids", $$v)
                            },
                            expression: "form.service_ids",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(item.name) +
                              " ￥" +
                              _vm._s(item.price) +
                              "元"
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }