var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.parentVisible,
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.parentVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "parentForm",
          attrs: { model: _vm.parentForm, rules: _vm.rules },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "一级品类：",
                "label-width": "120px",
                prop: "name",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.parentForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.parentForm, "name", $$v)
                  },
                  expression: "parentForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "上传封面：",
                "label-width": "120px",
                prop: "image",
              },
            },
            [
              _c("crop-upload", {
                attrs: {
                  "show-type": "photo",
                  "init-val": _vm.parentForm.image,
                  "file-size": "100K",
                  "wrapper-width": "95px",
                  "wrapper-height": "95px",
                },
                on: {
                  setVal: function (val) {
                    return _vm.setUrl("image", val)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "分类banner：",
                "label-width": "120px",
                prop: "banner_image",
              },
            },
            [
              _c("crop-upload", {
                attrs: {
                  "show-type": "photo",
                  "init-val": _vm.parentForm.banner_image,
                  "file-size": "300K",
                  "wrapper-width": "300px",
                  "wrapper-height": "70px",
                },
                on: {
                  setVal: function (val) {
                    return _vm.setUrl("banner_image", val)
                  },
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.parentForm.banner_image,
                      expression: "!parentForm.banner_image",
                    },
                  ],
                  staticClass: "banner-tips",
                },
                [_vm._v("banner尺寸: 700 x 150")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "banner链接：",
                "label-width": "120px",
                prop: "banner_link",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.parentForm.banner_link,
                  callback: function ($$v) {
                    _vm.$set(_vm.parentForm, "banner_link", $$v)
                  },
                  expression: "parentForm.banner_link",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "状态:",
                "label-width": "110px",
                prop: "enabled",
              },
            },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.parentForm.enabled,
                    callback: function ($$v) {
                      _vm.$set(_vm.parentForm, "enabled", $$v)
                    },
                    expression: "parentForm.enabled",
                  },
                },
                [_vm._v("显示")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.parentForm.enabled,
                    callback: function ($$v) {
                      _vm.$set(_vm.parentForm, "enabled", $$v)
                    },
                    expression: "parentForm.enabled",
                  },
                },
                [_vm._v("隐藏")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "排序：",
                "label-width": "120px",
                prop: "displayorder",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.parentForm.displayorder,
                  callback: function ($$v) {
                    _vm.$set(_vm.parentForm, "displayorder", $$v)
                  },
                  expression: "parentForm.displayorder",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务城市", "label-width": "140px" } },
            [
              _c("el-cascader", {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择服务城市",
                  options: _vm.areaList,
                  props: _vm.optionProps,
                  separator: "-",
                },
                on: { change: _vm.ziduanSearch },
                model: {
                  value: _vm.parentForm.area_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.parentForm, "area_id", $$v)
                  },
                  expression: "parentForm.area_id",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("parentForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }