"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editInBoundPartForm = _interopRequireDefault(require("../dialog/editInBoundPartForm"));
var _editAPayPwd = _interopRequireDefault(require("../dialog/editAPayPwd.vue"));
var _merchant = require("@/api/merchant");
var _inOutBound = require("@/api/inOutBound");
var _MerchantFilter = _interopRequireDefault(require("../components/MerchantFilter.vue"));
var _auth = require("@/utils/auth");
var _editParceling = _interopRequireDefault(require("../dialog/editParceling.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    editInBoundPartForm: _editInBoundPartForm.default,
    editAPayPwd: _editAPayPwd.default,
    MerchantFilter: _MerchantFilter.default,
    editParceling: _editParceling.default
  },
  data: function data() {
    return {
      searchForm: {
        id: '',
        cert_name: '',
        created_at: ''
      },
      createTime: [],
      tableData: [],
      total: 0,
      selectList: [],
      selectedItem: {},
      tableLoading: false,
      userType: 0,
      current_id: undefined
    };
  },
  methods: {
    handlePayPwd4: function handlePayPwd4(item) {
      this.current_id = item.id; //出库订单ID
      this.selectedItem = item;
      this.selectedItem['parcel_status'] = 1;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editParceling.dialogVisible = true;
    },
    initCreateTime: function initCreateTime() {
      return;
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      var startOfDay = "".concat(year, "-").concat(month.toString().padStart(2, '0'), "-").concat(day.toString().padStart(2, '0'), " 00:00:00");
      var endOfDay = "".concat(year, "-").concat(month.toString().padStart(2, '0'), "-").concat(day.toString().padStart(2, '0'), " 23:59:59");
      this.createTime = [startOfDay, endOfDay];
    },
    handleMerchantSelected: function handleMerchantSelected(val) {
      console.log('handleMerchantSelected', val);
      this.searchForm.merchantId = val;
    },
    // 删除维修记录
    handleDelRepair: function handleDelRepair(row) {
      var _this = this;
      (0, _inOutBound.delRepair)({
        id: row.id,
        is_other: row.is_other || undefined
      }).then(function (res) {
        _this.getList();
      });
    },
    // 获取数据
    getList: function getList() {
      var _this2 = this;
      // flag为true, 则不刷新表格
      this.selectedItem = {};
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _merchant.getMerchantList)({}).then(function (res) {
        console.log('res: ', res);
      });
      this.tableLoading = true;
      (0, _inOutBound.parcelDataDetail)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        console.log('repairCount: ', res.data);
        _this2.tableData = res.data.data || [];
        _this2.total = res.data.total || 0;
        console.log('this.tableData', _this2.tableData);
      }).finally(function () {
        _this2.tableLoading = false;
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        cert_name: '',
        created_at: ''
      };
      this.createTime = [];
      if (this.userType == 0) {
        this.$refs.merchantFilterRef.reset();
      }
      this.initCreateTime();
      this.getList();
    },
    // 导出分佣统计
    exportFileParcel: function exportFileParcel() {
      var _this$searchForm = this.searchForm,
        cert_name = _this$searchForm.cert_name,
        created_at = _this$searchForm.created_at;
      var params = {
        cert_name: cert_name,
        created_at: created_at,
        export: true
      };
      if (this.userType == 0) {
        params['merchantId'] = this.$refs.merchantFilterRef.selectedMerchant;
      }
      (0, _inOutBound.parcelDataExport)(params);
    },
    partCountInfo: function partCountInfo() {
      var chassis_number = 1;
      this.$router.push({
        path: '/inOutBound/in',
        query: {
          chassis_number: chassis_number
        }
      });
    },
    saveAPaymentData: function saveAPaymentData(item) {
      this.selectedItem = item;
      this.$refs.editInBoundPartForm.dialogVisible = true;
    },
    handlePayPwd: function handlePayPwd(id) {
      this.selectedItem = id;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editAPayPwd.dialogVisible = true;
    }
  },
  watch: {
    createTime: function createTime(val) {
      if (val) {
        this.searchForm.created_at = val.join('~');
      } else {
        this.searchForm.created_at = '';
      }
    }
  },
  mounted: function mounted() {
    var merchantId = (0, _auth.getMerchantId)();
    this.userType = merchantId == 0 ? 0 : 1; // 如果商户ID为0，则表示供应商，否则表示商户
    this.initCreateTime();
    this.getList();
  }
};