"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _sort = _interopRequireDefault(require("./child/sort"));
var _banner = _interopRequireDefault(require("./child/banner"));
var _classify = _interopRequireDefault(require("./child/classify"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    sortSet: _sort.default,
    bannerSet: _banner.default,
    classifySet: _classify.default
  },
  data: function data() {
    return {
      activeName: 'sort'
    };
  },
  methods: {
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
    }
  }
};