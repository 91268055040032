"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
var _validate = require("@/utils/validate");
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
var _methods; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  data: function data() {
    var _this = this;
    var validateConfirm = function validateConfirm(rule, value, callback) {
      if (value !== _this.form.pwd) {
        callback(new Error('两次输入密码不同！'));
      } else {
        callback();
      }
    };
    var validatePwd = function validatePwd(rule, value, callback) {
      var reg = /^[^\u4e00-\u9fa5]{0,}$/;
      if (value.length < 6) {
        callback(new Error('密码必须大于等于6个字符'));
      } else if (!reg.test(value)) {
        callback(new Error('密码不能包含汉字！'));
      } else {
        callback();
      }
    };
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    return {
      loginType: 0,
      titleList: ['供应商登录', '商户登录'],
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePwd
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      hasVerify: true,
      showVerify: false,
      showType: 'login',
      form: {
        mobile: "",
        code: "",
        pwd: "",
        confirmPwd: ""
      },
      rules: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateUsername,
          trigger: 'blur'
        }],
        code: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          required: true,
          message: '确认密码不能为空',
          trigger: 'blur'
        }, {
          validator: validateConfirm,
          trigger: 'blur'
        }]
      },
      showTime: false,
      // 显示60s倒计时
      timeCount: "",
      // 计时显示
      timer: null // 计时
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: (_methods = {
    init: function init() {
      this.chkIdentity();
      this.chkToken();
    },
    chkIdentity: function chkIdentity() {
      var identity = this.$func.chkLoginIdentity();
      if (identity == 2) {
        this.loginType = 1;
        this.titleList = ['商户登录'];
      } else if (identity == 1) {
        this.loginType = 0;
        this.titleList = ['供应商登录'];
      }
    },
    chkToken: function chkToken() {
      var token = this.$route.query.token;
      var loginType = this.$route.query.type;
      // console.log('chkToken', token)
      if (token) {
        this.tokenLogin(token, loginType);
      }
    },
    toApply: function toApply() {
      this.$router.push({
        name: 'apply'
      });
    },
    changeType: function changeType() {
      var _this2 = this;
      this.showType = this.showType === 'login' ? 'forget' : 'login';
      this.$nextTick(function () {
        if (_this2.showType === 'login') {
          _this2.showVerify = false;
          _this2.loginForm = {
            username: '',
            password: ''
          };
          _this2.showTime = false;
          clearInterval(_this2.timer);
          _this2.timer = null;
          _this2.$refs.loginForm.clearValidate();
        } else {
          _this2.form = {
            mobile: "",
            code: "",
            pwd: "",
            confirmPwd: ""
          };
          _this2.$refs.form.clearValidate();
        }
      });
    }
  }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "changeType", function changeType(index) {
    if (this.titleList == 1) {
      return;
    }
    this.loginType = index;
  }), "submit", function submit() {
    var _this3 = this;
    var that = this;
    this.$refs['form'].validate(function (valid) {
      if (valid) {
        (0, _login.forgetPwd)({
          mobile: _this3.form.mobile,
          pwd: _this3.form.pwd ? _this3.$md5(_this3.form.pwd) : "",
          code: _this3.form.code
        }).then(function (res) {
          _this3.$alert('<span><i class="el-icon-circle-check success_icon"></i></span><br />修改密码成功！去登录。', '', {
            confirmButtonText: '知道了',
            dangerouslyUseHTMLString: true,
            center: true,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            customClass: 'success_msgbox',
            callback: function callback(action) {
              that.changeType();
            }
          });
        });
      } else {
        _this3.$message({
          message: "请检查表单错误",
          type: "error"
        });
        return false;
      }
    });
  }), "getCodes", function getCodes(mobile) {
    var _this4 = this;
    (0, _login.sendMobileCode)(mobile).then(function (res) {
      _this4.showTime = true;
      var TIME_COUNT = 60;
      _this4.timeCount = 60;
      _this4.timer = setInterval(function () {
        if (_this4.timeCount > 0 && _this4.timeCount <= TIME_COUNT) {
          _this4.timeCount--;
        } else {
          _this4.showTime = false;
          clearInterval(_this4.timer);
          _this4.timer = null;
        }
      }, 1000);
    });
  }), "getPhoneCode", function getPhoneCode() {
    if (this.showTime === true) {
      return;
    }
    var reg = /^1[3-9]\d{9}$/;
    if (!this.form.mobile) {
      this.$message.warning("手机号不能为空");
    } else if (!reg.test(this.form.mobile)) {
      this.$message.error("手机号格式不正确");
    } else {
      this.getCodes(this.form.mobile);
    }
  }), "handleNameChange", function handleNameChange() {
    this.loginForm.username = this.loginForm.username.replace(/[^\d]/g, "");
  }), "handleMobileChange", function handleMobileChange() {
    this.form.mobile = this.form.mobile.replace(/[^\d]/g, "");
  }), "onSuccess", function onSuccess() {
    this.hasVerify = true;
  }), "showPwd", function showPwd() {
    var _this5 = this;
    if (this.passwordType === 'password') {
      this.passwordType = '';
    } else {
      this.passwordType = 'password';
    }
    this.$nextTick(function () {
      _this5.$refs.password.focus();
    });
  }), "loginTo", function loginTo() {
    var imgBaseUrl = (0, _auth.getImgBaseUrl)();
    this.$globalObj.imgBaseUrl = imgBaseUrl ? imgBaseUrl : this.$globalObj.imgBaseUrl;
    this.$router.push({
      path: this.redirect || '/'
    });
    this.loading = false;
  }), "handleLogin", function handleLogin() {
    var _this6 = this;
    if (!this.hasVerify) {
      this.showVerify = true;
      return;
    }
    this.$refs.loginForm.validate(function (valid) {
      if (valid) {
        _this6.loading = true;
        if (_this6.loginType) {
          _this6.$store.dispatch('user/merchantLogin', {
            username: _this6.loginForm.username,
            password: _this6.$md5(_this6.loginForm.password)
          }).then(function () {
            _this6.loginTo();
          }).catch(function (error) {
            _this6.loading = false;
          });
        } else {
          _this6.$store.dispatch('user/login', {
            username: _this6.loginForm.username,
            password: _this6.$md5(_this6.loginForm.password)
          }).then(function () {
            _this6.loginTo();
          }).catch(function (error) {
            _this6.loading = false;
          });
        }
      } else {
        _this6.$message({
          message: "请检查表单错误",
          type: "error"
        });
        return false;
      }
    });
  }), (0, _defineProperty2.default)(_methods, "tokenLogin", function tokenLogin(token, loginType) {
    var _this7 = this;
    this.$store.dispatch('user/tokenLogin', {
      token: token,
      loginType: loginType
    }).then(function () {
      _this7.loginTo();
    }).catch(function (error) {
      _this7.$router.replace({
        name: 'login'
      });
      _this7.loading = false;
    });
  }))
};