"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addCategory = addCategory;
exports.addList = addList;
exports.categoryList = categoryList;
exports.chooseGoods = chooseGoods;
exports.editActivityPage = editActivityPage;
exports.editCategory = editCategory;
exports.editEventPage = editEventPage;
exports.getActivityPage = getActivityPage;
exports.getActivityPageById = getActivityPageById;
exports.getActivityPageByType = getActivityPageByType;
exports.getApplyListAPI = getApplyListAPI;
exports.getBannerInfo = getBannerInfo;
exports.getEventInfo = getEventInfo;
exports.getEventList = getEventList;
exports.getParamsByType = getParamsByType;
exports.getVisitCount = getVisitCount;
exports.getVisitCount2 = getVisitCount2;
exports.getVisitList = getVisitList;
exports.goodsList = goodsList;
exports.listCategoryList = listCategoryList;
exports.popularizeDel = popularizeDel;
exports.previewIndex = previewIndex;
exports.release = release;
exports.releaseEventPage = releaseEventPage;
exports.releaseOnline = releaseOnline;
exports.saveBannerInfo = saveBannerInfo;
exports.saveParams = saveParams;
exports.setCategoryDel = setCategoryDel;
exports.setCategorySort = setCategorySort;
exports.setCategoryStatus = setCategoryStatus;
exports.setListSort = setListSort;
exports.setListStatus = setListStatus;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 *  小程序首页配置
 */
// 首页设置保存数据
function saveParams(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/setIndex', params, true);
}
// 首页查询单个设置
function getParamsByType(type) {
  return _fetch.default.post('/Supplier/AlipayIndex/findInfo', {
    type: type
  });
}
// 首页设置发布
function releaseOnline() {
  return _fetch.default.post('/Supplier/AlipayIndex/releaseOnline', {}, true);
}
// 首页设置预览
function previewIndex() {
  return _fetch.default.post('/Supplier/AlipayIndex/preview');
}

/**
 * 推荐位管理
 */
// 获取推荐位轮播图
function getBannerInfo(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/getBannerInfo', params);
}
// 保存推荐位轮播图
function saveBannerInfo(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/saveBannerInfo', params, true);
}

/**
 * 商品推广管理
 */
// 获取首页推荐分类列表
function categoryList(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/categoryList', params);
}
// 首页推荐添加分类
function addCategory(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/addCategory', params, true);
}
// 首页推荐编辑分类
function editCategory(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/editCategory', params, true);
}
// 首页推荐删除分类
function setCategoryDel(id) {
  return _fetch.default.post('/Supplier/AlipayIndex/setCategoryDel', {
    id: id
  }, true);
}
// 首页推荐设置分类状态
function setCategoryStatus(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/setCategoryStatus', params, true);
}
// 首页推荐设置分类排序
function setCategorySort(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/setCategorySort', params, true);
}
// 首页推荐商品列表获取分类
function listCategoryList() {
  return _fetch.default.post('/Supplier/AlipayIndex/listCategoryList');
}
// 首页推荐商品列表设置商品推荐排序
function setListSort(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/setListSort', params, true);
}
// 首页推荐商品列表
function goodsList(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/goodsList', params);
}
// 首页推荐商品列表设置状态
function setListStatus(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/setListStatus', params, true);
}

// 商品推广列表删除商品信息
function popularizeDel(id) {
  return _fetch.default.post('/Supplier/AlipayIndex/popularizeDel', {
    id: id
  }, true);
}
// 首页推荐商品列表添加商品
function addList(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/addList', params, true);
}
// 首页推荐商品列表选择商品
function chooseGoods(params) {
  return _fetch.default.post('/Supplier/AlipayIndex/chooseGoods', params);
}

/**
 * 九大分类
 */
// 获取活动页
function getActivityPage() {
  return _fetch.default.get('/Supplier/ActivityManage/getActivityPage');
}
// 编辑活动页
function editActivityPage(params) {
  return _fetch.default.post('/Supplier/ActivityManage/editActivityPage', params, true);
}
// 根据id获取活动页
function getActivityPageById(id) {
  return _fetch.default.get('/Supplier/ActivityManage/getActivityPageById', {
    id: id
  });
}
// 根据type获取活动页
function getActivityPageByType(type) {
  return _fetch.default.get('/Supplier/ActivityManage/getActivityPageByType', {
    type: type
  });
}
// 获取申请商户合作的列表
function getApplyListAPI() {
  return _fetch.default.get('/Supplier/ActivityManage/getApplyList');
}
// 发布
// 发布活动页
function release(type) {
  return _fetch.default.post('/Supplier/ActivityManage/release', {
    type: type
  }, true);
}

/**
 * 访问统计
 */
// 获取访问统计
function getVisitCount(params) {
  return _fetch.default.post('/Supplier/ActivityManage/getVisitCount', params);
}
// 获取访问统计-旧
function getVisitCount2(params) {
  return _fetch.default.post('/Supplier/ActivityManage/getVisitCount2', params);
}
// 获取访问列表
function getVisitList(params) {
  return _fetch.default.post('/Supplier/ActivityManage/getVisitList', params);
}

// 获取活动页列表
function getEventList(params) {
  return _fetch.default.post('/Supplier/ActivityManage/getEventList', params);
}
// 获取活动页详情
function getEventInfo(id) {
  return _fetch.default.get('/Supplier/ActivityManage/getEventInfo', {
    id: id
  });
}
// 添加/编辑活动页详情
function editEventPage(params) {
  return _fetch.default.post('/Supplier/ActivityManage/editEventPage', params, true);
}
// 发布/取消活动页
function releaseEventPage(id, status) {
  return _fetch.default.post('/Supplier/ActivityManage/releaseEventPage', {
    id: id,
    status: status
  }, true);
}