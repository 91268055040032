var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "500px",
        title: "增值服务详情",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "服务订单号" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.editItem.moreservice_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.editItem, "moreservice_no", $$v)
                  },
                  expression: "editItem.moreservice_no",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务名" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.editItem.moreservice_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.editItem, "moreservice_name", $$v)
                  },
                  expression: "editItem.moreservice_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务价格" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.editItem.moreservice_price,
                  callback: function ($$v) {
                    _vm.$set(_vm.editItem, "moreservice_price", $$v)
                  },
                  expression: "editItem.moreservice_price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实际收费" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.editItem.receipt_amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.editItem, "receipt_amount", $$v)
                  },
                  expression: "editItem.receipt_amount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手续费" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.price,
                  callback: function ($$v) {
                    _vm.price = $$v
                  },
                  expression: "price",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
          _vm.status == 0 && _vm.$hasMethod("#payOfflineMoreServer")
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "warning" },
                  on: { click: _vm.payOfflineMoreServer },
                },
                [_vm._v("线下还款")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }