"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _typeof2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

/* Layout */

/* 静态路由 */
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  name: 'login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/editPwd',
  name: 'editPwd',
  component: _layout.default,
  hidden: true
}, {
  path: '/recharge',
  name: 'recharge',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/recharge/index'));
    });
  },
  hidden: true
}, {
  path: '/rechargeCode',
  name: 'rechargeCode',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/recharge/code'));
    });
  },
  hidden: true
}, {
  path: '/rechargeSuccess',
  name: 'rechargeSuccess',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/recharge/success'));
    });
  },
  hidden: true
}, {
  path: '/apply',
  name: 'apply',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/apply/index'));
    });
  },
  hidden: true
}, {
  path: '/guest/throughput',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/guest/throughput'));
    });
  },
  meta: {
    title: '订单查看',
    icon: ''
  },
  hidden: true
}, {
  path: '/h5/appLogin',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/h5/appLogin'));
    });
  },
  meta: {
    title: '登录',
    icon: ''
  },
  hidden: true
}, {
  path: '/h5',
  name: 'h5',
  meta: {
    title: '推荐位管理',
    icon: 'position'
  },
  hidden: true
}, {
  path: '/trans',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/trans'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/trans',
  hidden: true
}];

/* 动态路由 */
var asyncRoutes = exports.asyncRoutes = [/* 支付宝小程序管理 */
{
  path: '/xcxPos',
  name: 'xcxPos',
  component: _layout.default,
  redirect: '/xcxPos/home',
  meta: {
    title: '推荐位管理',
    icon: 'position'
  },
  children: [{
    path: 'home',
    name: 'xcxPosHome',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/xcxPos/home/index'));
      });
    },
    meta: {
      title: '首页推荐位',
      icon: ''
    }
  }, {
    path: 'preview',
    name: 'xcxPosPreview',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/xcxPos/home/preview'));
      });
    },
    meta: {
      title: '小程序预览',
      icon: '',
      activeMenu: '/xcxPos/home',
      parentTitle: '首页推荐位',
      parentRouteName: 'xcxPosHome'
    }
  }, {
    path: 'user',
    name: 'xcxPosUser',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/xcxPos/user/index'));
      });
    },
    meta: {
      title: '我的推荐位',
      icon: ''
    }
  }, {
    path: 'template',
    name: 'xcxPosTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/xcxPos/template/index'));
      });
    },
    meta: {
      title: '模板管理',
      icon: ''
    }
  }]
}, {
  path: '/popularize',
  name: 'popularize',
  component: _layout.default,
  redirect: '/popularize/classify',
  meta: {
    title: '商品推广管理',
    icon: 'audit'
  },
  children: [{
    path: 'classify',
    name: 'popularizeClassify',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/popularize/classify'));
      });
    },
    meta: {
      title: '商品推广分类列表',
      icon: ''
    }
  }, {
    path: 'list',
    name: 'popularizeList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/popularize/list'));
      });
    },
    meta: {
      title: '商品推广列表',
      icon: ''
    }
  }]
}, {
  path: '/nine',
  name: 'nine',
  component: _layout.default,
  redirect: '/nine/office',
  meta: {
    title: '9大分类管理',
    icon: 'position'
  },
  children: [{
    path: 'office',
    name: 'nineOffice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '办公设备',
      icon: ''
    }
  }, {
    path: 'shop/:id',
    name: 'nineShop',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    hidden: true,
    meta: {
      title: '商户配置',
      icon: '',
      activeMenu: '/nine',
      parentTitle: '供应商列表',
      parentRouteName: 'nine'
    }
  }, {
    path: 'shopList/:id',
    name: 'nineShopList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    hidden: true,
    meta: {
      title: '店铺列表',
      icon: '',
      activeMenu: '/nine',
      parentTitle: '供应商列表',
      parentRouteName: 'nine'
    }
  }, {
    path: 'computer',
    name: 'nineComputer',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '电脑平板',
      icon: ''
    }
  }, {
    path: 'health',
    name: 'nineHealth',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '健康理疗',
      icon: ''
    }
  }, {
    path: 'mobile',
    name: 'nineMobile',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '品牌手机',
      icon: ''
    }
  }, {
    path: 'electric',
    name: 'nineElectric',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '能源设备',
      icon: ''
    }
  }, {
    path: 'energy',
    name: 'nineEnergy',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '开店赋能',
      icon: ''
    }
  }, {
    path: 'camera',
    name: 'nineCamera',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '数码摄影',
      icon: ''
    }
  }, {
    path: 'game',
    name: 'nineGame',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '游戏设备',
      icon: ''
    }
  }, {
    path: 'home',
    name: 'nineHome',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '智能家居',
      icon: ''
    }
  }, {
    path: 'uav',
    name: 'nineUav',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '无人机相机',
      icon: ''
    }
  }, {
    path: 'second',
    name: 'nineSecond',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '二手精选',
      icon: ''
    }
  }]
}, /* 信息管理 */
{
  path: '/article',
  name: 'article',
  component: _layout.default,
  redirect: '/article/platform',
  meta: {
    title: '文章信息管理',
    icon: 'info'
  },
  children: [{
    path: 'platform',
    name: 'articlePlatform',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/article/index'));
      });
    },
    meta: {
      title: '文章信息列表',
      icon: ''
    }
  }, {
    path: 'platform-add/:type/:editId?',
    name: 'articlePlatformAdd',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/article/add'));
      });
    },
    meta: {
      title: '平台发布文章',
      icon: '',
      activeMenu: '/article/platform',
      parentTitle: '平台文章列表',
      parentRouteName: 'articlePlatform'
    }
  }, {
    path: 'platform/help',
    name: 'articleHelp',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/article/index'));
      });
    },
    meta: {
      title: '帮助中心',
      icon: ''
    }
  }, {
    path: 'platform/agree',
    name: 'articleAgree',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/article/index'));
      });
    },
    meta: {
      title: '协议中心',
      icon: ''
    }
  }, {
    path: 'platform/share',
    name: 'articleShare',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/article/index'));
      });
    },
    meta: {
      title: '分享教程',
      icon: ''
    }
  }, {
    path: 'platform/essay',
    name: 'articleEssay',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/article/index'));
      });
    },
    meta: {
      title: '分享文章',
      icon: ''
    }
  }, {
    path: 'info',
    name: 'articleInfoConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/web/info/index'));
      });
    },
    meta: {
      title: '信息配置',
      icon: ''
    }
  }, {
    path: 'info-add/:parentId?/:editId?',
    name: 'articleInfoConfigAdd',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/web/info/add'));
      });
    },
    meta: {
      title: '添加信息',
      icon: '',
      activeMenu: '/webConfig/info',
      parentTitle: '信息配置',
      parentRouteName: 'articleInfoConfig'
    }
  }]
}, {
  path: '/info',
  name: 'info',
  component: _layout.default,
  redirect: '/info/list',
  meta: {
    title: '基础信息管理',
    icon: 'category'
  },
  children: [{
    path: 'list',
    name: 'infoCategoryList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/info/list/index'));
      });
    },
    meta: {
      title: '品类列表',
      icon: ''
    }
  }, {
    path: 'custom',
    name: 'infoDefineForm',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/info/custom/index'));
      });
    },
    meta: {
      title: '定义表单项',
      icon: ''
    }
  }, {
    path: 'source',
    name: 'infoOrderSource',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/info/source/index'));
      });
    },
    meta: {
      title: '订单来源',
      icon: ''
    }
  }, {
    path: 'link',
    name: 'infoAliAppLink',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/info/aliAppLink/index'));
      });
    },
    meta: {
      title: '小程序链接',
      icon: ''
    }
  }]
}, {
  path: '/activity',
  name: 'activity',
  component: _layout.default,
  redirect: '/activity/hb',
  meta: {
    title: '活动专区管理',
    icon: 'position'
  },
  children: [{
    path: 'hb',
    name: 'activityHb',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '花呗专区',
      icon: ''
    }
  }, {
    path: 'hot',
    name: 'activityHot',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '热租爆品',
      icon: ''
    }
  }, {
    path: 'short',
    name: 'activityShort',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '短租精选',
      icon: ''
    }
  }, {
    path: 'rent',
    name: 'activityRent',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '租完即送',
      icon: ''
    }
  }, {
    path: 'one',
    name: 'activityOne',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/index'));
      });
    },
    meta: {
      title: '一元租机',
      icon: ''
    }
  }, {
    path: 'jump',
    name: 'activityJump',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jump'));
      });
    },
    meta: {
      title: '活动跳转',
      icon: ''
    }
  }, {
    path: 'jump1',
    name: 'activityJump1',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转1',
      icon: ''
    }
  }, {
    path: 'jump2',
    name: 'activityJump2',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转2',
      icon: ''
    }
  }, {
    path: 'jump3',
    name: 'activityJump3',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转3',
      icon: ''
    }
  }, {
    path: 'jump4',
    name: 'activityJump4',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转4',
      icon: ''
    }
  }, {
    path: 'jump5',
    name: 'activityJump5',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转5',
      icon: ''
    }
  }, {
    path: 'jump6',
    name: 'activityJump6',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转6',
      icon: ''
    }
  }, {
    path: 'jump7',
    name: 'activityJump7',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转7',
      icon: ''
    }
  }, {
    path: 'jump8',
    name: 'activityJump8',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转8',
      icon: ''
    }
  }, {
    path: 'jump9',
    name: 'activityJump9',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转9',
      icon: ''
    }
  }, {
    path: 'jump10',
    name: 'activityJump10',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/nine/jumpPage'));
      });
    },
    meta: {
      title: '活动跳转10',
      icon: ''
    }
  }]
},
// 活动页管理
{
  path: '/event',
  name: 'event',
  component: _layout.default,
  redirect: '/event/list',
  meta: {
    title: '活动页管理',
    icon: 'position'
  },
  children: [{
    path: 'list',
    name: 'eventList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/event/list'));
      });
    },
    meta: {
      title: '活动列表',
      icon: ''
    }
  }, {
    path: 'edit/:id',
    name: 'eventEdit',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/event/edit'));
      });
    },
    meta: {
      title: '活动详情',
      icon: '',
      activeMenu: '/event/list',
      parentTitle: '活动列表',
      parentRouteName: 'eventList'
    }
  }]
}, {
  path: '/visit',
  name: 'visit',
  component: _layout.default,
  redirect: '/visit/count',
  meta: {
    title: '访问统计',
    icon: 'position'
  },
  children: [{
    path: 'count',
    name: 'visitCount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/visit/count'));
      });
    },
    meta: {
      title: '跳转统计',
      icon: ''
    }
  }, {
    path: 'count2',
    name: 'visitCount2',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/visit/count2'));
      });
    },
    meta: {
      title: '跳转统计-旧',
      icon: ''
    }
  }]
}, {
  path: '/attractInvestment',
  name: 'attractInvestment',
  component: _layout.default,
  redirect: '/attractInvestment/leasebusiness',
  meta: {
    title: '招商配置',
    icon: 'position'
  },
  children: [{
    path: 'cooperation',
    name: 'attractInvestmentCooperation',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/businessCooperation/index'));
      });
    },
    meta: {
      title: '商户合作',
      icon: ''
    }
  }, {
    path: 'leasebusiness',
    name: 'attractInvestmentLeasebusiness',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/businessCooperation/index'));
      });
    },
    meta: {
      title: '租机业务',
      icon: ''
    }
  }, {
    path: 'merchant',
    name: 'attractInvestmentMerchant',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/businessCooperation/index'));
      });
    },
    meta: {
      title: '招商入驻',
      icon: ''
    }
  }, {
    path: 'shoplist',
    name: 'attractInvestmentShoplist',
    // component: () => import('@/views/aliApp/businessCooperation/shopList'),
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/businessCooperation/applyList'));
      });
    },
    meta: {
      title: '申请列表',
      icon: ''
    }
  }
  // {
  //     path: 'applylist',
  //     name: 'attractInvestmentApplylist',
  //     component: () => import('@/views/aliApp/businessCooperation/applyList'),
  //     meta: { title: '申请列表', icon: '' },
  // },
  ]
}, {
  path: '/service',
  name: 'service',
  component: _layout.default,
  redirect: '/service/contract',
  meta: {
    title: '增值服务',
    icon: 'service'
  },
  children: [{
    path: 'message',
    name: 'serviceMessage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/service/message'));
      });
    },
    meta: {
      title: '短信提醒',
      icon: '',
      keepAlive: true
    }
  }, {
    path: 'messageRecord',
    name: 'serviceMessageRecord',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/service/messageRecord'));
      });
    },
    meta: {
      title: '短信购买记录',
      icon: '',
      activeMenu: '/service/message',
      parentTitle: '短信提醒',
      parentRouteName: 'serviceMessage'
    }
  }, {
    path: 'risk',
    name: 'serviceRisk',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/service/risk'));
      });
    },
    meta: {
      title: '百融风控',
      icon: ''
    }
  }, {
    path: 'riskRecord',
    name: 'serviceRiskRecord',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/service/riskRecord'));
      });
    },
    meta: {
      title: '风控记录',
      icon: '',
      activeMenu: '/service/risk',
      parentTitle: '百融风控',
      parentRouteName: 'serviceRisk'
    }
  }, {
    path: 'channel',
    name: 'serviceChannel',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/service/list/index'));
      });
    },
    meta: {
      title: '渠道列表',
      icon: ''
    }
  }]
}, {
  path: '/order',
  name: 'order',
  component: _layout.default,
  redirect: '/order/list',
  meta: {
    title: '订单管理',
    icon: 'order'
  },
  children: [{
    path: 'list',
    name: 'orderList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/list'));
      });
    },
    meta: {
      title: '订单列表',
      icon: ''
    }
  }, {
    path: 'hb',
    name: 'orderHb',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/hb'));
      });
    },
    meta: {
      title: '花呗订单',
      icon: ''
    }
  }, {
    path: 'buyout',
    name: 'orderBuyout',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/buyout'));
      });
    },
    meta: {
      title: '买断订单',
      icon: ''
    }
  }, {
    path: 'unpay',
    name: 'orderUnpay',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/unpay'));
      });
    },
    meta: {
      title: '签约待支付订单',
      icon: ''
    }
  },
  //          {
  //              path: "relet",
  //              name: "orderRelet",
  //              component: () => import("@/views/order/relet"),
  //              meta: { title: "续租订单", icon: "" },
  // },
  {
    path: 'num',
    name: 'orderNumCount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/num'));
      });
    },
    meta: {
      title: '待审核统计',
      icon: ''
    }
  }, {
    path: 'auditCount',
    name: 'orderAuditCount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/auditCount'));
      });
    },
    meta: {
      title: '审核统计',
      icon: ''
    }
  }, {
    path: 'tradingCount',
    name: 'orderTradingCount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/order/tradingCount'));
      });
    },
    meta: {
      title: '成交统计',
      icon: ''
    }
  }]
}, {
  path: '/auditOrder',
  name: 'auditOrder',
  component: _layout.default,
  redirect: '/auditOrder/auditOrderWithhold',
  meta: {
    title: '订单审核',
    icon: 'user'
  },
  children: [{
    path: 'auditOrderWithhold',
    name: 'auditOrderWithhold',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auditOrder/withhold'));
      });
    },
    meta: {
      title: '扣款申请表',
      icon: ''
    }
  }, {
    path: 'auditOrderCancel',
    name: 'auditOrderCancel',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auditOrder/cancel'));
      });
    },
    meta: {
      title: '关单申请表',
      icon: ''
    }
  }, {
    path: 'auditOrderFinish',
    name: 'auditOrderFinish',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/auditOrder/finish'));
      });
    },
    meta: {
      title: '完成申请表',
      icon: ''
    }
  }]
}, {
  path: '/member',
  name: 'member',
  component: _layout.default,
  redirect: '/member/list',
  meta: {
    title: '用户管理',
    icon: 'user'
  },
  children: [{
    path: 'list',
    name: 'memberList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/member'));
      });
    },
    meta: {
      title: '用户列表',
      icon: '',
      keepAlive: true
    }
  }, {
    path: 'view/:viewId/:toEdit?',
    name: 'memberView',
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/member/view'));
      });
    },
    meta: {
      title: '用户信息',
      icon: '',
      activeMenu: '/member/list',
      parentTitle: '用户列表',
      parentRouteName: 'memberList'
    }
  }, {
    path: 'settle',
    name: 'memberSettle',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/member/settle'));
      });
    },
    meta: {
      title: '用户列表',
      icon: '',
      keepAlive: true
    }
  }, {
    path: 'supplierlist',
    name: 'memberSupplierList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aliApp/businessCooperation/settle'));
      });
    },
    meta: {
      title: '供应商入驻列表',
      icon: '',
      keepAlive: true
    }
  }]
}, {
  path: '/refund',
  name: 'refund',
  component: _layout.default,
  redirect: '/refund/list',
  meta: {
    title: '退款管理',
    icon: 'refund'
  },
  children: [{
    path: 'list',
    name: 'refundList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/refund/list'));
      });
    },
    meta: {
      title: '仅退款列表',
      icon: ''
    }
  }, {
    path: 'refundDetails/:id',
    name: 'refundDetail',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/refund/refundDetails'));
      });
    },
    meta: {
      title: '退款详情',
      icon: '',
      activeMenu: '/refund/list',
      parentTitle: '仅退款列表',
      parentRouteName: 'refund'
    }
  }]
}, {
  path: '/risk',
  name: 'risk',
  component: _layout.default,
  redirect: '/risk/address',
  meta: {
    title: '风险管理',
    icon: 'refund'
  },
  children: [{
    path: 'address',
    name: 'riskAddress',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/risk/address'));
      });
    },
    meta: {
      title: '风险区域',
      icon: ''
    }
  }, {
    path: 'name',
    name: 'riskName',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/risk/name'));
      });
    },
    meta: {
      title: '黑名单',
      icon: ''
    }
  }, {
    path: 'intercept',
    name: 'riskIntercept',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/risk/intercept'));
      });
    },
    meta: {
      title: '下单拦截',
      icon: ''
    }
  }]
}, {
  path: '/goods',
  name: 'goods',
  component: _layout.default,
  redirect: '/goods/index',
  meta: {
    title: '商品管理',
    icon: 'product'
  },
  children: [{
    path: 'index',
    name: 'goodsIndex',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/index'));
      });
    },
    meta: {
      title: '商品列表',
      icon: '',
      keepAlive: true
    }
  }, {
    path: 'release/:draftId?',
    name: 'goodsAdd',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/add'));
      });
    },
    meta: {
      title: '发布商品',
      icon: '',
      activeMenu: '/goods/index',
      parentTitle: '商品列表',
      parentRouteName: 'goodsIndex'
    }
  }, {
    path: 'view/:goodsId',
    name: 'goodsView',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/view'));
      });
    },
    meta: {
      title: '查看商品',
      icon: '',
      activeMenu: '/goods/index',
      parentTitle: '商品列表',
      parentRouteName: 'goodsIndex'
    }
  }, {
    path: 'success/:goodsId/:isEdit?',
    name: 'goodsSuccess',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/success'));
      });
    },
    meta: {
      title: '发布商品',
      icon: '',
      activeMenu: '/goods/index',
      parentTitle: '商品列表',
      parentRouteName: 'goodsIndex'
    }
  }, {
    path: 'draft',
    name: 'goodsDraft',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/draft'));
      });
    },
    meta: {
      title: '草稿箱',
      icon: '',
      activeMenu: '/goods/index',
      parentTitle: '商品列表',
      parentRouteName: 'goodsIndex'
    }
  }, {
    path: 'baseEdit/:goodsId',
    name: 'goodsBaseEdit',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/edit'));
      });
    },
    meta: {
      title: '编辑商品信息',
      icon: '',
      activeMenu: '/goods/index',
      parentTitle: '商品列表',
      parentRouteName: 'goodsIndex'
    }
  }, {
    path: 'meal/:goodsId/:pact_type/:mealId/:isCopy?',
    name: 'goodsMeal',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/edit'));
      });
    },
    meta: {
      title: '编辑套餐',
      icon: '',
      activeMenu: '/goods/index',
      parentTitle: '商品列表',
      parentRouteName: 'goodsIndex'
    }
  }, {
    path: 'mealInfo/:goodsId',
    name: 'goodsMealInfo',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/mealList'));
      });
    },
    meta: {
      title: '套餐信息',
      icon: '',
      activeMenu: '/goods/index',
      parentTitle: '商品列表',
      parentRouteName: 'goodsIndex'
    }
  }, {
    path: 'service',
    name: 'goodsService',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/service'));
      });
    },
    meta: {
      title: '增值服务',
      icon: ''
    }
  }, {
    path: 'explain',
    name: 'goodsExplain',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/goods/explain'));
      });
    },
    meta: {
      title: '附加说明',
      icon: ''
    }
  }, {
    path: 'category',
    name: 'goodsCategory',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/info/list/index'));
      });
    },
    meta: {
      title: '品类管理',
      icon: ''
    }
  }, {
    path: 'add',
    name: 'goodsCategoryApply',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/category/add'));
      });
    },
    meta: {
      title: '申请品类',
      icon: '',
      activeMenu: '/goods/category',
      parentTitle: '品类管理',
      parentRouteName: 'goodsCategory'
    }
  }]
}, {
  path: '/account',
  name: 'account',
  component: _layout.default,
  redirect: '/account/list',
  meta: {
    title: '账号管理',
    icon: 'user'
  },
  children: [{
    path: 'list',
    name: 'accountList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/account/index'));
      });
    },
    meta: {
      title: '账号列表',
      icon: ''
    }
  }, {
    path: 'role',
    name: 'accountRole',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/role/index'));
      });
    },
    meta: {
      title: '账号分组',
      icon: ''
    }
  }]
}, {
  path: '/merchantAccount',
  name: 'merchantAccount',
  component: _layout.default,
  redirect: '/merchantAccount/list',
  meta: {
    title: '商户管理',
    icon: 'user'
  },
  children: [{
    path: 'list',
    name: 'merchantAccountList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/merchant/index'));
      });
    },
    meta: {
      title: '商户列表',
      icon: ''
    },
    children: []
  }, {
    path: 'view/:viewId/:toEdit?',
    name: 'merchantAccountView',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/merchant/view'));
      });
    },
    meta: {
      title: '商户详情',
      icon: '',
      activeMenu: '/merchantAccount/list',
      parentTitle: '商户列表',
      parentRouteName: 'merchantAccountList'
    }
  }, {
    path: 'role',
    name: 'merchantAccountRole',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/merchant/role/index'));
      });
    },
    meta: {
      title: '商户权限',
      icon: ''
    }
  }, {
    path: '/mayiplatadd/:id',
    name: 'merchantAccountMayiPlat',
    props: true,
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/merchant/mayiplatadd'));
      });
    },
    meta: {
      title: '商户列表',
      icon: '',
      activeMenu: '/merchantAccount/list',
      parentTitle: '供应商列表',
      parentRouteName: 'merchantAccountList'
    }
  }]
}, {
  path: '/financeAudit',
  name: 'financeAudit',
  component: _layout.default,
  redirect: '/financeAudit/withdrawal',
  meta: {
    title: '财务审核',
    icon: 'bill'
  },
  children: [{
    path: 'withdrawal',
    name: 'financeAuditWithdrawal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/finance/withdrawal'));
      });
    },
    meta: {
      title: '商户提现审核',
      icon: ''
    }
  }, {
    path: 'userWithdrawal',
    name: 'financeAuditUserWithdrawal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/finance/userWithdrawal/withdrawal'));
      });
    },
    meta: {
      title: '用户提现审核',
      icon: ''
    }
  }]
}, {
  path: '/payment',
  name: 'payment',
  component: _layout.default,
  redirect: '/payment/list',
  meta: {
    title: '支付管理',
    icon: 'el-icon-message'
  },
  children: [{
    path: 'list',
    name: 'paymentList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/payment/index'));
      });
    },
    meta: {
      title: '支付通道列表',
      icon: ''
    }
  }]
}, {
  path: '/setting',
  name: 'setting',
  component: _layout.default,
  redirect: '/setting/config',
  meta: {
    title: '配置管理',
    icon: 'set'
  },
  children: [{
    path: 'config',
    name: 'settingConfig',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/setting/config'));
      });
    },
    meta: {
      title: '配置设置',
      icon: ''
    }
  }]
}, {
  path: '/safe',
  name: 'safe',
  component: _layout.default,
  redirect: '/safe/ip',
  meta: {
    title: '安全管理',
    icon: 'el-icon-message'
  },
  children: [{
    path: 'ip',
    name: 'safeIp',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/safe/ip'));
      });
    },
    meta: {
      title: 'IP管理',
      icon: ''
    }
  }]
}, {
  path: '/app',
  name: 'app',
  component: _layout.default,
  redirect: '/app/version',
  meta: {
    title: 'App管理',
    icon: 'set'
  },
  children: [{
    path: 'version',
    name: 'appVersion',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/app/version'));
      });
    },
    meta: {
      title: 'App版本管理',
      icon: ''
    }
  }]
}, {
  path: '/merchantSafe',
  name: 'merchantSafe',
  component: _layout.default,
  redirect: '/merchantSafe/ip',
  meta: {
    title: '安全管理',
    icon: 'el-icon-message'
  },
  children: [{
    path: 'ip',
    name: 'merchantSafeIp',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/system/safe/ip'));
      });
    },
    meta: {
      title: 'IP管理',
      icon: ''
    }
  }]
}, {
  path: '/shop',
  name: 'shop',
  component: _layout.default,
  redirect: '/shop/returnAd',
  meta: {
    title: '店铺管理',
    icon: 'shop'
  },
  children: [{
    path: 'returnAd',
    name: 'shopReturnAd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/returnAd'));
      });
    },
    meta: {
      title: '归还地址设置',
      icon: ''
    }
  }, {
    path: 'addReturnAd/:editId?',
    name: 'shopAddReturnAd',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/addReturnAd'));
      });
    },
    meta: {
      title: '添加归还地址',
      icon: '',
      activeMenu: '/shop/returnAd',
      parentTitle: '归还地址设置',
      parentRouteName: 'shopReturnAd'
    }
  }, {
    path: 'rentAd',
    name: 'shopRentAd',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/rentAd'));
      });
    },
    meta: {
      title: '租赁地址',
      icon: ''
    }
  }, {
    path: 'info',
    name: 'shopInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/info'));
      });
    },
    meta: {
      title: '店铺信息',
      icon: ''
    }
  }, {
    path: 'shop',
    name: 'shopShop',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/shop'));
      });
    },
    meta: {
      title: '旺铺管理',
      icon: ''
    }
  }, {
    path: 'manage/:editId/:editName',
    name: 'shopManage',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/shop/manage'));
      });
    },
    meta: {
      title: '关联商品',
      icon: '',
      activeMenu: '/shop/shop',
      parentTitle: '旺铺管理',
      parentRouteName: 'shopShop'
    }
  }]
}, {
  path: '/bill',
  name: 'bill',
  component: _layout.default,
  redirect: '/bill/list',
  meta: {
    title: '账单管理',
    icon: 'bill'
  },
  children: [{
    path: 'list',
    name: 'billList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/index'));
      });
    },
    meta: {
      title: '订单财务列表',
      icon: ''
    }
  }, {
    path: 'bill',
    name: 'billBill',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/bill'));
      });
    },
    meta: {
      title: '账单财务列表',
      icon: ''
    }
  }, {
    path: 'pay',
    name: 'billPay',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/pay'));
      });
    },
    meta: {
      title: '账单扣款列表',
      icon: ''
    }
  }, {
    path: 'member',
    name: 'billMember',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/member'));
      });
    },
    meta: {
      title: '用户收益列表',
      icon: ''
    }
  }, {
    path: 'hb',
    name: 'billHb',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/hb'));
      });
    },
    meta: {
      title: '花呗分期账单',
      icon: ''
    }
  }, {
    path: 'device',
    name: 'billDevice',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/device'));
      });
    },
    meta: {
      title: '设备分期导出',
      icon: ''
    }
  }, {
    path: 'payment',
    name: 'billPayment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/payment'));
      });
    },
    meta: {
      title: '回款导出',
      icon: ''
    }
  }, {
    path: 'moreList',
    name: 'billMoreList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/moreList'));
      });
    },
    meta: {
      title: '更多服务列表',
      icon: ''
    }
  }, {
    path: 'merhantChild',
    name: 'billMerhantChild',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/merhantChild'));
      });
    },
    meta: {
      title: '跟单统计列表',
      icon: ''
    }
  }, {
    path: 'risControllerkList',
    name: 'billRisControllerkList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/bill/risk/index'));
      });
    },
    meta: {
      title: '供应商风控统计',
      icon: ''
    }
  }]
}, {
  path: '/overdue',
  name: 'overdue',
  component: _layout.default,
  redirect: '/overdue/list',
  meta: {
    title: '逾期管理',
    icon: 'overdue'
  },
  children: [{
    path: 'list',
    name: 'overdueList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/overdue/index'));
      });
    },
    meta: {
      title: '逾期列表',
      icon: ''
    }
  }, {
    path: 'payment',
    name: 'overduePayment',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/overdue/payment'));
      });
    },
    meta: {
      title: '逾期还款列表',
      icon: ''
    }
  }, {
    path: 'sue',
    name: 'overdueSue',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/overdue/sue'));
      });
    },
    meta: {
      title: '起诉列表',
      icon: ''
    }
  }]
}, {
  path: '/fixOrder',
  name: 'fixOrder',
  component: _layout.default,
  redirect: '/fixOrder/sesame',
  meta: {
    title: '订单修复',
    icon: 'order'
  },
  children: [{
    path: 'sesame',
    name: 'fixOrderSesame',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/fixOrder/sesame'));
      });
    },
    meta: {
      title: '芝麻信用',
      icon: ''
    }
  }]
}, {
  path: '/aPayment',
  name: 'aPayment',
  component: _layout.default,
  redirect: '/aPayment/list',
  meta: {
    title: '转账管理',
    icon: 'bill'
  },
  children: [{
    path: 'list',
    name: 'aPaymentList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/aPayment/list'));
      });
    },
    meta: {
      title: '转账列表',
      icon: ''
    }
  }]
}, {
  path: '/money',
  name: 'money',
  component: _layout.default,
  redirect: '/money/count',
  meta: {
    title: '金额统计',
    icon: 'bill'
  },
  children: [{
    path: 'count',
    name: 'moneycount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/money/count'));
      });
    },
    meta: {
      title: '金额列表',
      icon: ''
    }
  }]
}, {
  path: '/inOutBound',
  name: 'inOutBound',
  component: _layout.default,
  redirect: '/inOutBound/inOutBoundIn',
  meta: {
    title: '出入库管理',
    icon: 'bill'
  },
  children: [{
    path: 'in',
    name: 'inOutBoundIn',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/inOutBound/in'));
      });
    },
    meta: {
      title: '入库管理',
      icon: ''
    }
  }, {
    path: 'out',
    name: 'inOutBoundOut',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/inOutBound/out'));
      });
    },
    meta: {
      title: '出库管理',
      icon: ''
    }
  }, {
    path: 'out/:id',
    name: 'inOutBoundOut',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/inOutBound/partRepairHistory'));
      });
    },
    meta: {
      title: '配件维修历史',
      icon: '',
      activeMenu: '/inOutBound/out',
      parentTitle: '出库管理',
      parentRouteName: 'inOutBoundOut'
    }
  }, {
    path: 'part',
    name: 'inOutBoundPart',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/inOutBound/part'));
      });
    },
    meta: {
      title: '配件管理',
      icon: ''
    }
  },
  // {
  //     path: 'statisRent',
  //     name: 'inOutBoundStatisticRent',
  //     component: () => import('@/views/inOutBound/statis/rent'),
  //     meta: { title: '出租统计', icon: '' },
  // },
  {
    path: 'sell',
    name: 'inOutBoundStatisticSell',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/inOutBound/statis/sell'));
      });
    },
    meta: {
      title: '卖出统计',
      icon: ''
    }
  }, {
    path: 'partInfo',
    name: 'inOutBoundStatisticRepair',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/inOutBound/statis/partInfo'));
      });
    },
    meta: {
      title: '维修统计',
      icon: ''
    }
  }, {
    path: 'ower',
    name: 'inOutBoundStatisticOwer',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/inOutBound/statis/ower'));
      });
    },
    meta: {
      title: '分拥统计',
      icon: ''
    }
  }]
}, {
  path: '/my',
  name: 'my',
  component: _layout.default,
  redirect: '/my/index',
  meta: {
    title: '账户管理',
    icon: 'user'
  },
  children: [{
    path: 'index',
    name: 'myIndex',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account/index'));
      });
    },
    meta: {
      title: '我的账户',
      icon: ''
    }
  }, {
    path: 'withdrawal',
    name: 'myWithdrawal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account/withdrawal'));
      });
    },
    meta: {
      title: '提现记录',
      icon: ''
    }
  },
  // {
  // 	path: "flow",
  // 	name: "myFlow",
  // 	component: () => import("@/views/account/flow_old"),
  // 	meta: { title: "对账流水", icon: "" },
  // },
  {
    path: 'flow',
    name: 'myFlowNew',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account/flow'));
      });
    },
    meta: {
      title: '流水记录新',
      icon: ''
    }
  }, {
    path: 'withdraw/:withdrawId?',
    name: 'myWithdraw',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/account/withdraw'));
      });
    },
    meta: {
      title: '详情',
      icon: '',
      activeMenu: '/my/withdrawal',
      parentTitle: '提现记录',
      parentRouteName: 'myWithdrawal'
    }
  }]
}, {
  path: '/merchantMy',
  name: 'merchantMy',
  component: _layout.default,
  redirect: '/merchantMy/index',
  meta: {
    title: '账户管理',
    icon: 'user'
  },
  children: [{
    path: 'index',
    name: 'merchantMyIndex',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchantMy/index'));
      });
    },
    meta: {
      title: '我的账户',
      icon: ''
    }
  }, {
    path: 'info',
    name: 'merchantMyInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchantMy/info'));
      });
    },
    meta: {
      title: '商户详情',
      icon: ''
    }
  }, {
    path: 'withdrawal',
    name: 'merchantMyWithdrawal',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchantMy/withdrawal'));
      });
    },
    meta: {
      title: '提现记录',
      icon: ''
    }
  }, {
    path: 'flow',
    name: 'merchantMyFlow',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchantMy/flow'));
      });
    },
    meta: {
      title: '对账流水',
      icon: ''
    }
  }, {
    path: 'withdraw/:withdrawId?',
    name: 'merchantMyWithdraw',
    hidden: true,
    props: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchantMy/withdraw'));
      });
    },
    meta: {
      title: '详情',
      icon: '',
      activeMenu: '/merchantMy/withdrawal',
      parentTitle: '提现记录',
      parentRouteName: 'merchantMyWithdrawal'
    }
  }]
}, {
  path: '/merchantMember',
  name: 'merchantMember',
  component: _layout.default,
  redirect: '/merchantMember/account',
  meta: {
    title: '成员管理',
    icon: 'user'
  },
  children: [{
    path: 'account',
    name: 'merchantMemberAccount',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchantMember/account/index'));
      });
    },
    meta: {
      title: '成员列表',
      icon: ''
    }
  }, {
    path: 'role',
    name: 'merchantMemberRole',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/merchantMember/role/index'));
      });
    },
    meta: {
      title: '成员分组',
      icon: ''
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return {
          x: 0,
          y: 0
        };
      }
    },
    routes: constantRoutes
  });
};
var router = createRouter();
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;