"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _login2 = require("@/api/login");
var _auth = require("@/utils/auth");
var _router = _interopRequireWildcard(require("@/router"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var func = {
  setInfo: function setInfo(commit, data) {
    commit("SET_TOKEN", data.token);
    (0, _auth.setToken)(data.token);
    (0, _auth.setName)(data.shopname);
    (0, _auth.setShopUserId)(data.shopUserId);
    (0, _auth.setMemberId)(data.memberId);
    (0, _auth.setMerchantId)(data.merchantId);
    (0, _auth.setImgBaseUrl)(data.imgHost + '/');
    (0, _auth.setAccount)(data.name);
    (0, _auth.setPhoto)(data.photo);
    (0, _auth.setMobile)(data.mobile);
  },
  clearInfo: function clearInfo(commit) {
    commit("SET_TOKEN", "");
    commit("SET_MENU", []);
    commit("SET_RULE", []);
    commit("SET_RULEIDS", []);
    (0, _auth.removeToken)();
    (0, _auth.removeName)();
    (0, _auth.removeMemberId)();
    (0, _auth.removeMerchantId)();
    (0, _auth.removeShopUserId)();
    (0, _auth.removeImgBaseUrl)();
    (0, _auth.removeAccount)();
    (0, _auth.removePhoto)();
    (0, _router.resetRouter)();
  }
};
var state = {
  token: (0, _auth.getToken)(),
  menu: [],
  rule: [],
  ruleIds: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_MENU: function SET_MENU(state, menu) {
    state.menu = menu;
  },
  SET_RULE: function SET_RULE(state, rule) {
    state.rule = rule;
  },
  SET_RULEIDS: function SET_RULEIDS(state, ruleIds) {
    state.ruleIds = ruleIds;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      var name = username.trim();
      (0, _login2.login)({
        mobile: name,
        password: password
      }).then(function (response) {
        var data = response;
        func.setInfo(commit, data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // token登录
  tokenLogin: function tokenLogin(_ref2, data) {
    var commit = _ref2.commit;
    var token = data.token,
      loginType = data.loginType;
    return new Promise(function (resolve, reject) {
      if (loginType == 0) {
        (0, _login2.supplierTokenLogin)({
          token: token
        }).then(function (response) {
          var data = response;
          func.setInfo(commit, data);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      } else {
        (0, _login2.merchantTokenLogin)({
          token: token
        }).then(function (response) {
          var data = response;
          func.setInfo(commit, data);
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      }
    });
  },
  // 获取用户授权菜单
  getMenu: function getMenu(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      (0, _login2.getAuthRule)().then(function (response) {
        if (!response) {
          reject("授权失败，请重新登录！");
        }
        var menu = response.menu,
          rule = response.rule,
          ruleIds = response.ruleIds;
        // 如果没有页面级，则整个菜单隐藏
        var filterList = [];
        var _iterator = _createForOfIteratorHelper(menu),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            if (item.children) {
              var obj = _objectSpread({}, item);
              obj.children = [];
              var _iterator2 = _createForOfIteratorHelper(item.children),
                _step2;
              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var child = _step2.value;
                  if (child.children) {
                    obj.children.push(child);
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
              if (obj.children.length > 0) {
                filterList.push(obj);
              }
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        commit("SET_MENU", filterList);
        commit("SET_RULE", rule);
        commit("SET_RULEIDS", ruleIds);
        resolve({
          menu: filterList
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      (0, _login2.logout)().then(function () {
        func.clearInfo(commit);
        _router.default.push("/login");
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      func.clearInfo(commit);
      // commit("SET_TOKEN", "");
      // commit("SET_MENU", []);
      // commit("SET_RULE", []);
      // commit("SET_RULEIDS", []);

      // removeToken();
      // removeName();
      // removeMerchantId();
      // removeShopUserId();
      // removeAccount();
      // removePhoto();
      resolve();
    });
  },
  // merchantLogin
  merchantLogin: function merchantLogin(_ref6, userInfo) {
    var commit = _ref6.commit;
    var username = userInfo.username,
      password = userInfo.password;
    return new Promise(function (resolve, reject) {
      var name = username.trim();
      (0, _login2.merchantLogin)({
        mobile: name,
        password: password
      }).then(function (response) {
        var data = response;
        func.setInfo(commit, data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // merchantLogout
  merchantLogout: function merchantLogout(_ref7) {
    var commit = _ref7.commit,
      state = _ref7.state;
    return new Promise(function (resolve, reject) {
      (0, _login2.merchantLogout)().then(function () {
        func.clearInfo(commit);
        _router.default.push("/login");
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};