var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top_banner" }, [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 },
          }),
          _vm._m(0),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "main_content" },
      [
        _c("span", [_vm._v("请选择：")]),
        _c("el-color-picker", {
          attrs: { "show-alpha": "", predefine: _vm.predefineColors },
          on: { change: _vm.colorChange },
          model: {
            value: _vm.bannerList,
            callback: function ($$v) {
              _vm.bannerList = $$v
            },
            expression: "bannerList",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("首页顶部背景颜色")]), _c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }