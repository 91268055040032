"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _qrcode = _interopRequireDefault(require("qrcode"));
var _inOutBound = require("@/api/inOutBound");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['payInfo'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        amount: '',
        qr_code: '',
        log_id: '',
        out_trade_no: ''
      },
      deadline2: '',
      // 未来三分钟
      formDataDefault: {
        amount: '',
        qr_code: '',
        log_id: '',
        out_trade_no: ''
      },
      code_url: '',
      //转化后的支付二维码
      minute: '00',
      seconds: '00',
      interval: null,
      timer: null // 计时
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.formData = Object.assign(this.formData, this.payInfo);
        this.getData();
      } else {
        this.formData = Object.assign(this.formData, this.formDataDefault);
        this.clear();
      }
    }
  },
  methods: {
    changeCountDown: function changeCountDown() {
      var _this = this;
      this.timer = setInterval(function () {
        _this.isPaySuccess();
      }, 2000);
    },
    hilarity: function hilarity() {
      this.$notify({
        title: '提示',
        message: '退出支付',
        duration: 3000
      });
      this.handleClose();
    },
    paySuccessCallBack: function paySuccessCallBack() {
      this.handleClose();
      this.$emit('success', true);
    },
    getData: function getData() {
      var _this2 = this;
      console.log('getdata执行了', this.formData);
      if (this.formData.qr_code) {
        this.deadline2 = Date.now() + 1000 * 60 * 3;
        _qrcode.default.toDataURL(this.formData.qr_code).then(function (res) {
          _this2.code_url = res;
          _this2.changeCountDown();
          // this.startLoop()
        }).catch(function (err) {
          console.error(err);
        });
      }
    },
    isPaySuccess: function isPaySuccess() {
      var _this3 = this;
      (0, _inOutBound.qrCodePayQuery)({
        rechargeId: this.formData.log_id
      }).then(function (res) {
        _this3.paySuccessCallBack();

        // this.$router.push({ name: 'rechargeSuccess' })
      }).catch(function (err) {});
    },
    clear: function clear() {
      this.deadline2 = '';
      this.code_url = ''; //清除支付二维码链接
      // 清除timer
      clearInterval(this.timer); // 清除定时器
      this.timer = null; // 将定时器变量重置为null
    },
    handleClose: function handleClose() {
      this.clear();
      this.dialogVisible = false;
    }
  },
  computed: {
    userName: function userName() {
      return (0, _auth.getName)();
    }
  },
  mounted: function mounted() {
    this.getData();
  }
};