"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addGoodsData = addGoodsData;
exports.addSetmeal = addSetmeal;
exports.batchDeleteGoodsDraft = batchDeleteGoodsDraft;
exports.delService = delService;
exports.deleteGoods = deleteGoods;
exports.deleteGoodsSetmeal = deleteGoodsSetmeal;
exports.editGoodsData = editGoodsData;
exports.editGoodsPriceRate = editGoodsPriceRate;
exports.editGoodsSetmeal = editGoodsSetmeal;
exports.editGoodsSetmealStatus = editGoodsSetmealStatus;
exports.editGoodsStock = editGoodsStock;
exports.editServiceInfo = editServiceInfo;
exports.editSort = editSort;
exports.getGoodsAndSetmeal = getGoodsAndSetmeal;
exports.getGoodsData = getGoodsData;
exports.getGoodsDraft = getGoodsDraft;
exports.getGoodsExplain = getGoodsExplain;
exports.getGoodsInfo = getGoodsInfo;
exports.getGoodsList = getGoodsList;
exports.getGoodsParameter = getGoodsParameter;
exports.getGoodsRentData = getGoodsRentData;
exports.getGoodsServiceList = getGoodsServiceList;
exports.getGoodsSetmealInfo = getGoodsSetmealInfo;
exports.getGoodsSetmealList = getGoodsSetmealList;
exports.getParameterList = getParameterList;
exports.getServiceList = getServiceList;
exports.getSetmealStock = getSetmealStock;
exports.getShopCategory = getShopCategory;
exports.goodsCancelReview = goodsCancelReview;
exports.goodsGrounding = goodsGrounding;
exports.goodsShare = goodsShare;
exports.goodsSubmitReview = goodsSubmitReview;
exports.itemCopy = itemCopy;
exports.itemDownShelf = itemDownShelf;
exports.saveGoodsExplain = saveGoodsExplain;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 获取商品列表
function getGoodsList(params) {
  return _fetch.default.post("/Supplier/Goods/getGoodsList", params);
}
// 获取商户下所有申请品类
function getShopCategory() {
  return _fetch.default.get("/Supplier/Goods/getShopCategory");
}
// 获取商品添加各参数
function getGoodsRentData() {
  return _fetch.default.get("/Supplier/Goods/getGoodsRentData");
}
// 通过分类id获取商品参数
function getGoodsParameter(id) {
  return _fetch.default.post("/Supplier/Goods/getGoodsParameter", {
    cate_id: id
  });
}
// 添加商品基础信息
function addGoodsData(params) {
  return _fetch.default.post("/Supplier/Goods/addGoodsData", params);
}
// 套餐获取参数
function getParameterList() {
  return _fetch.default.get("/Supplier/Goods/getParameterList");
}
// 添加套餐信息
function addSetmeal(params) {
  return _fetch.default.post("/Supplier/Goods/addSetmeal", params);
}
// 编辑获取商品基础信息
function getGoodsData(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsData", {
    goods_id: id
  });
}
// 编辑商品基础信息
function editGoodsData(params) {
  return _fetch.default.post("/Supplier/Goods/editGoodsData", params);
}
// 编辑商品价格系数
function editGoodsPriceRate(params) {
  return _fetch.default.post("/Supplier/Goods/editGoodsPriceRate", params);
}
// 编辑获取商品套餐列表
function getGoodsSetmealList(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsSetmealList", {
    goods_id: id
  });
}
// 编辑获取套餐信息
function getGoodsSetmealInfo(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsSetmealInfo", {
    setmeal_id: id
  });
}
// 编辑套餐信息
function editGoodsSetmeal(params) {
  return _fetch.default.post("/Supplier/Goods/editGoodsSetmeal", params);
}
// 编辑套餐状态
function editGoodsSetmealStatus(params) {
  return _fetch.default.post("/Supplier/Goods/editGoodsSetmealStatus", params);
}
// 草稿箱商品列表
function getGoodsDraft(params) {
  return _fetch.default.get("/Supplier/Goods/getGoodsDraft", params);
}
// 草稿箱获取商品信息
function getGoodsAndSetmeal(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsAndSetmeal", {
    goods_id: id
  });
}
// 商品上架
function goodsGrounding(id) {
  return _fetch.default.post("/Supplier/Goods/goodsGrounding", {
    goods_id: id
  }, true);
}
// 商品下架
function itemDownShelf(id) {
  return _fetch.default.post("/Supplier/Goods/itemDownShelf", {
    goods_id: id
  }, true);
}
// 商品复制
function itemCopy(id) {
  return _fetch.default.post("/Supplier/Goods/itemCopy", {
    goods_id: id
  }, true);
}
// 提交审核
function goodsSubmitReview(id) {
  return _fetch.default.post("/Supplier/Goods/goodsSubmitReview", {
    goods_id: id
  }, true);
}
// 删除商品
function deleteGoods(id) {
  return _fetch.default.post("/Supplier/Goods/deleteGoods", {
    goods_id: id
  }, true);
}
// 删除套餐
function deleteGoodsSetmeal(params) {
  return _fetch.default.post("/Supplier/Goods/deleteGoodsSetmeal", params, true);
}
// 获取套餐库存
function getSetmealStock(id) {
  return _fetch.default.get("/Supplier/Goods/getSetmealStock", {
    goods_id: id
  });
}
// 编辑套餐库存
function editGoodsStock(params) {
  return _fetch.default.post("/Supplier/Goods/editGoodsStock", params, true);
}
// 取消审核
function goodsCancelReview(id) {
  return _fetch.default.post("/Supplier/Goods/goodsCancelReview", {
    goods_id: id
  }, true);
}
// 批量删除草稿
function batchDeleteGoodsDraft(id) {
  return _fetch.default.post("/Supplier/Goods/batchDeleteGoodsDraft", {
    goods_id: id
  }, true);
}
// 商品分享
function goodsShare(id) {
  return _fetch.default.get("/Supplier/Goods/goodsShare", {
    goods_id: id
  });
}
// 查看商品详情
function getGoodsInfo(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsInfo", {
    goods_id: id
  });
}

// 获取商品附加说明
function getGoodsExplain(params) {
  return _fetch.default.post("/Supplier/Goods/getGoodsExplain", params);
}
// 保存商品附加说明
function saveGoodsExplain(params) {
  return _fetch.default.post("/Supplier/Goods/saveGoodsExplain", params, true);
}
// 编辑商品排序
function editSort(params) {
  return _fetch.default.post("/Supplier/Goods/editSort", params, true);
}

// 获取增值服务列表
function getServiceList(params) {
  return _fetch.default.get("/Supplier/MoreSer/get", params);
}
// 编辑增值服务
function editServiceInfo(params) {
  return _fetch.default.post("/Supplier/MoreSer/save", params, true);
}
// 删除增值服务
function delService(id) {
  return _fetch.default.post("/Supplier/MoreSer/del", {
    id: id
  }, true);
}
// 获取商品增值服务列表
function getGoodsServiceList(params) {
  return _fetch.default.get("/Supplier/MoreSer/getGoodsServiceList", params);
}