"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['item', 'order'],
  data: function data() {
    return {
      form: {
        orderId: "",
        whichPeriod: "",
        tradeNo: "",
        rent: "",
        spliteRent: ""
      },
      rules: {
        spliteRent: [{
          required: true,
          message: '金额不能为空'
        }]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      if (this.form.spliteRent < 0 || this.form.rent - this.form.spliteRent < 0) {
        this.$message({
          message: "拆分金额不对",
          type: "error"
        });
        return false;
      }
      var title = '';
      if (this.form.spliteRent < this.form.rent) {
        title = '注意:扣款金额小于租期金额，默认优惠处理，确认本期将无法代扣,是否继续确认';
      } else {
        title = '请确认已经足额线下收款，此操作后本期将无法代扣';
      }
      this.$confirm(title, '确认取消', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true
      }).then(function () {
        (0, _order.mayicancelPayOne)({
          orderId: _this.form.orderId,
          outTradeNo: _this.item.outTradeNo,
          lease_num: _this.item.lease_num,
          spliteRent: _this.form.spliteRent
        }).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('success', true);
        });
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.form.orderId = this.order.id;
        this.form.whichPeriod = this.item.lease_num;
        this.form.tradeNo = this.order.trade_no;
        this.form.rent = this.item.rent;
        this.form.spliteRent = this.item.rent;
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};