"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _global = _interopRequireDefault(require("../../utils/global"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _deductLog = _interopRequireDefault(require("./deductLog"));
var _editBillstatus = _interopRequireDefault(require("./editBillstatus"));
var _finance = require("@/api/finance");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    deductLog: _deductLog.default,
    editBillstatus: _editBillstatus.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: "",
        orderStatus: "",
        goodsName: "",
        receivingName: "",
        leaseTerm: "",
        orderCreateTime: "",
        leaseTime: "",
        orderPayTime: ""
      },
      statusList: _global.default.orderStatus,
      selectList: [],
      tableData: [],
      total: 0,
      selectedItem: {},
      payData: {}
    };
  },
  methods: {
    editStatus: function editStatus(item, leaseItem) {
      var _this = this;
      if (leaseItem.status == 0) {
        (0, _finance.getOfflinePayData)(item.order_id, leaseItem.id).then(function (res) {
          _this.selectedItem = _objectSpread({}, item);
          _this.selectedItem.leaseId = leaseItem.id;
          _this.payData = _objectSpread({}, res);
          _this.$refs.editStatusLog.dialogStatus = true;
        }).catch(function (err) {});
      }
    },
    autoChange: function autoChange(orderId, leaseId, withholdStatus) {
      var _this2 = this;
      (0, _finance.withholdSwitch)({
        orderId: orderId,
        leaseId: leaseId,
        withholdStatus: withholdStatus
      }).then(function (res) {
        _this2.getList(true);
      });
    },
    viewLog: function viewLog(item, leaseId) {
      this.selectedItem = _objectSpread({}, item);
      this.selectedItem.leaseId = leaseId;
      this.$refs.deductLog.dialogVisible = true;
    },
    // 发起代扣
    withholdItem: function withholdItem(orderId, leaseId) {
      var _this3 = this;
      this.$confirm("您确定要发起代扣吗？", "发起代扣", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(function () {
        (0, _finance.manualWithholding)({
          orderId: orderId,
          leaseId: leaseId
        }).then(function (res) {
          setTimeout(function () {
            _this3.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: "orderList",
          params: {
            orderId: orderId
          }
        });
      }
    },
    getOrderStatusName: function getOrderStatusName(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.name : "";
    },
    getOrderStatusType: function getOrderStatusType(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.type : "warning";
    },
    // 获取数据
    getList: function getList(flag) {
      var _this4 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _finance.financeOrderList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.total = res.total || 0;
      });
    },
    // 查询
    search: function search() {
      var pickerVal1 = this.$refs.makeOrderPicker.getVal();
      var pickerVal2 = this.$refs.withholdPicker.getVal();
      var pickerVal3 = this.$refs.payPicker.getVal();
      if (pickerVal1 == "error" || pickerVal2 == "error" || pickerVal3 == "error") {
        return;
      }
      this.searchForm.orderCreateTime = pickerVal1;
      this.searchForm.leaseTime = pickerVal2;
      this.searchForm.orderPayTime = pickerVal3;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.makeOrderPicker) {
        this.$refs.makeOrderPicker.reset();
      }
      if (this.$refs.withholdPicker) {
        this.$refs.withholdPicker.reset();
      }
      if (this.$refs.payPicker) {
        this.$refs.payPicker.reset();
      }
      this.searchForm = {
        orderNo: "",
        orderStatus: "",
        goodsName: "",
        receivingName: "",
        leaseTerm: "",
        orderCreateTime: "",
        leaseTime: "",
        orderPayTime: ""
      };
      this.getList();
    },
    getSelect: function getSelect() {
      var _this5 = this;
      (0, _finance.getOrderStatus)().then(function (res) {
        var list = [];
        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }
        _this5.selectList = list || [];
      });
    },
    exportFile: function exportFile() {
      (0, _finance.orderListExport)(this.searchForm);
    }
  },
  mounted: function mounted() {
    this.getSelect();
    this.getList();
  }
};