"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['item'],
  data: function data() {
    return {
      form: {
        order_id: this.item.order_id,
        status: 0,
        remark: ''
      },
      rules: {
        // remark: [
        //   { required: true, message: '备注不能为空', trigger: 'blur' }
        // ]
      },
      dialogVisible: false
    };
  },
  methods: {
    getlist: function getlist() {
      var _this = this;
      // getOverdueLockByOrderId({}).t

      (0, _finance.getOverdueLockByOrderId)({
        id: this.item.order_id
      }).then(function (res) {
        _this.form.status = res.status;
        _this.form.remark = res.remark;
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.form.order_id = this.item.order_id;
      (0, _finance.saveOverdueLock)(this.form).then(function (res) {
        _this2.dialogVisible = false;
        _this2.$emit("getList");
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getlist();
      }
    }
  }
};