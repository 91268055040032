"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
var _global = _interopRequireDefault(require("../../utils/global"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _order = require("@/api/order");
var _checklease = _interopRequireDefault(require("./dialogs/checklease"));
var _setAddress = _interopRequireDefault(require("./dialogs/setAddress"));
var _setDevice = _interopRequireDefault(require("./dialogs/setDevice"));
var _setRemark = _interopRequireDefault(require("./dialogs/setRemark"));
var _checkRemark = _interopRequireDefault(require("./dialogs/checkRemark"));
var _checkFlow = _interopRequireDefault(require("./dialogs/checkFlow"));
var _delivery = _interopRequireDefault(require("./dialogs/delivery"));
var _receipt = _interopRequireDefault(require("./dialogs/receipt"));
var _authInfo = _interopRequireDefault(require("./dialogs/authInfo"));
var _checkBill = _interopRequireDefault(require("./dialogs/checkBill"));
var _checkLogistics = _interopRequireDefault(require("./dialogs/checkLogistics"));
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    myDatePicker: _myDatePicker.default,
    customPage: _customPage.default,
    checkLease: _checklease.default,
    setAddress: _setAddress.default,
    setDevice: _setDevice.default,
    setRemark: _setRemark.default,
    checkRemark: _checkRemark.default,
    checkFlow: _checkFlow.default,
    delivery: _delivery.default,
    receipt: _receipt.default,
    authInfo: _authInfo.default,
    checkBill: _checkBill.default,
    checkLogistics: _checkLogistics.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: '',
        goodsName: '',
        receivingName: '',
        receivingMobile: '',
        riskName: '',
        riskMobile: '',
        orderCreateTime: ''
      },
      statusList: _global.default.orderStatus,
      total: 0,
      tableData: [],
      activeName: 'all',
      tabList: [{
        id: 'all',
        name: '全部订单',
        count: 0
      }],
      selectedId: '',
      editItem: {}
    };
  },
  methods: {
    openTip: function openTip() {
      this.$alert('商家暂未开启风控', '提示', {
        confirmButtonText: '确定'
      }).then(function () {});
    },
    handleRefundOrder: function handleRefundOrder(tradeNo) {
      this.$router.push({
        name: 'refundList',
        params: {
          tradeNo: tradeNo
        }
      });
    },
    // 查看合同
    checkPact: function checkPact(url) {
      window.open(url);
    },
    // 查看物流
    checkLogistics: function checkLogistics(id) {
      this.selectedId = id;
      this.$refs.checkLogistics.dialogVisible = true;
    },
    // 查看已付账单详情
    checkPaidBill: function checkPaidBill(id, paid_rent) {
      if (this.$hasMethod('#checkBill') && paid_rent > 0) {
        this.selectedId = id;
        this.$refs.checkBill.dialogVisible = true;
      }
    },
    // 查看认证资料
    checkAuthInfo: function checkAuthInfo(item) {
      this.editItem = {
        idcard_back: item.idcard_back,
        idcard_front: item.idcard_front
      };
      this.$refs.authInfo.dialogVisible = true;
    },
    // 确认收货
    confirmReceipt: function confirmReceipt(id) {
      this.selectedId = id;
      this.$refs.receipt.dialogVisible = true;
    },
    // 订单发货
    openDelivery: function openDelivery(id) {
      this.selectedId = id;
      this.$refs.delivery.dialogVisible = true;
    },
    // 查看流水记录
    viewFlow: function viewFlow(trade_no) {
      this.selectedId = trade_no;
      this.$refs.checkFlow.dialogVisible = true;
    },
    // 查看客服备注
    viewRemark: function viewRemark(id) {
      this.selectedId = id;
      this.$refs.checkRemark.dialogVisible = true;
    },
    // 添加客服备注
    addRemark: function addRemark(id) {
      this.selectedId = id;
      this.$refs.setRemark.dialogVisible = true;
    },
    // 更改设备编号
    editDevice: function editDevice(item) {
      this.editItem = {
        orderId: item.id,
        deviceNumber: item.device_number ? item.device_number.split(',') : [],
        amount: item.amount
      };
      this.$refs.setDevice.dialogVisible = true;
    },
    // 修改地址
    editAddress: function editAddress(item) {
      this.editItem = {
        orderId: item.id,
        realname: item.realname,
        mobile: item.address_mobile,
        address: item.address,
        prov: item.prov,
        city: item.city,
        area: item.area,
        province: [item.prov, item.city, item.area]
      };
      this.$refs.setAddress.dialogVisible = true;
    },
    // 查看租期
    viewLease: function viewLease(id) {
      this.selectedId = id;
      this.$refs.checkLease.dialogVisible = true;
    },
    getOrderStatusType: function getOrderStatusType(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.type : 'warning';
    },
    getOrderStatusName: function getOrderStatusName(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.name : '';
    },
    // 获取数据
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _order.orderBuyOutList)(_objectSpread(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        orderStatusGroup: this.activeName
      })).then(function (res) {
        var list = res.list,
          count = res.count;
        _this.tableData = list.data || [];
        _this.total = list.total || 0;
        var _iterator = _createForOfIteratorHelper(_this.tabList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            item.count = count[item.id];
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.orderCreateTime = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = {
        orderNo: '',
        goodsName: '',
        receivingName: '',
        receivingMobile: '',
        riskName: '',
        riskMobile: '',
        orderCreateTime: ''
      };
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.orderCreateTime = pickerVal;
        (0, _order.orderBuyOutListExport)(_objectSpread(_objectSpread({}, this.searchForm), {}, {
          orderStatusGroup: this.activeName
        }));
      }
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    }
  },
  mounted: function mounted() {
    if (this.$route.params.orderId) {
      this.searchForm.orderNo = this.$route.params.orderId;
    }
    this.getList();
  }
};