var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex align-center justify-end custom_page" },
    [
      _c("span", { staticClass: "total" }, [
        _vm._v("共" + _vm._s(_vm.pageTotal) + "页"),
      ]),
      _c("span", { staticClass: "total" }, [
        _vm._v("共" + _vm._s(_vm.total) + "条"),
      ]),
      _vm.pageTotal > 1
        ? _c(
            "button",
            {
              staticClass: "page_btn",
              attrs: { type: "button" },
              on: { click: _vm.goFirst },
            },
            [_vm._v("首页")]
          )
        : _vm._e(),
      _c("el-pagination", {
        staticClass: "text-right",
        attrs: {
          "prev-text": "上一页",
          "next-text": "下一页",
          "page-size": _vm.pageSize,
          "page-sizes": _vm.pageList,
          total: _vm.total,
          background: "",
          layout: _vm.layout,
          "current-page": _vm.page,
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.page = $event
          },
          "update:current-page": function ($event) {
            _vm.page = $event
          },
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _vm.pageTotal > 1
        ? _c(
            "button",
            {
              staticClass: "page_btn",
              attrs: { type: "button" },
              on: { click: _vm.goEnd },
            },
            [_vm._v("尾页")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }