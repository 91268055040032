var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "supplier_view footer-page" },
    [
      _c(
        "el-form",
        {
          ref: "viewForm",
          staticClass: "demo-ruleForm",
          class: { edit_form: _vm.isEdit },
          attrs: { model: _vm.viewForm, rules: _vm.rules },
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "基本信息", name: "base" } },
                [
                  _c("p", { staticClass: "edit-header" }, [
                    _c("span", [_vm._v("店铺信息")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info_list" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("店铺头像:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("preview-img", {
                                        attrs: {
                                          "img-url": _vm.viewForm.photo,
                                        },
                                      })
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "photo" } },
                                        [
                                          _c("crop-upload", {
                                            attrs: {
                                              "show-type": "photo",
                                              "init-val": _vm.viewForm.photo,
                                              "wrapper-width": "95px",
                                              "wrapper-height": "95px",
                                            },
                                            on: {
                                              setVal: function (val) {
                                                return _vm.setUrl("photo", val)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("店铺名称:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.shopname)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "shopname" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.shopname,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "shopname",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.shopname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("经营项目:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.viewForm.category_name)
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "category_name" } },
                                        [
                                          _c("el-input", {
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.viewForm.category_name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "category_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "viewForm.category_name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("账户类型:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.viewForm.account_name)
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "account" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { clearable: "" },
                                              on: {
                                                change: _vm.accountTypeChange,
                                              },
                                              model: {
                                                value: _vm.viewForm.account,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.viewForm,
                                                    "account",
                                                    $$v
                                                  )
                                                },
                                                expression: "viewForm.account",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "融资账户",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "非融资账户",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("是否风控:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.viewForm.account_type_name)
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "account_type" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                disabled:
                                                  _vm.viewForm.account == 1,
                                              },
                                              model: {
                                                value:
                                                  _vm.viewForm.account_type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.viewForm,
                                                    "account_type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "viewForm.account_type",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "风控",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "不风控",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("发票类型:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.viewForm.faptype_name)
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "faptype" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { clearable: "" },
                                              model: {
                                                value: _vm.viewForm.faptype,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.viewForm,
                                                    "faptype",
                                                    $$v
                                                  )
                                                },
                                                expression: "viewForm.faptype",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "普通发票",
                                                  value: "1",
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "增值税发票",
                                                  value: "2",
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "普通发票+增值税发票",
                                                  value: "1,2",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("当前经营位置:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [_vm._v(_vm._s(_vm.shopcity))])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "shopcity" } },
                                        [
                                          _c("el-cascader", {
                                            attrs: {
                                              clearable: "",
                                              filterable: "",
                                              placeholder: "请选择当前经营位置",
                                              options: _vm.regionList,
                                              props: _vm.optionProps,
                                              separator: "-",
                                            },
                                            model: {
                                              value: _vm.viewForm.shopcity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "shopcity",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.shopcity",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("店铺联系人:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.people)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "people" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.people,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "people",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.people",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("联系人手机号:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.viewForm.contact_tel)
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "contact_tel" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.contact_tel,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "contact_tel",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "viewForm.contact_tel",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("客服电话:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.mobile)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "mobile" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.mobile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "mobile",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.mobile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("签署合同类型:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.viewForm.contract_type_name
                                          )
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "contract_type" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { clearable: "" },
                                              on: {
                                                change: _vm.contractTypeChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.viewForm.contract_type,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.viewForm,
                                                    "contract_type",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "viewForm.contract_type",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "蚂蚁智能合同",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "E签宝合同",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("签署方式:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.viewForm.sign_num_name)
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "sign_num" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                clearable: "",
                                                disabled:
                                                  _vm.viewForm.contract_type ==
                                                  1,
                                              },
                                              model: {
                                                value: _vm.viewForm.sign_num,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.viewForm,
                                                    "sign_num",
                                                    $$v
                                                  )
                                                },
                                                expression: "viewForm.sign_num",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "自营两方",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "供应商两方",
                                                  value: 2,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "三方",
                                                  value: 3,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [_vm._v("是否开启城市定位:")]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.viewForm.is_city_location_name
                                          )
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "is_city_location" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { clearable: "" },
                                              model: {
                                                value:
                                                  _vm.viewForm.is_city_location,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.viewForm,
                                                    "is_city_location",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "viewForm.is_city_location",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "开启",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "关闭",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("店铺邮箱:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.email)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "email" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.email,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "edit-header" }, [
                    _c("span", [_vm._v("公司信息")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info_list" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("公司名称:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.mechanism)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "mechanism" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.mechanism,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "mechanism",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.mechanism",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("工商注册号:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.companyid)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "companyid" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.companyid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "companyid",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.companyid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex area_item" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("公司所在区域:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [_vm._v(_vm._s(_vm.companyArea))])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "company_area" } },
                                        [
                                          _c("el-cascader", {
                                            attrs: {
                                              clearable: "",
                                              filterable: "",
                                              placeholder: "请选择公司所在区域",
                                              options: _vm.areaList,
                                              props: _vm.optionProps,
                                              separator: "-",
                                            },
                                            model: {
                                              value: _vm.viewForm.company_area,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "company_area",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "viewForm.company_area",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("公司地址:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.company)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "company" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.company,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "company",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.company",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("法人姓名:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.faname)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "faname" } },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              maxlength: 10,
                                            },
                                            model: {
                                              value: _vm.viewForm.faname,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "faname",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.faname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("法人身份证号:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.idcard)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "idcard" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.idcard,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "idcard",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.idcard",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("营业执照:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("preview-img", {
                                        attrs: {
                                          "img-url": _vm.viewForm.shopcard,
                                        },
                                      })
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "shopcard" } },
                                        [
                                          _c("crop-upload", {
                                            attrs: {
                                              "show-type": "photo",
                                              "init-val": _vm.viewForm.shopcard,
                                              "wrapper-width": "95px",
                                              "wrapper-height": "95px",
                                            },
                                            on: {
                                              setVal: function (val) {
                                                return _vm.setUrl(
                                                  "shopcard",
                                                  val
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("公司门头照:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("preview-img", {
                                        attrs: {
                                          "img-url": _vm.viewForm.doorphoto,
                                        },
                                      })
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "doorphoto" } },
                                        [
                                          _c("crop-upload", {
                                            attrs: {
                                              "show-type": "photo",
                                              "init-val":
                                                _vm.viewForm.doorphoto,
                                              "wrapper-width": "95px",
                                              "wrapper-height": "95px",
                                            },
                                            on: {
                                              setVal: function (val) {
                                                return _vm.setUrl(
                                                  "doorphoto",
                                                  val
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("公章图片:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("preview-img", {
                                        attrs: {
                                          "img-url": _vm.viewForm.seal_url,
                                        },
                                      })
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "seal_url" } },
                                        [
                                          _c("crop-upload", {
                                            attrs: {
                                              "show-type": "photo",
                                              "init-val": _vm.viewForm.seal_url,
                                              "wrapper-width": "95px",
                                              "wrapper-height": "95px",
                                            },
                                            on: {
                                              setVal: function (val) {
                                                return _vm.setUrl(
                                                  "seal_url",
                                                  val
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("身份证正面照:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("preview-img", {
                                        attrs: {
                                          "img-url": _vm.viewForm.faidcard,
                                        },
                                      })
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "faidcard" } },
                                        [
                                          _c("crop-upload", {
                                            attrs: {
                                              "show-type": "photo",
                                              "init-val": _vm.viewForm.faidcard,
                                              "wrapper-width": "95px",
                                              "wrapper-height": "95px",
                                            },
                                            on: {
                                              setVal: function (val) {
                                                return _vm.setUrl(
                                                  "faidcard",
                                                  val
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("身份证背面照:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("preview-img", {
                                        attrs: {
                                          "img-url": _vm.viewForm.faidcard_back,
                                        },
                                      })
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "faidcard_back" } },
                                        [
                                          _c("crop-upload", {
                                            attrs: {
                                              "show-type": "photo",
                                              "init-val":
                                                _vm.viewForm.faidcard_back,
                                              "wrapper-width": "95px",
                                              "wrapper-height": "95px",
                                            },
                                            on: {
                                              setVal: function (val) {
                                                return _vm.setUrl(
                                                  "faidcard_back",
                                                  val
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isEdit
                    ? [
                        _c("p", { staticClass: "edit-header" }, [
                          _c("span", [_vm._v("操作记录")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "info_list" },
                          [
                            _c(
                              "el-table",
                              { attrs: { data: _vm.tableData } },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "operation_content",
                                    label: "内容",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "operator",
                                    label: "操作人",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "operation_time",
                                    label: "操作时间",
                                    width: "200",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "提现信息", name: "withdraw" } },
                [
                  _c("p", { staticClass: "edit-header" }, [
                    _c("span", [_vm._v("支付宝信息")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info_list" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [_vm._v("支付宝姓名:")]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.payeename)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "payeename" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.payeename,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "payeename",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.payeename",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [_vm._v("支付宝账号:")]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(_vm.viewForm.alipayaccount)
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "alipayaccount" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.alipayaccount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "alipayaccount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "viewForm.alipayaccount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "增值服务信息", name: "service" } },
                [
                  _c("p", { staticClass: "edit-header" }, [
                    _c("span", [_vm._v("短信增值服务信息")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info_list" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("短信剩余数量:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(_vm._s(_vm.viewForm.sms_num)),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        { attrs: { prop: "sms_num" } },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value: _vm.viewForm.sms_num,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "sms_num",
                                                  $$v
                                                )
                                              },
                                              expression: "viewForm.sms_num",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("p", { staticClass: "edit-header" }, [
                    _c("span", [_vm._v("百融风控信息")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info_list" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [_vm._v("是否开启百融风控:")]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.viewForm.risk_credit_status == 1
                                              ? "开启"
                                              : "关闭"
                                          )
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        {
                                          staticClass: "guarantee_item",
                                          staticStyle: { "margin-bottom": "0" },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: {
                                                width: "100px",
                                                "margin-bottom": "10px",
                                              },
                                              attrs: { clearable: "" },
                                              model: {
                                                value:
                                                  _vm.viewForm
                                                    .risk_credit_status,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.viewForm,
                                                    "risk_credit_status",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "viewForm.risk_credit_status",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "开启",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "关闭",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("div", { staticClass: "flex" }, [
                              _c("label", [
                                _vm.isEdit
                                  ? _c(
                                      "span",
                                      { staticClass: "required-icon" },
                                      [_vm._v("*")]
                                    )
                                  : _vm._e(),
                                _vm._v("百融风控余量:"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex-1" },
                                [
                                  !_vm.isEdit
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.viewForm.risk_credit_amount
                                          )
                                        ),
                                      ])
                                    : _c(
                                        "el-form-item",
                                        {
                                          attrs: { prop: "risk_credit_amount" },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: { clearable: "" },
                                            model: {
                                              value:
                                                _vm.viewForm.risk_credit_amount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.viewForm,
                                                  "risk_credit_amount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "viewForm.risk_credit_amount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$hasMethod("#edit")
        ? _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              !_vm.isEdit
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.back()
                          },
                        },
                      },
                      [_vm._v("返回")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.isEdit = true
                          },
                        },
                      },
                      [_vm._v("修改信息")]
                    ),
                  ]
                : [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.cancelEdit },
                      },
                      [_vm._v("返回")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.submit },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }