"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        order_no: '',
        status: null
      },
      total: 0,
      tableData: []
    };
  },
  filters: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _order.getApplyWithholdList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
      });
    },
    // 发起扣款
    // withholdItem(row){
    //   console.log('row2',row)
    //   this.$confirm("您确定要发起扣款吗？", "发起扣款", {
    // 	confirmButtonText: '确定',
    // 	cancelButtonText: '取消'
    //   }).then(() => {
    // 	manualWithholding({
    // 	  orderId: row.order_id,
    // 	  leaseId: row.id
    // 	}).then(res => {
    // 	  this.getLease()
    // 	  setTimeout(() => {
    // 		this.$parent.getList(true);
    // 	  }, 1000);
    // 	})
    //   }).catch(() => { });
    // },
    // 通过审核
    passAudit: function passAudit(row) {
      var _this2 = this;
      console.log(row);
      this.$confirm('通过审核将给商户扣款权限，是否通过?', '通过审核', {
        confirmButtonText: '通过',
        cancelButtonText: '取消'
      }).then(function () {
        // manualWithholding({
        // 	orderId: row.order_id,
        // 	leaseId: row.lease_id
        // }).then(res => {
        // 	setTimeout(() => {
        // 		this.getList(true)
        // 	}, 1000)
        // })
        (0, _order.applyWithhold)({
          id: row.id,
          status: 1
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 拒绝审核
    rejectAudit: function rejectAudit(id) {
      var _this3 = this;
      this.$confirm('您确定要拒绝审核吗?', '拒绝审核', {
        confirmButtonText: '拒绝',
        cancelButtonText: '取消',
        confirmButtonClass: 'el-button--danger'
      }).then(function () {
        (0, _order.applyWithhold)({
          id: id,
          status: 2
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    jumpOrderList: function jumpOrderList(trade_no) {
      this.$router.push({
        path: '/order/list?orderId=' + trade_no
      });
    }
  }
};