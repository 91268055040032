"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _inOutBound = require("@/api/inOutBound");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id', 'editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        purchase_price: '',
        name: ''
      },
      defaultForm: {
        purchase_price: '',
        name: ''
      },
      rules: {
        purchase_price: [{
          required: true,
          message: '请输入车价值',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入归还名称',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.formData = Object.assign(this.formData, this.editItem);
      } else {
        this.formData = Object.assign(this.formData, this.defaultForm);
        this.$refs['formData'].resetFields();
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var param = {
            chassis_number: _this.id,
            purchase_price: _this.formData.purchase_price,
            name: _this.formData.name
          };
          (0, _inOutBound.returnInBound)(param).then(function (res) {
            _this.$emit('getList');
            _this.close();
            console.log('res', res);
          }).catch(function () {
            _this.close();
          });
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};