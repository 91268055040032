var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "备注记录", visible: _vm.dialogVisible, width: "1000px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "备注信息", prop: "remark" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作人员", prop: "username", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "时间", prop: "create_time", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "删除", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-button", {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-delete",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.delRemark(scope.row.id)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }