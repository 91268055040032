"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _stock = _interopRequireDefault(require("../components/stock"));
var _share = _interopRequireDefault(require("../components/share"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _setDialog = _interopRequireDefault(require("../components/setDialog"));
var _goods = require("@/api/goods");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['activeTab', 'tabCount'],
  components: {
    customPage: _customPage.default,
    stockDialog: _stock.default,
    shareDialog: _share.default,
    myDatePicker: _myDatePicker.default,
    setDialog: _setDialog.default
  },
  data: function data() {
    return {
      searchForm: {
        goods_name: '',
        cate_id: null,
        rent_type: null,
        is_relet: '',
        create_time: '',
        check_type: '',
        check_status: ''
      },
      total: 0,
      tableData: [],
      categoryList: [],
      selectIdList: []
    };
  },
  watch: {
    activeTab: function activeTab(val) {
      // 切换tab，重新获取列表
      this.$refs.customPage.page = 1;
      this.reset();
    }
  },
  mounted: function mounted() {
    this.getSelectList();
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _goods.getGoodsList)(_objectSpread(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        goods_status: this.activeTab
      })).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        for (var key in _this.tabCount) {
          _this.tabCount[key] = res.count[key];
        }
        _this.selectIdList = [];
      });
    },
    getSelectList: function getSelectList() {
      var _this2 = this;
      (0, _goods.getShopCategory)().then(function (res) {
        _this2.categoryList = res || [];
      });
    },
    view: function view(goodsId) {
      this.$router.push({
        name: 'goodsView',
        params: {
          goodsId: goodsId
        }
      });
    },
    // 分享
    share: function share(id) {
      this.$refs.shareDialog.goodsId = id;
      this.$refs.shareDialog.dialogVisible = true;
    },
    // 设置活动
    setActivity: function setActivity(id) {
      this.$refs.setDialog.goodsId = id;
      this.$refs.setDialog.dialogVisible = true;
    },
    // 审核取消
    cancelAudit: function cancelAudit(id, type) {
      var _this3 = this;
      var tip = '',
        title = '';
      if (type == 1) {
        tip = '取消商品审核后，审核中的商品将保存到商品草稿箱，确认要取消商品审核吗？';
        title = '取消商品审核';
      } else {
        tip = '取消下架审核后，将撤回下架审核申请，确认要取消商品审核吗？';
        title = '取消下架审核';
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _goods.goodsCancelReview)(id).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 提交审核
    submitAudit: function submitAudit(id) {
      var _this4 = this;
      this.$confirm('该商品未提审，确定提交审核？', '提交审核', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        if (_this4.activeTab == 3) {
          (0, _goods.itemDownShelf)(id).then(function (res) {
            _this4.getList(true);
          });
        } else {
          (0, _goods.goodsSubmitReview)(id).then(function (res) {
            _this4.getList(true);
          });
        }
      });
    },
    handleCommand: function handleCommand(command, goodsId) {
      if (command == 1) {
        this.$router.push({
          name: 'goodsBaseEdit',
          params: {
            goodsId: goodsId
          }
        });
      } else {
        this.$router.push({
          name: 'goodsMealInfo',
          params: {
            goodsId: goodsId
          }
        });
      }
    },
    // 更改库存
    editStock: function editStock(id) {
      this.$refs.stockDialog.editId = id;
      this.$refs.stockDialog.dialogVisible = true;
    },
    // 修改排序
    editSort: function editSort(item) {
      var _this5 = this;
      (0, _goods.editSort)({
        goods_id: item.id,
        goods_sort: item.goods_sort
      }).then(function (res) {
        _this5.getList(true);
      });
    },
    /**
     *@description 处理商品复制逻辑
     **/
    handleSupplierGoodsCopy: function handleSupplierGoodsCopy(id) {
      var _this6 = this;
      var tip = '是否确认复制商品?';
      var title = '复制';
      console.log('current goods', id);
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        // TODO
        (0, _goods.itemCopy)(id).then(function (res) {
          _this6.getList(true);
          // this.$message.success("制成功后的商品请前往已下架模块查看")
        });
      }).catch(function () {});
    },
    // 上架/下架
    /**
     * @description:status：商品当前的状态（上架、下架）;flag:当前商品是否有参与活动
     **/
    changeStatus: function changeStatus(id, status, flag) {
      var _this7 = this;
      var tip = '',
        title = '';
      if (status == 1 && flag) {
        tip = '该商品参与平台推荐活动，下架需通过平台审核。您确定要下架商品吗？';
        title = '下架';
      }
      if (status == 1) {
        tip = '您确定要下架商品吗？';
        title = '下架';
      } else {
        tip = '您确定要上架商品吗？';
        title = '上架';
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        if (status == 1) {
          (0, _goods.itemDownShelf)(id).then(function (res) {
            _this7.getList(true);
          });
        } else {
          (0, _goods.goodsGrounding)(id).then(function (res) {
            _this7.getList(true);
          });
        }
      }).catch(function () {});
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this8 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _goods.deleteGoods)(id).then(function (res) {
          _this8.getList(true);
        });
      }).catch(function () {});
    },
    // 查询
    search: function search() {
      if (this.activeTab != 3) {
        var pickerVal = this.$refs.myDatePicker.getVal();
        if (pickerVal !== 'error') {
          this.searchForm.create_time = pickerVal;
          this.$refs.customPage.page = 1;
          this.getList();
        }
      } else {
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = {
        goods_name: '',
        cate_id: null,
        rent_type: null,
        create_time: '',
        check_type: '',
        check_status: ''
      };
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this9 = this;
      // console.log('handleSelectionChange', val)
      if (val.length) {
        this.selectIdList = [];
        val.forEach(function (item) {
          _this9.selectIdList.push(item.id);
        });
        // console.log('selectIdList', this.selectIdList)
      }
    },
    // 批量上下架 0-上架,1-下架
    setStatus: function setStatus(status) {
      if (this.selectIdList.length == 0) {
        this.$message.error('请先选中商品');
      } else {
        var ids = this.selectIdList.join(',');
        // console.log('ids', ids)
        this.changeStatus(ids, status);
      }
    }
  }
};