"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inOutBound = require("@/api/inOutBound");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id', 'editItem'],
  data: function data() {
    return {
      btn_disabled: false,
      dialogVisible: false,
      formData: {
        repair_record: [],
        repair_remark: ''
      },
      defaultForm: {
        repair_record: [],
        repair_remark: ''
      },
      rules: {
        repair_record: [{
          required: true,
          message: '请选择维修部件',
          trigger: 'change'
        }]
      },
      repairList: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
        this.formData = Object.assign(this.formData, this.editItem);
        this.formData.repair_record = []; //维修记录只能增不能删除
      } else {
        this.$refs['formData'].resetFields();
        this.formData = Object.assign(this.formData, this.defaultForm);
      }
    }
  },
  methods: {
    repairChange: function repairChange(val) {
      console.log('维修选项', val);
      // this.formData.repair_record = val
    },
    init: function init() {
      this.getOptionList();
    },
    // 获取车架号列表和维修部件记录表
    getOptionList: function getOptionList() {
      var _this = this;
      (0, _inOutBound.getRepairList)().then(function (res) {
        _this.repairList = res;
        console.log('this.repairList', _this.repairList);
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var _this2$formData = _this2.formData,
            repair_remark = _this2$formData.repair_remark,
            repair_record = _this2$formData.repair_record,
            id = _this2$formData.id;
          var param = {
            repair_remark: repair_remark,
            repair_record: repair_record,
            id: id
          };
          if (param.repair_record) {
            param.repair_record = param.repair_record.join(',');
          }
          console.log('最终参数', param);
          _this2.btn_disabled = true;
          (0, _inOutBound.editRepair)(param).then(function (res) {
            _this2.$emit('getList');
            _this2.close();
            console.log('res', res);
          }).catch(function () {
            _this2.close();
          }).finally(function () {
            _this2.btn_disabled = false;
          });
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};