"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editAPaymentData = _interopRequireDefault(require("./dialog/editAPaymentData"));
var _editAPayPwd = _interopRequireDefault(require("./dialog/editAPayPwd.vue"));
var _apayment = require("@/api/apayment");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    editAPaymentData: _editAPaymentData.default,
    editAPayPwd: _editAPayPwd.default
  },
  data: function data() {
    return {
      searchForm: {
        pay_no: '',
        name: '',
        account: '',
        status: ''
      },
      tableData: [],
      total: 0,
      selectList: [],
      selectedItem: {}
    };
  },
  methods: {
    // 获取数据
    getList: function getList() {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _apayment.getAPaymentList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        pay_no: '',
        name: '',
        account: '',
        status: ''
      };
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      leaseListExport(this.searchForm);
    },
    addAPaymentData: function addAPaymentData() {
      this.selectedItem = {};
      this.$refs.editAPaymentData.dialogVisible = true;
    },
    saveAPaymentData: function saveAPaymentData(item) {
      this.selectedItem = item;
      this.$refs.editAPaymentData.dialogVisible = true;
    },
    delAPayment: function delAPayment(id) {
      var _this2 = this;
      this.$confirm("您确定要删除记录吗？", "删除信息", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _apayment.delAPayment)({
          id: id
        }).then(function (res) {
          setTimeout(function () {
            _this2.getList();
          }, 1000);
        });
      }).catch(function () {});
    },
    handlePayPwd: function handlePayPwd(id) {
      this.selectedItem = id;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editAPayPwd.dialogVisible = true;
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};