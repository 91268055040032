"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _colForm = _interopRequireDefault(require("../components/colForm"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    colForm: _colForm.default
  },
  data: function data() {
    return {
      configData: {
        backgroundImg: '',
        title: '',
        subtitle: '',
        jumpUrl: '',
        min_price: ''
      }
    };
  }
};