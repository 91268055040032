var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tab-title-page" }, [
    _c(
      "div",
      { staticClass: "child_page" },
      [
        _c("mianInfo", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeName === "main",
              expression: "activeName==='main'",
            },
          ],
          ref: "mianInfo",
          attrs: { id: _vm.id },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }