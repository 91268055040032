var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("div", { staticClass: "fixed-header" }, [_c("navbar")], 1),
      _vm.$route.name === "editPwd"
        ? _c("pwd-edit")
        : _c(
            "div",
            { staticClass: "main-content" },
            [
              _c("sidebar", { staticClass: "sidebar-container" }),
              _c(
                "div",
                { staticClass: "main-container" },
                [_c("top-nav"), _c("app-main")],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }