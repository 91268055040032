var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base_info" }, [
    _c(
      "div",
      { staticClass: "warn-box" },
      [
        _c("svg-icon", {
          staticStyle: { "margin-right": "17px" },
          attrs: { "icon-class": "warn", size: 20 },
        }),
        _c("p", [
          _vm._v("重要提示：店铺头像、店铺名称会直接展示给买家，请认真填写。"),
        ]),
      ],
      1
    ),
    _vm._m(0),
    _c("div", { staticClass: "flex flex-wrap list_info" }, [
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("店铺头像：")]),
        _c(
          "div",
          [
            _c("crop-upload", {
              ref: "cropUpload",
              attrs: {
                "show-type": "photo",
                "init-val": _vm.infoData.photo,
                "wrapper-width": "95px",
                "wrapper-height": "95px",
                "photo-width": 95,
                "photo-height": 95,
                hideDelete: !_vm.$hasMethod("#edit"),
              },
              on: { setVal: _vm.imgChange },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("店铺编号：")]),
        _c("p", [_vm._v(_vm._s(_vm.infoData.id))]),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("店铺名称：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.nameEdit
              ? _c("p", [_vm._v(_vm._s(_vm.infoData.shopname))])
              : _c("el-input", {
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "请输入店铺名称", clearable: "" },
                  model: {
                    value: _vm.infoData.shopname,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData, "shopname", $$v)
                    },
                    expression: "infoData.shopname",
                  },
                }),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.saveName },
                  },
                  [_vm._v(_vm._s(_vm.nameEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("经营项目：")]),
        _c("p", [_vm._v(_vm._s(_vm.infoData.category_name))]),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("账户类型：")]),
        _c("p", [_vm._v(_vm._s(_vm.infoData.account_name))]),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("是否开启风控：")]),
        _c("p", [_vm._v(_vm._s(_vm.infoData.account_type == 1 ? "是" : "否"))]),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("发票类型：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.invoiceEdit
              ? _c("p", [_vm._v(_vm._s(_vm.infoData.faptype_name))])
              : _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.infoData.faptype,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoData, "faptype", $$v)
                      },
                      expression: "infoData.faptype",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "普通发票", value: "1" },
                    }),
                    _c("el-option", {
                      attrs: { label: "增值税发票", value: "2" },
                    }),
                    _c("el-option", {
                      attrs: { label: "普通发票+增值税发票", value: "1,2" },
                    }),
                  ],
                  1
                ),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.saveInvoice },
                  },
                  [_vm._v(_vm._s(_vm.invoiceEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("当前经营位置：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.posEdit
              ? _c("p", [_vm._v(_vm._s(_vm.shopcity))])
              : _c("el-cascader", {
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择当前经营位置",
                    options: _vm.regionList,
                    props: _vm.optionProps,
                    separator: "-",
                  },
                  model: {
                    value: _vm.infoData.shopcity,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData, "shopcity", $$v)
                    },
                    expression: "infoData.shopcity",
                  },
                }),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.savePos },
                  },
                  [_vm._v(_vm._s(_vm.posEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("签署合同类型：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.contractEdit
              ? _c("p", [_vm._v(_vm._s(_vm.infoData.contract_type_name))])
              : _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.infoData.contract_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoData, "contract_type", $$v)
                      },
                      expression: "infoData.contract_type",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "蚂蚁智能合同", value: 1 },
                    }),
                    _c("el-option", {
                      attrs: { label: "E签宝合同", value: 2 },
                    }),
                  ],
                  1
                ),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.saveContract },
                  },
                  [_vm._v(_vm._s(_vm.contractEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("签署方式：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.signNumEdit
              ? _c("p", [_vm._v(_vm._s(_vm.infoData.sign_num_name))])
              : _c(
                  "el-select",
                  {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.infoData.sign_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.infoData, "sign_num", $$v)
                      },
                      expression: "infoData.sign_num",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "自营两方", value: 1 } }),
                    _c("el-option", {
                      attrs: { label: "供应商两方", value: 2 },
                    }),
                    _vm.infoData.contract_type != 1
                      ? _c("el-option", { attrs: { label: "三方", value: 3 } })
                      : _vm._e(),
                  ],
                  1
                ),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.saveSignNum },
                  },
                  [_vm._v(_vm._s(_vm.signNumEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _vm._m(1),
    _c("div", { staticClass: "flex flex-wrap list_info" }, [
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("店铺联系人：")]),
        _c("p", [_vm._v(_vm._s(_vm.infoData.people))]),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("联系人手机号：")]),
        _c("p", [_vm._v(_vm._s(_vm.infoData.contact_tel))]),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("店铺客服电话：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.telEdit
              ? _c("p", [_vm._v(_vm._s(_vm.infoData.mobile))])
              : _c("el-input", {
                  attrs: { clearable: "" },
                  on: {
                    input: function (val) {
                      return _vm.changeNumberInput(val)
                    },
                  },
                  model: {
                    value: _vm.infoData.mobile,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData, "mobile", $$v)
                    },
                    expression: "infoData.mobile",
                  },
                }),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.saveTel },
                  },
                  [_vm._v(_vm._s(_vm.telEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _vm._m(2),
    _c("div", { staticClass: "flex flex-wrap list_info" }, [
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("供应商域名：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.DomainSupplierEdit
              ? _c("p", [_vm._v(_vm._s(_vm.infoData.domain_supplier))])
              : _c("el-input", {
                  attrs: { clearable: "" },
                  on: {
                    input: function (val) {
                      return _vm.changeDomainSupplierInput(val)
                    },
                  },
                  model: {
                    value: _vm.infoData.domain_supplier,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData, "domain_supplier", $$v)
                    },
                    expression: "infoData.domain_supplier",
                  },
                }),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.saveDomainSupplier },
                  },
                  [_vm._v(_vm._s(_vm.DomainSupplierEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "list_item" }, [
        _c("label", [_vm._v("商户域名：")]),
        _c(
          "div",
          { staticClass: "flex align-center" },
          [
            !_vm.DomainShopEdit
              ? _c("p", [_vm._v(_vm._s(_vm.infoData.domain_shop))])
              : _c("el-input", {
                  attrs: { clearable: "" },
                  on: {
                    input: function (val) {
                      return _vm.changeDomainShopInput(val)
                    },
                  },
                  model: {
                    value: _vm.infoData.domain_shop,
                    callback: function ($$v) {
                      _vm.$set(_vm.infoData, "domain_shop", $$v)
                    },
                    expression: "infoData.domain_shop",
                  },
                }),
            _vm.$hasMethod("#edit")
              ? _c(
                  "span",
                  {
                    staticClass: "blue-font pointer",
                    on: { click: _vm.saveDomainShop },
                  },
                  [_vm._v(_vm._s(_vm.DomainShopEdit ? "保存" : "修改"))]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_title" }, [
      _c("p", [_vm._v("基础信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_title" }, [
      _c("p", [_vm._v("联系方式")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "label_title" }, [
      _c("p", [_vm._v("独立域名设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }