"use strict";

require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _merchant = require("@/api/merchant");
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["list", "editItem"],
  data: function data() {
    return {
      dialogVisible: false,
      dialogTitle: '经营项目',
      form: {
        id: 0,
        category_ids: ""
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.$refs['form'].resetFields();
          if (_this.editItem) {
            // 编辑
            _this.form.id = _this.editItem.id;
            _this.form.category_ids = _this.editItem.category_ids;
            var list = _this.editItem.category_ids.split(',');
            _this.$refs.tree.setCheckedKeys(list);
          }
        });
      } else {
        this.$refs.tree.setCheckedKeys([]);
        this.form = {
          category_ids: ""
        };
      }
    }
  },
  methods: {
    checkClick: function checkClick(data, checkObj) {
      var check = checkObj.checkedKeys.indexOf(data.id) > -1;
      // 如果有子节点，勾选子节点
      if (check && data.children) {
        this.checkChildren(data.children);
      }
    },
    checkChange: function checkChange(data, check) {
      // 父节点操作
      if (data.parentid != 0) {
        if (check === true) {
          // 如果选中，设置父节点为选中
          this.$refs.tree.setChecked(data.parentid, true);
        } else {
          // 如果取消选中，检查父节点是否该取消选中（可能仍有子节点为选中状态）
          var parentNode = this.$refs.tree.getNode(data.parentid);
          var parentHasCheckedChild = false;
          for (var i = 0, parentChildLen = parentNode.childNodes.length; i < parentChildLen; i++) {
            if (parentNode.childNodes[i].checked === true) {
              parentHasCheckedChild = true;
              break;
            }
          }
          if (!parentHasCheckedChild) this.$refs.tree.setChecked(data.parentid, false);
        }
      }
      // 子节点操作，如果取消选中，取消子节点选中
      if (data.children != null && check === false) {
        for (var j = 0, len = data.children.length; j < len; j++) {
          var childNode = this.$refs.tree.getNode(data.children[j].id);
          if (childNode.checked === true) {
            this.$refs.tree.setChecked(childNode.data.id, false);
          }
        }
      }
    },
    checkChildren: function checkChildren(childList) {
      var _iterator = _createForOfIteratorHelper(childList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          this.$refs.tree.setChecked(item.id, true);
          if (item.children) {
            this.checkChildren(item.children);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    submit: function submit() {
      var _this2 = this;
      var selectList = this.$refs.tree.getCheckedKeys();
      this.form.category_ids = selectList.join(',');
      (0, _merchant.editMerchantCategoryIds)(this.form).then(function (res) {
        _this2.dialogVisible = false;
        _this2.$emit("getList");
        _this2.$message.success('操作成功');
      });
    }
  }
};