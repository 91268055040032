"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _goodsAdd = _interopRequireDefault(require("./goodsAdd"));
var _nineGrid = _interopRequireDefault(require("./nineGrid.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    goodsAdd: _goodsAdd.default,
    cropUpload: _cropUpload.default,
    nineGrid: _nineGrid.default
  },
  props: ['goodsNum', 'levelName', 'compFormData', 'imgWidth', 'imgHeight'],
  data: function data() {
    return {
      formData: {
        main_title: '',
        background_image: '',
        background_color: '#FFFFFF',
        goods: [],
        is_nineGrid: false,
        is_editShopList: false
      },
      exam_list: [{
        img: '',
        title: '',
        desc: '',
        time: '',
        phone: '',
        link: '',
        province: ''
      }],
      iconList: [],
      photoWidth: 359
    };
  },
  computed: {
    photoHeight: function photoHeight() {
      var height = 359 * this.imgHeight / this.imgWidth;
      return height;
    }
  },
  watch: {
    compFormData: function compFormData(val) {
      var _this = this;
      this.formData = _objectSpread({}, val);
      console.log('this.formData', this.formData);
      if (this.levelName == 'first_level') {
        this.formData.background_color = this.formData.background_color ? this.formData.background_color : '#FFFFFF';
      }
      if (this.levelName == 'first_level' && this.formData.is_nineGrid) {
        // 分割多张图片，将字符串用逗号分割成数组
        if (this.formData.is_nineGrid) {
          this.exam_list = this.formData.background_image.split(',').map(function (k, i) {
            return {
              img: k,
              title: "\u8F6E\u64AD\u56FE".concat(i + 1),
              jumpUrl: ''
            };
          });
        }
        this.$nextTick(function () {
          _this.$refs.nineGrid.iconList = _this.exam_list;
        });
      }
      // 编辑店铺列表
      if (this.levelName == 'second_level' && this.formData.is_editShopList) {
        this.formData.background_color = this.formData.background_color ? this.formData.background_color : '#FFFFFF';
        if (this.formData.is_editShopList) {
          this.exam_list = this.formData.exam_list;
        }
        console.log('this.exam_list', this.exam_list);
        this.$nextTick(function () {
          if (!_this.exam_list) {
            _this.exam_list = [{
              img: '',
              title: '',
              desc: '',
              time: '',
              phone: '',
              link: '',
              province: ''
            }];
          }
          _this.$refs.nineGrid2.iconList = _this.exam_list;
          console.log('this.$refs.nineGrid2.iconList', _this.$refs.nineGrid2.iconList);
        });
        this.iconList = this.exam_list;
      }
    }
  },
  mounted: function mounted() {
    console.log('mounted', this.levelName, this.formData.is_nineGrid, this.is_editShopList);
  },
  methods: {
    setGoods: function setGoods(list) {
      this.formData.goods = list;
    },
    getSubmitData: function getSubmitData() {
      console.log('this.formDatathis.formData', this.formData);
      var goodsidStr = '';
      if (this.formData.goods.length > 0) {
        if (!this.formData.main_title) {
          this.$message({
            message: '主标题不能为空',
            type: 'error'
          });
          return false;
        }
        goodsidStr += this.formData.goods.map(function (k) {
          return k.goods_id;
        }).join(',');
      }
      if (this.levelName == 'first_level' && this.formData.is_nineGrid) {
        this.formData.background_image = this.$refs.nineGrid.iconList.map(function (k) {
          return k.img;
        }).join(',');
      }
      if (this.levelName == 'second_level' && this.formData.is_editShopList) {
        this.formData.exam_list = this.$refs.nineGrid2.iconList;
      }
      var res = {
        level: this.levelName,
        background_image: this.formData.background_image,
        main_title: this.formData.main_title,
        background_color: this.formData.background_color,
        goodsidStr: goodsidStr
      };
      console.log('内容', this.formData.exam_list);
      if (this.levelName == 'second_level' && this.formData.is_editShopList) {
        res.exam_list = this.formData.exam_list;
      }
      console.log('resres', res);
      return res;
    }
  }
};