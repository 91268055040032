"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _finance = require("@/api/finance");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        user_id: "",
        trans_type: "",
        status: ""
      },
      amount: 0,
      service_fee: 0,
      total: 0,
      tableData: [],
      editItem: {},
      riskList: [{
        id: '1',
        name: '正常'
      }, {
        id: '2',
        name: '已退款'
      }],
      merchantList: [],
      transTypeList: [{
        rate_value: 1,
        rate_name: '推广分红'
      }, {
        rate_value: 2,
        rate_name: '提现'
      }]
    };
  },
  mounted: function mounted() {
    if (this.$route.params.userId) {
      this.searchForm.user_id = this.$route.params.userId;
    }
    this.getFromSearch();
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _finance.getMemberFlowList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        _this.service_fee = res.service_fee;
        _this.amount = res.amount;
        // console.log(this.tableData.length)
      });
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    goToOrderpUserId: function goToOrderpUserId(pUserId) {
      if (pUserId) {
        this.$router.push({
          name: 'orderList',
          params: {
            pUserId: pUserId
          }
        });
      }
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      var searchForm = this.$options.data().searchForm;
      this.searchForm = _objectSpread({}, searchForm);
      this.getList();
    },
    getFromSearch: function getFromSearch() {}
  }
};