var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer-page goods_info" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "base" },
        [
          _c("h5", { staticClass: "blue-font" }, [_vm._v("1、商品基础信息")]),
          _vm._l(_vm.baseMeta, function (item, index) {
            return _c("div", { key: index, staticClass: "flex info_item" }, [
              _c("label", [_vm._v(_vm._s(item.label))]),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  item.name === "convey_type"
                    ? [
                        _c("p", [
                          _vm._v(
                            "寄出" +
                              _vm._s(item.convey_type1) +
                              "-归还" +
                              _vm._s(item.convey_type2)
                          ),
                        ]),
                      ]
                    : item.name === "service_province"
                    ? [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.goodsInfo.province) +
                              "，" +
                              _vm._s(_vm.goodsInfo.city)
                          ),
                        ]),
                      ]
                    : item.name === "imgurl"
                    ? [
                        _c(
                          "el-image",
                          {
                            staticStyle: { width: "120px", height: "120px" },
                            attrs: {
                              src: _vm.goodsInfo.imgurl
                                ? _vm.$globalObj.imgBaseUrl +
                                  _vm.goodsInfo.imgurl
                                : "",
                              "preview-src-list": [
                                _vm.$globalObj.imgBaseUrl +
                                  _vm.goodsInfo.imgurl,
                              ],
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    : item.name === "carousel_images"
                    ? _vm._l(
                        _vm.goodsInfo.carousel_images,
                        function (img, index) {
                          return _c(
                            "el-image",
                            {
                              key: index,
                              staticStyle: {
                                width: "120px",
                                height: "120px",
                                "margin-right": "10px",
                              },
                              attrs: {
                                src: img ? _vm.$globalObj.imgBaseUrl + img : "",
                                "preview-src-list": [
                                  _vm.$globalObj.imgBaseUrl + img,
                                ],
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "image-slot",
                                  attrs: { slot: "error" },
                                  slot: "error",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-picture-outline",
                                  }),
                                ]
                              ),
                            ]
                          )
                        }
                      )
                    : item.name === "goods_content"
                    ? [
                        _c("p", {
                          staticClass: "detail",
                          domProps: {
                            innerHTML: _vm._s(_vm.goodsInfo.goods_content),
                          },
                        }),
                      ]
                    : item.name === "attribute"
                    ? _vm._l(_vm.goodsInfo.attribute, function (attr, index) {
                        return _c(
                          "p",
                          { key: index, staticClass: "attr_item" },
                          [
                            _c("span", [
                              _vm._v(_vm._s(attr.field_title) + ":"),
                            ]),
                            _vm._v(
                              _vm._s(attr.field_val) +
                                "\n                        "
                            ),
                          ]
                        )
                      })
                    : [_c("p", [_vm._v(_vm._s(_vm.goodsInfo[item.name]))])],
                ],
                2
              ),
            ])
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "meal" },
        [
          _c("h5", { staticClass: "blue-font" }, [_vm._v("2、商品价格与参数")]),
          _c("div", { staticClass: "meal_item" }, [
            _c("div", { staticClass: "flex info_item" }, [
              _c("label", { staticClass: "flex align-center flex-end" }, [
                _vm._v("价格系数"),
              ]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { placeholder: "价格系数" },
                    on: { change: _vm.chkPriceRate },
                    model: {
                      value: _vm.goodsInfo.price_rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsInfo, "price_rate", $$v)
                      },
                      expression: "goodsInfo.price_rate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "flex align-center",
                  staticStyle: { "padding-left": "10px" },
                },
                [_vm._v("(仅用于下方价格校验参考,无法保存,默认1.25)")]
              ),
            ]),
          ]),
          _vm._l(_vm.goodsInfo.setmeal, function (meal) {
            return _c(
              "div",
              { key: meal.id, staticClass: "meal_item" },
              _vm._l(_vm.mealMeta, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "flex info_item" },
                  [
                    _c("label", [_vm._v(_vm._s(item.label))]),
                    _c(
                      "div",
                      { staticClass: "flex-1" },
                      [
                        item.name === "lease_set"
                          ? [
                              meal.lease_set != 3
                                ? _c(
                                    "table",
                                    {
                                      staticClass: "lease_table",
                                      attrs: { cellspacing: "0" },
                                    },
                                    [
                                      _c(
                                        "tr",
                                        [
                                          _c("th", [_vm._v("租期")]),
                                          _vm._l(meal.spec, function (lease) {
                                            return _c("th", { key: lease.id }, [
                                              _vm._v(_vm._s(lease.lease_time)),
                                            ])
                                          }),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "tr",
                                        [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                meal.lease_set == 1
                                                  ? "月租金"
                                                  : "日租金"
                                              )
                                            ),
                                          ]),
                                          _vm._l(meal.spec, function (lease) {
                                            return _c("td", { key: lease.id }, [
                                              _vm._v(_vm._s(lease.lease_price)),
                                            ])
                                          }),
                                        ],
                                        2
                                      ),
                                    ]
                                  )
                                : _c(
                                    "el-table",
                                    {
                                      staticClass: "set_table",
                                      attrs: { data: meal.spec, border: "" },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "期数",
                                          width: "100px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      "第" +
                                                        _vm._s(
                                                          scope.row.which_period
                                                        ) +
                                                        "期"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "租赁天数",
                                          prop: "lease_days",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "租期间隔",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.withhold_cycle
                                                      ) + "天"
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "每期租金",
                                          width: "200",
                                          prop: "lease_price",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ]
                          : item.name === "huabei_data"
                          ? [
                              meal.huabei_data
                                ? _c(
                                    "el-table",
                                    {
                                      staticClass: "set_table",
                                      attrs: {
                                        data: meal.huabei_data,
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "租期设置",
                                          width: "100px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.lease_term
                                                          ? scope.row
                                                              .lease_term +
                                                              (meal.lease_set ==
                                                              1
                                                                ? "个月"
                                                                : "天")
                                                          : ""
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "花呗总金额",
                                          prop: "rent_total",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "花呗3期承担-承担金额",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        scope.row.three_bear
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        scope.row.three_fee
                                                          ? "￥" +
                                                              scope.row
                                                                .three_fee
                                                          : ""
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "花呗6期承担-承担金额",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        scope.row.six_bear
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        scope.row.six_fee
                                                          ? "￥" +
                                                              scope.row.six_fee
                                                          : ""
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "花呗12期承担-承担金额",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        scope.row.twelve_bear
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        scope.row.twelve_fee
                                                          ? "￥" +
                                                              scope.row
                                                                .twelve_fee
                                                          : ""
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : item.name === "title"
                          ? [
                              _c("p", [
                                _vm._v(_vm._s(_vm.getSpecLable(meal)) + " / "),
                                _c("span", { staticClass: "color_red" }, [
                                  _vm._v(
                                    "市场价格: " +
                                      _vm._s(
                                        (
                                          parseFloat(meal.price) /
                                          _vm.goodsInfo.price_rate
                                        ).toFixed(2)
                                      )
                                  ),
                                ]),
                              ]),
                            ]
                          : [_c("p", [_vm._v(_vm._s(meal[item.name]))])],
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            )
          }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "page-footer-btns" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }