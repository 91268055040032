var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "goods_dialog",
      attrs: {
        width: _vm.dialogWidth,
        title: "选择商品",
        visible: _vm.dialogVisible,
        "append-to-body": _vm.appendToBody,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "flex main_wrapper" }, [
        _c(
          "div",
          { staticClass: "flex-1" },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-form-inline",
                attrs: { inline: true, model: _vm.searchForm },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "商品ID或名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入商品ID或名称" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.search($event)
                        },
                      },
                      model: {
                        value: _vm.searchForm.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "title", $$v)
                        },
                        expression: "searchForm.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.search },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.clearSearch },
                      },
                      [_vm._v("重置")]
                    ),
                    _vm._t("btns"),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex flex-wrap goods_list" },
              [
                _vm._l(_vm.goodsList, function (item) {
                  return [
                    _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "flex goods_item",
                        class: { active: _vm.isSelect(item.id) },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(item)
                          },
                        },
                      },
                      [
                        _vm.isSelect(item.id)
                          ? _c("svg-icon", {
                              staticClass: "check_icon",
                              attrs: { "icon-class": "check", size: 10 },
                            })
                          : _vm._e(),
                        _c("el-image", {
                          attrs: {
                            src: _vm.$globalObj.imgBaseUrl + item.imgurl,
                            fit: "fill",
                          },
                        }),
                        _c("div", { staticClass: "line-block" }, [
                          _c("p", { staticClass: "goods_title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c("p", { staticClass: "goods_id" }, [
                            _vm._v("ID: " + _vm._s(item.id)),
                          ]),
                          _c(
                            "p",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showCate,
                                  expression: "showCate",
                                },
                              ],
                              staticClass: "goods_id",
                            },
                            [_vm._v("分类: " + _vm._s(item.cate_name))]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.searchForm.page,
                    "page-size": _vm.pageSize,
                    layout: "total, prev, pager, next",
                    total: _vm.total,
                    "prev-text": "上一页",
                    "next-text": "下一页",
                    background: "",
                  },
                  on: {
                    "current-change": _vm.handleCurrentChange,
                    "update:currentPage": function ($event) {
                      return _vm.$set(_vm.searchForm, "page", $event)
                    },
                    "update:current-page": function ($event) {
                      return _vm.$set(_vm.searchForm, "page", $event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "checked_wrap" },
          [
            _c("p", { staticStyle: { "margin-bottom": "30px" } }, [
              _vm._v("已选商品（" + _vm._s(_vm.selectItems.length)),
              _vm.maxNum
                ? _c("span", [_vm._v("/" + _vm._s(_vm.maxNum))])
                : _vm._e(),
              _vm._v("）"),
            ]),
            _vm._l(_vm.selectItems, function (sItem, index) {
              return [
                _c("goods-link", {
                  key: sItem.id,
                  ref: "goodsLink",
                  refInFor: true,
                  attrs: {
                    "show-cate": _vm.showCate,
                    "goods-data": sItem,
                    "show-sort": _vm.multiple,
                    "show-tool": _vm.showCheckedTool,
                  },
                  on: {
                    handleDelete: function ($event) {
                      return _vm.deleteGoods(index)
                    },
                    upSort: function ($event) {
                      return _vm.upSort(index)
                    },
                    downSort: function ($event) {
                      return _vm.downSort(index)
                    },
                  },
                }),
              ]
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }