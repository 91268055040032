"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _index = require("@/api/index");
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      title: '详情'
    };
  },
  mounted: function mounted() {
    // this.getRegion()
  },
  methods: {
    // getRegion() {
    // 	console.log(123123123);
    // 	console.log(this.editItem);
    // 	this.value = this.editItem;
    // 	},
  }
};