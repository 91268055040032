"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['item'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {},
      defaultForm: {
        evidence_url: '',
        merchant_remark: ''
      },
      rules: {
        evidence_url: [{
          required: true,
          message: '请上传图片',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        console.log('item', this.item);
        if (0) {} else {
          this.formData = this.$func.deepClone(this.defaultForm);
        }
      } else {
        this.$refs['formData'].resetFields();
      }
    }
  },
  methods: {
    setUrl: function setUrl(val) {
      this.formData.evidence_url = val;
    },
    submit: function submit() {
      var _this = this;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var param = _objectSpread({
            order_id: _this.item.order_id,
            lease_id: _this.item.id
          }, _this.formData);
          console.log('param', param);
          (0, _order.applyWithhold)(param).then(function (res) {
            _this.$emit('success');
            _this.close();
          }).catch(function () {});
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};