"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.sub");
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _index = require("@/api/index");
var _shop = require("@/api/shop");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editId'],
  data: function data() {
    return {
      form: {
        provinceArr: '',
        address: '',
        realname: '',
        mobile: '',
        isdefault: 2
      },
      rules: {
        provinceArr: [{
          required: true,
          message: '请选择所在地址',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '地址详细信息不能为空',
          trigger: 'blur'
        }],
        realname: [{
          required: true,
          message: '收货人姓名不能为空',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '手机号码不能为空',
          trigger: 'blur'
        }]
      },
      areaList: [],
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      }
    };
  },
  methods: {
    submitForm: function submitForm() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var params = _objectSpread({}, _this.form);
          params.province = params.provinceArr[0];
          params.city = params.provinceArr[1];
          params.area = params.provinceArr[2];
          delete params.provinceArr;
          if (_this.editId) {
            params.id = _this.editId;
          }
          (0, _shop.addOrEditReturnAddress)(params).then(function (res) {
            _this.$router.push({
              name: "shopReturnAd"
            });
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    resetForm: function resetForm() {
      this.$refs['form'].resetFields();
      if (this.editId) {
        this.getData();
      } else {
        this.form = {
          provinceArr: '',
          address: '',
          realname: '',
          mobile: '',
          isdefault: 2
        };
      }
    },
    handleMobileChange: function handleMobileChange() {
      this.form.mobile = this.form.mobile.replace(/[^\d]/g, "");
    },
    getSelect: function getSelect() {
      var _this2 = this;
      (0, _index.getRegion)().then(function (res) {
        _this2.areaList = res ? res[0].sub : [];
      });
    },
    getData: function getData() {
      var _this3 = this;
      (0, _shop.returnAddressById)(this.editId).then(function (res) {
        var province = res.province,
          city = res.city,
          area = res.area,
          address = res.address,
          realname = res.realname,
          mobile = res.mobile,
          isdefault = res.isdefault;
        _this3.form.provinceArr = [province, city, area];
        _this3.form.address = address;
        _this3.form.realname = realname;
        _this3.form.mobile = mobile;
        _this3.form.isdefault = isdefault;
      });
    }
  },
  mounted: function mounted() {
    this.getSelect();
    if (this.editId) {
      this.$route.meta.title = "编辑归还地址";
      this.getData();
    } else {
      this.$route.meta.title = "添加归还地址";
    }
  }
};