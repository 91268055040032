var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "拒绝接单信息",
        visible: _vm.dialogVisible,
        width: "1000px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                align: "center",
                label: "订单",
                prop: "trade_no",
                width: "210px",
              },
            },
            [_vm._v(_vm._s(this.orderNo))]
          ),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "商户名",
              prop: "merchant_name",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "拒单员",
              prop: "merchant_child_name",
              width: "160px",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "商户备注", prop: "memo" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "拒绝时间",
              prop: "createtime",
              width: "170px",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }