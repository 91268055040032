var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        { staticClass: "mychart" },
        [
          _c(
            "div",
            { staticClass: "common_form_search" },
            [
              _c(
                "el-form",
                {
                  ref: "searchForm",
                  attrs: { inline: true, model: _vm.searchForm },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "日期" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "390px" },
                        attrs: {
                          type: "datetimerange",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.createTime,
                          callback: function ($$v) {
                            _vm.createTime = $$v
                          },
                          expression: "createTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.search()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "100%", margin: "10px" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "header" }, [
                      _c("h3", [_vm._v("数据概括")]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      [
                        _c("el-statistic", {
                          attrs: {
                            "group-separator": ",",
                            value:
                              _vm.tableDataComp.veh_inventory_info.total_num,
                            title: "车辆库存",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "statistic" },
                      [
                        _c(
                          "el-statistic",
                          { attrs: { title: "库存总价值" } },
                          [
                            _c("template", { slot: "formatter" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.tableDataComp.veh_inventory_info
                                      .total_amount
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-right": "3rem" },
                            attrs: {
                              size: "mini",
                              type: "success",
                              icon: "el-icon-view",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.jumpRoute("vel")
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      [
                        _c("el-statistic", {
                          attrs: {
                            "group-separator": ",",
                            value:
                              _vm.tableDataComp.part_inventory_info.total_num,
                            title: "配件库存",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "statistic" },
                      [
                        _c(
                          "el-statistic",
                          { attrs: { title: "配件总价值" } },
                          [
                            _c("template", { slot: "formatter" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.tableDataComp.part_inventory_info
                                      .total_amount
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-right": "3rem" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-view",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.jumpRoute("part")
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c("el-divider", { attrs: { direction: "vertical" } }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-statistic",
                          { attrs: { value: 33, title: "总出租车辆数量" } },
                          [
                            _c("template", { slot: "suffix" }, [
                              _c("i", {
                                staticClass: "el-icon-s-flag",
                                staticStyle: { color: "blue" },
                              }),
                            ]),
                            _c("template", { slot: "formatter" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.tableDataComp.total_vehicle_cost
                                      .total_count
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-statistic",
                          { attrs: { title: "总成本" } },
                          [
                            _c("template", { slot: "suffix" }, [
                              _c("i", {
                                staticClass: "el-icon-s-flag",
                                staticStyle: { color: "rgb(48, 198, 14)" },
                              }),
                            ]),
                            _c("template", { slot: "formatter" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.tableDataComp.total_vehicle_cost
                                      .origin_amount_total
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "100%", margin: "10px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-statistic",
                          {
                            attrs: {
                              "group-separator": ",",
                              precision: 2,
                              "decimal-separator": ".",
                              value: _vm.rent_data.day_amount,
                              title: "总出租车辆成本 / 利润",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _c("i", {
                                staticClass: "el-icon-s-flag",
                                staticStyle: { color: "red" },
                              }),
                            ]),
                            _c("template", { slot: "formatter" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.tableDataComp.total_vehicle_cost
                                      .purchase_total
                                      ? _vm.tableDataComp.total_vehicle_cost.purchase_total.toFixed(
                                          2
                                        )
                                      : 0
                                  ) +
                                  " /\n                            " +
                                  _vm._s(
                                    _vm.tableDataComp.total_vehicle_cost
                                      .profit_total
                                      ? _vm.tableDataComp.total_vehicle_cost.profit_total.toFixed(
                                          2
                                        )
                                      : 0
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                            _c("template", { slot: "suffix" }, [
                              _c("i", {
                                staticClass: "el-icon-s-flag",
                                staticStyle: { color: "blue" },
                              }),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "my_row", attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "hover" } },
                    [
                      _c(
                        "el-descriptions",
                        {
                          attrs: {
                            title: "出租统计",
                            "label-class-name": "my-label",
                            "content-class-name": "my-content",
                          },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "出租车辆" } },
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: { size: "small", type: "prima124ry" },
                                },
                                [_vm._v(_vm._s(_vm.tableDataRent.rent_total))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "车辆收租金额(包含提前支付)" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v(_vm._s(_vm.tableDataRent.day_amount))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "归还" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v(_vm._s(_vm.tableDataRent.return_total))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "租金到期已付" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tableDataRent.rent_due_data
                                        .rent_due_paid
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "租金到期未付" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tableDataRent.rent_due_data
                                        .rent_due_unpaid
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          false
            ? _c(
                "el-row",
                { staticClass: "my_row", attrs: { gutter: 16 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-card",
                        { attrs: { shadow: "hover" } },
                        [
                          _c(
                            "el-descriptions",
                            { attrs: { title: "出库车辆统计" } },
                            [
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "租用中" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "small" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tableDataComp
                                              .total_vehicle_count.renting_count
                                          )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "已归还" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "small" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tableDataComp
                                              .total_vehicle_count.return_count
                                          )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "已购买" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "small" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tableDataComp
                                              .total_vehicle_count
                                              .purchase_count
                                          )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "已买断" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "small" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tableDataComp
                                              .total_vehicle_count.buyout_count
                                          )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-card",
                        { attrs: { shadow: "hover" } },
                        [
                          _c(
                            "el-descriptions",
                            { attrs: { title: "配件库存展示" } },
                            [
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "配件库存" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "small" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tableDataComp
                                              .part_inventory_info.total_num
                                          )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "库存总价值" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { type: "success", size: "small" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.tableDataComp
                                              .part_inventory_info.total_amount
                                          )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { staticClass: "my_row", attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "hover" } },
                    [
                      _c(
                        "el-descriptions",
                        { attrs: { title: "卖出统计" } },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "卖出车辆" } },
                            [
                              _c(
                                "el-tag",
                                {
                                  attrs: { size: "small", type: "prima124ry" },
                                },
                                [_vm._v(_vm._s(_vm.tableDataSell.bought_total))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "卖出进货价" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v(_vm._s(_vm.tableDataSell.bought_price))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "卖出收入" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.tableDataSell.bought_income)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "hover" } },
                    [
                      _c(
                        "el-descriptions",
                        { attrs: { title: "维修统计" } },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "维修订单" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.tableDataRepair.repair_count)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "配件进货价" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tableDataRepair.total_purchase_price
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "维修收入" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tableDataRepair.total_repair_price
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "my_row", attrs: { gutter: 16 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "hover" } },
                    [
                      _c(
                        "el-descriptions",
                        { attrs: { title: "订单成交分佣" } },
                        [
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "分佣总金额" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.tableDataParcel.total_parcel_amount
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-descriptions-item",
                            { attrs: { label: "分佣订单数" } },
                            [
                              _c(
                                "el-tag",
                                { attrs: { size: "small", type: "primary" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.tableDataParcel.total_parcel_num)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              false
                ? _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-card",
                        { attrs: { shadow: "hover" } },
                        [
                          _c(
                            "el-descriptions",
                            { attrs: { title: "租金到期付款状态" } },
                            [
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "租金到期已付" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tableDataRent.rent_due_data
                                            .rent_due_paid
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-descriptions-item",
                                { attrs: { label: "租金到期未付" } },
                                [
                                  _c(
                                    "el-tag",
                                    {
                                      attrs: { size: "small", type: "primary" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tableDataRent.rent_due_data
                                            .rent_due_unpaid
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-chart", {
            ref: "chart",
            staticClass: "chart",
            attrs: { option: _vm.option },
            on: { click: _vm.jumpPage },
          }),
          _c("v-chart", {
            staticClass: "chart",
            attrs: { option: _vm.option2 },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }