var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    attrs: {
      clearable: "",
      filterable: "",
      placeholder: "请选择",
      options: _vm.areaData,
      props: _vm.optionProps,
      separator: "-",
    },
    model: {
      value: _vm.provinceModel,
      callback: function ($$v) {
        _vm.provinceModel = $$v
      },
      expression: "provinceModel",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }