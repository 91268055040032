var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "rate_info footer-page",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "main_content",
        },
        [
          _c("div", { staticClass: "label_title" }, [
            _vm.platform_exist
              ? _c("p", [_vm._v("黑白名单设置")])
              : _c("p", [
                  _vm._v("当前供应商未添加到浓度算法平台中，请联系管理员操作"),
                ]),
          ]),
          _vm.platform_exist
            ? _c(
                "div",
                { staticClass: "flex flex-wrap list_info" },
                [
                  _c("setWhiteBlackList", {
                    attrs: {
                      "platform-id": _vm.platformId,
                      "form-list": _vm.formList,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }