var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "office_index" }, [
    _c("div", { staticClass: "flex main" }, [
      _c("div", { staticClass: "left flex-1" }, [
        _c(
          "div",
          { staticClass: "main_form" },
          [
            _c(
              "div",
              { staticClass: "warn-box" },
              [
                _c("svg-icon", {
                  staticStyle: { "margin-right": "17px" },
                  attrs: { "icon-class": "warn", size: 20 },
                }),
                _c("p", [
                  _c("span", [_vm._v("图片要求！")]),
                  _c("br"),
                  _vm._v(
                    "图片宽度" +
                      _vm._s(_vm.imgWidth) +
                      "px，高度" +
                      _vm._s(_vm.imgHeight) +
                      "px。"
                  ),
                ]),
              ],
              1
            ),
            _c("crop-upload", {
              staticClass: "w-100 img_bg flex justify-center",
              attrs: {
                "show-type": "photo",
                "init-val": _vm.formData.background_image,
                "wrapper-width": _vm.photoWidth + "px",
                "wrapper-height": _vm.photoHeight + "px",
                "img-width": _vm.imgWidth,
                "img-height": _vm.imgHeight,
                "photo-width": _vm.photoWidth,
                "photo-height": _vm.photoHeight,
              },
              on: {
                setVal: function (val) {
                  _vm.formData.background_image = val
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input_wrapper text-center" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "small", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.addParam()
                  },
                },
              },
              [_vm._v("添加")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input_wrapper" },
          [
            _c("div", { staticClass: "flex name" }, [
              _c(
                "div",
                { staticClass: "flex label align-center justify-center" },
                [_vm._v(" 主标题\n                    ")]
              ),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入主标题", clearable: "" },
                    model: {
                      value: _vm.formData.main_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "main_title", $$v)
                      },
                      expression: "formData.main_title",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "param-box" }, [
              _c("div", { staticClass: "flex name" }, [
                _c(
                  "div",
                  { staticClass: "flex label align-center justify-center" },
                  [_vm._v(" appid\n                        ")]
                ),
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入跳转小程序appid",
                        clearable: "",
                      },
                      model: {
                        value: _vm.formData.param.appId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.param, "appId", $$v)
                        },
                        expression: "formData.param.appId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex name" }, [
                _c(
                  "div",
                  { staticClass: "flex label align-center justify-center" },
                  [_vm._v(" 跳转路径\n                        ")]
                ),
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入跳转路径", clearable: "" },
                      model: {
                        value: _vm.formData.param.path,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.param, "path", $$v)
                        },
                        expression: "formData.param.path",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex name" }, [
                _c(
                  "div",
                  { staticClass: "flex label align-center justify-center" },
                  [_vm._v(" 跳转参数\n                        ")]
                ),
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "参数示例: a=1&b=2&c3",
                        clearable: "",
                      },
                      model: {
                        value: _vm.formData.param.query,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.param, "query", $$v)
                        },
                        expression: "formData.param.query",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "flex name" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入跳转延迟", clearable: "" },
                      model: {
                        value: _vm.formData.param.delay,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData.param, "delay", $$v)
                        },
                        expression: "formData.param.delay",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._l(_vm.formData.param_plus, function (item, index) {
              return _c("div", { staticClass: "param-box" }, [
                _c(
                  "div",
                  { staticClass: "flex name" },
                  [
                    _vm._m(1, true),
                    _c(
                      "div",
                      { staticClass: "flex-1" },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入统计标识",
                            clearable: "",
                          },
                          model: {
                            value: item.key,
                            callback: function ($$v) {
                              _vm.$set(item, "key", $$v)
                            },
                            expression: "item.key",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.delParam(index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex name" }, [
                  _c(
                    "div",
                    { staticClass: "flex label align-center justify-center" },
                    [_vm._v(" 名称\n                        ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称", clearable: "" },
                        model: {
                          value: item.title,
                          callback: function ($$v) {
                            _vm.$set(item, "title", $$v)
                          },
                          expression: "item.title",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex name" }, [
                  _c(
                    "div",
                    { staticClass: "flex label align-center justify-center" },
                    [_vm._v(" appid\n                        ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入跳转小程序appid",
                          clearable: "",
                        },
                        model: {
                          value: item.appId,
                          callback: function ($$v) {
                            _vm.$set(item, "appId", $$v)
                          },
                          expression: "item.appId",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex name" }, [
                  _c(
                    "div",
                    { staticClass: "flex label align-center justify-center" },
                    [_vm._v(" 跳转路径\n                        ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入跳转路径", clearable: "" },
                        model: {
                          value: item.path,
                          callback: function ($$v) {
                            _vm.$set(item, "path", $$v)
                          },
                          expression: "item.path",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex name" }, [
                  _c(
                    "div",
                    { staticClass: "flex label align-center justify-center" },
                    [_vm._v(" 跳转参数\n                        ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "参数示例: a=1&b=2&c3",
                          clearable: "",
                        },
                        model: {
                          value: item.query,
                          callback: function ($$v) {
                            _vm.$set(item, "query", $$v)
                          },
                          expression: "item.query",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex name" }, [
                  _vm._m(2, true),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入跳转延迟", clearable: "" },
                        model: {
                          value: item.delay,
                          callback: function ($$v) {
                            _vm.$set(item, "delay", $$v)
                          },
                          expression: "item.delay",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
          ],
          2
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "fix-footer-btns" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.save },
          },
          [_vm._v("保存并发布")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex label align-center justify-center" },
      [
        _vm._v(" 跳转延迟"),
        _c("br"),
        _vm._v("(毫秒:1秒=1000毫秒)\n                        "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex label align-center justify-center" },
      [
        _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
        _vm._v(" 统计标识key\n                        "),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex label align-center justify-center" },
      [
        _vm._v(" 跳转延迟"),
        _c("br"),
        _vm._v("(毫秒:1秒=1000毫秒)\n                        "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }