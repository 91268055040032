"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _settle = require("@/api/settle");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default
  },
  props: ['shopname'],
  data: function data() {
    return {
      searchForm: {
        name: "",
        mobile: ""
      },
      total: 0,
      tableData: [],
      routerName: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name === "memberView") {
      next(function (vm) {
        vm.getList();
      });
    } else {
      next();
    }
  },
  methods: {
    handleMore: function handleMore(command, item) {
      console.log(command, item);
      // this.selectedItem = item;
      if (command == 'edit') {
        // 编辑资料
        this.$router.push({
          name: 'memberView',
          params: {
            viewId: item.id,
            toEdit: true
          }
        });
      } else {
        // 查看备注
      }
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        shopname: "",
        account_num: "",
        status: null
      };
      this.routerName = '';
      this.getList();
    },
    // 启用/禁用
    enable: function enable(id, endisable) {
      var _this = this;
      editSupplier({
        id: id,
        endisable: endisable == 1 ? 2 : 1
      }).then(function (res) {
        _this.getList(true);
      });
    },
    getList: function getList(flag) {
      var _this2 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _settle.getSettleList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.total = res.total;
      });
    },
    delSettleById: function delSettleById(id) {
      var _this3 = this;
      this.$confirm("是否确认删除!!!", '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _settle.delSettleById)({
          id: id
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    }
  }
};