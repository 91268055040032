var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-title-page device_bill" },
    [
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入订单号", clearable: "" },
                    model: {
                      value: _vm.searchForm.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "orderNo", $$v)
                      },
                      expression: "searchForm.orderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "goodsName", $$v)
                      },
                      expression: "searchForm.goodsName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "订单状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "全部", clearable: "" },
                      model: {
                        value: _vm.searchForm.orderStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "orderStatus", $$v)
                        },
                        expression: "searchForm.orderStatus",
                      },
                    },
                    _vm._l(_vm.selectList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "下单日" } },
                [_c("my-date-picker", { ref: "myDatePicker" })],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider", { staticClass: "middle_divider" }),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _c(
            "div",
            { staticClass: "flex align-center flex-between table-top-btns" },
            [
              _c(
                "div",
                { staticClass: "flex align-center" },
                [
                  _c(
                    "h5",
                    { staticClass: "flex align-center" },
                    [
                      _c("svg-icon", {
                        staticStyle: { "margin-right": "15px" },
                        attrs: { "icon-class": "list", size: 15 },
                      }),
                      _vm._v("数据列表\n        "),
                    ],
                    1
                  ),
                  _vm.$hasMethod("#export")
                    ? _c(
                        "el-button",
                        {
                          staticClass: "export-btn",
                          attrs: { size: "small", icon: "el-icon-upload2" },
                          on: { click: _vm.exportFile },
                        },
                        [_vm._v("导出")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "top",
                    width: "570",
                    offset: "50",
                    trigger: "click",
                  },
                },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.stateData } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          width: "150",
                          prop: "title",
                          label: "表头标题",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "content", label: "对应内容" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "color-666 state_btn pointer",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [
                      _c("i", { staticClass: "el-icon-warning-outline" }),
                      _vm._v("注释说明"),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { border: "", data: _vm.tableData } },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  width: "190",
                  align: "center",
                  label: "订单号",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "blue-font pointer",
                            on: {
                              click: function ($event) {
                                return _vm.goToOrder(scope.row.trade_no)
                              },
                            },
                          },
                          [_vm._v(_vm._s(scope.row.trade_no))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "200",
                  prop: "goods_title",
                  label: "商品名称",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "month_total",
                  label: "总租期",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "月租金/台" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.month_rent &&
                                "￥" + scope.row.month_rent
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "租金/台" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.company_rent &&
                                "￥" + scope.row.company_rent
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "amount", label: "分期台数" },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "总租金" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.rent_total &&
                                "￥" + scope.row.rent_total
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  prop: "create_time",
                  label: "下单日",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  prop: "begin_time",
                  label: "起租日",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "100",
                  prop: "end_time",
                  label: "到期日",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", prop: "status", label: "订单状态" },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "periods",
                  label: "当月应收期数",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "当月应收金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.rent && "￥" + scope.row.rent)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "当月实收金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.pay_money && "￥" + scope.row.pay_money
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "当月逾期金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.overdue_money &&
                                "￥" + scope.row.overdue_money
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  prop: "total_leasenum",
                  label: "累计应收期数",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "累计应收金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.total_money &&
                                "￥" + scope.row.total_money
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "累计实收金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.total_paymoney &&
                                "￥" + scope.row.total_paymoney
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "累计逾期金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.total_overduemoney &&
                                "￥" + scope.row.total_overduemoney
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "应收账款" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.receivables &&
                                "￥" + scope.row.receivables
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "违约金额" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.default_money &&
                                "￥" + scope.row.default_money
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("custom-page", {
            ref: "customPage",
            attrs: { total: _vm.total },
            on: { getList: _vm.getList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }