import { render, staticRenderFns } from "./changeVehicle.vue?vue&type=template&id=37dff0e5"
import script from "./changeVehicle.vue?vue&type=script&lang=js"
export * from "./changeVehicle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\xingxingzu\\multi_merchant_web\\supplier\\node_modules\\_vue-hot-reload-api@2.3.4@vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37dff0e5')) {
      api.createRecord('37dff0e5', component.options)
    } else {
      api.reload('37dff0e5', component.options)
    }
    module.hot.accept("./changeVehicle.vue?vue&type=template&id=37dff0e5", function () {
      api.rerender('37dff0e5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/inOutBound/dialog/changeVehicle.vue"
export default component.exports