"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderStatistics = OrderStatistics;
exports.SupplierFlowSearchList = SupplierFlowSearchList;
exports.agreeWithholding = agreeWithholding;
exports.changeRefund = changeRefund;
exports.collectionListExport = collectionListExport;
exports.editRateConfig = editRateConfig;
exports.editShopIsCanPayType = editShopIsCanPayType;
exports.equipmentListExport = equipmentListExport;
exports.financeCollectionList = financeCollectionList;
exports.financeEquipmentList = financeEquipmentList;
exports.financeHuaBeiList = financeHuaBeiList;
exports.financeLeaseList = financeLeaseList;
exports.financeOrderList = financeOrderList;
exports.financeOverdueList = financeOverdueList;
exports.financeOverduePaymentList = financeOverduePaymentList;
exports.financeOverdueQuery = financeOverdueQuery;
exports.functionSet = functionSet;
exports.getFeeDetails = getFeeDetails;
exports.getLeaseFlow = getLeaseFlow;
exports.getMemberFlowList = getMemberFlowList;
exports.getMoreServiceOrderList = getMoreServiceOrderList;
exports.getOfflinePayData = getOfflinePayData;
exports.getOrderBillPayDayData = getOrderBillPayDayData;
exports.getOrderLog = getOrderLog;
exports.getOrderRemark = getOrderRemark;
exports.getOrderStatus = getOrderStatus;
exports.getOverdueLockByOrderId = getOverdueLockByOrderId;
exports.getOverdueSmsLog = getOverdueSmsLog;
exports.getRateConfig = getRateConfig;
exports.getShopUserInfo = getShopUserInfo;
exports.getSupplierFlowList = getSupplierFlowList;
exports.huaBeiListExport = huaBeiListExport;
exports.leaseListExport = leaseListExport;
exports.leaseWithholdLog = leaseWithholdLog;
exports.manualWithholding = manualWithholding;
exports.merchantChild = merchantChild;
exports.merchantChildExport = merchantChildExport;
exports.moreServerListExport = moreServerListExport;
exports.offlinePaySendCode = offlinePaySendCode;
exports.orderListExport = orderListExport;
exports.overdueListExport = overdueListExport;
exports.overduePaymentExport = overduePaymentExport;
exports.reconciliationExport = reconciliationExport;
exports.reconciliationFlow = reconciliationFlow;
exports.saveOverdueLock = saveOverdueLock;
exports.sendOverdueSms = sendOverdueSms;
exports.setOfflinePay = setOfflinePay;
exports.setOrderRemark = setOrderRemark;
exports.shopIsCanPayType = shopIsCanPayType;
exports.withdrawalList = withdrawalList;
exports.withholdSwitch = withholdSwitch;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 筛选获取所有订单状态
function getOrderStatus() {
  return _fetch.default.post("/Supplier/Finance/getOrderStatus");
}
// 财务订单列表
function financeOrderList(params) {
  return _fetch.default.post("/Supplier/Finance/financeOrderList", params);
}
// 账单财务列表
function financeLeaseList(params) {
  return _fetch.default.post("/Supplier/Finance/financeLeaseList", params);
}
// 财务逾期列表
function financeOverdueList(params) {
  return _fetch.default.post("/Supplier/Finance/financeOverdueList", params);
}
// 获取记录流水
function getLeaseFlow(params) {
  return _fetch.default.post("/Supplier/Finance/getLeaseFlow", params);
}
// 财务订单导出
function orderListExport(params) {
  return _fetch.default.export("/Supplier/Finance/orderListExport", params, "订单财务列表");
}
// 财务租期列表导出
function leaseListExport(params) {
  return _fetch.default.export("/Supplier/Finance/leaseListExport", params, "账单财务列表");
}
// 财务逾期列表导出
function overdueListExport(params) {
  return _fetch.default.export("/Supplier/Finance/overdueListExport", params, "逾期列表");
}

// 逾期还款列表导出
function overduePaymentExport(params) {
  return _fetch.default.export("/Supplier/Finance/overduePaymentExport", params, "逾期还款列表");
}

// 财务对账流水导出
function reconciliationExport(params) {
  return _fetch.default.export("/Supplier/Finance/reconciliationExport", params, "对账流水");
}
// 财务对账流水
function reconciliationFlow(params) {
  return _fetch.default.post("/Supplier/Finance/reconciliationFlow", params);
}
// 获取手续费率和手续费详情
function getFeeDetails(params) {
  return _fetch.default.post("/Supplier/Finance/getFeeDetails", params);
}
// 提现记录
function withdrawalList(params) {
  return _fetch.default.post("/Supplier/Withdrawal/withdrawalList", params);
}
// 手动代扣
function manualWithholding(params) {
  return _fetch.default.post("/Supplier/Finance/manualWithholding", params, true);
}
// 签约代扣手动触发
function agreeWithholding(params) {
  return _fetch.default.post("/Supplier/Finance/agreeWithholding", params, true);
}
// 逾期列表-客服备注记录
function getOrderRemark(params) {
  return _fetch.default.post("/Supplier/Finance/getOrderRemark", params);
}
// 逾期列表-短信发送记录
function getOverdueSmsLog(leaseId) {
  return _fetch.default.post("/Supplier/Finance/getOverdueSmsLog", {
    leaseId: leaseId
  });
}
// 逾期列表-逾期短信批量发送
function sendOverdueSms(leaseId) {
  return _fetch.default.post("/Supplier/Finance/sendOverdueSms", {
    leaseId: leaseId
  });
}
// 添加客服备注
function setOrderRemark(params) {
  return _fetch.default.post("/Supplier/Finance/setOrderRemark", params, true);
}
// 查看扣款记录
function leaseWithholdLog(params) {
  return _fetch.default.post("/Supplier/Finance/leaseWithholdLog", params);
}

// 花呗财务账单列表
function financeHuaBeiList(params) {
  return _fetch.default.post("/Supplier/Finance/financeHuaBeiList", params);
}
// 花呗财务账单列表-导出
function huaBeiListExport(params) {
  return _fetch.default.export("/Supplier/Finance/huaBeiListExport", params, "花呗财务账单列表");
}
// 设备分期列表
function financeEquipmentList(params) {
  return _fetch.default.post("/Supplier/Finance/financeEquipmentList", params);
}
// 设备分期列表-导出
function equipmentListExport(params) {
  return _fetch.default.export("/Supplier/Finance/equipmentListExport", params, "设备分期列表");
}
// 回款列表
function financeCollectionList(params) {
  return _fetch.default.post("/Supplier/Finance/financeCollectionList", params);
}
// 回款列表-导出
function collectionListExport(params) {
  return _fetch.default.export("/Supplier/Finance/collectionListExport", params, "回款列表");
}
// 功能设置
function functionSet(is_automatic_report_bill) {
  return _fetch.default.get("/Supplier/ReportBill/functionSet", {
    is_automatic_report_bill: is_automatic_report_bill
  }, true);
}
// 获取供应商信息
function getShopUserInfo() {
  return _fetch.default.get("/Supplier/ReportBill/getShopUserInfo");
}
// 自动代扣开关操作
function withholdSwitch(params) {
  return _fetch.default.post("/Supplier/Finance/withholdSwitch", params, true);
}

// 获取修改账单为线下支付订单信息
function getOfflinePayData(oId, lId) {
  return _fetch.default.get("/Supplier/Finance/getOfflinePayData", {
    order_id: oId,
    lease_id: lId
  });
}
// 线下支付发送验证码
function offlinePaySendCode(oId, lId) {
  return _fetch.default.post("/Supplier/Finance/offlinePaySendCode", {
    order_id: oId,
    lease_id: lId
  }, true);
}
// 修改账单为线下支付
function setOfflinePay(params) {
  return _fetch.default.post("/Supplier/Finance/setOfflinePay", params, true);
}
// 修改账单为线下支付
function changeRefund(params) {
  return _fetch.default.post("/Supplier/Finance/changeRefund", params, true);
}
// 获取订单操作记录
function getOrderLog(params) {
  return _fetch.default.get("/Supplier/Finance/getOrderLog", params);
}

/**
 * 扣费管理
 */
// 获取订单操作记录
function getRateConfig(params) {
  return _fetch.default.get("/Supplier/AMRateConfig/get", params);
}
// 获取订单操作记录
function editRateConfig(params) {
  return _fetch.default.post("/Supplier/AMRateConfig/add", params);
}

// 获取订单操作记录
function shopIsCanPayType(params) {
  return _fetch.default.get("/Supplier/ShopInfo/shopIsCanPayType", params);
}

// 获取订单操作记录
function editShopIsCanPayType(params) {
  return _fetch.default.post("/Supplier/ShopInfo/editShopIsCanPayType", params);
}
// 获取供应商流水（新的，商户扣费和平台扣费）
function getSupplierFlowList(params) {
  return _fetch.default.get("/Supplier/Finance/getSupplierFlowList", params);
}
// 获取供应商流水（新的，商户扣费和平台扣费）
function SupplierFlowSearchList(params) {
  return _fetch.default.get("/Supplier/Finance/SupplierFlowSearchList", params);
}
// 账单扣款列表 日期数据
function getOrderBillPayDayData(params) {
  return _fetch.default.get("/Supplier/Finance/getOrderBillPayDayData", params);
}

// 财务逾期支付列表
function financeOverduePaymentList(params) {
  return _fetch.default.post("/Supplier/Finance/financeOverduePaymentList", params);
}

// 获取用户流水（新的，商户扣费和平台扣费）
function getMemberFlowList(params) {
  return _fetch.default.get("/Supplier/Finance/getMemberFlowList", params);
}
// 获取更多服务器订单
function getMoreServiceOrderList(params) {
  return _fetch.default.get("/Supplier/MoreSer/getMoreServiceOrderList", params);
}
// 获取更多服务器订单
function moreServerListExport(params) {
  return _fetch.default.export("/Supplier/MoreSer/moreServerListExport", params, true);
}

// 获取账单-》逾期列表搜索条件
function financeOverdueQuery(params) {
  return _fetch.default.get("/Supplier/Finance/financeOverdueQuery", params);
}
// 获取账单-》跟单员订单列表详情
function merchantChild(params) {
  return _fetch.default.post("/Supplier/Finance/merchantChild", params);
}

// 逾期列表-》修改逾期后设备锁转台
function saveOverdueLock(params) {
  return _fetch.default.post("/Supplier/Finance/saveOverdueLock", params, true);
}
// 逾期列表-》修改逾期后设备锁转台
function getOverdueLockByOrderId(params) {
  return _fetch.default.get("/Supplier/Finance/getOverdueLockByOrderId", params);
}

// 跟单统计列表-导出
function merchantChildExport(params) {
  return _fetch.default.export("/Supplier/Finance/merchantChildExport", params, "跟单统计");
}

// 账单财务列表
function OrderStatistics(params) {
  return _fetch.default.post("/Supplier/Order/statistics", params);
}