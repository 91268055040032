var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner_col" },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 },
          }),
          _vm._m(0),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        _vm._l(_vm.carousel, function (item, index) {
          return _c(
            "el-col",
            { key: index, attrs: { span: 12 } },
            [
              _c("banner-form", {
                attrs: {
                  type: "bannerColThree",
                  "item-index": index,
                  "form-data": item,
                  "show-tool": true,
                },
                on: {
                  upSortItem: function ($event) {
                    return _vm.upSortItem(index)
                  },
                  downSortItem: function ($event) {
                    return _vm.downSortItem(index)
                  },
                  deleteItem: function ($event) {
                    return _vm.deleteItem(index)
                  },
                },
              }),
            ],
            1
          )
        }),
        1
      ),
      _vm.carousel.length < 5
        ? _c("div", { staticClass: "add-div", on: { click: _vm.addBanner } }, [
            _c("i", { staticClass: "el-icon-plus" }),
            _vm._v("添加图片(" + _vm._s(_vm.carousel.length) + "/5)\n    "),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 },
          }),
          _vm._m(1),
        ],
        1
      ),
      _c("col-form", {
        ref: "colForm1",
        attrs: {
          title: "三栏式1",
          type: "bannerColThree",
          "content-type": "img",
          "form-data": _vm.configData[0],
        },
        on: { setVal: _vm.setVal0 },
      }),
      _c("col-form", {
        ref: "colForm2",
        attrs: {
          title: "三栏式2",
          type: "bannerColThree",
          "content-type": "img",
          "form-data": _vm.configData[1],
        },
        on: { setVal: _vm.setVal1 },
      }),
      _c("col-form", {
        ref: "colForm3",
        attrs: {
          title: "三栏式3",
          type: "bannerColThree",
          "content-type": "img",
          "form-data": _vm.configData[2],
        },
        on: { setVal: _vm.setVal2 },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("轮播图图片宽度335px，高度500px"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("图片背景宽度341px，高度148px"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }