"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['orderId', 'merchantId'],
  components: {},
  data: function data() {
    return {
      drewTip: '查看风控详情(掌签风控)',
      titleTip: '风控详情(null代表没有)',
      loading: true,
      dialogVisible: false,
      tableData: [],
      selectedItem: {},
      headerCellStyle: {
        'text-align': 'center'
      } //只能是这个名字
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.loading = true;
        this.getOrderRiskControl();
      }
    }
  },
  methods: {
    getOrderRiskControl: function getOrderRiskControl() {
      var _this = this;
      console.log('用户信息', this.orderId, '商户信息', this.merchantId);
      // return
      (0, _order.getOrderRiskControl)(this.orderId).then(function (res) {
        console.log('resss', res);
        _this.tableData = res || [];
        console.log('表格信息', _this.tableData);
        _this.loading = false;
      });
    },
    getType: function getType(row) {
      if (row.curentPhase == 'risk_T0') {
        return '初始数据';
      }
      if (row.curentPhase == 'risk_T1') {
        return '隔天数据';
      }
      if (row.curentPhase == 'risk_T3') {
        return '三天数据';
      }
      if (row.curentPhase == 'risk_T7') {
        return '七天评估';
      }
      // return "未知";
    },
    getLevel: function getLevel(row) {
      console.log(row);
      if (row.curentPhase == 'risk_T0') {
        switch (row.riskLevel) {
          case '0':
            return '无结果';
          case '998':
            return '极高危';
          case '996':
            return '高危';
          case '979':
            return '中风险';
          case '975':
            return '正常';
          case '969':
            return '低风险';
          case '989':
            return '优质';
        }
      }
      if (row.curentPhase == 'risk_T1') {
        switch (row.riskLevel) {
          case '0':
            return row.riskContent;
          case '998':
            return '极高危';
          case '996':
            return '高危';
          case '979':
            return '中风险';
          case '975':
            return '正常';
          case '969':
            return '低风险';
          case '989':
            return '优质';
        }
      }
      if (row.curentPhase == 'risk_T3') {
        switch (row.riskLevel) {
          case '0':
            return row.riskContent;
          case '998':
            return '极高风险';
          case '996':
            return '高风险';
          case '979':
            return '中高风险';
          case '975':
            return '低风险';
          case '969':
            return '无异常人群';
        }
      }
      if (row.curentPhase == 'risk_T7') {
        switch (row.riskLevel) {
          case '998':
            return '极高聚集';
          case '996':
            return '高聚集';
          case '979':
            return '中聚集';
          case '975':
            return '低聚集';
          case '969':
            return '无异常聚集';
          default:
            return row.riskContent;
        }
      }
      // return "未知";
    },
    getRecom: function getRecom(row) {
      if (row.key == 'rentOnlineRiskLevelOverall') {
        switch (row.value) {
          case '0':
            return '建议发货';
          case '1':
            return '可适当放宽商家风控条件，提高发货率';
          case '2':
            return '缴纳押金或者预缴多期租金情况下，谨慎发货';
          case '3':
            return '不建议发货';
        }
      }
      if (row.key == 'rentOnlineRiskLevelMultiRent') {
        switch (row.value) {
          case '0':
            return '无明显共租行为';
          case '1':
            return '共租行为较多';
          case '2':
            return '共租行为过多，明显超过平均水位';
        }
      }
      if (row.key == 'rentOnlineRiskLevelFulfill') {
        return '该字段仅供商家参考使用，等级为1不一定代表用户有较高风险';
      }

      // return "未知"
    },
    getTask: function getTask(row) {
      if (row.curentPhase == 'risk_T0') {
        return '实时模型输出，用于判断是否通过订单。用户在完成押金授权，或者租金免密代扣授权或者人脸之后即可查询，最多可查询24小时之内的订单，注意，同步风险识别是根据用户实时行为计算的模型，需要在用户授权后尽快查询';
      }
      if (row.curentPhase == 'risk_T1') {
        return '用户下单后到发货前的异变判断';
      }
      if (row.curentPhase == 'risk_T3') {
        return '下单到收货间的异变判断';
      }
      if (row.curentPhase == 'risk_T7') {
        return '近7天内目前未被取消的订单即可查询，输出结果为是否与中介关联以及关联的程度';
      }
      // return "未知";
    }
  }
};