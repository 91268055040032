var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nine_grid" },
    [
      _c("h5", [_vm._v(_vm._s(_vm.title || "顶部轮播图"))]),
      _vm._l(_vm.iconList, function (item, index) {
        return _c("icon-form", {
          key: index,
          attrs: {
            "mul-props": _vm.mulProps,
            "img-width": _vm.imgWidth,
            "img-height": _vm.imgHeight,
            "item-index": index,
            "form-data": item,
            "area-data": _vm.areaList,
          },
          on: {
            handleDelete: function ($event) {
              return _vm.deleteItem(index)
            },
            handleAdd: function ($event) {
              return _vm.addItem(index)
            },
            upSortItem: function ($event) {
              return _vm.upSortItem(index)
            },
            downSortItem: function ($event) {
              return _vm.downSortItem(index)
            },
            getProvince: function (e) {
              return _vm.getProvince(index, e)
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }