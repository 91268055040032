"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _apayment = require("@/api/apayment");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        password: ''
      },
      rules: {
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {} else {
        this.$refs['formData'].resetFields();
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var param = {
            id: _this.id,
            password: _this.$md5(_this.formData.password)
          };
          (0, _apayment.doPayAPayment)(param).then(function (res) {
            _this.$emit('getList');
            _this.close();
            console.log('res', res);
          }).catch(function () {
            _this.close();
          });
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};