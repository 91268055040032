var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex goods_tag" }, [
      _c(
        "div",
        { staticClass: "flex flex-1 left_info" },
        [
          _c("el-image", {
            attrs: {
              src: _vm.$globalObj.imgBaseUrl + _vm.goodsData.imgurl,
              fit: "fill",
            },
          }),
          _c("div", { staticClass: "flex-1" }, [
            _c("p", { staticClass: "goods_title" }, [
              _vm._v(_vm._s(_vm.goodsData.title)),
            ]),
            _c("p", { staticClass: "goods_id" }, [
              _vm._v("ID: " + _vm._s(_vm.goodsData.id)),
            ]),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCate,
                    expression: "showCate",
                  },
                ],
                staticClass: "goods_id",
              },
              [_vm._v("分类: " + _vm._s(_vm.goodsData.cate_name))]
            ),
          ]),
        ],
        1
      ),
      _vm.showTool
        ? _c(
            "div",
            {
              staticClass: "flex flex-column align-center justify-center tool",
            },
            [
              _vm.showSort
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("upSort")
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-top" })]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "pointer",
                        on: {
                          click: function ($event) {
                            return _vm.$emit("downSort")
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-bottom" })]
                    ),
                  ]
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("handleDelete")
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-delete" })]
              ),
            ],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }