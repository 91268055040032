var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info_config_page" },
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 },
              }),
              _vm._v("数据列表\n\t\t"),
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.editChannel()
                    },
                  },
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "80", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "应用名称",
              prop: "app_name",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "渠道名称",
              prop: "name",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "渠道key",
              prop: "key",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "渠道链接", prop: "key" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-link",
                      {
                        attrs: {
                          type: "primary",
                          href:
                            _vm.$globalObj.host +
                            "/guest/throughput?channel=" +
                            scope.row.key,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$globalObj.host) +
                            "/guest/throughput?channel=" +
                            _vm._s(scope.row.key)
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作",
              fixed: "right",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.$hasMethod("#edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.editChannel(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList },
      }),
      _c("add", {
        ref: "add",
        attrs: { editItem: _vm.editItem },
        on: { getList: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }