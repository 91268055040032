"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _goods = require("@/api/goods");
var _html2canvas = _interopRequireDefault(require("html2canvas"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dialogVisible: false,
      goodsId: null,
      goodsData: {},
      loading: false,
      posterImg: '' // 最终生成的海报图片
    };
  },
  methods: {
    onError: function onError() {
      this.$message({
        message: "复制失败",
        type: "error"
      });
    },
    onCopy: function onCopy() {
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },
    createPoster: function createPoster() {
      var _this = this;
      setTimeout(function () {
        // 生成海报
        (0, _html2canvas.default)(document.getElementById("posterHtml"), {
          useCORS: true,
          //（图片跨域相关）
          allowTaint: false //允许跨域（图片跨域相关）
        }).then(function (canvas) {
          _this.posterImg = canvas.toDataURL();
          _this.loading = false;
        }).catch(function () {
          _this.loading = false;
        });
      }, 1000);
    },
    getData: function getData() {
      var _this2 = this;
      this.loading = true;
      (0, _goods.goodsShare)(this.goodsId).then(function (res) {
        _this2.goodsData = res || {};
        _this2.createPoster();
      }).catch(function () {
        _this2.loading = false;
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      } else {
        // 清空数据
        this.posterImg = "";
        this.goodsData = {};
      }
    }
  }
};