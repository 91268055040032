"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _goodsAdd = _interopRequireDefault(require("./goodsAdd"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    goodsAdd: _goodsAdd.default,
    cropUpload: _cropUpload.default
  },
  props: ['goodsNum', 'levelName', 'compFormData', 'imgWidth', 'imgHeight'],
  data: function data() {
    return {
      formData: {
        main_title: '',
        background_image: '',
        background_color: '#FFFFFF',
        goods: []
      },
      photoWidth: 359
    };
  },
  computed: {
    photoHeight: function photoHeight() {
      var height = 359 * this.imgHeight / this.imgWidth;
      return height;
    }
  },
  watch: {
    compFormData: function compFormData(val) {
      this.formData = _objectSpread({}, val);
      if (this.levelName == 'first_level') {
        this.formData.background_color = this.formData.background_color ? this.formData.background_color : '#FFFFFF';
      }
    }
  },
  methods: {
    setGoods: function setGoods(list) {
      this.formData.goods = list;
    },
    getSubmitData: function getSubmitData() {
      var goodsidStr = '';
      if (this.formData.goods.length > 0) {
        if (!this.formData.main_title) {
          this.$message({
            message: "主标题不能为空",
            type: "error"
          });
          return false;
        }
        goodsidStr += this.formData.goods.map(function (k) {
          return k.goods_id;
        }).join(',');
      }
      return {
        level: this.levelName,
        background_image: this.formData.background_image,
        main_title: this.formData.main_title,
        background_color: this.formData.background_color,
        goodsidStr: goodsidStr
      };
    }
  }
};