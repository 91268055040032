var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.handleDialog,
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.handleDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { ref: "formData", attrs: { model: _vm.formData, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "页面名称：",
                "label-width": "140px",
                prop: "title",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.formData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "title", $$v)
                  },
                  expression: "formData.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "链接说明：",
                "label-width": "140px",
                prop: "path",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  placeholder:
                    "/pages/productDetails/productDetails?goodsId=商品id",
                },
                model: {
                  value: _vm.formData.path,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "path", $$v)
                  },
                  expression: "formData.path",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "链接示例：",
                "label-width": "140px",
                prop: "path_example",
              },
            },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  placeholder: "/pages/productDetails/productDetails?goodsId=1",
                },
                model: {
                  value: _vm.formData.path_example,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "path_example", $$v)
                  },
                  expression: "formData.path_example",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "图片示例：",
                "label-width": "140px",
                prop: "images",
              },
            },
            [
              _c("el-form-item", { attrs: { prop: "photo" } }, [
                _c(
                  "div",
                  { staticClass: "image-box" },
                  _vm._l(_vm.imagesList, function (item, index) {
                    return _c("crop-upload", {
                      key: index,
                      attrs: {
                        "show-type": "photo",
                        "init-val": item,
                        "wrapper-width": "95px",
                        "wrapper-height": "95px",
                      },
                      on: {
                        setVal: function (val) {
                          return _vm.setVal(index, val)
                        },
                      },
                    })
                  }),
                  1
                ),
              ]),
            ],
            1
          ),
          _vm.isEdit
            ? [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "状态:",
                      "label-width": "130px",
                      prop: "status",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.formData.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "status", $$v)
                          },
                          expression: "formData.status",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("显示"),
                        ]),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("隐藏"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeDialog } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formData")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }