var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "550px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formDataRef",
          attrs: {
            "label-position": "left",
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "cert_name" } },
            [
              _c("el-input", {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入姓名", clearable: "" },
                model: {
                  value: _vm.formData.cert_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "cert_name", $$v)
                  },
                  expression: "formData.cert_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单号", prop: "trade_no" } },
            [
              _c("el-input", {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入订单号", clearable: "" },
                model: {
                  value: _vm.formData.trade_no,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "trade_no", $$v)
                  },
                  expression: "formData.trade_no",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "出租金额", prop: "rent_price" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "240px" },
                attrs: { min: 0, placeholder: "请输入出租价格", clearable: "" },
                model: {
                  value: _vm.formData.rent_price,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "rent_price", $$v)
                  },
                  expression: "formData.rent_price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "租期", prop: "month_total" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "240px" },
                attrs: { min: 0, placeholder: "请输入租期", clearable: "" },
                model: {
                  value: _vm.formData.month_total,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "month_total", $$v)
                  },
                  expression: "formData.month_total",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态", prop: "order_status" } },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm.orderStatusChange },
                  model: {
                    value: _vm.formData.order_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "order_status", $$v)
                    },
                    expression: "formData.order_status",
                  },
                },
                _vm._l(_vm.optionsOrderStatList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          this.formData.order_status == 4
            ? _c(
                "el-form-item",
                { attrs: { label: "购买金额", prop: "license_plate" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      min: 0,
                      placeholder: "请输入购买金额",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.purchase_real,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "purchase_real", $$v)
                      },
                      expression: "formData.purchase_real",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          this.formData.order_status == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "租用中状态", prop: "lease_status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.formData.lease_status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "lease_status", $$v)
                        },
                        expression: "formData.lease_status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "以租代购", value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: "随租随还", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码", prop: "mobile" } },
            [
              _c("el-input", {
                staticStyle: { width: "220px" },
                attrs: { placeholder: "请输入手机号码", clearable: "" },
                model: {
                  value: _vm.formData.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "mobile", $$v)
                  },
                  expression: "formData.mobile",
                },
              }),
            ],
            1
          ),
          !(_vm.editItem && _vm.editItem.id)
            ? _c(
                "el-form-item",
                { attrs: { label: "车架号", prop: "chassis_number" } },
                [
                  _c("el-cascader", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.optionsLoading,
                        expression: "optionsLoading",
                      },
                    ],
                    attrs: { options: _vm.options },
                    on: { change: _vm.chassisChange },
                    model: {
                      value: _vm.formData.chassis_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "chassis_number", $$v)
                      },
                      expression: "formData.chassis_number",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "合同照片", prop: "contract_image" } },
            [
              _c("crop-upload", {
                ref: "cropUpload",
                attrs: {
                  multiple: true,
                  "show-type": "photo",
                  "init-val": _vm.formData.contract_image,
                  "wrapper-width": "100px",
                  "wrapper-height": "100px",
                  "photo-width": 100,
                  "photo-height": 100,
                },
                on: { setVal: _vm.imgChange },
              }),
            ],
            1
          ),
          false
            ? _c(
                "el-form-item",
                { attrs: { label: "租赁时长", prop: "lease_duration" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "请输入租赁时长", clearable: "" },
                    model: {
                      value: _vm.formData.lease_duration,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "lease_duration", $$v)
                      },
                      expression: "formData.lease_duration",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          !_vm.widthImg && false
            ? _c(
                "el-form-item",
                { attrs: { label: "维修部件", prop: "repair_record" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        placeholder: "请选择",
                        clearable: "",
                      },
                      on: { change: _vm.repairChange },
                      model: {
                        value: _vm.formData.repair_record,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "repair_record", $$v)
                        },
                        expression: "formData.repair_record",
                      },
                    },
                    _vm._l(_vm.repairList, function (item, index) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "是否上牌", prop: "is_registered" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部", clearable: "" },
                  model: {
                    value: _vm.formData.is_registered,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_registered", $$v)
                    },
                    expression: "formData.is_registered",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "已上牌", value: 1 } }),
                  _c("el-option", { attrs: { label: "未上牌", value: 2 } }),
                ],
                1
              ),
            ],
            1
          ),
          this.formData.is_registered == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "车牌", prop: "license_plate" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入车牌", clearable: "" },
                    model: {
                      value: _vm.formData.license_plate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "license_plate", $$v)
                      },
                      expression: "formData.license_plate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "remark", $$v)
                  },
                  expression: "formData.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.btn_disabled, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }