"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _finance = require("@/api/finance");
var _order = require("@/api/order");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import viewDialog from "./view";
// import remarkDialog from "./remark";
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      loading: true,
      searchForm: {
        orderNo: '',
        receivingName: '',
        receivingMobile: '',
        merchant_child_id: 0,
        payDate: '',
        payLeaseDate: '',
        billDate: '',
        verifyDate: '',
        orderStatus: ''
      },
      statusList: [],
      tableData: [],
      total: 0,
      count: {},
      overdueCount: 0,
      overdueAmount: 0,
      merchantChild: [{
        id: 0,
        username: '全部'
      }]
    };
  },
  mounted: function mounted() {
    this.getQuery();
    this.getStatusList();
    this.getList();
  },
  methods: {
    // 获取数据
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      var params = _objectSpread({}, this.searchForm);
      (0, _finance.merchantChild)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, params)).then(function (res) {
        _this.tableData = res.list.data || [];
        _this.total = res.list.total || 0;
        _this.count = res.count || 0;
        _this.loading = false;
        // this.overdueCount = overdueCount;
        // this.overdueAmount = overdueAmount;
      });
    },
    getStatusList: function getStatusList() {
      var _this2 = this;
      (0, _order.getOrderStatus)().then(function (res) {
        var list = [];
        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }
        _this2.statusList = list || [];
      });
    },
    batchSend: function batchSend() {
      var _this3 = this;
      var list = this.$refs.multipleTable.selection;
      if (list.length === 0) {
        this.$message({
          message: '没有勾选项',
          type: 'warning'
        });
        return;
      }
      var ids = list.map(function (k) {
        return k.lease_id;
      }).join(',');
      (0, _finance.sendOverdueSms)(ids).then(function (res) {
        _this3.getList();
      });
    },
    // 发起代扣
    withholdItem: function withholdItem(orderId, leaseId) {
      var _this4 = this;
      this.$confirm('您确定要发起代扣吗？', '发起代扣', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.manualWithholding)({
          orderId: orderId,
          leaseId: leaseId
        }).then(function (res) {
          setTimeout(function () {
            _this4.getList();
          }, 1000);
        });
      }).catch(function () {});
    },
    handleMore: function handleMore(command, item) {
      this.selectedItem = item;
      if (command == 1) {
        // 查看记录
        this.$refs.viewDialog.dialogVisible = true;
      } else {
        // 查看备注
        this.$refs.remarkDialog.dialogVisible = true;
      }
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    exportFile: function exportFile() {
      var params = _objectSpread({}, this.searchForm);
      (0, _finance.merchantChildExport)(params);
    },
    changeNumberInput: function changeNumberInput(key, value) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        this.searchForm[key] = '';
      }
    },
    // 查询
    search: function search() {
      var payDate = this.$refs.payDate.getVal();
      var payLeaseDate = this.$refs.payLeaseDate.getVal();
      var billDate = this.$refs.billDate.getVal();
      var verifyDate = this.$refs.verifyDate.getVal();
      if (payDate == 'error' || verifyDate == 'error' || billDate == 'error') {
        return;
      }
      this.searchForm.payDate = payDate;
      this.searchForm.payLeaseDate = payLeaseDate;
      this.searchForm.verifyDate = verifyDate;
      this.searchForm.billDate = billDate;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.payDate) {
        this.$refs.payDate.reset();
      }
      if (this.$refs.payLeaseDate) {
        this.$refs.payLeaseDate.reset();
      }
      if (this.$refs.verifyDate) {
        this.$refs.verifyDate.reset();
      }
      if (this.$refs.billDate) {
        this.$refs.billDate.reset();
      }
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    getQuery: function getQuery() {
      var _this5 = this;
      (0, _finance.financeOverdueQuery)().then(function (res) {
        var _this5$merchantChild;
        (_this5$merchantChild = _this5.merchantChild).push.apply(_this5$merchantChild, (0, _toConsumableArray2.default)(res.data));
      });
    }
  }
};