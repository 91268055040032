var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nine_grid" },
    _vm._l(_vm.iconList, function (item, index) {
      return _c("icon-form", {
        key: index,
        attrs: {
          "img-width": _vm.imgWidth,
          "img-height": _vm.imgHeight,
          "item-index": index,
          "form-data": item,
        },
        on: {
          handleDelete: function ($event) {
            return _vm.deleteItem(index)
          },
          handleAdd: function ($event) {
            return _vm.addItem(index)
          },
          upSortItem: function ($event) {
            return _vm.upSortItem(index)
          },
          downSortItem: function ($event) {
            return _vm.downSortItem(index)
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }