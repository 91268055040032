"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  data: function data() {
    return {
      form: {
        order_id: '',
        reason: '' //取消原因
      },
      rules: {
        reason: [{
          required: false,
          message: '取消原因不能为空',
          trigger: 'blur',
          type: 'string'
        }]
      },
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      }
    }
  },
  methods: {
    init: function init() {
      this.form = this.editItem;
    },
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          console.log('当前内容', _this.form);
          var params = _this.form;
          (0, _order.cancelOrderEquityAPI)(params).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};