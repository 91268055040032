var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "60vw",
        fullscreen: false,
        title: "用户地址附近标志建筑",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "100%", height: "500px" },
          })
        : _c(
            "div",
            [
              _vm.showMap
                ? _c(
                    "baidu-map",
                    {
                      ref: "customComponent",
                      staticStyle: { width: "100%", height: "500px" },
                      attrs: {
                        ak: _vm.mapAK,
                        center: _vm.center,
                        zoom: _vm.zoom,
                        "inertial-dragging": "",
                        "scroll-wheel-zoom": true,
                      },
                      on: { ready: _vm.mapReady },
                    },
                    [
                      _c("map-marker", {
                        attrs: {
                          position: _vm.markerPosition,
                          "z-index": 200,
                          icon: {
                            url: _vm.imgEndSrc,
                            size: { width: 32, height: 32 },
                          },
                        },
                      }),
                      _c("map-type", {
                        attrs: {
                          "map-types": ["BMAP_NORMAL_MAP", "BMAP_HYBRID_MAP"],
                          anchor: "BMAP_ANCHOR_TOP_LEFT",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        height: "500px",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("h1", [
                        _vm._v("百度地图AK秘钥不存在，请先配置百度地图AK秘钥"),
                      ]),
                    ]
                  ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }