"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delAPayment = delAPayment;
exports.doPayAPayment = doPayAPayment;
exports.editAPaymentData = editAPaymentData;
exports.getAPaymentList = getAPaymentList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 获取转账信息
function getAPaymentList(params) {
  return _fetch.default.get('/Supplier/aliPayment/getAPaymentList', params);
}

// 编辑转账信息
function editAPaymentData(params) {
  return _fetch.default.post('/Supplier/aliPayment/editAPaymentData', params, true);
}
// 删除转账信息
function delAPayment(id) {
  return _fetch.default.post('/Supplier/aliPayment/delAPayment', id);
}

// 转账
function doPayAPayment(id) {
  return _fetch.default.post('/Supplier/aliPayment/doPayAPayment', id);
}