var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop_banner" },
    [
      _c("div", { staticClass: "flex top_exp" }, [
        _c("img", {
          staticStyle: { width: "280px", height: "200px" },
          attrs: {
            src: require("../../../assets/images/banner_exp.jpg"),
            alt: "轮播图设置",
          },
        }),
        _c("div", { staticClass: "flex-1 warn-box" }, [
          _c(
            "p",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "17px" },
                attrs: { "icon-class": "warn", size: 20 },
              }),
              _c("span", { staticStyle: { "font-size": "16px" } }, [
                _vm._v("店铺轮播图设置说明"),
              ]),
            ],
            1
          ),
          _vm._m(0),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 },
              }),
              _vm._v("数据列表\n    "),
            ],
            1
          ),
          _vm.$hasMethod("#addBanner")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.openAddDialog()
                    },
                  },
                },
                [_vm._v("添加轮播图")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !_vm.$hasMethod("#sortBanner")
                      ? _c("span", [_vm._v(_vm._s(scope.row.sort))])
                      : _c("el-input", {
                          attrs: {
                            oninput: "value=value.replace(/[^\\d]/g,'')",
                            maxLength: "9",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeSort(
                                scope.row.id,
                                scope.row.sort
                              )
                            },
                          },
                          model: {
                            value: scope.row.sort,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "sort", $$v)
                            },
                            expression: "scope.row.sort",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "id", label: "轮播图ID", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "图片", width: "150", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-image",
                      {
                        staticStyle: { width: "123px", height: "50px" },
                        attrs: {
                          src: scope.row.banner_img
                            ? _vm.$globalObj.imgBaseUrl + scope.row.banner_img
                            : "",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "image-slot",
                            attrs: { slot: "error" },
                            slot: "error",
                          },
                          [_c("i", { staticClass: "el-icon-picture-outline" })]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "create_time",
              label: "添加时间",
              align: "center",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "title", label: "关联商品", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "status", label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.$hasMethod("#enableBanner")
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "pointer",
                            attrs: {
                              type:
                                scope.row.status == 1 ? "success" : "danger",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeStatus(
                                  scope.row.id,
                                  scope.row.status
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status == 1 ? "已上架" : "已下架"
                              )
                            ),
                          ]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type:
                                scope.row.status == 1 ? "success" : "danger",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.status == 1 ? "已上架" : "已下架"
                              )
                            ),
                          ]
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "140",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.$hasMethod("#editBanner")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.openAddDialog(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#deleteBanner")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList },
      }),
      _c("add-banner", {
        ref: "addBanner",
        attrs: { "edit-id": _vm.editId },
        on: { getList: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "color-666", staticStyle: { "line-height": "28px" } },
      [
        _vm._v("\n        1. 店铺轮播图要求尺寸：宽度690像素，高度280像素。"),
        _c("br"),
        _vm._v("\n        2. 店铺轮播图数量最多5张。"),
        _c("br"),
        _vm._v("\n        3. 排序数值越小，展示位置越靠前。\n      "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }