"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _orderRefund = require("@/api/orderRefund");
var _agreeRefund = _interopRequireDefault(require("./dialog/agreeRefund.vue"));
var _refuseRefund = _interopRequireDefault(require("./dialog/refuseRefund.vue"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default,
    agreeRefund: _agreeRefund.default,
    refuseRefund: _refuseRefund.default
  },
  data: function data() {
    return {
      searchForm: {
        order_no: '',
        refund_status: '',
        create_time: ''
      },
      count: {
        admin_nocheck: 0,
        order_total_count: 0,
        refund_finish: 0,
        refund_rufuse: 0,
        user_apply_refund: 0
      },
      activeName: '0',
      total: 0,
      tableData: [],
      currentId: '',
      orderId: '',
      refundStatus: '',
      resubmitVisible: false
    };
  },
  methods: {
    // 获取数据
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _orderRefund.orderRefundList)(_objectSpread(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        order_status: this.activeName
      })).then(function (res) {
        var count = res.count,
          data = res.data;
        _this.tableData = data || [];
        _this.total = res.total || 0;
        _this.count = count;
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = {
        order_no: '',
        refund_status: '',
        create_time: ''
      };
      this.getList();
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    changeSelect: function changeSelect(val) {
      this.searchForm.refund_status = val;
    },
    agreeRefund: function agreeRefund(row) {
      this.currentId = row.id;
      this.orderId = row.order_id;
      this.refundStatus = row.refund_status;
      this.$refs.agreeRefund.dialogVisible = true;
      this.$refs.agreeRefund.isSuccess = true;
    },
    refuseRefund: function refuseRefund(row) {
      this.currentId = row.id;
      this.refundStatus = row.refund_status;
      this.$refs.refuseRefund.dialogVisible = true;
    },
    checkRefund: function checkRefund(row) {
      var id = row.order_id;
      this.$router.push({
        name: 'refundDetail',
        params: {
          id: id
        }
      });
    },
    resubmit: function resubmit(id) {
      this.currentId = id;
      this.resubmitVisible = true;
    },
    resubmit_sure: function resubmit_sure() {
      var _this2 = this;
      (0, _orderRefund.shopCheckPass)(this.currentId).then(function (res) {
        _this2.resubmitVisible = false;
        _this2.getList();
      });
    },
    checkLogistics: function checkLogistics(id) {
      this.currentId = id;
    },
    jumpOrderList: function jumpOrderList(trade_no) {
      this.$router.push({
        path: '/order/list?orderId=' + trade_no
      });
    }
  },
  mounted: function mounted() {
    this.searchForm.order_no = this.$route.query.tradeNo ? this.$route.query.tradeNo : '';
    this.getList();
  }
};