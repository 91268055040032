"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
require("core-js/modules/web.dom.iterable");
var _toConsumableArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/toConsumableArray.js"));
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _goods = require("@/api/goods");
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['currentMeal', 'editId', 'goodInfo'],
  data: function data() {
    return {
      isCopy: 0,
      submitForm: {
        sort: 0,
        setmeal_id: '',
        title: '',
        lease_set: 1,
        is_huabei: 2,
        huabei_data: [{
          lease_term: '',
          rent_total: '',
          three_bear: '',
          three_fee: '',
          six_bear: '',
          six_fee: '',
          twelve_bear: '',
          twelve_fee: ''
        }],
        quantity: 99999,
        foregift: '',
        is_buy_out: 1,
        meal_status: 1,
        price: '',
        start_date: '',
        methods: '',
        specs_name: '规格',
        specs_value: '',
        price_rate: 1.3,
        reference_price: 0
      },
      defaultPriceRate: 1.3,
      // priceRate: 0,
      // referencePrice: 0,
      rules: {
        specs_name: [{
          required: true,
          message: '请输入规格名称',
          trigger: 'blur'
        }],
        specs_value: [{
          required: true,
          message: '请输入规格参数',
          trigger: 'blur'
        }],
        methods: [{
          required: true,
          message: '请输入套餐方式',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '请输入套餐名字',
          trigger: 'blur'
        }],
        huabei_data: [{
          required: true,
          message: '请填写花呗设置',
          trigger: 'change'
        }],
        quantity: [{
          required: true,
          message: '请输入库存数量',
          trigger: 'blur'
        }],
        foregift: [{
          required: true,
          message: '请输入押金',
          trigger: 'blur'
        }],
        is_buy_out: [{
          required: true,
          message: '请选择是否买断',
          trigger: 'change'
        }],
        price: [{
          required: true,
          message: '请输入销售价格',
          trigger: 'blur'
        }],
        lease_set: [{
          required: true,
          message: '请选择租期设置',
          trigger: 'change'
        }]
      },
      // 按月租期设置
      monthList: [{
        lease_time: null,
        lease_price: '',
        spec_value: ''
      }],
      monthSelect: [],
      // 可选月租期
      dayList: [{
        lease_time: '',
        lease_price: '',
        spec_value: ''
      }],
      // 按天租期设置
      customList: [],
      //自定义租期
      customForm: {
        lease_time: '',
        withhold_cycle: '',
        lease_price: '',
        spec_value: ''
      },
      intervalList: [],
      // 可选租期间隔
      setForm: {
        third: '',
        sixth: '',
        twelve: ''
      },
      feeData: [{
        num: 3,
        merchant: '1.80%',
        user: '2.30%'
      }, {
        num: 6,
        merchant: '4.50%',
        user: '4.50%'
      }, {
        num: 12,
        merchant: '7.50%',
        user: '7.50%'
      }],
      startDateSelect: [],
      rentType: 0,
      goodsId: 0,
      status: 2,
      hideAuditBtn: false
    };
  },
  watch: {
    currentMeal: function currentMeal(val, oldVal) {
      this.getData();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.init();
    this.$nextTick(function () {
      if (_this.$route.name === 'goodsMeal') {
        _this.goodsId = _this.$route.params.goodsId;
        if (_this.editId > 0) {
          _this.isCopy = _this.$route.params.isCopy ? _this.$route.params.isCopy : 0;
          _this.getInfo();
        } else {
          _this.rentType = _this.$route.params.pact_type;
        }
      } else {
        _this.rentType = JSON.parse(sessionStorage.getItem('baseInfo')) ? JSON.parse(sessionStorage.getItem('baseInfo')).formData.pact_type : 0;
        _this.goodsId = sessionStorage.getItem('goodsId') || '';
        _this.getData();
      }
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.$route.name === 'goodsAdd') {
      this.saveStorage(this.currentMeal);
    }
  },
  methods: {
    init: function init() {
      var _this2 = this;
      // this.getPriceRate()
      (0, _goods.getParameterList)().then(function (res) {
        _this2.startDateSelect = res.start_date || [];
        _this2.intervalList = res.withhold_cycle || [];
        _this2.monthSelect = res.lease_term_month || [];
      });
    },
    getData: function getData() {
      this.monthList = [{
        lease_time: null,
        lease_price: ''
      }];
      this.dayList = [{
        lease_time: '',
        lease_price: ''
      }];
      this.customList = [];
      if (sessionStorage.getItem('mealInfo')) {
        var list = JSON.parse(sessionStorage.getItem('mealInfo'));
        if (list[this.currentMeal - 1]) {
          var data = list[this.currentMeal - 1];
          console.log('data.formData', data.formData);
          this.submitForm = _objectSpread({}, data.formData);
          this.monthList = (0, _toConsumableArray2.default)(data.monthList);
          this.dayList = (0, _toConsumableArray2.default)(data.dayList);
          this.customList = (0, _toConsumableArray2.default)(data.customList);
        }
      }
      this.status = parseInt(sessionStorage.getItem('goodsStatus'));
    },
    getInfo: function getInfo() {
      var _this3 = this;
      (0, _goods.getGoodsSetmealInfo)(this.editId).then(function (res) {
        _this3.hideAuditBtn = res.ischeck == 3;
        for (var key in _this3.submitForm) {
          if (key === 'huabei_data') {
            if (res.is_huabei == 1) {
              _this3.submitForm[key] = res[key];
            }
          } else if (key === 'setmeal_id') {
            _this3.submitForm[key] = res.id;
          } else {
            _this3.submitForm[key] = res[key];
          }
        }
        _this3.rentType = res.pact_type;
        _this3.status = res.goods_status;
        if (res.lease_set == 1) {
          _this3.monthList = res.spec;
          if (_this3.submitForm.is_huabei == 2) {
            for (var index in _this3.monthList) {
              var _this3$monthList$inde = _this3.monthList[index],
                lease_time = _this3$monthList$inde.lease_time,
                lease_price = _this3$monthList$inde.lease_price;
              var rentTotal = (parseFloat(lease_price) * lease_time).toFixed(2);
              if (index == 0) {
                _this3.submitForm.huabei_data[index].lease_term = lease_time;
                _this3.submitForm.huabei_data[index].rent_total = rentTotal;
              } else {
                _this3.submitForm.huabei_data.push({
                  lease_term: lease_time,
                  rent_total: rentTotal,
                  three_bear: '',
                  three_fee: '',
                  six_bear: '',
                  six_fee: '',
                  twelve_bear: '',
                  twelve_fee: ''
                });
              }
            }
          }
          _this3.calcReferencePrice(1);
        } else if (res.lease_set == 2) {
          _this3.dayList = res.spec;
          if (_this3.submitForm.is_huabei == 2) {
            for (var _index in _this3.dayList) {
              var _this3$dayList$_index = _this3.dayList[_index],
                _lease_time = _this3$dayList$_index.lease_time,
                _lease_price = _this3$dayList$_index.lease_price;
              var _rentTotal = (parseFloat(_lease_price) * _lease_time).toFixed(2);
              if (_index == 0) {
                _this3.submitForm.huabei_data[_index].lease_term = _lease_time;
                _this3.submitForm.huabei_data[_index].rent_total = _rentTotal;
              } else {
                _this3.submitForm.huabei_data.push({
                  lease_term: _lease_time,
                  rent_total: _rentTotal,
                  three_bear: '',
                  three_fee: '',
                  six_bear: '',
                  six_fee: '',
                  twelve_bear: '',
                  twelve_fee: ''
                });
              }
            }
          }
          _this3.calcReferencePrice(1);
        } else {
          // console.log(res.spec)
          _this3.customList = res.spec;
          _this3.monthList = res.spec;
          _this3.calcReferencePrice(2);
        }
      });
    },
    // 计算referencePrice
    calcReferencePrice: function calcReferencePrice(type) {
      // console.log(this.monthList[0])
      // console.log(this.submitForm.foregift)
      this.chkPriceRate();
      if (this.submitForm.reference_price <= 0) {
        if (type == 1) {
          var price = this.monthList[0].lease_time * this.monthList[0].lease_price + parseFloat(this.submitForm.foregift);
          this.submitForm.reference_price = (price / this.submitForm.price_rate).toFixed(2);
        } else {
          var total = this.subTotal(this.monthList[0]);
          var _price = total + parseFloat(this.submitForm.foregift);
          this.submitForm.reference_price = (_price / this.submitForm.price_rate).toFixed(2);
        }
      }
    },
    subTotal: function subTotal(items) {
      return items.reduce(function (acc, ele) {
        console.log(ele.lease_price);
        return acc + parseFloat(ele.lease_price);
      }, 0);
    },
    // setPriceRate() {
    //     localStorage.setItem('priceRate', this.priceRate)
    //     let data = {
    //         goods_id: this.goodsId,
    //         price_rate: this.priceRate
    //     }
    //     console.log('setPriceRate', data)
    //     editGoodsPriceRate(data).then(res => {})
    // },
    // getPriceRate() {
    //     let priceRate = this.goodInfo && this.goodInfo.price_rate ? this.goodInfo.price_rate : localStorage.getItem('priceRate')
    //     this.chkPriceRate(priceRate)
    // },
    chkPriceRate: function chkPriceRate() {
      var value = this.submitForm.price_rate;
      value = parseFloat(value);
      value = isNaN(value) || value <= 0 ? this.defaultPriceRate : value;
      this.submitForm.price_rate = value.toFixed(2);
    },
    //到期买断计算
    buyout: function buyout(referencePrice) {
      var all_money = (referencePrice * this.submitForm.price_rate).toFixed(2);
      var month_money = referencePrice * this.submitForm.price_rate / 12;
      month_money = (month_money - 0.01).toFixed(2);
      var cash_money = all_money - month_money * 12;
      cash_money = cash_money.toFixed(2);
      return '推荐销售价格: ' + all_money + ', 押金:' + cash_money + ', 月租金:' + month_money;
    },
    //到期归还计算
    getback: function getback(referencePrice) {
      var all_money = (referencePrice * this.submitForm.price_rate).toFixed(2);
      var month_money = referencePrice * 0.8 / 12;
      month_money = (month_money - 0.01).toFixed(2);
      var cash_money = all_money - month_money * 12;
      cash_money = cash_money.toFixed(2);
      return '推荐销售价格: ' + all_money + ', 押金:' + cash_money + ', 月租金:' + month_money;
    },
    //首月1元计算
    firstMoney: function firstMoney(referencePrice) {
      var all_money = (referencePrice * this.submitForm.price_rate).toFixed(2);
      var month_money = (referencePrice * 0.8 - 1) / 11 - 0.01;
      month_money = month_money.toFixed(2);
      var cash_money = all_money - month_money * 11 - 1;
      cash_money = cash_money.toFixed(2);
      return '推荐销售价格: ' + all_money + ', 押金:' + cash_money + ', 月租金:' + month_money;
    },
    firstMoneybuyout: function firstMoneybuyout(referencePrice) {
      var all_money = (referencePrice * this.submitForm.price_rate).toFixed(2);
      var month_money = (referencePrice * this.submitForm.price_rate - 1) / 11 - 0.01;
      month_money = month_money.toFixed(2);
      var cash_money = all_money - month_money * 11 - 1;
      cash_money = cash_money.toFixed(2);
      return '推荐销售价格: ' + all_money + ', 押金:' + cash_money + ', 月租金:' + month_money;
    },
    // 提交审核
    submitAudit: function submitAudit() {
      var _this4 = this;
      (0, _goods.goodsSubmitReview)(this.goodsId).then(function (res) {
        _this4.$router.push({
          name: 'goodsSuccess',
          params: {
            goodsId: _this4.goodsId,
            isEdit: true
          }
        });
      });
    },
    // 保存草稿
    saveToDraft: function saveToDraft() {
      var _this5 = this;
      if (!this.goodsId) {
        this.$message({
          message: '请先提交商品基础信息',
          type: 'warning'
        });
        return;
      }
      if (!this.submitForm.title) {
        this.$message({
          message: '套餐名字不能为空',
          type: 'warning'
        });
        return;
      }
      var params = _objectSpread(_objectSpread({
        goods_id: this.goodsId,
        spec: []
      }, this.submitForm), {}, {
        status: this.status,
        operation_type: 1
      });
      if (this.submitForm.lease_set == 1) {
        params.spec = (0, _toConsumableArray2.default)(this.monthList);
      } else if (this.submitForm.lease_set == 2) {
        params.spec = (0, _toConsumableArray2.default)(this.dayList);
      } else {
        params.spec = (0, _toConsumableArray2.default)(this.customList);
      }
      if (params.setmeal_id) {
        // 存在套餐id，编辑套餐
        (0, _goods.editGoodsSetmeal)(params).then(function (res) {
          _this5.saveStorage();
          _this5.$message({
            message: '保存成功',
            type: 'success'
          });
        });
      } else {
        // 不存在套餐id，添加套餐
        delete params.setmeal_id;
        (0, _goods.addSetmeal)(params).then(function (res) {
          _this5.submitForm.setmeal_id = res.setmeal_id;
          _this5.saveStorage();
          _this5.$message({
            message: '保存成功',
            type: 'success'
          });
        });
      }
    },
    // 返回上一标签
    returnTab: function returnTab() {
      if (this.currentMeal == 1) {
        this.$emit('setTab', 'base');
      } else {
        this.$emit('setTab', 'meal' + (this.currentMeal - 1));
      }
    },
    save: function save() {
      var _this6 = this;
      var that = this;
      // this.$confirm('商品信息变更需要平台审核，审核时间1个工作日，确定修改商品？', '修改商品', {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消',
      // }).then(() => {
      that.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          if (!that.validCheck()) {
            return;
          }
          _this6.setMonthListSpecValue(that.submitForm.lease_set);
          that.status = 2; // 商品编辑之后,状态自动下架
          var params = _objectSpread(_objectSpread({
            goods_id: that.goodsId,
            spec: []
          }, that.submitForm), {}, {
            status: that.status,
            operation_type: 3
          });
          if (that.submitForm.lease_set == 1) {
            params.spec = (0, _toConsumableArray2.default)(that.monthList);
          } else if (that.submitForm.lease_set == 2) {
            params.spec = (0, _toConsumableArray2.default)(that.dayList);
          } else {
            params.spec = (0, _toConsumableArray2.default)(that.customList);
          }
          // this.setPriceRate()
          if (_this6.editId > 0 && _this6.isCopy == 0) {
            (0, _goods.editGoodsSetmeal)(params).then(function (res) {
              that.$message({
                message: '保存成功',
                type: 'success'
              });
              that.$router.back();
            });
          } else {
            delete params.setmeal_id;
            (0, _goods.addSetmeal)(params).then(function (res) {
              that.$message({
                message: '添加成功',
                type: 'success'
              });
              that.$router.back();
            });
          }
        } else {
          that.$message({
            message: '请检查表单错误',
            type: 'error'
          });
          return false;
        }
      });
      // }).catch(() => {});
    },
    setMonthListSpecValue: function setMonthListSpecValue(type) {
      var _this7 = this;
      switch (type) {
        case 1:
          this.monthList.forEach(function (item, index) {
            item.spec_value = _this7.submitForm.specs_value;
          });
          break;
        case 2:
          this.dayList.forEach(function (item, index) {
            item.spec_value = _this7.submitForm.specs_value;
          });
          break;
        case 3:
          this.customList.forEach(function (leaseList) {
            leaseList.forEach(function (item) {
              item.spec_value = _this7.submitForm.specs_value;
            });
          });
          break;
      }
      console.log('this.monthList', this.monthList);
    },
    // 表单校验
    validCheck: function validCheck() {
      // 校验租期设置
      if (this.submitForm.lease_set == 1) {
        if (this.monthList.length === 0) {
          this.$message({
            message: '租期设置不能为空',
            type: 'warning'
          });
          return false;
        }
        var _iterator = _createForOfIteratorHelper(this.monthList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            if (!item.lease_time || !item.lease_price) {
              this.$message({
                message: '租期设置必填项不能为空',
                type: 'warning'
              });
              return false;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        var arr = this.monthList.map(function (k) {
          return k.lease_time;
        });
        var uniqueArr = (0, _toConsumableArray2.default)(new Set(arr));
        console.log(uniqueArr, arr);
        if (uniqueArr.length < arr.length) {
          // this.$message({
          //   message: "租期设置租期不能重复",
          //   type: "warning"
          // });
          // return false;
        }
      } else if (this.submitForm.lease_set == 2) {
        if (this.dayList.length === 0) {
          this.$message({
            message: '租期设置不能为空',
            type: 'warning'
          });
          return false;
        }
        var _iterator2 = _createForOfIteratorHelper(this.dayList),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var _item = _step2.value;
            if (!_item.lease_time || !_item.lease_price) {
              this.$message({
                message: '租期设置必填项不能为空',
                type: 'warning'
              });
              return false;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        var _arr = this.dayList.map(function (k) {
          return k.lease_time;
        });
        var _uniqueArr = (0, _toConsumableArray2.default)(new Set(_arr));
        if (_uniqueArr.length < _arr.length) {
          // this.$message({
          //   message: "租期设置租期不能重复",
          //   type: "warning"
          // });
          // return false;
        }
      } else {
        if (this.customList.length === 0) {
          this.$message({
            message: '租期设置不能为空',
            type: 'warning'
          });
          return false;
        }
        var _iterator3 = _createForOfIteratorHelper(this.customList),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _item2 = _step3.value;
            var _iterator4 = _createForOfIteratorHelper(_item2),
              _step4;
            try {
              for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                var row = _step4.value;
                if (!row.lease_price) {
                  this.$message({
                    message: '租期设置每期租金不能为空',
                    type: 'warning'
                  });
                  return false;
                }
              }
            } catch (err) {
              _iterator4.e(err);
            } finally {
              _iterator4.f();
            }
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
      }
      if (this.submitForm.is_huabei == 1) {
        // 开启花呗，校验花呗设置数组
        if (this.submitForm.huabei_data.length === 0) {
          this.$message({
            message: '花呗设置不能为空',
            type: 'warning'
          });
          return false;
        }
        var _iterator5 = _createForOfIteratorHelper(this.submitForm.huabei_data),
          _step5;
        try {
          for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
            var _item3 = _step5.value;
            if (!_item3.three_bear || !_item3.six_bear || !_item3.twelve_bear) {
              this.$message({
                message: '花呗设置承担选项不能为空',
                type: 'warning'
              });
              return false;
            }
          }
        } catch (err) {
          _iterator5.e(err);
        } finally {
          _iterator5.f();
        }
      }
      return true;
    },
    // 添加套餐
    addMeal: function addMeal(isPublish) {
      var _this8 = this;
      // 判断是否有商品id
      if (!this.goodsId) {
        this.$message({
          message: '请先提交商品基础信息',
          type: 'warning'
        });
        return;
      }
      // 提交当前表单
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          if (!_this8.validCheck()) {
            return;
          }
          var params = _objectSpread(_objectSpread({
            goods_id: _this8.goodsId,
            spec: []
          }, _this8.submitForm), {}, {
            status: _this8.status,
            operation_type: isPublish ? 2 : 3
          });
          _this8.setMonthListSpecValue(_this8.submitForm.lease_set);
          if (_this8.submitForm.lease_set == 1) {
            params.spec = (0, _toConsumableArray2.default)(_this8.monthList);
          } else if (_this8.submitForm.lease_set == 2) {
            params.spec = (0, _toConsumableArray2.default)(_this8.dayList);
          } else {
            params.spec = (0, _toConsumableArray2.default)(_this8.customList);
          }
          // this.setPriceRate()
          if (params.setmeal_id) {
            // 存在套餐id，编辑套餐
            (0, _goods.editGoodsSetmeal)(params).then(function (res) {
              if (isPublish) {
                _this8.$router.push({
                  name: 'goodsSuccess',
                  params: {
                    goodsId: _this8.goodsId
                  }
                });
              } else {
                _this8.saveStorage();
                _this8.$emit('addTab');
              }
            });
          } else {
            // 不存在套餐id，添加套餐
            delete params.setmeal_id;
            (0, _goods.addSetmeal)(params).then(function (res) {
              if (isPublish) {
                _this8.$router.push({
                  name: 'goodsSuccess',
                  params: {
                    goodsId: _this8.goodsId
                  }
                });
              } else {
                _this8.submitForm.setmeal_id = res.setmeal_id;
                _this8.saveStorage();
                _this8.$emit('addTab');
              }
            });
          }
        } else {
          _this8.$message({
            message: '请检查表单错误',
            type: 'error'
          });
          return false;
        }
      });
    },
    // 切换租期设置类型
    changeType: function changeType(val) {
      // 切换重置
      this.submitForm.is_huabei = 2;
      this.submitForm.huabei_data = [{
        lease_term: '',
        rent_total: '',
        three_bear: '',
        three_fee: '',
        six_bear: '',
        six_fee: '',
        twelve_bear: '',
        twelve_fee: ''
      }];
      this.monthList = [{
        lease_time: null,
        lease_price: ''
      }];
      this.dayList = [{
        lease_time: '',
        lease_price: ''
      }];
      this.customForm = {
        lease_days: 0,
        withhold_cycle: '',
        lease_price: '',
        spec_value: ''
      };
    },
    quickSet: function quickSet() {
      var _this9 = this;
      this.submitForm.huabei_data.forEach(function (element) {
        if (_this9.setForm.third) {
          element.three_bear = _this9.setForm.third;
        }
        if (_this9.setForm.sixth) {
          element.six_bear = _this9.setForm.sixth;
        }
        if (_this9.setForm.twelve) {
          element.twelve_bear = _this9.setForm.twelve;
        }
        _this9.setHbFee(element);
      });
      // 清空上次设置
      this.setForm = {
        third: '',
        sixth: '',
        twelve: ''
      };
    },
    // 花呗承担切换
    changeHbBear: function changeHbBear(val, obj, key) {
      if (val == 1 || val == 2) {
        this.setHbFee(obj);
      } else {
        obj[key] = '';
      }
    },
    // 保留两位小数
    toFix2: function toFix2(calVal) {
      return (Math.round(calVal * 100) / 100).toFixed(2);
    },
    // 设置花呗分期承担金额
    setHbFee: function setHbFee(tableItem) {
      if (tableItem.three_bear == 1) {
        tableItem.three_fee = this.toFix2(0.018 * tableItem.rent_total);
      }
      if (tableItem.three_bear == 2) {
        tableItem.three_fee = this.toFix2(0.023 * tableItem.rent_total);
      }
      if (tableItem.six_bear == 1) {
        tableItem.six_fee = this.toFix2(0.045 * tableItem.rent_total);
      }
      if (tableItem.six_bear == 2) {
        tableItem.six_fee = this.toFix2(0.045 * tableItem.rent_total);
      }
      if (tableItem.twelve_bear == 1) {
        tableItem.twelve_fee = this.toFix2(0.075 * tableItem.rent_total);
      }
      if (tableItem.twelve_bear == 2) {
        tableItem.twelve_fee = this.toFix2(0.075 * tableItem.rent_total);
      }
    },
    // 按月租期项更改
    itemChange: function itemChange(index) {
      var _ref = this.submitForm.lease_set == 1 ? this.monthList[index] : this.dayList[index],
        lease_time = _ref.lease_time,
        lease_price = _ref.lease_price;
      if (!lease_time || !lease_price) {
        return;
      }
      var hbSetItem = this.submitForm.huabei_data[index];
      hbSetItem.lease_term = lease_time;
      hbSetItem.rent_total = (parseFloat(lease_price) * lease_time).toFixed(2);
      this.setHbFee(hbSetItem);
    },
    changeNumberInput: function changeNumberInput(obj, key) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(obj[key])) {
        // input 框绑定的内容为空
        obj[key] = '';
      }
    },
    changeMoneyInput: function changeMoneyInput(obj, key) {
      var pattern = /^[0-9]+([.]{1}[0-9]{1,2})?$/; // 正数的正则表达式
      // 不符合正数时
      if (!pattern.test(obj[key])) {
        // input 框绑定的内容为空
        obj[key] = '';
      }
    },
    // 删除自定义租期
    removeCustomItem: function removeCustomItem(index) {
      this.customList.splice(index, 1);
    },
    // 新增自定义租期
    addCustomItem: function addCustomItem() {
      console.log(this.customForm);
      if (!this.customForm.lease_time || !this.customForm.withhold_cycle) {
        this.$message({
          message: '总租期、租期间隔不能为空',
          type: 'warning'
        });
        return;
      }
      if (this.customForm.withhold_cycle < 7 || this.customForm.withhold_cycle > 30) {
        this.$message({
          message: '租期间隔为7-30天',
          type: 'warning'
        });
        return;
      }
      // if (!this.customForm.withhold_cycle) {
      //     this.$message({
      //         message: "租期不能为空",
      //         type: "warning"
      //     });
      //     return;
      // }
      // const periodTotal = Math.ceil(parseInt(this.customForm.lease_days) / parseInt(this.customForm.withhold_cycle));
      var periodTotal = parseInt(this.customForm.lease_time);
      var data = [];
      for (var index = 1; index <= periodTotal; index++) {
        var obj = {
          which_period: index,
          lease_days: this.customForm.withhold_cycle,
          withhold_cycle: this.customForm.withhold_cycle,
          lease_price: this.customForm.lease_price ? this.customForm.lease_price : 0
        };
        data.push(obj);
      }
      this.customList.push(data);
      // 清空表单
      this.customForm = this.$options.data().customForm;
    },
    // 删除天租期
    removeDayItem: function removeDayItem(index) {
      this.dayList.splice(index, 1);
      this.submitForm.huabei_data.splice(index, 1);
    },
    // 新增天租期
    addDayItem: function addDayItem() {
      this.dayList.push({
        lease_time: '',
        lease_price: ''
      });
      this.submitForm.huabei_data.push({
        lease_term: '',
        rent_total: '',
        three_bear: '',
        three_fee: '',
        six_bear: '',
        six_fee: '',
        twelve_bear: '',
        twelve_fee: ''
      });
    },
    // 删除月租期
    removeMonthItem: function removeMonthItem(index) {
      this.monthList.splice(index, 1);
      this.submitForm.huabei_data.splice(index, 1);
    },
    // 新增月租期
    addMonthItem: function addMonthItem() {
      this.monthList.push({
        lease_time: null,
        lease_price: '',
        spec_value: this.submitForm.specs_value
      });
      this.submitForm.huabei_data.push({
        lease_term: '',
        rent_total: '',
        three_bear: '',
        three_fee: '',
        six_bear: '',
        six_fee: '',
        twelve_bear: '',
        twelve_fee: ''
      });
    },
    saveStorage: function saveStorage(index) {
      var params = {
        formData: this.submitForm,
        monthList: this.monthList,
        dayList: this.dayList,
        customList: this.customList
      };
      var list = sessionStorage.getItem('mealInfo') ? JSON.parse(sessionStorage.getItem('mealInfo')) : [];
      list[index - 1] = _objectSpread({}, params);
      sessionStorage.setItem('mealInfo', JSON.stringify(list));
      sessionStorage.setItem('goodsStatus', this.status);
    }
  }
};