"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _finance = require("@/api/finance");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default
  },
  props: ['orderNo', 'leaseId', 'orderId'],
  data: function data() {
    return {
      activeName: 'deduct',
      dialogVisible: false,
      tableData: [],
      total: 0
    };
  },
  methods: {
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.getDeduct();
    },
    getDeduct: function getDeduct() {
      var _this = this;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      var pageInfo = {
        page: page,
        pageSize: pageSize
      };
      if (this.activeName == 'deduct') {
        (0, _finance.leaseWithholdLog)(_objectSpread({
          orderNo: this.orderNo,
          leaseId: this.leaseId
        }, pageInfo)).then(function (res) {
          _this.tableData = res.data || [];
          _this.total = res.total || 0;
        });
      } else {
        this.tableData = [];
        (0, _finance.getOrderLog)(_objectSpread({
          order_id: this.orderId
        }, pageInfo)).then(function (res) {
          _this.tableData = res.data || [];
          _this.total = res.total || 0;
        });
      }
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;
      if (val) {
        this.$nextTick(function () {
          _this2.getDeduct();
        });
        this.activeName = 'deduct';
      }
    }
  }
};