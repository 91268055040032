var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: { title: "查看风控详情", visible: _vm.dialogVisible, size: "50%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticStyle: { width: "100%", height: "400px" },
      }),
      !_vm.loading
        ? _c("div", { staticStyle: { position: "relative", height: "100%" } }, [
            _c("iframe", {
              attrs: {
                src: _vm.tableData,
                width: "100%",
                height: "100%",
                frameborder: "0",
              },
            }),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }