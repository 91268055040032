"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.func = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
var _elementUi = require("element-ui");
var _config = _interopRequireDefault(require("../config"));
var func = exports.func = {
  chkLoginIdentity: function chkLoginIdentity() {
    var flag = 0;
    var host = window.location.host;
    if (host.indexOf('supplier') != -1) {
      // 供应商
      flag = 1;
    } else if (host.indexOf('shop') != -1) {
      // 商户
      flag = 2;
    }
    return flag;
  },
  // 修改页面标题
  setTitle: function setTitle(title) {
    document.title = title;
  },
  // 根据host修改页面标题
  setTitleByHost: function setTitleByHost() {
    var title = '管理系统';
    var identity = this.chkLoginIdentity();
    if (identity == 1) {
      title = '供应商后台管理系统';
    } else if (identity == 2) {
      title = '商户后台管理系统';
    }
    this.setTitle(title);
  },
  openWindow: function openWindow(url) {
    window.open(url, '_blank');
  },
  // 深度克隆
  deepClone: function deepClone(source) {
    return JSON.parse(JSON.stringify(source));
  },
  // 整数过滤
  filterNum: function filterNum(val) {
    return val.replace(/[^[0-9-]*/g, '');
  },
  // 浮点数过滤
  filterFloat: function filterFloat(val) {
    return val.replace(/[^[0-9.-]*/g, '');
  }
};

// 阿里云图片
func.handleOssImg = function (url) {
  // 本地文件和远程文件不做处理
  if (url.indexOf('http') == -1 && url.indexOf('/static/') == -1) {
    if (url.indexOf('/') == -1) {
      url = _config.default.defaultImgUrl + url;
    } else {
      url = _config.default.imgBaseUrl + url;
    }
  }
  return url;
};
func.isError = function (msg, callback) {
  if (msg) {
    (0, _elementUi.Message)({
      message: msg,
      type: 'error',
      duration: 1000,
      onClose: function onClose() {
        typeof callback == 'function' && callback();
      }
    });
  } else {
    typeof callback == 'function' && callback();
  }
};

/** 格式 YYYY/yyyy/YY/yy 表示年份
 * MM/mm/M/m 月份
 * W/w 星期
 * dd/DD/d/D 日期
 * hh/HH/h/H 时间
 * II/ii/I/i 分钟
 * ss/SS/s/S 秒
 **/
func.dateFormat = function () {
  var formatStr = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'yyyy-mm-dd hh:ii:ss';
  var timestamp = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date.now();
  timestamp = timestamp.toString().length == 10 ? timestamp * 1000 : timestamp;
  var str = formatStr;
  var Week = ['日', '一', '二', '三', '四', '五', '六'];
  var date = new Date(timestamp);
  str = str.replace(/yyyy|YYYY/, date.getFullYear());
  str = str.replace(/yy|YY/, date.getYear() % 100 > 9 ? (date.getYear() % 100).toString() : '0' + date.getYear() % 100);
  str = str.replace(/mm|MM/, date.getMonth() > 8 ? (date.getMonth() + 1).toString() : '0' + (date.getMonth() + 1));
  str = str.replace(/m|M/g, date.getMonth() + 1);
  str = str.replace(/w|W/g, Week[date.getDay()]);
  str = str.replace(/dd|DD/, date.getDate() > 9 ? date.getDate().toString() : '0' + date.getDate());
  str = str.replace(/d|D/g, date.getDate());
  str = str.replace(/hh|HH/, date.getHours() > 9 ? date.getHours().toString() : '0' + date.getHours());
  str = str.replace(/h|H/g, date.getHours());
  str = str.replace(/ii|II/, date.getMinutes() > 9 ? date.getMinutes().toString() : '0' + date.getMinutes());
  str = str.replace(/i|I/g, date.getMinutes());
  str = str.replace(/ss|SS/, date.getSeconds() > 9 ? date.getSeconds().toString() : '0' + date.getSeconds());
  str = str.replace(/s|S/g, date.getSeconds());
  return str;
};