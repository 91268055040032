var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "账单详情", visible: _vm.dialogVisible, width: "1000px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, border: "" } },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c("div", { staticClass: "flex flex-wrap detail" }, [
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("平台手续费：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.platform_amount) +
                            "（" +
                            _vm._s(props.row.trans.platform_rate) +
                            "%）\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("实名认证：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.realname_amount) +
                            "\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("合同：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.contract_amount) +
                            "\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("风控：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.risk_amount) +
                            "\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("保险：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.insurance_amount) +
                            "（" +
                            _vm._s(props.row.trans.insurance_rate) +
                            "%）\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("融资总租金：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.financing_amount) +
                            "（" +
                            _vm._s(props.row.trans.financing_rate) +
                            "%）\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("存证：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.file_amount) +
                            "（" +
                            _vm._s(props.row.trans.file_rate) +
                            "%）\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("代扣手续费：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.withhold_amount) +
                            "（" +
                            _vm._s(props.row.trans.withhold_rate) +
                            "%）\n          "
                        ),
                      ]),
                      _c("p", { staticClass: "detail_item" }, [
                        _c("label", [_vm._v("花呗：")]),
                        _vm._v(
                          "\n            ￥" +
                            _vm._s(props.row.trans.huabei_fee || "0.00") +
                            "\n          "
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "编号", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "付款方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.pay_type == 1
                            ? "智能合同代扣"
                            : scope.row.pay_type == 2
                            ? "芝麻信用"
                            : scope.row.pay_type == 3
                            ? "花呗"
                            : "主动支付"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "付款时间", prop: "pay_time" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "付款内容" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.status == 3
                            ? "订单买断"
                            : scope.row.status == 1
                            ? "第" + scope.row.which_period + "期账单交租"
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "流水号", prop: "trade_no" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "付款金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.rent ? "￥" + scope.row.rent : "")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "结算手续费" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.trans.total_charge
                            ? "￥" + scope.row.trans.total_charge
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "结算金额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.trans.settlement_amount
                            ? "￥" + scope.row.trans.settlement_amount
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type:
                            scope.row.settle_type == 1 ? "success" : "warning",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.settle_type == 1
                              ? "已结算"
                              : scope.row.settle_type == 2
                              ? "拒绝计算"
                              : scope.row.settle_type == 3
                              ? "已退款结算"
                              : "未结算"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }