"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "AppMain",
  computed: {
    key: function key() {
      return this.$route.fullPath;
    },
    scrollHeight: function scrollHeight() {
      return this.$refs.appMain.scrollHeight;
    },
    clientHeight: function clientHeight() {
      return this.$refs.appMain.clientHeight;
    }
  },
  data: function data() {
    return {
      fixFooter: false,
      noShadowList: ['goodsIndex', 'goodsAdd', 'shopInfo', 'shopShop', 'billList', 'myFlow', 'myIndex', 'myWithdraw'],
      noPaddingList: ['goodsMeal', 'goodsBaseEdit'],
      noTitleList: ['myWithdraw']
    };
  },
  watch: {
    $route: function $route(val) {
      this.fixFooter = false;
    }
  },
  mounted: function mounted() {
    // this.fixFooter = this.scrollHeight > this.clientHeight;
  },
  methods: {
    handleScroll: function handleScroll(e) {
      var scrollBottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
      if (scrollBottom < 80) {
        this.fixFooter = false;
      } else {
        this.fixFooter = true;
      }
    }
  }
};