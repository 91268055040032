"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editMerchantAdminGruop = editMerchantAdminGruop;
exports.editMerchantCategoryIds = editMerchantCategoryIds;
exports.editMerchantGroup = editMerchantGroup;
exports.editMerchantInfo = editMerchantInfo;
exports.editMerchantMember = editMerchantMember;
exports.getBindMerchantList = getBindMerchantList;
exports.getDistributionMerchantList = getDistributionMerchantList;
exports.getEsignOrgId = getEsignOrgId;
exports.getEsignPsnId = getEsignPsnId;
exports.getEsignSealId = getEsignSealId;
exports.getMerchantAccount = getMerchantAccount;
exports.getMerchantAdminGruopList = getMerchantAdminGruopList;
exports.getMerchantChildList = getMerchantChildList;
exports.getMerchantGroupList = getMerchantGroupList;
exports.getMerchantInfo = getMerchantInfo;
exports.getMerchantList = getMerchantList;
exports.getMerchantOnceToken = getMerchantOnceToken;
exports.getPayeeInfo = getPayeeInfo;
exports.getServiceFeeList = getServiceFeeList;
exports.maYiPlatformCreate = maYiPlatformCreate;
exports.myAccount = myAccount;
exports.recharge = recharge;
exports.rechargeQuery = rechargeQuery;
exports.reconciliationExport = reconciliationExport;
exports.reconciliationFlow = reconciliationFlow;
exports.setAuditOrder = setAuditOrder;
exports.setMerchantMember = setMerchantMember;
exports.withdrawalApply = withdrawalApply;
exports.withdrawalInfo = withdrawalInfo;
exports.withdrawalList = withdrawalList;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// // 获取省份列表
// export function getProvinceList() {
//   return http.get("/Admin/Supplier/getCompanyArea");
// }
// // 获取区域二级列表
// export function getCompanyArea(id) {
//   return http.get("/Admin/Supplier/getSecondCompanyArea", { id, id });
// }

/**
 * 商户管理
 */

// 获取商户账号信息
function getMerchantAccount(id) {
  return _fetch.default.post('/Supplier/Merchant/getMerchantAccount', {
    id: id
  });
}
// 获取商户账号列表
function getMerchantList(params) {
  return _fetch.default.post('/Supplier/Merchant/getMerchantList', params);
}
// 获取商户(包含绑定商户)列表
function getBindMerchantList(params) {
  return _fetch.default.post('/Supplier/Merchant/getBindMerchantList', params);
}
// 获取接单商户列表
function getDistributionMerchantList(params) {
  return _fetch.default.post('/Supplier/Merchant/getDistributionMerchantList', params);
}
// 查看商户信息
function getMerchantInfo(id) {
  return _fetch.default.get('/Supplier/Merchant/getMerchantInfo', {
    id: id
  });
}

// 编辑商户信息
function editMerchantInfo(params) {
  return _fetch.default.post('/Supplier/Merchant/editMerchantInfo', params, true);
}
// 编辑商户经营项目
function editMerchantCategoryIds(params) {
  return _fetch.default.post('/Supplier/Merchant/editMerchantCategoryIds', params);
}
// 获取商户一次性登录token
function getMerchantOnceToken(id) {
  return _fetch.default.post('/Supplier/getMerchantOnceToken', {
    id: id
  });
}

// 获取商户管理员组权限
function getMerchantAdminGruopList(params) {
  return _fetch.default.post('/Supplier/Merchant/getMerchantAdminGruopList', params);
}
// 编辑商户管理员组权限
function editMerchantAdminGruop(params) {
  return _fetch.default.post('/Supplier/Merchant/editMerchantAdminGruop', params, true);
}
/**
 * e签宝认证
 */
// 获取e签宝psn_id
function getEsignPsnId(id) {
  return _fetch.default.post('/Supplier/Merchant/getEsignPsnId', {
    id: id
  }, true);
}
// 获取e签宝org_id
function getEsignOrgId(id) {
  return _fetch.default.post('/Supplier/Merchant/getEsignOrgId', {
    id: id
  }, true);
}
// 获取e签宝seal_id
function getEsignSealId(id) {
  return _fetch.default.post('/Supplier/Merchant/getEsignSealId', {
    id: id
  }, true);
}
// 获取e签宝蚂蚁链印章授权
// export function getEsignMayiSealId(id) {
//     return http.post('/Supplier/Merchant/getEsignMayiSealId', { id }, true)
// }

/**
 * 账号管理
 */
// 我的账户
function myAccount() {
  return _fetch.default.post('/Supplier/Merchant/myAccount');
}
// 获取收款账户信息
function getPayeeInfo() {
  return _fetch.default.post('/Supplier/Merchant/getPayeeInfo');
}
// 账户充值
function recharge(amount) {
  return _fetch.default.post('/Supplier/Merchant/recharge', {
    amount: amount
  });
}
// 提现记录
function withdrawalList(params) {
  return _fetch.default.post('/Supplier/Merchant/withdrawalList', params);
}
// 申请提现
function withdrawalApply(amount) {
  return _fetch.default.post('/Supplier/Merchant/withdrawalApply', {
    amount: amount
  }, true);
}
// 提现详情
function withdrawalInfo(withdrawal_id) {
  return _fetch.default.get('/Supplier/Merchant/withdrawalInfo', {
    withdrawal_id: withdrawal_id
  });
}
// 扫码结果查询
function rechargeQuery(rechargeId) {
  return _fetch.default.post('/Supplier/Merchant/rechargeQuery', {
    rechargeId: rechargeId
  });
}

// 获取手续费列表
function getServiceFeeList() {
  return _fetch.default.get('/Supplier/Merchant/getServiceFeeList');
}
// 财务对账流水
function reconciliationFlow(params) {
  return _fetch.default.post('/Supplier/Merchant/reconciliationFlow', params);
}
// 财务对账流水导出
function reconciliationExport(params) {
  return _fetch.default.export('/Supplier/Merchant/reconciliationExport', params, '对账流水');
}
/**
 * 成员管理
 */
// 获取商户成员分组列表
function getMerchantGroupList(params) {
  return _fetch.default.post('/Supplier/Merchant/getMerchantGroupList', params);
}
// 编辑商户成员分组
function editMerchantGroup(params) {
  return _fetch.default.post('/Supplier/Merchant/editMerchantGroup', params, true);
}
// 获取商户成员列表
function getMerchantChildList(params) {
  return _fetch.default.post('/Supplier/Merchant/getMerchantChildList', params);
}
// 编辑商户成员
function editMerchantMember(params) {
  return _fetch.default.post('/Supplier/Merchant/editMerchantMember', params, true);
}
// 批量设置商户成员
function setMerchantMember(params) {
  return _fetch.default.post('/Supplier/Merchant/setMerchantMember', params, true);
}
// 设置商户成员跟单
function setAuditOrder(params) {
  return _fetch.default.post('/Supplier/Merchant/setAuditOrder', params);
}

// 批量操作设置状态
function maYiPlatformCreate(params) {
  return _fetch.default.post('/Supplier/Merchant/maYiPlatformCreate', params, true);
}