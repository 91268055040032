"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _system = require("@/api/system");
var _validate = require("@/utils/validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["editItem", "roleList"],
  data: function data() {
    var _this = this;
    var validateMobile = function validateMobile(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var validateConfirm = function validateConfirm(rule, value, callback) {
      if (value !== _this.form.pwd) {
        callback(new Error('两次输入密码不同！'));
      } else {
        callback();
      }
    };
    var validatePwd = function validatePwd(rule, value, callback) {
      var reg = /^[^\u4e00-\u9fa5]{0,}$/;
      if (value && value.length < 6) {
        callback(new Error('密码必须大于等于6个字符'));
      } else if (!reg.test(value)) {
        callback(new Error('密码不能包含汉字！'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        username: "",
        pwd: "",
        confirmPwd: "",
        role_id: null,
        mobile: ""
      },
      rules: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          required: true,
          message: '重复密码不能为空',
          trigger: 'blur'
        }, {
          validator: validateConfirm,
          trigger: 'blur'
        }],
        role_id: [{
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }]
      },
      rules1: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          validator: validateConfirm,
          trigger: 'blur'
        }],
        role_id: [{
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editItem ? "编辑账号" : "添加账号";
    }
  },
  methods: {
    handleMobileChange: function handleMobileChange() {
      this.form.mobile = this.form.mobile.replace(/[^\d]/g, "");
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this2.editItem) {
            (0, _system.editUser)({
              id: _this2.form.id,
              username: _this2.form.username,
              pwd: _this2.form.pwd ? _this2.$md5(_this2.form.pwd) : "",
              role_id: _this2.form.role_id
            }).then(function (res) {
              _this2.dialogVisible = false;
              _this2.$emit("getList");
            });
          } else {
            (0, _system.addUser)({
              mobile: _this2.form.mobile,
              username: _this2.form.username,
              pwd: _this2.$md5(_this2.form.pwd),
              role_id: _this2.form.role_id
            }).then(function (res) {
              _this2.dialogVisible = false;
              _this2.$emit("getList");
            });
          }
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this3 = this;
      if (val) {
        this.$nextTick(function () {
          _this3.$refs['form'].resetFields();
          if (_this3.editItem) {
            // 编辑
            _this3.form = {
              id: _this3.editItem.id,
              username: _this3.editItem.username,
              mobile: _this3.editItem.mobile,
              role_id: _this3.editItem.role_id
            };
          }
        });
      } else {
        this.form = {
          mobile: "",
          username: "",
          pwd: "",
          confirmPwd: "",
          role_id: null
        };
      }
    }
  }
};