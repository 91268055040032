var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增转账记录",
        visible: _vm.dialogVisible,
        width: "550px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "收款人" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付宝账号" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.account,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "account", $$v)
                  },
                  expression: "formData.account",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转账金额(元)" } },
            [
              _c("el-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.formData.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "amount", $$v)
                  },
                  expression: "formData.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.resaon,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "resaon", $$v)
                  },
                  expression: "formData.resaon",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }