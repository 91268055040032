"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _marketing = require("@/api/marketing");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    selectId: {
      type: String
    },
    smsType: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      dataList: []
    };
  },
  methods: {
    changeNumberInput: function changeNumberInput(item) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(item.send_mobile)) {
        // input 框绑定的内容为空
        item.send_mobile = '';
      }
    },
    submit: function submit() {
      var _this = this;
      if (this.multiple) {
        var list = [];
        var _iterator = _createForOfIteratorHelper(this.dataList),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            var id = item.id,
              is_open = item.is_open,
              send_mobile = item.send_mobile;
            var obj = {
              sms_template_id: id,
              status: is_open,
              send_mobile: send_mobile
            };
            list.push(obj);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        var jsonStr = JSON.stringify(list);
        (0, _marketing.setBatchSms)({
          list: jsonStr
        }).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('getList');
        });
      } else {
        var _this$dataList$ = this.dataList[0],
          _id = _this$dataList$.id,
          _send_mobile = _this$dataList$.send_mobile,
          _is_open = _this$dataList$.is_open;
        (0, _marketing.setSingleSms)({
          sms_template_id: _id,
          status: _is_open,
          send_mobile: _send_mobile
        }).then(function (res) {
          _this.dialogVisible = false;
          _this.$emit('getList');
        });
      }
    },
    getData: function getData() {
      var _this2 = this;
      if (this.multiple) {
        // 批量
        (0, _marketing.batchSmsInfo)(this.smsType).then(function (res) {
          _this2.dataList = res.sms_template || [];
          if (_this2.smsType == 2) {
            var _iterator2 = _createForOfIteratorHelper(_this2.dataList),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var item = _step2.value;
                item.send_mobile = '';
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        });
      } else {
        // 获取单个信息
        (0, _marketing.singleSmsInfo)(this.selectId).then(function (res) {
          if (res.sms_type == 2) {
            res.send_mobile = '';
          }
          _this2.dataList = [_objectSpread({}, res)];
        });
      }
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      } else {
        this.dataList = [];
      }
    }
  }
};