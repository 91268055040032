"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _auth = require("@/utils/auth");
var _login = require("@/api/login");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    var _this = this;
    var validateConfirm = function validateConfirm(rule, value, callback) {
      if (value !== _this.form.pwd) {
        callback(new Error('两次输入密码不同！'));
      } else {
        callback();
      }
    };
    var validatePwd = function validatePwd(rule, value, callback) {
      var reg = /^[^\u4e00-\u9fa5]{0,}$/;
      if (value.length < 6) {
        callback(new Error('密码必须大于等于6个字符'));
      } else if (!reg.test(value)) {
        callback(new Error('密码不能包含汉字！'));
      } else {
        callback();
      }
    };
    return {
      form: {
        code: "",
        pwd: "",
        confirmPwd: ""
      },
      rules: {
        code: [{
          required: true,
          message: '验证码不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          required: true,
          message: '确认密码不能为空',
          trigger: 'blur'
        }, {
          validator: validateConfirm,
          trigger: 'blur'
        }]
      },
      showTime: false,
      // 显示60s倒计时
      timeCount: "",
      // 计时显示
      timer: null // 计时
    };
  },
  methods: {
    submit: function submit() {
      var _this2 = this;
      var that = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          (0, _login.editPwd)({
            pwd: _this2.form.pwd ? _this2.$md5(_this2.form.pwd) : "",
            code: _this2.form.code ? _this2.$md5(_this2.form.code) : ""
          }).then(function (res) {
            _this2.$alert('<span><i class="el-icon-circle-check success_icon"></i></span><br />修改密码成功！请重新登录。', '', {
              confirmButtonText: '知道了',
              dangerouslyUseHTMLString: true,
              center: true,
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              customClass: 'success_msgbox',
              callback: function callback(action) {
                that.$store.dispatch('user/logout');
              }
            });
          });
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    getCodes: function getCodes(mobile) {
      var _this3 = this;
      (0, _login.sendMobileCode)(mobile).then(function (res) {
        _this3.showTime = true;
        var TIME_COUNT = 60;
        _this3.timeCount = 60;
        _this3.timer = setInterval(function () {
          if (_this3.timeCount > 0 && _this3.timeCount <= TIME_COUNT) {
            _this3.timeCount--;
          } else {
            _this3.showTime = false;
            clearInterval(_this3.timer);
            _this3.timer = null;
          }
        }, 1000);
      });
    },
    getPhoneCode: function getPhoneCode() {
      if (this.showTime === true) {
        return;
      }
      this.getCodes(this.account);
    }
  },
  computed: {
    account: function account() {
      //console.log(getAccount())
      return (0, _auth.getMobile)();
    }
  }
};