var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.activeTab != 3
        ? _c(
            "div",
            { staticClass: "warn-box" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "17px" },
                attrs: { "icon-class": "warn", size: 20 },
              }),
              _c("p", [
                _vm._v(
                  " 请确保提交的商品符合国家法律法规，不得包含违禁商品。平台将随时进行抽审，一经发现，将对商品和店铺进行处理，严重将追究法律责任。"
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "common_form_search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { inline: true, model: _vm.searchForm },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称", clearable: "" },
                    model: {
                      value: _vm.searchForm.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "goods_name", $$v)
                      },
                      expression: "searchForm.goods_name",
                    },
                  }),
                ],
                1
              ),
              _vm.activeTab != 3
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "商品品类" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择商品品类",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.cate_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "cate_id", $$v)
                              },
                              expression: "searchForm.cate_id",
                            },
                          },
                          _vm._l(_vm.categoryList, function (item) {
                            return _c("el-option", {
                              key: item.category_id,
                              attrs: {
                                label: item.name,
                                value: item.category_id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "租赁方式" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.rent_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "rent_type", $$v)
                              },
                              expression: "searchForm.rent_type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "随租随还", value: 3 },
                            }),
                            _c("el-option", {
                              attrs: { label: "固定租期", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "租完即送", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "续租商品" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.searchForm.is_relet,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "is_relet", $$v)
                              },
                              expression: "searchForm.is_relet",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "全部", value: "" },
                            }),
                            _c("el-option", {
                              attrs: { label: "普通商品", value: 0 },
                            }),
                            _c("el-option", {
                              attrs: { label: "续租商品", value: 1 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "创建时间" } },
                      [_c("my-date-picker", { ref: "myDatePicker" })],
                      1
                    ),
                  ]
                : [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核类型" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.check_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "check_type", $$v)
                              },
                              expression: "searchForm.check_type",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "商品审核", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "下架审核", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "审核状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "全部", clearable: "" },
                            model: {
                              value: _vm.searchForm.check_status,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "check_status", $$v)
                              },
                              expression: "searchForm.check_status",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "未审核", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "审核未通过", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.search()
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.reset()
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 },
              }),
              _vm._v("数据列表 "),
            ],
            1
          ),
          _vm.$hasMethod("#add") && _vm.activeTab != 3
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push({ name: "goodsAdd" })
                    },
                  },
                },
                [_vm._v("发布商品")]
              )
            : _vm._e(),
          _vm.$hasMethod("#enable") && _vm.activeTab == 2
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.setStatus(0)
                    },
                  },
                },
                [_vm._v("批量上架")]
              )
            : _vm._e(),
          _vm.$hasMethod("#enable") && _vm.activeTab == 1
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.setStatus(1)
                    },
                  },
                },
                [_vm._v("批量下架")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tableData.length > -1,
              expression: "tableData.length > -1",
            },
          ],
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "id", label: "id", width: "60" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "goods_sort",
              label: "排序",
              width: "70",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      on: {
                        change: function ($event) {
                          return _vm.editSort(scope.row)
                        },
                      },
                      model: {
                        value: scope.row.goods_sort,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "goods_sort", $$v)
                        },
                        expression: "scope.row.goods_sort",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "商品信息", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex align-center goods_info" },
                      [
                        _c(
                          "el-image",
                          {
                            staticStyle: { width: "60px", height: "60px" },
                            attrs: {
                              src: _vm.$func.handleOssImg(scope.row.imgurl),
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "flex-1 text-left info" }, [
                          _c(
                            "p",
                            { staticClass: "activity" },
                            _vm._l(scope.row.activity, function (item, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "inline-block vertical-middle",
                                },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top-start",
                                        width: "160",
                                        trigger: "hover",
                                        content: "商城商品推荐",
                                      },
                                    },
                                    [
                                      item.activity_img
                                        ? _c("img", {
                                            staticClass: "w-100 h-100",
                                            attrs: {
                                              slot: "reference",
                                              src:
                                                _vm.$globalObj.imgApiUrl +
                                                item.activity_img,
                                              alt: item.activity_title,
                                            },
                                            slot: "reference",
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c("p", [_vm._v(_vm._s(scope.row.title))]),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100",
              prop: "pact_type",
              label: "租赁方式",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "100",
              prop: "rent_type",
              label: "租期类型",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "120", label: "库存" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "p",
                      [
                        _vm.activeTab != 3
                          ? _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "top-start",
                                  width: "160",
                                  trigger: "hover",
                                  content: "商品套餐库存不足",
                                },
                              },
                              [
                                scope.row.is_lack
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "vertical-middle",
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning warning-font",
                                          staticStyle: { "font-size": "18px" },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n                        " +
                            _vm._s(scope.row.stock) +
                            "\n                    "
                        ),
                      ],
                      1
                    ),
                    _vm.$hasMethod("#editStock") && _vm.activeTab != 3
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { padding: "0" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editStock(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("修改库存")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", prop: "cate_name", label: "品类" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "create_time",
              label: "创建时间",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              prop: "is_relet",
              label: "是否续租商品",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.is_relet == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("续租商品"),
                        ])
                      : _c("el-tag", [_vm._v("普通商品")]),
                  ]
                },
              },
            ]),
          }),
          _vm.activeTab != 3
            ? _c(
                "el-table-column",
                {
                  attrs: { align: "center", prop: "status", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    scope.row.status == 1
                                      ? "success"
                                      : scope.row.status == 3
                                      ? "warning"
                                      : "danger",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    scope.row.status == 1
                                      ? "已上架"
                                      : scope.row.status == 3
                                      ? "申请下架"
                                      : scope.row.status == 4
                                      ? "申请下架拒绝"
                                      : scope.row.status == 2
                                      ? "商家下架"
                                      : "违规下架"
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2471286036
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _vm.activeTab == 1
                        ? _c("span", [_vm._v("状态")])
                        : _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "top",
                                width: "250",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", { staticClass: "color-666" }, [
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "color-333 bold-font" },
                                      [_vm._v("商家下架：")]
                                    ),
                                    _vm._v("商家手动下架商品状态。"),
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticStyle: { "font-size": "12px" } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "color-333 bold-font" },
                                      [_vm._v("违规下架：")]
                                    ),
                                    _vm._v(
                                      "商品存在违规信息，需要编辑后提交审核才可上架。 "
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [_vm._v("状态")]
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
          _vm.activeTab == 3
            ? [
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "status_name",
                    label: "审核后商品状态",
                  },
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "审核状态" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    scope.row.check_status == 1 ? "" : "danger",
                                },
                              },
                              [_vm._v(_vm._s(scope.row.check_status_name))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3165427135
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    prop: "check_type_name",
                    label: "审核类型",
                  },
                }),
              ]
            : _vm._e(),
          _vm.activeTab != 1
            ? _c("el-table-column", {
                attrs: { align: "center", label: "原因" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("p", [
                            _vm._v(_vm._s(scope.row.check_reason || "")),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2095466663
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              align: "center",
              label: "操作",
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.$hasMethod("#edit") &&
                    (_vm.activeTab == 2 ||
                      (_vm.activeTab == 3 && scope.row.check_type == 1))
                      ? _c(
                          "el-dropdown",
                          {
                            staticStyle: { "margin-right": "10px" },
                            on: {
                              command: function (command) {
                                return _vm.handleCommand(command, scope.row.id)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              { attrs: { type: "primary", size: "mini" } },
                              [
                                _vm._v("编辑"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]
                            ),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "1" } },
                                  [_vm._v("商品信息")]
                                ),
                                _c(
                                  "el-dropdown-item",
                                  { attrs: { command: "2" } },
                                  [_vm._v("套餐信息")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#submit") &&
                    ((_vm.activeTab == 2 &&
                      scope.row.check_status == 2 &&
                      scope.row.check_type == 2) ||
                      (_vm.activeTab == 3 &&
                        scope.row.check_type == 2 &&
                        scope.row.status == 4))
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.submitAudit(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("重新提交")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#enable") &&
                    _vm.activeTab != 3 &&
                    (scope.row.status == 2 || scope.row.status == 5)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.changeStatus(
                                  scope.row.id,
                                  scope.row.status,
                                  scope.row.activity &&
                                    scope.row.activity.length > 0
                                )
                              },
                            },
                          },
                          [_vm._v("上架\n                    ")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#enable") &&
                    _vm.activeTab != 3 &&
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "warning", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.changeStatus(
                                  scope.row.id,
                                  scope.row.status,
                                  scope.row.activity &&
                                    scope.row.activity.length > 0
                                )
                              },
                            },
                          },
                          [_vm._v("下架")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#enable")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.setActivity(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("设置活动")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#view")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.view(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#cancel") &&
                    _vm.activeTab == 3 &&
                    (scope.row.ischeck == 3 || scope.row.status == 3)
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelAudit(
                                  scope.row.id,
                                  scope.row.check_type
                                )
                              },
                            },
                          },
                          [_vm._v("取消")]
                        )
                      : _vm._e(),
                    _c(
                      "el-dropdown",
                      [
                        _c(
                          "span",
                          {
                            staticClass: "el-dropdown-link blue-font pointer",
                            staticStyle: { "margin-left": "10px" },
                          },
                          [
                            _vm._v(" 更多操作"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _vm.activeTab == 1
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.share(scope.row.id)
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("分享链接")])]
                                )
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.handleSupplierGoodsCopy(
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [_c("span", [_vm._v("复制商品")])]
                            ),
                            _vm.$hasMethod("#delete") && _vm.activeTab == 2
                              ? _c(
                                  "el-dropdown-item",
                                  {
                                    attrs: { type: "danger", size: "mini" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.removeItem(scope.row.id)
                                      },
                                    },
                                  },
                                  [_c("span", [_vm._v("删除")])]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList },
      }),
      _c("stock-dialog", { ref: "stockDialog", on: { getList: _vm.getList } }),
      _c("share-dialog", { ref: "shareDialog" }),
      _c("set-dialog", { ref: "setDialog", on: { getList: _vm.getList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }