"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _bus = _interopRequireDefault(require("./bus"));
var _auth = require("@/utils/auth");
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      activeRoutes: [],
      screenWidth: "",
      activeParentNav: {}
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['menu'])), {}, {
    userName: function userName() {
      return (0, _auth.getName)();
    },
    account: function account() {
      return (0, _auth.getAccount)();
    },
    avatar: function avatar() {
      return (0, _auth.getPhoto)();
    },
    merchantId: function merchantId() {
      return (0, _auth.getMerchantId)();
    },
    menuList: function menuList() {
      var list = [];
      var _iterator = _createForOfIteratorHelper(this.menu),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          var obj = {
            id: item.id,
            title: item.label,
            routes: []
          };
          obj.routes = item.children.map(function (c) {
            return c.path;
          });
          // console.log('obj', obj)
          // console.log('this.merchantId', this.merchantId)
          if (this.merchantId > 0 || obj.title != '商户') {
            // 仅商户登录才显示商户菜单
            list.push(obj);
          }
        }
        // const list = [
        //   {
        //     title: "Web端",
        //     type: "web",
        //     routes: ["webConfig", "article", "recommend"]
        //   },
        //   {
        //     title: "系统",
        //     type: "system",
        //     routes: ["account", "permission", "menu"]
        //   },
        //   // {
        //   //   title: "信息",
        //   //   type: "info",
        //   //   routes: ["message", "position"]
        //   // },
        //   {
        //     title: "用户",
        //     type: "user",
        //     routes: ["supplier"]
        //   },
        //   // {
        //   //   title: "审核",
        //   //   type: "audit",
        //   //   routes: ["audit", "audit_financial"]
        //   // },
        //   // {
        //   //   title: "积分商城",
        //   //   type: "mall",
        //   //   routes: []
        //   // },
        //   // {
        //   //   title: "财务",
        //   //   type: "financial",
        //   //   routes: ["bill"]
        //   // },
        //   // {
        //   //   title: "订单",
        //   //   type: "order",
        //   //   routes: ["order"]
        //   // },
        //   // {
        //   //   title: "店铺",
        //   //   type: "shop",
        //   //   routes: []
        //   // },
        //   // {
        //   //   title: "商品",
        //   //   type: "product",
        //   //   routes: ["product"]
        //   // },
        // ];
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return list;
    }
  }),
  watch: {
    $route: function $route(val) {
      this.setActive();
    }
  },
  created: function created() {
    var _this = this;
    this.$nextTick(function () {
      _this.setActive();
    });
  },
  mounted: function mounted() {
    var _this2 = this;
    this.screenWidth = document.body.clientWidth;
    window.onresize = function () {
      return function () {
        _this2.screenWidth = document.body.clientWidth;
      }();
    };
  },
  methods: {
    logout: function logout() {
      if (this.merchantId) {
        this.$store.dispatch('user/merchantLogout');
      } else {
        this.$store.dispatch('user/logout');
      }
    },
    goToPage: function goToPage(routes) {
      if (routes.length > 0) {
        this.$router.push({
          name: routes[0]
        });
      }
    },
    setActive: function setActive() {
      var _iterator2 = _createForOfIteratorHelper(this.menuList),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var menu = _step2.value;
          var _iterator3 = _createForOfIteratorHelper(menu.routes),
            _step3;
          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var item = _step3.value;
              var parentPath = this.$route.path.split('/')[1];
              if (parentPath === item) {
                this.activeRoutes = menu.routes;
                this.activeParentNav = {
                  id: menu.id,
                  route: item
                };
                _bus.default.$emit('setType', this.activeRoutes);
                _bus.default.$emit('setTopNav', this.activeParentNav);
              }
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  }
};