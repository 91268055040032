"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'querylOrderEquity',
  props: ['editItem'],
  data: function data() {
    return {
      loading: true,
      cert_info: {
        apply_No: '',
        apply_Status: '',
        contract_No: '',
        contract_Url: '',
        notarization_Url: null,
        certification_Date: null,
        files_Signed: null
      },
      form: {
        order_id: '',
        cert_status: 0
      },
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      }
    }
  },
  methods: {
    confirmOrderEquity: function confirmOrderEquity() {
      var _this = this;
      if (this.form.order_id) {
        (0, _order.confirmOrderEquityAPI)({
          order_id: this.form.order_id
        }).then(function (res) {
          console.log('res', res);
        }).finally(function (res) {
          _this.$emit('getList', true);
          _this.dialogVisible = false;
        });
      }
    },
    showDetail: function showDetail(url) {
      window.open(url);
    },
    init: function init() {
      this.form = this.editItem;
      this.getEqualInfo();
    },
    getEqualInfo: function getEqualInfo() {
      var _this2 = this;
      this.loading = true;
      (0, _order.queryOrderEquityAPI)({
        order_id: this.form.order_id
      }).then(function (res) {
        console.log('公正结果', res);
        _this2.cert_info = res;
        _this2.loading = false;
      }).catch(function (err) {
        console.log('error', err);
        _this2.loading = false;
      });
    }
  }
};