"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _inOutBound = require("@/api/inOutBound");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // 给props的title_prfix加上默认值
  props: ['selectedItem'],
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        id: '',
        amount: '',
        amount_remark: '',
        chassis_number: '',
        remark: '',
        cert_name: ''
      },
      defaultForm: {
        id: '',
        amount: '',
        amount_remark: '',
        chassis_number: '',
        remark: '',
        cert_name: ''
      },
      rules: {
        amount: [{
          required: true,
          message: '请输入金额',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    // 写一个计算属性，根据props传来的title值，得到新增转账记录还是编辑
    dialogTitle: function dialogTitle() {
      return this.selectedItem ? '编辑出库订单其他信息' : '添加出库订单其他信息';
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      console.log('收到selectedItem', this.selectedItem);
      if (val) {
        this.formData = Object.assign(this.formData, this.selectedItem);
      } else {
        this.$refs['formData'].resetFields();
        this.formData = Object.assign(this.formData, this.defaultForm);
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this.formData);
          (0, _inOutBound.makeVehicleInfo)(param).then(function (res) {
            _this.$emit('getList');
            _this.close();
          }).catch(function () {});
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};