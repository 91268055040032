"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _guest = require("@/api/guest");
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      loading: true,
      searchForm: {
        channel: '',
        orderStatus: '',
        orderCreateTime: '',
        riskMobile: ''
      },
      total: 0,
      tableData: [],
      statusList: []
    };
  },
  computed: {
    resetType: function resetType() {
      var type = '';
      for (var key in this.searchForm) {
        if (key != 'channel' && this.searchForm[key] != '') {
          return 'danger';
        }
      }
      return '';
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getChannel();
      this.getOrderStatus();
      this.getList();
    },
    getChannel: function getChannel() {
      this.searchForm.channel = this.$route.query.channel ? this.$route.query.channel : '';
    },
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        // this.tableData = []
      }
      this.loading = true;
      (0, _guest.getChannelOrderList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.list.data || [];
        _this.total = res.list.total;
        _this.loading = false;
      }).catch(function (res) {
        _this.loading = false;
      });
    },
    getOrderStatus: function getOrderStatus() {
      var _this2 = this;
      (0, _guest.getOrderStatus)().then(function (res) {
        var list = [];
        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }
        _this2.statusList = list || [];
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.orderCreateTime = pickerVal;
      }
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      this.searchForm = this.$options.data().searchForm;
      this.getChannel();
      this.getList();
    },
    filterUserOrder: function filterUserOrder(item) {
      this.searchForm.riskMobile = item.mobile;
      this.getList();
    }
  }
};