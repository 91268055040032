var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "掌签征信详情",
        visible: _vm.dialogVisible,
        width: "1100px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
        },
        [
          _c("div", { staticClass: "flex certify" }, [
            _c("div", { staticClass: "flex-1 flex flex-center" }, [
              _c("label", [_vm._v("用户签收凭证：")]),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c("crop-upload", {
                    ref: "cropUpload1",
                    attrs: {
                      multiple: true,
                      "show-type": "photo",
                      "hide-delete": true,
                      "init-val": _vm.infoData.sign_proof,
                      "wrapper-width": "100px",
                      "wrapper-height": "100px",
                      "photo-width": 100,
                      "photo-height": 100,
                    },
                    on: { setVal: _vm.proofChange },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "flex certify" }, [
            _c("div", { staticClass: "flex-1 flex flex-center" }, [
              _c("label", [_vm._v("物权证明：")]),
              _c(
                "div",
                { staticClass: "flex-1" },
                [
                  _c("crop-upload", {
                    ref: "pti",
                    attrs: {
                      multiple: true,
                      "show-type": "photo",
                      "hide-delete": true,
                      "init-val": _vm.infoData.proof_title_img,
                      "wrapper-width": "100px",
                      "wrapper-height": "100px",
                      "photo-width": 100,
                      "photo-height": 100,
                    },
                    on: { setVal: _vm.handleProofTitleImg },
                  }),
                ],
                1
              ),
            ]),
            _vm.orderInfo.is_report_credit == 1
              ? _c("div", { staticClass: "flex-1 flex flex-center" }, [
                  _c("label", [_vm._v("催收短信截图：")]),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("crop-upload", {
                        ref: "sci",
                        attrs: {
                          multiple: true,
                          "show-type": "photo",
                          "hide-delete": true,
                          "init-val": _vm.infoData.sms_comp_img,
                          "wrapper-width": "100px",
                          "wrapper-height": "100px",
                          "photo-width": 100,
                          "photo-height": 100,
                        },
                        on: { setVal: _vm.handleSMSCompImg },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.orderInfo.is_report_credit == 1
            ? _c("div", { staticClass: "flex certify" }, [
                _c("div", { staticClass: "flex-1 flex flex-center" }, [
                  _c("label", [_vm._v("代偿通知书：")]),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("crop-upload", {
                        ref: "nci",
                        attrs: {
                          multiple: true,
                          "show-type": "photo",
                          "hide-delete": true,
                          "init-val": _vm.infoData.notice_comp_img,
                          "wrapper-width": "100px",
                          "wrapper-height": "100px",
                          "photo-width": 100,
                          "photo-height": 100,
                        },
                        on: { setVal: _vm.handleNoticeCompImg },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "flex-1 flex flex-center" }, [
                  _c("label", [_vm._v("代偿证明：")]),
                  _c(
                    "div",
                    { staticClass: "flex-1" },
                    [
                      _c("crop-upload", {
                        ref: "pfi",
                        attrs: {
                          multiple: true,
                          "show-type": "photo",
                          "hide-delete": true,
                          "init-val": _vm.infoData.proof_com_img,
                          "wrapper-width": "100px",
                          "wrapper-height": "100px",
                          "photo-width": 100,
                          "photo-height": 100,
                        },
                        on: { setVal: _vm.handleProofComImg },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.orderInfo.is_report_credit == 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleOpenAccount },
                },
                [_vm._v("征信开户")]
              )
            : _vm.orderInfo.is_add_compensatory == 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleCollectionReport },
                },
                [_vm._v("上报催收")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }