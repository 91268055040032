"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _merchant = require("@/api/merchant");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./add"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      searchForm: {
        title: "",
        status: null
      },
      total: 0,
      tableData: [],
      editItem: null,
      menuData: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['rule'])),
  mounted: function mounted() {
    this.getList();
    this.menuData = this.rule || [];
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      // const { page, pageSize } = this.$refs.customPage;
      // if (!flag) {
      // 	this.tableData = [];
      // }
      (0, _merchant.getMerchantAdminGruopList)().then(function (res) {
        _this.tableData = res || [];
        // this.total = res.total;
      });
    },
    // 添加/编辑账号
    openAddDialog: function openAddDialog(val) {
      this.editItem = val || null;
      this.$refs.addDialog.dialogVisible = true;
    }
  }
};