"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeInBound = changeInBound;
exports.delInBound = delInBound;
exports.delInBoundPart = delInBoundPart;
exports.delOutBound = delOutBound;
exports.delRepair = delRepair;
exports.editInBoundData = editInBoundData;
exports.editInBoundPart = editInBoundPart;
exports.editOutBoundData = editOutBoundData;
exports.editParcel = editParcel;
exports.editRepair = editRepair;
exports.getChassisList = getChassisList;
exports.getContractImg = getContractImg;
exports.getInBoundList = getInBoundList;
exports.getOtherHistory = getOtherHistory;
exports.getOutBoundList = getOutBoundList;
exports.getPartsList = getPartsList;
exports.getRepairHistory = getRepairHistory;
exports.getRepairList = getRepairList;
exports.inBoundDataExport = inBoundDataExport;
exports.makeVehicleInfo = makeVehicleInfo;
exports.parcelDataDetail = parcelDataDetail;
exports.parcelDataExport = parcelDataExport;
exports.qrCodePay = qrCodePay;
exports.qrCodePayQuery = qrCodePayQuery;
exports.repairCount = repairCount;
exports.repairDataExport = repairDataExport;
exports.returnInBound = returnInBound;
exports.todayVehicleParcel = todayVehicleParcel;
exports.todayVehicleRent = todayVehicleRent;
exports.todayVehicleRepair = todayVehicleRepair;
exports.todayVehicleSell = todayVehicleSell;
exports.totalVehicleInfo = totalVehicleInfo;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
// 获取入库列表
function getInBoundList(params) {
  return _fetch.default.get('/Supplier/InOutBound/getInBoundList', params);
}

// 获取车架号列表
function getChassisList(params) {
  return _fetch.default.get('/Supplier/InOutBound/getChassisList', params);
}
// 获取维修部件列表(options)
function getRepairList(params) {
  return _fetch.default.get('/Supplier/InOutBound/getRepairList', params);
}
// 获取合同照片
function getContractImg(params) {
  return _fetch.default.post('/Supplier/InOutBound/getContractImg', params);
}

// 编辑配件入库信息
function editInBoundPart(params) {
  return _fetch.default.post('/Supplier/InOutBound/editInBoundPart', params, true);
}

// 获取配件入库表
function getPartsList(params) {
  return _fetch.default.get('/Supplier/InOutBound/getPartsList', params);
}
// 编辑入库信息
function editInBoundData(params) {
  return _fetch.default.post('/Supplier/InOutBound/editInBoundData', params, true);
}

// 删除入库信息
function delInBound(params) {
  return _fetch.default.post('/Supplier/InOutBound/delInBound', params, true);
}
// 删除配件入库信息
function delInBoundPart(params) {
  return _fetch.default.post('/Supplier/InOutBound/delInBoundPart', params, true);
}

// 获取出库列表
function getOutBoundList(params) {
  return _fetch.default.get('/Supplier/InOutBound/getOutBoundList', params);
}

// 编辑出库信息
function editOutBoundData(params) {
  return _fetch.default.post('/Supplier/InOutBound/editOutBoundData', params, true);
}

// 删除出库信息
function delOutBound(params) {
  return _fetch.default.post('/Supplier/InOutBound/delOutBound', params, true);
}
// 归还电动车

function returnInBound(params) {
  return _fetch.default.post('/Supplier/InOutBound/returnInBound', params, true);
}
// 更换电动车
function changeInBound(params) {
  return _fetch.default.post('/Supplier/InOutBound/changeInBound', params, true);
}

// 编辑维修记录
function editRepair(params) {
  return _fetch.default.post('/Supplier/InOutBound/editRepair', params, true);
}
// 出库订单其他消费
function makeVehicleInfo(params) {
  return _fetch.default.post('/Supplier/InOutBound/makeVehicleInfo', params, true);
}
// 获取维修记录
function getRepairHistory(params) {
  return _fetch.default.post('/Supplier/InOutBound/getRepairHistory', params, false);
}
// 获取维修记录
function getOtherHistory(params) {
  return _fetch.default.post('/Supplier/InOutBound/getOtherHistory', params, false);
}
// 编辑分拥状态

function editParcel(params) {
  return _fetch.default.post('/Supplier/InOutBound/editParcel', params, true);
}
// 统计表

function todayVehicleRent(params) {
  return _fetch.default.get('/Supplier/InOutBound/todayVehicleRent', params);
}
function repairCount(params) {
  return _fetch.default.get('/Supplier/InOutBound/repairCount', params);
}
function repairDataExport(params) {
  return _fetch.default.export('/Supplier/InOutBound/repairDataExport', params, '维修统计');
}
function inBoundDataExport(params) {
  return _fetch.default.export('/Supplier/InOutBound/inBoundDataExport', params, '入库信息导出');
}
function parcelDataExport(params) {
  return _fetch.default.export('/Supplier/InOutBound/parcelDataExport', params, '分佣统计');
}
function parcelDataDetail(params) {
  return _fetch.default.get('/Supplier/InOutBound/parcelDataDetail', params);
}
// 卖出统计
function todayVehicleSell(params) {
  return _fetch.default.get('/Supplier/InOutBound/todayVehicleSell', params);
}
// 维修统计
function todayVehicleRepair(params) {
  return _fetch.default.get('/Supplier/InOutBound/todayVehicleRepair', params);
}
// 分拥统计
function todayVehicleParcel(params) {
  return _fetch.default.get('/Supplier/InOutBound/todayVehicleParcel', params);
}

// 综合统计
function totalVehicleInfo(params) {
  return _fetch.default.get('/Supplier/InOutBound/totalVehicleInfo', params);
}
// 删除维修信息
function delRepair(params) {
  return _fetch.default.post('/Supplier/InOutBound/delRepair', params, true);
}

// 扫码支付
function qrCodePay(params) {
  return _fetch.default.post('/Supplier/InOutBound/qrCodePay', params, true);
}
// 扫码结果查询
function qrCodePayQuery(params) {
  return _fetch.default.post('/Supplier/InOutBound/qrCodePayQuery', params);
}