"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  token: function token(state) {
    return state.user.token;
  },
  menu: function menu(state) {
    return state.user.menu;
  },
  rule: function rule(state) {
    return state.user.rule;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  }
};
var _default = exports.default = getters;