"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.array.find");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editOutBoundForm = _interopRequireDefault(require("./dialog/editOutBoundForm"));
var _editAPayPwd = _interopRequireDefault(require("./dialog/editAPayPwd.vue"));
var _editRepairRecord = _interopRequireDefault(require("./dialog/editRepairRecord.vue"));
var _changeVehicle = _interopRequireDefault(require("./dialog/changeVehicle.vue"));
var _makeOtherAmount = _interopRequireDefault(require("./dialog/makeOtherAmount.vue"));
var _editParceling = _interopRequireDefault(require("./dialog/editParceling.vue"));
var _merchant = require("@/api/merchant");
var _inOutBound = require("@/api/inOutBound");
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
var _auth = require("@/utils/auth");
var _MerchantFilter = _interopRequireDefault(require("./components/MerchantFilter.vue"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    editOutBoundForm: _editOutBoundForm.default,
    editAPayPwd: _editAPayPwd.default,
    editRepairRecord: _editRepairRecord.default,
    myDatePicker: _myDatePicker.default,
    editParceling: _editParceling.default,
    MerchantFilter: _MerchantFilter.default,
    changeVehicle: _changeVehicle.default,
    makeOtherAmount: _makeOtherAmount.default
  },
  data: function data() {
    return {
      searchForm: {
        pay_no: '',
        account: '',
        status: '',
        name: '',
        //车辆名称
        cert_name: '',
        //姓名
        chassis_number: '',
        //车架号
        date: '',
        //创建时间
        merchantId: 0,
        order_id: undefined
      },
      repairDrawerShow: false,
      //
      otherDrawerShow: false,
      //
      allDrawerShow: false,
      repairHisData: [],
      outOtherData: [],
      current_drawer_id: '',
      //
      tableData: [],
      tableDataAllLoading: false,
      tableDataAll: [],
      total: 0,
      selectList: [],
      selectedItem: {},
      tableLoading: false,
      repairList: [],
      orderStatusList: [{
        value: 1,
        label: '租用中',
        color: 'primary'
      }, {
        value: 2,
        label: '已买断',
        color: 'danger'
      }, {
        value: 3,
        label: '已归还',
        color: 'info'
      }, {
        value: 4,
        label: '已购买',
        color: 'success'
      }],
      merchantList: [{
        id: 0,
        username: '请选择'
      }],
      chassis_number: '',
      current_id: '',
      //出库订单ID
      merchantId: 0,
      //0则代表是供应商
      userType: 0,
      deleLoading: false //删除按钮状态
    };
  },
  methods: {
    handleDelRepair: function handleDelRepair(row) {
      var _this = this;
      this.deleLoading = true;
      (0, _inOutBound.delRepair)({
        id: row.id,
        is_other: row.is_other || undefined
      }).then(function (res) {
        _this.handlePayPwd6(row);
        _this.deleLoading = false;
      });
    },
    getStatusTagType: function getStatusTagType(orderStatus) {
      var status = this.orderStatusList.find(function (item) {
        return item.value === orderStatus;
      });
      return status ? status.color : '';
    },
    getOrderStatusLabel: function getOrderStatusLabel(orderStatus) {
      var row = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
      var status = this.orderStatusList.find(function (item) {
        return item.value === orderStatus;
      });
      if (status.value == 1) {
        var info = '';
        if (row.lease_status == 1) {
          info = '以租代购';
        } else if (row.lease_status == 2) {
          info = '随租随还';
        }
        return "".concat(status.label, " ").concat(info);
      }
      return status ? status.value === 4 && row.purchase_real ? "".concat(status.label, " ").concat(row.purchase_real) : status.label : '';
    },
    // 获取维修表内容
    getRepairList: function getRepairList() {
      var _this2 = this;
      (0, _inOutBound.getRepairList)().then(function (res) {
        _this2.repairList = res;
        console.log('维修表内容', _this2.repairList);
      });
    },
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        // console.log(this.$router.resolve({ path:'/order/list?orderId='+orderId}))
        this.$func.openWindow('/order/list?orderId=' + orderId);
        // this.$router.push({ name: 'orderList', params: { orderId } });
      }
    },
    goToInBound: function goToInBound(chassis_number) {
      console.log('chassis_number', chassis_number);
      if (chassis_number) {
        // 在触发路由跳转时直接传递参数
        this.$router.push({
          path: '/inOutBound/in',
          query: {
            chassis_number: chassis_number
          }
        });
      }
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this3 = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _inOutBound.delOutBound)({
          id: id
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    changeVehicle: function changeVehicle(item) {
      this.chassis_number = item.chassis_number;
      this.selectedItem = item;
      console.log('selectedItem', this.selectedItem);
      this.$refs.changeVehicle.dialogVisible = true;
      return;
    },
    makeOtherAmount: function makeOtherAmount(item) {
      var id = item.id,
        chassis_number = item.chassis_number,
        cert_name = item.cert_name;
      this.selectedItem = {
        id: id,
        chassis_number: chassis_number,
        cert_name: cert_name
      };
      console.log('selectedItem', this.selectedItem);
      this.$refs.makeOtherAmount.dialogVisible = true;
      return;
    },
    // 获取数据
    getList: function getList() {
      var _this4 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _merchant.getMerchantList)({}).then(function (res) {
        console.log('res: ', res);
      });
      this.getRepairList();
      this.tableLoading = true;
      (0, _inOutBound.getOutBoundList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        console.log('resres', res);
        _this4.tableData = res.data || [];
        _this4.total = res.total || 0;
        console.log('table data: ', _this4.tableData);
      }).catch(function (err) {
        return console.log('eee', err);
      }).finally(function () {
        _this4.tableLoading = false;
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      var pickerVal = this.$refs.myDatePicker.getVal();
      if (pickerVal !== 'error') {
        this.searchForm.date = pickerVal;
      }
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        cert_name: '',
        chassis_number: '',
        //车架号
        date: '',
        //创建时间
        order_id: undefined
      };
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }
      if (this.userType == 0) {
        this.$refs.merchantFilterRef.reset();
      }
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      leaseListExport(this.searchForm);
    },
    addAPaymentData: function addAPaymentData() {
      this.selectedItem = {
        widthImg: false,
        //需要手动上传
        cert_name: '',
        mobile: '',
        chassis_number: '',
        contract_image: [],
        remark: '',
        repair_record: '',
        lease_duration: ''
      };
      this.$refs.editOutBoundForm.dialogVisible = true;
    },
    saveAPaymentData: function saveAPaymentData(item) {
      this.selectedItem = item;
      console.log('传给编辑组件的数据', this.selectedItem);
      this.selectedItem.trade_no = this.selectedItem.order_id;
      // this.selectedItem.repair_record = this.selectedItem.repair_record.split(',')
      this.$refs.editOutBoundForm.dialogVisible = true;
    },
    // 归还操作
    handlePayPwd: function handlePayPwd(item) {
      console.log('handlePayPwd', item);
      this.chassis_number = item.chassis_number;
      this.selectedItem = item;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editAPayPwd.dialogVisible = true;
    },
    // 编辑维修记录
    handlePayPwd2: function handlePayPwd2(item) {
      this.chassis_number = item.chassis_number;
      this.selectedItem = item;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editRepairRecord.dialogVisible = true;
    },
    handlePayPwd3: function handlePayPwd3(item) {
      var _this5 = this;
      this.current_drawer_id = item.chassis_number; //根据车架号，查找该辆车的所有维修记录
      this.selectedItem = item;
      console.log('selectedItem', this.selectedItem);
      // 获取维修详情
      (0, _inOutBound.getRepairHistory)({
        chassis_number: item.chassis_number
      }).then(function (res) {
        if (!res.length) return _this5.$message({
          message: '暂无维修记录',
          type: 'warning'
        });
        _this5.repairHisData = res;
        _this5.repairDrawerShow = true;
        console.log('this.repairHisData', _this5.repairHisData, _this5.repairHisData.length);
      });
    },
    handlePayPwd6: function handlePayPwd6(item) {
      var _this6 = this;
      this.current_drawer_id = item.chassis_number; //根据车架号，查找该辆车的所有维修记录
      this.selectedItem = item;
      var chassis_number = item.chassis_number,
        cert_name = item.cert_name;
      console.log('selectedItem', this.selectedItem);
      // 获取维修详情
      // const { page, pageSize } = this.$refs.customPage
      var params = {
        page: 1,
        pageSize: 50
      };
      this.allDrawerShow = true;
      this.tableDataAllLoading = true;
      (0, _inOutBound.repairCount)(_objectSpread(_objectSpread({}, params), {}, {
        chassis_number: chassis_number,
        cert_name: cert_name
      })).then(function (res) {
        console.log('repairCount: ', res.data);
        _this6.tableDataAll = res.data || [];
        _this6.total = res.total || 0;
        console.log('this.tableData', _this6.tableDataAll);
      }).finally(function () {
        _this6.tableDataAllLoading = false;
      });
    },
    handlePayPwd5: function handlePayPwd5(item) {
      var _this7 = this;
      var id = item.id; //根据车架号，查找该辆车的所有维修记录
      console.log('id', item);
      // 获取维修详情
      (0, _inOutBound.getOtherHistory)({
        id: id
      }).then(function (res) {
        if (!res.length) return _this7.$message({
          message: '暂无维修记录',
          type: 'warning'
        });
        _this7.outOtherData = res;
        _this7.otherDrawerShow = true;
        console.log('this.outOtherData', _this7.outOtherData, _this7.outOtherData.length);
      });
    },
    handlePayPwd4: function handlePayPwd4(item) {
      this.current_id = item.id; //出库订单ID
      this.selectedItem = item;
      console.log('selectedItem', this.selectedItem);
      this.$refs.editParceling.dialogVisible = true;
    },
    // 供应商可筛选商户
    suplierFilter: function suplierFilter() {
      var mid = (0, _auth.getMerchantId)();
      this.userType = mid == 0 ? 0 : 1; // 如果商户ID为0，则表示供应商，否则表示商户
      this.merchantId = mid;
      this.searchForm.merchantId = mid;
    },
    handleMerchantSelected: function handleMerchantSelected(val) {
      console.log('handleMerchantSelected', val);
      this.searchForm.merchantId = val;
    }
  },
  mounted: function mounted() {
    console.log('路由参数', this.$route.query);
    if (this.$route.query.order_id) {
      this.searchForm.order_id = this.$route.query.order_id;
    }
    console.log('this.searchForm.order_id', this.searchForm.order_id);
    this.suplierFilter();
    this.getList();
  }
};