"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _order = require("@/api/order");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _setAddress = _interopRequireDefault(require("./dialogs/setAddress"));
var _lookJson = _interopRequireDefault(require("./dialogs/lookJson"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    setAddress: _setAddress.default,
    lookJson: _lookJson.default
  },
  data: function data() {
    return {
      searchForm: {
        name: "",
        mobile: "",
        type: ""
      },
      total: 0,
      tableData: [],
      editItem: {},
      riskList: [{
        id: '1',
        name: '风控一'
      }, {
        id: '2',
        name: '风控二'
      }]
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _order.getRiskBlacklistList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        // console.log(this.tableData.length)
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      var searchForm = this.$options.data().searchForm;
      this.searchForm = _objectSpread({}, searchForm);
      this.getList();
    },
    lookJson: function lookJson(item) {
      console.log("sdfsdfsd");
      this.editItem = item.json;
      console.log(this.editItem);
      this.$refs.lookJson.dialogVisible = true;
    }
  }
};