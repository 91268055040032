"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['orderId'],
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      activeName: 'supplier',
      infoData: {},
      dataList: [],
      innerVisible: false,
      innerTitle: '',
      folwForm: {
        logistics_name: '',
        logistics_no: '',
        cost_price: 0
      },
      editCode: 'logistics_name',
      logisticsNames: []
    };
  },
  methods: {
    getSelect: function getSelect() {
      var _this = this;
      (0, _order.getLogisticAll)().then(function (res) {
        _this.logisticsNames = res || [];
      });
    },
    handleClick: function handleClick(tab, event) {
      this.getInfo();
    },
    editFlow: function editFlow(type) {
      this.innerVisible = true;
      this.innerTitle = type == 'name' ? '修改物流名称' : '修改物流单号';
      this.editCode = type == 'name' ? 'logistics_name' : 'logistics_no';
    },
    editFlowInfo: function editFlowInfo() {
      var _this2 = this;
      var _this$folwForm = this.folwForm,
        logistics_name = _this$folwForm.logistics_name,
        logistics_no = _this$folwForm.logistics_no,
        cost_price = _this$folwForm.cost_price;
      var eev = this.editCode == 'logistics_name' ? logistics_name : this.editCode == 'logistics_no' ? logistics_no : cost_price;
      var params = {
        orderId: this.orderId,
        editCode: this.editCode,
        editValue: eev
      };
      if (this.editCode == 'logistics_name') {
        if (!logistics_name) {
          this.$message.warning('物流名称不能为空');
          return false;
        }
      }
      if (this.editCode == 'logistics_no') {
        if (!logistics_no) {
          this.$message.warning('物流单号不能为空');
          return false;
        }
        if (logistics_no && !/^[a-zA-Z0-9]{1,20}$/.test(logistics_no)) {
          this.$message.warning('物流单号格式错误');
          return false;
        }
      }
      (0, _order.editOrderExpress)(params).then(function (res) {
        _this2.cancelEdit();
        _this2.getInfo();
      });
    },
    cancelEdit: function cancelEdit() {
      this.folwForm = {
        logistics_name: '',
        logistics_no: '',
        cost_price: 0
      };
      this.innerVisible = false;
    },
    imgChange: function imgChange(val) {
      var _this3 = this;
      (0, _order.setOrderVoucher)({
        orderId: this.orderId,
        type: 1,
        images: val
      }).then(function (res) {
        _this3.infoData.images.push(val);
        _this3.$refs.cropUpload.sortList = _this3.infoData.images;
      });
    },
    // 物权证明截图
    handleProofTitleImg: function handleProofTitleImg(val) {
      var _this4 = this;
      (0, _order.setCommodityCertificate)({
        orderId: this.orderId,
        images: val,
        target: 'proof_title_img'
      }).then(function (res) {
        _this4.infoData.proof_title_img.push(val);
        _this4.$refs.pti.sortList = _this4.infoData.proof_title_img;
      });
    },
    // 短信催收
    handleSMSCompImg: function handleSMSCompImg(val) {
      var _this5 = this;
      (0, _order.setCommodityCertificate)({
        orderId: this.orderId,
        images: val,
        target: 'sms_comp_img'
      }).then(function (res) {
        _this5.infoData.sms_comp_img.push(val);
        _this5.$refs.sci.sortList = _this5.infoData.sms_comp_img;
      });
    },
    //代偿通知
    handleNoticeCompImg: function handleNoticeCompImg(val) {
      var _this6 = this;
      (0, _order.setCommodityCertificate)({
        orderId: this.orderId,
        images: val,
        target: 'notice_comp_img'
      }).then(function (res) {
        _this6.infoData.notice_comp_img.push(val);
        _this6.$refs.nci.sortList = _this6.infoData.notice_comp_img;
      });
    },
    //代偿证明
    handleProofComImg: function handleProofComImg(val) {
      var _this7 = this;
      (0, _order.setCommodityCertificate)({
        orderId: this.orderId,
        images: val,
        target: 'proof_com_img'
      }).then(function (res) {
        _this7.infoData.proof_com_img.push(val);
        _this7.$refs.pfi.sortList = _this7.infoData.proof_com_img;
      });
    },
    proofChange: function proofChange(val) {
      var _this8 = this;
      (0, _order.setOrderVoucher)({
        orderId: this.orderId,
        type: 3,
        images: val
      }).then(function (res) {
        _this8.infoData.sign_proof.push(val);
        _this8.$refs.cropUpload1.sortList = _this8.infoData.sign_proof;
      });
    },
    /**
     * infoData对象字段说明：images——发货凭证，sign_proof——用户签收凭证，
     * SMSCompImg——催收短信截图， NoticeCompImg——代偿通知书截图， proof_com_img-代偿证明截图，ProofTitleImg——物权证明截图，
     **/
    getInfo: function getInfo() {
      var _this9 = this;
      this.loading = true;
      (0, _order.getLogisticInfo)({
        orderId: this.orderId,
        type: this.activeName === 'supplier' ? 1 : 2
      }).then(function (res) {
        _this9.infoData = res || {};
        _this9.infoData.images = _this9.infoData.images ? _this9.infoData.images.split(',') : [];
        _this9.infoData.sign_proof = _this9.infoData.sign_proof ? _this9.infoData.sign_proof.split(',') : [];
        // 新增催收短信截图 代偿通知书截图 物权证明截图
        _this9.infoData.sms_comp_img = _this9.infoData.sms_comp_img ? _this9.infoData.sms_comp_img.split(',') : [];
        _this9.infoData.notice_comp_img = _this9.infoData.notice_comp_img ? _this9.infoData.notice_comp_img.split(',') : [];
        _this9.infoData.proof_com_img = _this9.infoData.proof_com_img ? _this9.infoData.proof_com_img.split(',') : [];
        _this9.infoData.proof_title_img = _this9.infoData.proof_title_img ? _this9.infoData.proof_title_img.split(',') : [];
        _this9.dataList = _this9.infoData.info ? _this9.infoData.info.data : [];
        _this9.loading = false;
        console.log('this.infoData', _this9.infoData);
      }).catch(function (err) {
        _this9.loading = false;
      });
    },
    editCostPrice: function editCostPrice() {
      this.innerVisible = true;
      this.innerTitle = '供应商价格';
      this.editCode = 'cost_price';
      this.folwForm.cost_price = this.infoData.cost_price;
      // setOrderCostPrice({ ...this.form }).then(res => {
      //   this.dialogVisible = false;
      //   this.$emit('getList', true);
      // })
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getInfo();
        this.getSelect();
      } else {
        this.activeName = 'supplier';
      }
    }
  }
};