"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
var _auth = require("@/utils/auth");
var _qrcode = _interopRequireDefault(require("qrcode"));
var _account = require("@/api/account");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      amount: '',
      qr_code: '',
      code_url: '',
      log_id: '',
      out_trade_no: '',
      minute: "00",
      seconds: "00",
      interval: null,
      timer: null // 计时
    };
  },
  methods: {
    getData: function getData() {
      var _this2 = this;
      this.amount = sessionStorage.getItem('amount');
      this.qr_code = sessionStorage.getItem('qr_code');
      this.log_id = sessionStorage.getItem('log_id');
      this.out_trade_no = sessionStorage.getItem('out_trade_no');
      if (this.qr_code) {
        this.setTimer();
        this.setTimeData(3 * 60 * 1000);
      }
      _qrcode.default.toDataURL(this.qr_code).then(function (res) {
        _this2.code_url = res;
      }).catch(function (err) {
        console.error(err);
      });
    },
    startLoop: function startLoop() {
      var _this3 = this;
      this.timer = setInterval(function () {
        _this3.isPaySuccess();
      }, 3000);
    },
    isPaySuccess: function isPaySuccess() {
      var _this4 = this;
      (0, _account.rechargeQuery)(this.log_id).then(function (res) {
        _this4.$router.push({
          name: 'rechargeSuccess'
        });
      }).catch(function (err) {});
    },
    setTimeData: function setTimeData(cm) {
      this.minute = Math.floor(cm / 1000 / 60 % 60);
      this.minute = this.minute.toString().length == 1 ? "0" + this.minute : this.minute;
      this.seconds = Math.floor(cm / 1000 % 60);
      this.seconds = this.seconds.toString().length == 1 ? "0" + this.seconds : this.seconds;
    },
    setTimer: function setTimer() {
      var _this = this;
      this.interval = setInterval(function () {
        _this.payCountdown(3 * 60 * 1000);
        clearInterval(_this.interval);
      }, 1000);
    },
    /**
     * 支付倒计时
     */
    payCountdown: function payCountdown(cm) {
      if (cm >= 0) {
        this.setTimeData(cm);
        var _this = this;
        setTimeout(function () {
          cm -= 1000;
          _this.payCountdown(cm);
        }, 1000);
      } else {
        this.clear();
        this.$router.push({
          name: 'myIndex'
        });
      }
    },
    clear: function clear() {
      clearInterval(this.timer);
      this.timer = null;
      clearInterval(this.interval);
      this.interval = null;
      this.minute = "00";
      this.seconds = "00";
      sessionStorage.removeItem("amount");
      sessionStorage.removeItem("out_trade_no");
      sessionStorage.removeItem("log_id");
      sessionStorage.removeItem("qr_code");
    },
    toNext: function toNext() {
      clearInterval(this.timer);
      this.timer = null;
      clearInterval(this.interval);
      this.interval = null;
      this.minute = "00";
      this.seconds = "00";
      sessionStorage.removeItem("log_id");
      sessionStorage.removeItem("qr_code");
    }
  },
  created: function created() {
    this.getData();
    this.startLoop();
  },
  computed: {
    userName: function userName() {
      return (0, _auth.getName)();
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    var _this5 = this;
    if (to.name !== "rechargeSuccess" && to.name !== "myIndex") {
      this.$confirm('您确定要离开支付吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this5.clear();
        next();
      }).catch(function () {
        next(false);
      });
    } else if (to.name === "rechargeSuccess") {
      this.toNext();
      next();
    } else {
      this.clear();
      next();
    }
  },
  beforeDestroy: function beforeDestroy() {
    // this.clear();
  }
};