"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.object.entries");
var _inOutBound = require("@/api/inOutBound");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // 给props的title_prfix加上默认值
  props: ['selectedItem'],
  data: function data() {
    var _this = this;
    var validateLicensePlate = function validateLicensePlate(rule, value, callback) {
      if (_this.formData.is_registered == 1 && !value) {
        callback(new Error('请输入车牌'));
      } else {
        callback();
      }
    };
    return {
      btn_disabled: false,
      dialogVisible: false,
      formData: {
        vehicle_id: '',
        name: '',
        purchase_price: undefined,
        chassis_number: '',
        is_registered: undefined,
        remark: '',
        license_plate: ''
      },
      defaultForm: {
        vehicle_id: '',
        name: '',
        purchase_price: undefined,
        chassis_number: '',
        is_registered: undefined,
        remark: '',
        license_plate: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        purchase_price: [{
          required: true,
          message: '请输入进货价格',
          trigger: 'blur'
        }],
        chassis_number: [{
          required: true,
          message: '请输入车架号',
          trigger: 'blur'
        }],
        is_registered: [{
          required: true,
          message: '请选择是否上牌',
          trigger: 'change'
        }],
        license_plate: [{
          required: false,
          trigger: 'blur'
        }],
        remark: [{
          required: false,
          message: '请输入备注',
          trigger: 'blur'
        }],
        evidence_url: [{
          required: false,
          message: '请上传图片',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    // 写一个计算属性，根据props传来的title值，得到新增转账记录还是编辑
    dialogTitle: function dialogTitle() {
      return Object.entries(this.selectedItem).length ? '编辑入库信息' : '添加入库信息';
    },
    isTextarea: function isTextarea() {
      return Object.entries(this.selectedItem).length ? '' : 'textarea';
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.formData = Object.assign(this.formData, this.selectedItem);
        console.log('cx', this.formData);
        // 将两个对象的内容合并
      } else {
        this.$refs['formDataRef'].resetFields();
        this.formData = Object.assign(this.formData, this.defaultForm);
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this2 = this;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      this.$refs['formDataRef'].validate(function (valid) {
        console.log('valid', valid);
        console.log('this.formData', _this2.formData);
        if (valid) {
          var param = _objectSpread({}, _this2.formData);
          console.log('最终参数', param);
          if (_this2.formData.is_registered == 2) {
            param.license_plate = undefined;
          }
          // return;
          _this2.btn_disabled = true;
          (0, _inOutBound.editInBoundData)(param).then(function (res) {
            _this2.$emit('getList');
            _this2.close();
          }).catch(function () {}).finally(function () {
            _this2.btn_disabled = false;
          });
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};