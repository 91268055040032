var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "服务名称：", prop: "name" } },
            [
              _c("el-input", {
                attrs: { maxlength: "10", "show-word-limit": "" },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "价格：", prop: "price" } },
            [
              _c(
                "el-input",
                {
                  on: {
                    change: function ($event) {
                      return _vm.chkPrice("price")
                    },
                  },
                  model: {
                    value: _vm.formData.price,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "price", $$v)
                    },
                    expression: "formData.price",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "结算比例：", prop: "settle_percen" } },
            [
              _c(
                "el-input",
                {
                  on: {
                    change: function ($event) {
                      return _vm.chkPrice("settle_percen")
                    },
                  },
                  model: {
                    value: _vm.formData.settle_percen,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "settle_percen", $$v)
                    },
                    expression: "formData.settle_percen",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "商品id：", prop: "goods_ids" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.goods_ids,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "goods_ids", $$v)
                  },
                  expression: "formData.goods_ids",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { height: "280px" },
              attrs: { label: "文章内容：" },
            },
            [
              _c("text-editor", {
                attrs: { content: _vm.formData.content },
                on: { onEditorChange: _vm.onEditorChange },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                [_vm._v("显示")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 0 },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                [_vm._v("隐藏")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }