"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _order = require("@/api/order");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ['editItem'],
  data: function data() {
    return {
      dialog: {
        title: '拒绝审核',
        message: '确定要拒绝审核此订单吗？'
      },
      dialogVisible: false,
      formData: {
        // image: '',
        memo: ''
      },
      rules: {
        // image: [
        // 	{ required: true, message: '请上传图片', trigger: 'change' }
        // ],
        memo: [{
          required: true,
          message: '请上填写备注',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        // console.log('item', this.editItem)
      } else {
        this.$refs['formData'].resetFields();
        this.formData = this.$options.data().formData;
      }
    }
  },
  methods: {
    setUrl: function setUrl(val) {
      this.formData.image = val;
    },
    submit: function submit() {
      var _this = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          _this.$confirm(_this.dialog.message, _this.dialog.title, {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(function () {
            (0, _order.saveDistributionOrder)(_objectSpread({
              orderId: _this.editItem.id,
              type: 2
            }, _this.formData)).then(function (res) {
              _this.$emit('getList');
              _this.close();
            });
          }).catch(function (err) {});
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};