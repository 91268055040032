"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _orderHandle = require("@/api/orderHandle");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      searchForm: {
        trade_no: '',
        type: ''
      },
      statusList: [{
        name: "请选择",
        status: ""
      }, {
        name: "申请中",
        status: "APPLYING"
      }, {
        name: "审批中",
        status: "APPROVAL"
      }, {
        name: "审核拒绝",
        status: "REJECT"
      }, {
        name: "待支付",
        status: "WAIT_PAY"
      }, {
        name: "已取消",
        status: "CLOSED"
      }, {
        name: "待发货",
        status: "TO_SEND_GOODS"
      }, {
        name: "已发货",
        status: "IN_DELIVERY"
      }, {
        name: "租赁中",
        status: "IN_THE_LEASE"
      }, {
        name: "履约完成",
        status: "EXERCISED"
      }, {
        name: "已逾期",
        status: "OVERDUE"
      }, {
        name: "租赁到期",
        status: "RENT_DUE"
      }, {
        name: "归还逾期",
        status: "RETURN_OVERDUE"
      }, {
        name: "买断中",
        status: "IN_THE_BUYOUT"
      }, {
        name: "归还中",
        status: "IN_THE_BACK"
      }, {
        name: "待赔付",
        status: "TO_BE_PAID"
      }, {
        name: "续租中",
        status: "RELET"
      }, {
        name: "已买断",
        status: "BUYOUT"
      }, {
        name: "已归还",
        status: "FINISHED"
      }],
      tableData: [],
      total: 0,
      overdueCount: 0,
      overdueAmount: 0,
      selectedItem: {}
    };
  },
  mounted: function mounted() {},
  methods: {
    // 查询
    search: function search() {
      var _this = this;
      (0, _orderHandle.fixSesame)(_objectSpread({}, this.searchForm)).then(function (res) {
        _this.$message.success("操作成功");
      }).catch(function (error) {});
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        trade_no: '',
        type: ''
      };
    }
  }
};