"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/slicedToArray.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _ServiceCitySelector = _interopRequireDefault(require("./ServiceCitySelector.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    cropUpload: _cropUpload.default,
    ServiceCitySelector: _ServiceCitySelector.default
  },
  props: {
    imgHeight: {
      type: Number
    },
    imgWidth: {
      type: Number
    },
    formData: {
      type: Object,
      default: {}
    },
    areaData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    itemIndex: {
      type: Number
    },
    mulProps: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      optionProps: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      timeInfo: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)]
    };
  },
  methods: {
    changeSelect: function changeSelect(e) {
      this.$emit('getProvince', e);
    },
    parseTimeRange: function parseTimeRange(timeRangeString) {
      return timeRangeString.split('-').map(function (timeStr) {
        return new Date("2024-04-01T".concat(timeStr, ".000Z"));
      });
    },
    timeChange: function timeChange(value) {
      console.log('index', value);
      var timeRangeString = '10:45-11:45';
      var _this$parseTimeRange = this.parseTimeRange(timeRangeString),
        _this$parseTimeRange2 = (0, _slicedToArray2.default)(_this$parseTimeRange, 2),
        startTime = _this$parseTimeRange2[0],
        endTime = _this$parseTimeRange2[1];
      console.log(startTime); // 输出开始时间的 Date 对象
      console.log(endTime); // 输出结束时间的 Date 对象

      // this.$emit('current_time', { time: value, index: this.itemIndex })
    }
  }
};