"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _order = require("@/api/order");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  data: function data() {
    return {
      form: {
        orderId: "",
        deviceNumber: []
      },
      dialogVisible: false,
      deviceRule: {
        value: [{
          required: true,
          message: '设备编号不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = _objectSpread({}, _this.form);
          params.deviceNumber = params.deviceNumber ? params.deviceNumber.map(function (item) {
            return item.value;
          }).join(',') : '';
          (0, _order.setDeviceNumber)(params).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        var _this$editItem = this.editItem,
          orderId = _this$editItem.orderId,
          deviceNumber = _this$editItem.deviceNumber,
          amount = _this$editItem.amount;
        this.form.orderId = orderId;
        this.form.deviceNumber = [];
        for (var i = 0; i < amount; i++) {
          var obj = {
            label: '设备编号' + (i + 1),
            value: deviceNumber[i] || ''
          };
          this.form.deviceNumber.push(obj);
        }
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};