"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _category = require("@/api/category");
var _xcx = require("@/api/xcx");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['cateId'],
  data: function data() {
    return {
      dialogVisible: false,
      searchForm: {
        page: 1,
        goods_name: '',
        cate_id: '',
        shop_name: ''
      },
      categoryList: [],
      total: 0,
      tableData: [],
      pageSize: 5
    };
  },
  methods: {
    add: function add(id) {
      var _this = this;
      (0, _xcx.addList)({
        goodsId: id,
        listCateId: this.cateId
      }).then(function (res) {
        _this.getList();
        _this.$emit('getList');
      });
    },
    getList: function getList() {
      var _this2 = this;
      (0, _xcx.chooseGoods)(_objectSpread({
        pageSize: this.pageSize,
        list_cate_id: this.cateId
      }, this.searchForm)).then(function (res) {
        _this2.tableData = res.data;
        _this2.total = res.total;
      });
    },
    // 页码切换
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchForm.page = val;
      this.getList();
    },
    search: function search() {
      this.searchForm.page = 1;
      this.getList();
    },
    reset: function reset() {
      this.searchForm = {
        page: 1,
        goods_name: '',
        cate_id: '',
        shop_name: ''
      };
      this.getList();
    },
    getSelectList: function getSelectList() {
      var _this3 = this;
      (0, _category.getSonCategoryList)().then(function (res) {
        _this3.categoryList = res || [];
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this4 = this;
      if (val) {
        this.getSelectList();
        this.$nextTick(function () {
          _this4.getList();
        });
      } else {
        // 重置
        this.searchForm = {
          page: 1,
          goods_name: '',
          cate_id: '',
          shop_name: ''
        };
      }
    }
  }
};