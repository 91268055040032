"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  data: function data() {
    return {
      dialogVisible: false,
      loading: true,
      selectArr: [],
      filesList: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      } else {
        this.selectArr = [];
      }
    }
  },
  methods: {
    init: function init() {
      this.setSelectArr();
      this.getContractList();
    },
    setSelectArr: function setSelectArr() {
      if (this.editItem.esign_extra_contract) {
        this.selectArr = this.editItem.esign_extra_contract.split(',');
      }
    },
    getContractList: function getContractList() {
      var _this = this;
      this.loading = true;
      (0, _order.getContractList)().then(function (res) {
        console.log('getContractList', res);
        // console.log('res.files.downloadUrl', res.files[0].downloadUrl)
        _this.filesList = res;
        _this.loading = false;
      });
    },
    submit: function submit() {
      var _this2 = this;
      var param = {
        order_id: this.editItem.id,
        contract_temp_id: this.selectArr.join(',')
      };
      console.log('submit', param);
      (0, _order.saveOrderExtraContract)(param).then(function (res) {
        console.log('saveOrderExtraContract', res);
        _this2.$emit('selectedState', {
          order_id: param.order_id,
          status: true
        });
        _this2.$emit('getList', true);
        _this2.dialogVisible = false;
      });
    }
  }
};