"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _web = require("@/api/web");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: []
    };
  },
  methods: {
    // 列表项删除
    removeItem: function removeItem(id) {
      var _this = this;
      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _web.delInfoConfig)(id).then(function (res) {
          _this.getList();
        });
      }).catch(function () {});
    },
    // 更改显示隐藏状态
    changeStatus: function changeStatus(id, status) {
      var _this2 = this;
      var value = status == 1 ? 2 : 1;
      (0, _web.editArticleTypeStatus)({
        id: id,
        status: value
      }).then(function (res) {
        _this2.getList();
      });
    },
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this3 = this;
      (0, _web.addOrEditInfoConfig)({
        id: id,
        sort: val
      }).then(function (res) {
        _this3.getList();
      });
    },
    // 获取列表
    getList: function getList() {
      var _this4 = this;
      (0, _web.getInfoTypeList)().then(function (res) {
        // console.log(res);
        _this4.tableData = res;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};