"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _slicedToArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/slicedToArray.js"));
require("core-js/modules/es6.string.iterator");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.object.entries");
var _inOutBound = require("@/api/inOutBound");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  // 给props的title_prfix加上默认值
  props: ['editItem', 'merchantList'],
  data: function data() {
    var _this = this;
    var validateLeaseStatus = function validateLeaseStatus(rule, value, callback) {
      if (_this.formData.order_status == 1 && !value) {
        callback(new Error('请输入租用中状态'));
      } else {
        callback();
      }
    };
    return {
      btn_disabled: false,
      dialogVisible: false,
      widthImg: false,
      formData: {
        trade_no: '',
        //订单编号
        user_id: '',
        cert_name: '',
        name: '',
        mobile: '',
        chassis_number: '',
        contract_image: [],
        remark: '',
        // repair_record: [],
        lease_duration: '',
        order_status: '',
        is_registered: undefined,
        license_plate: '',
        purchase_real: undefined,
        lease_status: '',
        rent_price: undefined,
        month_total: undefined
      },
      defaultForm: {
        trade_no: '',
        //订单编号
        user_id: '',
        cert_name: '',
        name: '',
        mobile: '',
        chassis_number: '',
        contract_image: [],
        // repair_record: [],
        remark: '',
        lease_duration: '',
        order_status: '',
        is_registered: undefined,
        license_plate: '',
        purchase_real: undefined,
        lease_status: '',
        rent_price: undefined,
        month_total: undefined
      },
      rules: {
        chassis_number: [{
          required: true,
          message: '请输入车架号',
          trigger: 'blur'
        }],
        remark: [{
          required: false,
          message: '请输入备注',
          trigger: 'blur'
        }],
        contract_image: [{
          required: false,
          message: '请上传合同信息',
          trigger: 'change'
        }],
        mobile: [{
          required: false,
          message: '请输入手机号',
          trigger: 'blur'
        }],
        cert_name: [{
          required: true,
          message: '请输入真实姓名',
          trigger: 'blur'
        }],
        lease_duration: [{
          required: false,
          message: '请输入租期',
          trigger: 'blur'
        }],
        // repair_record: [{ required: false, message: '请输入维修记录', trigger: 'blur' }],
        is_registered: [{
          required: false,
          message: '请选择是否上牌',
          trigger: 'change'
        }],
        license_plate: [{
          required: false,
          trigger: 'blur'
        }],
        purchase_real: [{
          required: false,
          trigger: 'blur',
          message: '请输入购买价格'
        }],
        lease_status: [{
          validator: validateLeaseStatus,
          trigger: 'blur',
          message: '请选择租用状态'
        }],
        rent_price: [{
          required: false,
          trigger: 'blur',
          message: '请输入出租价格'
        }]
      },
      optionsLoading: false,
      options: [],
      repairList: [],
      optionsOrderStatList: [{
        value: 1,
        label: '租用中'
      }, {
        value: 2,
        label: '已买断'
      },
      //  {
      //     value: 3,
      //     label: '已归还',
      // },
      {
        value: 4,
        label: '已购买'
      }]
    };
  },
  computed: {
    // 写一个计算属性，根据props传来的title值，得到新增转账记录还是编辑
    dialogTitle: function dialogTitle() {
      return this.editItem.id ? '编辑出库信息' : '添加出库信息';
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      this.init();
      console.log('editItem', this.editItem);
      console.log('merchantList', this.merchantList);
      if (val) {
        if (Object.entries(this.editItem).length) {
          this.formData = Object.assign(this.formData, this.editItem);
          this.widthImg = this.editItem.widthImg;
          this.widthImg && this.getContractImgs(); //判断是否需要手动上传
          // if (this.formData.repair_record) {
          //     this.formData.repair_record = this.formData.repair_record.split(',').map(Number)
          // }
        }
      } else {
        this.$refs['formDataRef'].resetFields();
        this.formData = Object.assign(this.formData, this.defaultForm);
        // this.formData = Object.assign(this.formData, this.defaultForm)
      }
    }
  },
  methods: {
    repairChange: function repairChange(val) {
      console.log('维修选项', val);
      // this.formData.repair_record = val
    },
    chassisChange: function chassisChange(val) {
      console.log('车架号选择', val);
      this.formData.name = val[0]; //车辆名称
      this.formData.chassis_number = val[1];
    },
    orderStatusChange: function orderStatusChange(val) {
      console.log('订单状态', val);
      this.formData.order_status = val;
    },
    getOptionList: function getOptionList() {
      var _this2 = this;
      this.optionsLoading = true;
      // 获取车架号列表和维修部件记录表
      Promise.all([(0, _inOutBound.getChassisList)(), (0, _inOutBound.getRepairList)()]).then(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          chassisOptionList = _ref2[0],
          repairOptionList = _ref2[1];
        _this2.options = chassisOptionList;
        console.log('this.options', _this2.options);
        _this2.repairList = repairOptionList;
        console.log('this.repairList', _this2.repairList);
      }).catch(function (error) {
        console.error('Error:', error);
      }).finally(function () {
        _this2.optionsLoading = false;
      });
    },
    init: function init() {
      this.getOptionList();
      // 获取物流表中的合同照片信息，根据订单ID
    },
    getContractImgs: function getContractImgs() {
      var _this3 = this;
      if (this.formData.trade_no) (0, _inOutBound.getContractImg)({
        trade_no: this.formData.trade_no //实际是一个订单编号
      }).then(function (res) {
        console.log('合同照片', res);
        _this3.formData.contract_image = res;
      });
    },
    imgChange: function imgChange(val, type) {
      console.log('imgChange', val, type);
      if (type === 'del') {
        this.formData.contract_image.splice(val, 1);
      } else {
        this.formData.contract_image.push(val);
      }
      this.$refs.cropUpload.sortList = this.formData.contract_image;
    },
    handleParam: function handleParam(param) {
      // 处理参数
      if (param.contract_image) {
        param.contract_image = param.contract_image.join(',');
      }
      // if (param.repair_record) {
      //     param.repair_record = param.repair_record.join(',')
      // }
      if (this.formData.is_registered == 2) {
        param.license_plate = undefined;
      }
      if (param.order_status != 4) {
        param.purchase_real = undefined; //如果不是购买状态的话，那就是购买金额为0
      }
    },
    submit: function submit() {
      var _this4 = this;
      this.$refs['formDataRef'].validate(function (valid) {
        console.log('valid', valid);
        console.log('出库信息', _this4.formData);
        if (valid) {
          var param = _objectSpread({}, _this4.formData);
          _this4.handleParam(param);
          console.log('最终参数', param);
          // return
          _this4.btn_disabled = true;
          (0, _inOutBound.editOutBoundData)(param).then(function (res) {
            _this4.$emit('getList');
            _this4.close();
          }).catch(function () {}).finally(function () {
            _this4.btn_disabled = false;
          });
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};