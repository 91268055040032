"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _mayicancelPay = _interopRequireDefault(require("./mayicancelPay"));
var _setWithhold = _interopRequireDefault(require("./setWithhold"));
var _mayi = require("@/api/mayi");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  components: {
    mayicancelPay: _mayicancelPay.default,
    setWithhold: _setWithhold.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      isFullscreen: false,
      tableData: {
        payRules: [],
        order: {
          trade_no: ''
        }
      },
      searchForm: {
        leaseStatus: ''
      },
      selectList: [{
        id: -1,
        name: '全部'
      }, {
        id: 1,
        name: '已支付'
      }, {
        id: 2,
        name: '未支付'
      }],
      selectedItem: {},
      allSuccessAmount: 0,
      allErrorAmount: 0,
      orderId: '',
      order: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.isFullscreen = false;
        this.getLease();
      }
    }
  },
  methods: {
    getLease: function getLease() {
      var _this = this;
      this.loading = true;
      (0, _order.getLeaseForMayi)({
        orderId: this.editItem.id,
        status: this.searchForm.leaseStatus
      }).then(function (res) {
        console.log('getLeaseForMayi', res.list.payRules);
        // console.log('getLeaseForMayi', res.list.payRules.length)
        // this.tableData.payRules = [];
        // this.tableData.payRules = res.list.payRules
        _this.tableData = res.list;
        _this.allSuccessAmount = res.amount ? res.amount.allSuccessAmount : 0;
        _this.allErrorAmount = res.amount ? res.amount.allErrorAmount : 0;
        _this.loading = false;
      });
    },
    getTagLabel: function getTagLabel(status) {
      var label = '';
      switch (status) {
        case 1:
          label = '已支付';
          break;
        case 2:
          label = '已退款';
          break;
        case 3:
          label = '已买断';
          break;
        case 4:
          label = '已逾期';
          break;
        case 5:
          label = '线下支付';
          break;
        case 6:
          label = '审核中';
          break;
        case 7:
          label = '审核被拒';
          break;
        case 8:
          label = '代扣失败';
          break;
        default:
          label = '未支付';
      }
      return label;
    },
    getTagType: function getTagType(status) {
      var type = 'info';
      if (["扣款成功", "交易成功无法退款"].indexOf(status) > -1) {
        type = 'success';
      } else if (["等待扣款", "代扣准备中", "代扣进行中"].indexOf(status) > -1) {
        type = 'warning';
      } else if (["已退款或一年未成功", "扣款失败", "代扣取消"].indexOf(status) > -1) {
        type = 'danger';
      } else if ([2, 3].indexOf(status) > -1) {
        type = '';
      }
      return type;
    },
    getTagTypeN: function getTagTypeN(status) {
      var type = 'info';
      if ([1, 5].indexOf(status) > -1) {
        type = 'success';
      } else if ([6].indexOf(status) > -1) {
        type = 'warning';
      } else if ([4, 7, 8].indexOf(status) > -1) {
        type = 'danger';
      } else if ([2, 3].indexOf(status) > -1) {
        type = '';
      }
      return type;
    },
    switchChange: function switchChange(row) {
      var _this2 = this;
      (0, _finance.withholdSwitch)({
        orderId: row.order_id,
        leaseId: row.id,
        withholdStatus: row.withhold_status
      }).then(function (res) {
        _this2.$parent.getList(true);
      });
    },
    cancelPay: function cancelPay(order, item) {
      var _this3 = this;
      // this.$confirm("您确定要取消扣款吗？取消无法扣用户钱", "申请取消", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      // 	mayicancelPayOne({orderId:orderId,outTradeNo:item.outTradeNo,lease_num:item.lease_num}).then((res)=>{
      // 		this.getLease()
      // 	})
      // }).catch(() => {});
      this.$confirm('注意:取消代扣后本期将无法代扣,建议足额取消。如果不足额,不足部分按优惠处理！！！', '确认取消', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true
      }).then(function () {
        _this3.order = _objectSpread({}, order);
        _this3.selectedItem = _objectSpread({}, item);
        _this3.$refs.mayicancelPay.dialogVisible = true;
      }).catch(function () {});
    },
    // 申请扣款
    openWithhold: function openWithhold(row) {
      this.selectedItem = _objectSpread({}, row);
      this.$refs.setWithhold.dialogVisible = true;
      return;
      // this.$confirm("您确定要申请扣款吗？", "申请扣款", {
      // 	confirmButtonText: '确定',
      // 	cancelButtonText: '取消'
      // }).then(() => {
      // 	applyWithhold({
      // 		order_id: row.order_id,
      // 		lease_id: row.id,
      // 	}).then(res => {
      // 		this.getLease()
      // 		setTimeout(() => {
      // 			this.$parent.getList(true);
      // 		}, 1000);
      // 	})
      // }).catch(() => {});
    },
    refresh: function refresh() {
      var _this4 = this;
      setTimeout(function () {
        _this4.getLease();
        _this4.$parent.getList(true);
      }, 1000);
    },
    // 发起扣款
    withholdItem: function withholdItem(row) {
      var _this5 = this;
      this.$confirm("您确定要发起扣款吗？", "发起扣款", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.manualWithholding)({
          orderId: row.order_id,
          leaseId: row.id
        }).then(function (res) {
          _this5.getLease();
          setTimeout(function () {
            _this5.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    // 线下还款
    payOffline: function payOffline(item) {
      var _this6 = this;
      this.$confirm("请确认是否收到账款,您确定要线下还款吗？", "线下还款", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _finance.setOfflinePay)({
          order_id: item.order_id,
          lease_id: item.id
        }).then(function (res) {
          console.log('getOfflinePayData', res);
          _this6.getLease();
          setTimeout(function () {
            _this6.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    },
    toggleFullscreen: function toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    mayiOrderRentalOne: function mayiOrderRentalOne(row) {
      var _this7 = this;
      this.$confirm("您确定要上链租期信息吗？", "上链信息", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _mayi.maYiLeaseRentalCreateOne)({
          order_id: row.order_id,
          lease_id: row.id
        }).then(function (res) {
          _this7.getLease();
          setTimeout(function () {
            _this7.$parent.getList(true);
          }, 1000);
        });
      }).catch(function () {});
    }
  }
};