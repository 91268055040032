"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _index = require("@/api/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    dialogTitle: {
      type: String
    },
    childVisible: {
      type: Boolean,
      default: false
    },
    childForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    firstLevel: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    rent_list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    insure_list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    zlb_list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    areaList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    optionProps: {
      type: Object,
      default: function _default() {
        return {
          value: 'id',
          label: 'name',
          expandTrigger: 'hover',
          children: 'sub'
        };
      }
    }
  },
  watch: {
    childVisible: function childVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          _this.$refs.childForm.clearValidate();
        });
      }
    }
  },
  data: function data() {
    var checkNum = function checkNum(rule, value, callback) {
      var reg = /^[0-9]\d*$/;
      if (!value && value !== 0) {
        callback(new Error('请输入数字'));
      } else if (!reg.test(value)) {
        callback(new Error('请输入正整数'));
      } else {
        callback();
      }
    };
    return {
      rules: {
        parentid: [{
          required: true,
          message: '请选择一级品类名称',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入二级品类名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 10,
          message: '长度在 1 到 10 个字符',
          trigger: 'blur'
        }],
        enabled: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }],
        link: [{
          required: true,
          message: '请输入链接',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请上传封面',
          trigger: 'change'
        }],
        displayorder: [{
          required: true,
          message: '请输入数字',
          trigger: 'blur'
        }, {
          validator: checkNum,
          trigger: 'blur'
        }],
        rent_category: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        insurance_category: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        zlb_class: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    ziduanSearch: function ziduanSearch(val) {
      this.childForm.area_id = val[val.length - 1];
      if (this.childForm.area_id) {
        this.childForm.area_id = Number(this.childForm.area_id);
      }
    },
    closeDialog: function closeDialog(param) {
      this.$emit('closeDialog', param);
    },
    setUrl: function setUrl(key, val) {
      this.childForm[key] = val;
      var obj = {
        key: key,
        val: val
      };
      this.$emit('setUrl', obj);
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      var strRegex = '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]';
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // var reg = new RegExp(strRegex);
          // if (this.childForm.link) {
          //   if (!reg.test(this.childForm.link)) {
          //     this.$message.error("请确保图片链接正确");
          //     return false;
          //   } else {
          //     this.$emit("submitForm", true);
          //   }
          // }
          _this2.$emit('submitForm', true);
        } else {
          return false;
        }
      });
    }
  }
};