var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "xcx_home_config" }, [
    _c("div", { staticClass: "flex main" }, [
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "flex flex-between-center header" }, [
          _c("h5", { staticClass: "color-666 normal-font" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c(
            "div",
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#4ccb86",
                  "inactive-color": "#ff4146",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.bannerInfo.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.bannerInfo, "status", $$v)
                  },
                  expression: "bannerInfo.status",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "v-middle",
                  staticStyle: { "margin-left": "10px" },
                },
                [_vm._v(_vm._s(_vm.bannerInfo.status ? "开启" : "隐藏"))]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "main_form" },
          [_c("secBanner", { ref: "secBanner" })],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "fix-footer-btns" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }