var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "550px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户姓名" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.formData.cert_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "cert_name", $$v)
                  },
                  expression: "formData.cert_name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车架号" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.formData.chassis_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "chassis_number", $$v)
                  },
                  expression: "formData.chassis_number",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款金额(元)" } },
            [
              _c("el-input-number", {
                model: {
                  value: _vm.formData.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "amount", $$v)
                  },
                  expression: "formData.amount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "如xxx工时费",
                },
                model: {
                  value: _vm.formData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "remark", $$v)
                  },
                  expression: "formData.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收款来源：" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "如微信收款" },
                model: {
                  value: _vm.formData.amount_remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "amount_remark", $$v)
                  },
                  expression: "formData.amount_remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }