var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "请填写维修信息",
        visible: _vm.dialogVisible,
        width: "550px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "维修部件", prop: "repair_record" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    multiple: "",
                    placeholder: "请选择",
                    clearable: "",
                  },
                  on: { change: _vm.repairChange },
                  model: {
                    value: _vm.formData.repair_record,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "repair_record", $$v)
                    },
                    expression: "formData.repair_record",
                  },
                },
                _vm._l(_vm.repairList, function (item, index) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.label, value: item.value },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(item.label)),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(item.repair_price))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.repair_remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "repair_remark", $$v)
                  },
                  expression: "formData.repair_remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.btn_disabled, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }