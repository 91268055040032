"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
var _default2 = exports.default = {
  props: {
    province: {
      type: String,
      required: true
    },
    areaData: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      areaList: [],
      optionProps: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      }
    };
  },
  computed: {
    provinceModel: {
      get: function get() {
        return this.province;
      },
      set: function set(value) {
        console.log('set', value);
        this.$emit('update:province', value);
      }
    }
  },
  mounted: function mounted() {
    console.log('地址组价');
    console.log('this.props', this.areaData);
    console.log('this.props', this.province);
  }
};