"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _goods = require("@/api/goods");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      dialogVisible: false,
      tableData: [],
      editId: null
    };
  },
  methods: {
    handleChange: function handleChange(row) {
      var value = row.add ? parseInt(row.quantity) + (row.type === 'add' ? parseInt(row.add) : -parseInt(row.add)) : '';
      this.$set(row, 'stock', value);
    },
    changeNumberInput: function changeNumberInput(row, value) {
      var pattern = /^[1-9][0-9]*$/; // 正整数的正则表达式
      // 不符合正整数时
      if (!pattern.test(value)) {
        // input 框绑定的内容为空
        row.add = '';
      }
    },
    submit: function submit() {
      var _this = this;
      var setmeal = this.tableData.map(function (item) {
        return {
          id: item.id,
          stock: item.stock || item.quantity
        };
      });
      (0, _goods.editGoodsStock)({
        goods_id: this.editId,
        setmeal: setmeal
      }).then(function (res) {
        _this.dialogVisible = false;
        _this.$emit('getList', true);
      });
    },
    getData: function getData() {
      var _this2 = this;
      (0, _goods.getSetmealStock)(this.editId).then(function (res) {
        _this2.tableData = res ? res.map(function (item) {
          return _objectSpread(_objectSpread({}, item), {}, {
            type: 'add',
            add: ''
          });
        }) : [];
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getData();
      }
    }
  }
};