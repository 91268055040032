"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inOutBound = require("@/api/inOutBound");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id', 'editItem'],
  data: function data() {
    return {
      btn_disabled: false,
      dialogVisible: false,
      formData: {
        vot_id: '',
        chassis_number: ''
      },
      defaultForm: {
        vot_id: '',
        chassis_number: ''
      },
      rules: {
        chassis_number: [{
          required: true,
          message: '请选择车架号',
          trigger: 'change'
        }]
      },
      repairList: [],
      optionsLoading: false,
      options: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
        this.formData = Object.assign(this.formData, this.editItem);
        this.formData.repair_record = []; //维修记录只能增不能删除
      } else {
        this.$refs['formData'].resetFields();
        this.formData = Object.assign(this.formData, this.defaultForm);
      }
    }
  },
  methods: {
    chassisChange: function chassisChange(val) {
      console.log('车架号选择', val);
      // this.formData.name = val[0] //车辆名称
      this.formData.chassis_number = val[1];
    },
    init: function init() {
      this.getOptionList();
    },
    // 获取车架号列表和维修部件记录表
    getOptionList: function getOptionList() {
      var _this = this;
      this.optionsLoading = true;
      (0, _inOutBound.getChassisList)().then(function (res) {
        _this.options = res;
        console.log('this.options', _this.options);
      }).finally(function () {
        _this.optionsLoading = false;
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var _this2$formData = _this2.formData,
            vot_id = _this2$formData.id,
            chassis_number = _this2$formData.chassis_number;
          var params = {
            vot_id: vot_id,
            chassis_number: chassis_number
          };
          console.log('最终参数', params);
          _this2.$confirm('确定更换车辆吗?更换车辆不会修改原车的维修信息、分佣信息等', '更换', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(function () {
            _this2.btn_disabled = true;
            (0, _inOutBound.changeInBound)(params).then(function (res) {
              _this2.$emit('getList');
              _this2.close();
            }).finally(function () {
              _this2.btn_disabled = false;
            });
          }).catch(function () {
            _this2.close();
          }).finally(function () {});
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};