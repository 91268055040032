"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.string.sub");
var _order = require("@/api/order");
var _index = require("@/api/index");
var _validate = require("@/utils/validate");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  data: function data() {
    var validateMobile = function validateMobile(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var validateString = function validateString(rule, value, callback) {
      if (!(0, _validate.checkString)(value)) {
        callback(new Error('不能包含特殊字符'));
      } else {
        callback();
      }
    };
    return {
      form: {
        orderId: '',
        realname: '',
        mobile: '',
        address: '',
        prov: '',
        city: '',
        area: '',
        province: ''
      },
      rules: {
        realname: [{
          required: true,
          message: '姓名不能为空',
          trigger: 'blur'
        }, {
          validator: validateString,
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        province: [{
          required: true,
          message: '请选择地区',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '详细地址不能为空',
          trigger: 'blur'
        }]
      },
      dialogVisible: false,
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      areaList: []
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.init();
      }
    }
  },
  methods: {
    init: function init() {
      var _this = this;
      (0, _index.getRegion)().then(function (res) {
        _this.areaList = res ? res[0].sub : [];
        for (var key in _this.form) {
          _this.form[key] = _this.editItem[key] || '';
        }
        console.log('this.form', _this.form);
      });
    },
    handleMobileChange: function handleMobileChange() {
      this.form.mobile = this.form.mobile.replace(/[^\d]/g, "");
    },
    handleAddressChange: function handleAddressChange() {
      this.form.address = '';
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = _objectSpread({}, _this2.form);
          params.prov = params.province[0];
          params.city = params.province[1];
          params.area = params.province[2];
          delete params.province;
          (0, _order.setAddress)(params).then(function (res) {
            _this2.dialogVisible = false;
            _this2.$emit('getList', true);
          });
        } else {
          _this2.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};