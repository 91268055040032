var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-title-page goods_list" },
    [
      _c(
        "div",
        { staticClass: "flex flex-between nav_tabs" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "flex-1",
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  label: "已上架商品(" + _vm.count.status_put + ")",
                  name: "1",
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  label: "已下架商品(" + _vm.count.status_off + ")",
                  name: "2",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "right" }, [
            _c(
              "span",
              {
                staticClass: "pointer",
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ name: "goodsDraft" })
                  },
                },
              },
              [
                _c("svg-icon", {
                  staticStyle: { "vertical-align": "middle" },
                  attrs: { "icon-class": "draft", size: 20 },
                }),
                _vm._v("草稿箱 (" + _vm._s(_vm.count.draft) + ")\n\t\t\t"),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("el-divider", { staticClass: "middle_divider" }),
      _c(
        "div",
        { staticClass: "child_page" },
        [
          _c("online", {
            ref: "listPage",
            attrs: { "active-tab": _vm.activeName, "tab-count": _vm.count },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }