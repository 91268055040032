"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./add"));
var _xcx = require("@/api/xcx");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      searchForm: {
        name: '',
        status: ''
      },
      total: 0,
      tableData: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 删除
    removeItem: function removeItem(id) {
      var _this = this;
      this.$confirm('您确定要删除推广分类吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _xcx.setCategoryDel)(id).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 显示/隐藏
    changeStatus: function changeStatus(id, status) {
      var _this2 = this;
      var tip = "",
        title = "";
      if (status == 2) {
        tip = "您确定要隐藏推广标签吗？";
        title = "隐藏";
      } else {
        tip = "您确定要显示推广标签吗？";
        title = "显示";
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _xcx.setCategoryStatus)({
          id: id,
          status: status == 2 ? 1 : 2
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this3 = this;
      (0, _xcx.setCategorySort)({
        id: id,
        sort: val
      }).then(function (res) {
        _this3.getList(true);
      });
    },
    getList: function getList(flag) {
      var _this4 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _xcx.categoryList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.total = res.total;
      });
    },
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    reset: function reset() {
      this.searchForm = {
        name: '',
        status: ''
      };
      this.$refs.customPage.page = 1;
      this.getList();
    },
    openAddDialog: function openAddDialog(val) {
      this.editItem = val || null;
      this.$refs.addDialog.dialogVisible = true;
    }
  }
};