"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _inOutBound = require("@/api/inOutBound");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id', 'editItem'],
  data: function data() {
    return {
      btn_disabled: false,
      dialogVisible: false,
      formData: {
        purchase_price: '',
        parcel_status: undefined,
        parcel_amount: undefined
      },
      defaultForm: {
        purchase_price: '',
        parcel_status: undefined,
        parcel_amount: undefined
      },
      rules: {
        parcel_status: [{
          required: true,
          message: '请输入',
          trigger: 'change'
        }],
        parcel_amount: [{
          required: true,
          message: '请输入',
          trigger: 'change'
        }]
      },
      optionsOrderStatList: [{
        value: 1,
        label: '是'
      }, {
        value: 2,
        label: '否'
      }]
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.formData = Object.assign(this.formData, this.editItem);
      } else {
        this.formData = Object.assign(this.formData, this.defaultForm);
        this.$refs['formData'].resetFields();
      }
    }
  },
  methods: {
    orderStatusChange: function orderStatusChange(val) {
      console.log('订单状态', val);
      this.formData.parcel_status = val;
    },
    handleParam: function handleParam(param) {
      console.log('参数', param);
      if (param.parcel_status == 2) {
        //关闭分拥
        param.parcel_amount = undefined;
      }
    },
    submit: function submit() {
      var _this = this;
      this.$refs['formData'].validate(function (valid) {
        if (valid) {
          var _this$formData = _this.formData,
            id = _this$formData.id,
            parcel_amount = _this$formData.parcel_amount,
            parcel_status = _this$formData.parcel_status;
          var param = {
            id: id,
            parcel_amount: parcel_amount,
            parcel_status: parcel_status
          };
          _this.handleParam(param);
          console.log('最终参数', param);
          _this.btn_disabled = true;
          // return
          (0, _inOutBound.editParcel)(param).then(function (res) {
            _this.$emit('getList');
            _this.close();
            console.log('res', res);
          }).catch(function () {
            _this.close();
          }).finally(function () {
            _this.btn_disabled = false;
          });
        }
      });
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};