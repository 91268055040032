var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "two_activity" },
    [
      _c(
        "div",
        { staticClass: "warn-box" },
        [
          _c("svg-icon", {
            staticStyle: { "margin-right": "17px" },
            attrs: { "icon-class": "warn", size: 20 },
          }),
          _vm._m(0),
        ],
        1
      ),
      _vm._l(_vm.imgList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "text-center item_wrap" },
          [
            _c("div", { staticClass: "text-left header" }, [
              _c("span", { staticStyle: { color: "#666666" } }, [
                _vm._v("图片" + _vm._s(index + 1)),
              ]),
            ]),
            _c("crop-upload", {
              staticClass: "w-100 img_wrap",
              attrs: {
                "show-type": "photo",
                "init-val": item.img,
                "wrapper-width": "359px",
                "wrapper-height": "171px",
                "img-width": 335,
                "img-height": 160,
                "photo-width": 359,
                "photo-height": 171,
              },
              on: {
                setVal: function (val) {
                  item.img = val
                },
              },
            }),
            _c("div", { staticClass: "input_wrapper" }, [
              _c(
                "div",
                { staticClass: "flex price" },
                [
                  _vm._m(1, true),
                  _c("el-input", {
                    attrs: { placeholder: "请输入链接", clearable: "" },
                    model: {
                      value: item.jumpUrl,
                      callback: function ($$v) {
                        _vm.$set(item, "jumpUrl", $$v)
                      },
                      expression: "item.jumpUrl",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("span", [_vm._v("图片要求！")]),
      _c("br"),
      _vm._v("图片宽度335px，高度160px。"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "flex align-center justify-center" }, [
      _c("span", { staticClass: "required-icon" }, [_vm._v("*")]),
      _vm._v("链接"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }