"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _xcx = require("@/api/xcx");
var _audit = require("@/api/audit");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _goods = _interopRequireDefault(require("./goods"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    goodsDialog: _goods.default
  },
  data: function data() {
    return {
      searchForm: {
        goodsName: '',
        shopName: '',
        categoryId: '',
        status: ''
      },
      activeName: '',
      total: 0,
      tableData: [],
      tabList: [],
      categoryList: []
    };
  },
  methods: {
    // 上架/下架推广
    changeStatus: function changeStatus(id, status) {
      var _this = this;
      var tip = "",
        title = "";
      if (status == 2) {
        tip = "您确定要下架推广吗？";
        title = "下架推广";
      } else {
        tip = "您确定要上架推广吗？";
        title = "上架推广";
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _xcx.setListStatus)({
          id: id,
          status: status == 2 ? 1 : 2
        }).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    delItem: function delItem(id) {
      var _this2 = this;
      this.$confirm("您确定要删除商品信息吗", "删除推广信息", {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _xcx.popularizeDel)(id).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 更改排序
    changeSort: function changeSort(id, val) {
      var _this3 = this;
      (0, _xcx.setListSort)({
        id: id,
        sort: val
      }).then(function (res) {
        _this3.getList(true);
      });
    },
    getList: function getList(flag) {
      var _this4 = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _xcx.goodsList)(_objectSpread({
        page: page,
        pageSize: pageSize,
        listCateId: this.activeName
      }, this.searchForm)).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.total = res.total;
      });
    },
    getClassify: function getClassify() {
      var _this5 = this;
      (0, _xcx.listCategoryList)().then(function (res) {
        _this5.tabList = res || [];
        _this5.activeName = _this5.tabList[0] ? _this5.tabList[0].id + '' : '';
        _this5.getList();
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        goodsName: '',
        shopName: '',
        categoryId: '',
        status: ''
      };
      this.$refs.customPage.page = 1;
      this.getList();
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.reset();
    },
    getSelectList: function getSelectList() {
      var _this6 = this;
      (0, _audit.getCategory)().then(function (res) {
        _this6.categoryList = res || [];
      });
    },
    openAdd: function openAdd() {
      this.$refs.goodsDialog.dialogVisible = true;
    }
  },
  mounted: function mounted() {
    this.getClassify();
    this.getSelectList();
  }
};