var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer-page" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { rules: _vm.rules, model: _vm.form, "label-width": "160px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所在地址:", prop: "provinceArr" } },
            [
              _c("el-cascader", {
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "请选择",
                  options: _vm.areaList,
                  props: _vm.optionProps,
                  separator: "-",
                },
                on: {
                  change: function ($event) {
                    _vm.form.address = ""
                  },
                },
                model: {
                  value: _vm.form.provinceArr,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "provinceArr", $$v)
                  },
                  expression: "form.provinceArr",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "地址详细信息", prop: "address" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 4,
                  placeholder: "请如实填写您的归还地址信息！",
                  maxlength: 50,
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "收货人姓名", prop: "realname" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  maxlength: "10",
                  placeholder: "请输入收货人姓名",
                },
                model: {
                  value: _vm.form.realname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "realname", $$v)
                  },
                  expression: "form.realname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码", prop: "mobile" } },
            [
              _c("el-input", {
                attrs: {
                  clearable: "",
                  maxlength: "11",
                  placeholder: "请输入手机号码",
                },
                on: { input: _vm.handleMobileChange },
                model: {
                  value: _vm.form.mobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mobile", $$v)
                  },
                  expression: "form.mobile",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设为默认地址" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: 1 },
                  model: {
                    value: _vm.form.isdefault,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isdefault", $$v)
                    },
                    expression: "form.isdefault",
                  },
                },
                [_vm._v("是")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: 2 },
                  model: {
                    value: _vm.form.isdefault,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isdefault", $$v)
                    },
                    expression: "form.isdefault",
                  },
                },
                [_vm._v("否")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-footer-btns" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.resetForm } },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }