"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.split");
var _system = require("@/api/system");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["editItem"],
  data: function data() {
    return {
      dialogVisible: false,
      loading: false,
      formData: {
        white_list: "",
        status: "0",
        member_id: "0"
      },
      options: [{
        value: "0",
        label: "管理后台"
      }, {
        value: "1",
        label: "供应商后台"
      }
      // {
      //     value: "2",
      //     label: "商户后台",
      // },
      ],
      value: "0",
      rules: {
        white_list: [{
          required: false,
          message: "请输入IP地址",
          trigger: "blur"
        }, {
          validator: this.validateIPAddress,
          trigger: "change"
        }]
      }
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          // this.init();
          _this.getData();
        });
      } else {
        // this.dialogVisible = false;
        this.$refs["formData"].resetFields();
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    validateIPAddress: function validateIPAddress(rule, value, callback) {
      // console.log("value: ", value);
      if (!value) {
        callback();
        return;
      }
      var addresses = value.split(",");
      for (var i = 0; i < addresses.length; i++) {
        var ip = addresses[i].trim();
        if (!/^((\d{1,3}\.){3}\d{1,3})$|^(?:(?:\d{1,3}\.){2})\*|(\d{1,3}\.\*)$|^[\u4E00-\u9FFF]+$/.test(ip) && !/^[\u4E00-\u9FFF]+$/.test(ip)) {
          callback(new Error("IP 地址格式不正确"));
        }
      }
      callback();
    },
    selectMemberId: function selectMemberId(e) {
      /**
       * 直接将 currentList 中的 white_list 赋值给 this.formData，并没有创建一个新的对象。
       * 这意味着 this.formData 与 currentList 共享引用关系，
       * 它们指向同一个内存地址。因此，当在代码的其他位置修改 this.formData 或 currentList 的 white_list 时，相互之间会互相影响。
       **/
      var currentList = this.allWhiteList.find(function (list) {
        return list.member_id == e;
      });
      console.log("currentList", currentList);
      if (currentList.white_list) {
        var white_list = currentList.white_list;
        console.log("white_list", white_list);
        this.formData = _objectSpread(_objectSpread({}, this.formData), {}, {
          white_list: white_list
        }); // 解构赋值更新 white_list
        this.formData = this.formData.white_list;
        console.log("this.formData11", this.formData);
      } else {
        this.formData = _objectSpread(_objectSpread({}, this.formData), {}, {
          white_list: "",
          member_id: currentList.member_id
        }); // 解构赋值更新 white_list 和 member_id
        // this.formData = this.formData.white_list;
      }
      console.log("this.formData", this.formData);
    },
    getData: function getData() {
      var _this2 = this;
      this.formData.member_id = this.editItem.id;
      this.loading = true;
      var params = {
        member_id: this.formData.member_id
      };
      (0, _system.getWhiteIpList)(_objectSpread({}, params)).then(function (res) {
        if (res) {
          // console.log("res", res);
          _this2.formData = res;
        }
        _this2.loading = false;
      });
    },
    submit: function submit() {
      var _this3 = this;
      // console.log("this.formData", this.formData);
      try {
        this.$refs.formData.validate(function (valid) {
          if (valid) {
            console.log("校验成功", _this3.formData);
            _this3.loading = true;
            (0, _system.saveWhiteIpList)(_this3.formData).then(function (res) {
              _this3.getData();
              _this3.loading = false;
            });
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};