"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _slicedToArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/slicedToArray.js"));
require("core-js/modules/es7.object.entries");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _xcx = require("@/api/xcx");
var _compForm = _interopRequireDefault(require("./components/compForm"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    compForm: _compForm.default
  },
  data: function data() {
    return {
      activeNum: 1,
      pageId: '',
      configData: {
        first_level: {
          background_color: '',
          background_image: '',
          btn_bg_color: '',
          main_title: '',
          is_show: false
        },
        second_level: {
          background_image: ''
        },
        third_level: {
          background_image: ''
        },
        fourth_level: {
          background_image: '',
          exam_list: [{
            img: '',
            title: ''
          }]
        }
      },
      pageTitle: '租机业务',
      pageType: 0,
      compFormData: {},
      imgWidth: 694,
      imgHeight: 359
      // sectionList: [],
    };
  },
  computed: {
    sectionList: function sectionList() {
      var sectionList = [];
      switch (this.pageTitle) {
        case '商户合作':
          sectionList = [{
            title: '商户合作背景图',
            key: 'first_level',
            imgName: 'ruzhu5.png',
            num: 0
          }, {
            title: '商户合作第一张图',
            key: 'second_level',
            imgName: 'coope.png',
            num: 0
          }, {
            title: '商户合作第二张图',
            key: 'third_level',
            imgName: 'coope2.png',
            num: 0
          }, {
            title: '取消按钮图',
            key: 'fourth_level',
            imgName: 'coope3.png',
            num: 0
          }];
          break;
        case '租机业务':
          sectionList = [{
            title: '租机业务顶部背景图',
            key: 'first_level',
            imgName: 'top_bg_url.png',
            num: 0
          }, {
            title: '租机业务解决方案图',
            key: 'second_level',
            imgName: 'center_bg_url.png',
            num: 0
          }, {
            title: '申请入驻按钮',
            key: 'third_level',
            imgName: 'ruzhu_img_url1.png',
            num: 0
          }, {
            title: '案例呈现列表',
            key: 'fourth_level',
            imgName: 'exam_list.png',
            num: 0
          }];
          break;
        case '招商入驻':
          sectionList = [{
            title: '商家入驻顶部背景图',
            key: 'first_level',
            imgName: 'ruzhu_top_bg_url.png',
            num: 0
          }, {
            title: '商家入驻优势图',
            key: 'second_level',
            imgName: 'ruzhu_center_bg_url.png',
            num: 0
          }, {
            title: '申请入驻按钮',
            key: 'third_level',
            imgName: 'ruzhu_img_url2.png',
            num: 0
          }, {
            title: '行业覆盖列表',
            key: 'fourth_level',
            imgName: 'ruzhu_exam_list.png',
            num: 0
          }];
          break;
      }
      return sectionList;
    }
  },
  watch: {
    activeNum: {
      immediate: true,
      handler: function handler(val) {
        // 在组件挂载时立即执行的回调函数逻辑
        if (val) {
          this.setData(val);
        }
      }
    }
  },
  beforeMount: function beforeMount() {
    // console.log('xx', this.pageTitle)
    // switch (this.pageTitle) {
    //     case '租机业务':
    //         this.sectionList = [
    //             {
    //                 title: '租机业务顶部背景图',
    //                 key: 'first_level',
    //                 imgName: 'top_bg_url.png',
    //                 num: 0,
    //             },
    //             {
    //                 title: '租机业务解决方案图',
    //                 key: 'second_level',
    //                 imgName: 'center_bg_url.png',
    //                 num: 0,
    //             },
    //             {
    //                 title: '申请入驻按钮',
    //                 key: 'third_level',
    //                 imgName: 'ruzhu_img_url.png',
    //                 num: 0,
    //             },
    //             {
    //                 title: '案例呈现列表',
    //                 key: 'fourth_level',
    //                 imgName: 'exam_list.png',
    //                 num: 0,
    //             },
    //         ]
    //         break
    // }
  },
  mounted: function mounted() {
    this.pageTitle = this.$route.meta.title;
    switch (this.pageTitle) {
      case '商户合作':
        // this.pageType = 20
        this.pageType = 27;
        this.imgHeight = 439;
        this.imgWidth = 750;
        break;
      case '租机业务':
        // this.pageType = 20
        this.pageType = 25;
        this.imgHeight = 439;
        this.imgWidth = 750;
        break;
      case '招商入驻':
        // this.pageType = 20
        this.pageType = 26;
        this.imgHeight = 439;
        this.imgWidth = 750;
        break;
    }
    this.getData();
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.activeNum = 1;
    this.pageTitle = to.$route.meta.title;
    this.getData();
  },
  methods: {
    submit: function submit() {
      (0, _xcx.release)(this.pageType).then(function (res) {});
    },
    setData: function setData(pos) {
      var key = this.sectionList[pos - 1].key;
      var data = _objectSpread({}, this.configData[key]);
      data.goods = data.goods ? data.goods : [];
      if (data.goods.length > 0) {
        data.goods.forEach(function (item) {
          item.id = item.id ? item.id : item.goods_id;
          item.goods_id = item.goods_id ? item.goods_id : item.id;
        });
      }
      console.log('key', key);
      if (key == 'third_level') {
        this.imgHeight = 79;
        this.imgWidth = 307;
      }
      if (key == 'second_level') {
        this.imgHeight = 359;
        this.imgWidth = 694;
      }
      if (key == 'fourth_level' && data.exam_list) {
        if (this.pageType == 25) {
          this.imgHeight = 106;
          this.imgWidth = 106;
        }
        if (this.pageType == 26) {
          this.imgHeight = 140;
          this.imgWidth = 205;
        }
      }
      if (key == 'first_level') {
        this.imgHeight = 439;
        this.imgWidth = 750;
      }
      if (this.pageType == 27) {
        if (key == 'first_level') {
          this.imgHeight = 686;
          this.imgWidth = 542;
        }
        if (key == 'third_level' || key == 'second_level') {
          this.imgHeight = 127;
          this.imgWidth = 440;
        }
        if (key == 'fourth_level') {
          this.imgHeight = 80;
          this.imgWidth = 241;
        }
      }
      this.compFormData = data;
      console.log('装备发送的数据', this.compFormData);

      // this.$nextTick(() => {
      // 	this.$refs.compForm.formData = data;
      // })
    },
    getData: function getData() {
      var _this = this;
      console.log('根据页面类型获取数据');
      (0, _xcx.getActivityPageByType)(this.pageType).then(function (result) {
        // console.log('getActivityPageByType', result)
        var data = result && result.details ? JSON.parse(result.details) : false;
        console.log('页面数据', data);
        console.log('set mock data');
        // data ={ "first_level": { "main_title": "租机业务222", "background_image": "20240104110330685666.png", "background_color": "#bfa", "btn_bg_color": "#e61717" }, "second_level": { "background_image": "20240104110330685666.png" }, "third_level": { "background_image": "20240104110330685666.png" }, "fourth_level": { "exam_list": [ { "img": "20230130150625862566.png", "title": "苹果系列" }, { "img": "20230130150632484801.png", "title": "华为系列" }, { "img": "20230130150223270189.png", "title": "小米系列" }, { "img": "20230130150651766616.png", "title": "oppo系列" }, { "img": "20230130150702723675.png", "title": "vivo系列" }, { "img": "20230130113517676178.jpg", "title": "电视" }, { "img": "20230130113525924136.jpg", "title": "冰箱" }, { "img": "20230130113534483259.jpg", "title": "空调" }, { "img": "20230130113539643329.jpg", "title": "洗衣机" }, { "img": "20230130113545148822.jpg", "title": "扫地机器人" } ] } }

        if (!data['fourth_level'] || !data['fourth_level']['exam_list'] || data['fourth_level']['exam_list'].length === 0) {
          console.log('xnxn');
          Object.assign(data, {
            fourth_level: _this.configData.fourth_level
          });
          // return this.$message.error('暂无数据')
        }
        _this.setConfigData(data);
        _this.setData(_this.activeNum);
      });
    },
    setConfigData: function setConfigData() {
      var _this2 = this;
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (data == false) {
        data = this.configData; //赋予默认值即可
      }
      this.sectionList.forEach(function (item, index) {
        var key = 'first_level';
        switch (index + 1) {
          case 1:
            key = 'first_level';
            break;
          case 2:
            key = 'second_level';
            break;
          case 3:
            key = 'third_level';
            break;
          case 4:
            key = 'fourth_level';
            break;
        }
        console.log('data[key]', data[key]);
        if (_this2.pageType == 27 && key == 'first_level') {
          data[key]['is_icon_conf'] = true;
          data[key]['is_control'] = true;
        }
        if (_this2.pageType != 27 && key == 'fourth_level') {
          if (!data[key]) {
            console.log('没有数据');
            data[key] = _this2.configData[key];
          }
          data[key]['is_nineGrid'] = true;
        }
        _this2.configData[key] = data && data[key] ? data[key] : {
          background_color: '',
          background_image: '',
          icon_img: '',
          is_icon_conf: _this2.pageType == 27 && key == 'first_level' ? true : false,
          is_control: _this2.pageType == 27 && key == 'first_level' ? true : false,
          is_nineGrid: _this2.pageType != 27 && key == 'fourth_level' ? true : false,
          btn_bg_color: '',
          main_title: '',
          is_show: _this2.pageType == 27 && key == 'first_level' ? true : false,
          exam_list: [{
            img: '',
            title: ''
          }]
        };
      });
    },
    save: function save() {
      var _this3 = this;
      var params = this.$refs.compForm.getSubmitData();
      console.log('表单的所有待设置的参数', params);
      var filteredData = Object.entries(params).reduce(function (acc, _ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        if (value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {});
      console.log('过滤后的参数', filteredData, 'this.activeNum', this.activeNum);
      if (params) {
        if (this.pageType == 27) {
          if (this.activeNum == 4) {
            // 过滤a中的exam_list属性
            filteredData = Object.entries(filteredData).reduce(function (acc, _ref3) {
              var _ref4 = (0, _slicedToArray2.default)(_ref3, 2),
                key = _ref4[0],
                value = _ref4[1];
              if (key !== 'exam_list') {
                acc[key] = value;
              }
              return acc;
            }, {});
            console.log('filteredDatafilteredData', filteredData);
          }
          (0, _xcx.editActivityPage)(_objectSpread({
            type: this.pageType,
            page_title: this.pageTitle
          }, filteredData)).then(function (res) {
            _this3.getData();
          });
          return;
        }
        switch (this.activeNum) {
          case 1:
            if (params) {
              (0, _xcx.editActivityPage)(_objectSpread({
                type: this.pageType,
                page_title: this.pageTitle
              }, filteredData)).then(function (res) {
                _this3.getData();
              });
            }
            break;
          case 2:
            if (params) {
              var level = params.level,
                background_image = params.background_image;
              (0, _xcx.editActivityPage)(_objectSpread({
                type: this.pageType,
                page_title: this.pageTitle
              }, filteredData)).then(function (res) {
                _this3.getData();
              });
            }
            break;
          case 3:
            if (params) {
              var _level = params.level,
                _background_image = params.background_image;
              (0, _xcx.editActivityPage)(_objectSpread({
                type: this.pageType,
                page_title: this.pageTitle
              }, filteredData)).then(function (res) {
                _this3.getData();
              });
            }
            break;
          case 4:
            console.log('icon校验通过');
            // 直接拿到九宫格数据这里处理就行了
            // this.saveNineGrid()
            if (params) {
              var _level2 = params.level,
                exam_list = params.exam_list;
              console.log('直接拿到exam_list', exam_list);
              (0, _xcx.editActivityPage)(_objectSpread({
                type: this.pageType,
                page_title: this.pageTitle
              }, filteredData)).then(function (res) {
                _this3.getData();
              });
            }
            break;
          default:
            break;
        }
      }
      return;
      if (params) {
        (0, _xcx.editActivityPage)(_objectSpread({
          type: this.pageType,
          page_title: this.pageTitle
        }, params)).then(function (res) {
          _this3.getData();
        });
      }
    },
    preview: function preview() {}
  }
};