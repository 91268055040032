"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.weak-map");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _slicedToArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/slicedToArray.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _core = require("echarts/core");
var _renderers = require("echarts/renderers");
var _charts = require("echarts/charts");
var _components = require("echarts/components");
var _vueEcharts = _interopRequireWildcard(require("vue-echarts"));
var _inOutBound = require("@/api/inOutBound");
var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
(0, _core.use)([_renderers.CanvasRenderer, _charts.PieChart, _components.TitleComponent, _components.TooltipComponent, _components.LegendComponent, _charts.BarChart, _components.DatasetComponent, _components.GridComponent]);
var _default = exports.default = {
  name: 'HelloWorld',
  components: {
    VChart: _vueEcharts.default,
    myDatePicker: _myDatePicker.default
  },
  provide: (0, _defineProperty2.default)({}, _vueEcharts.THEME_KEY, 'light'),
  data: function data() {
    return {
      createTime: [],
      isShow: false,
      searchForm: {
        createTime: ''
      },
      rent_data: {
        rent_due_data: {
          rent_due_paid: 0,
          rent_due_unpaid: 0
        },
        return_total: 0,
        rent_total: 0,
        day_amount: 0
      },
      //出租统计
      bought_data: {
        bought_total: 1,
        bought_price: '456546.00',
        bought_income: '555.00'
      },
      //卖出统计
      repair_data: {
        repair_count: 3,
        total_repair_price: 143,
        total_purchase_price: 46
      },
      //维修统计
      parcel_data: {
        total_parcel_num: 1,
        total_parcel_amount: '66.00'
      },
      //分佣统计
      option: {
        title: {
          text: '车辆销售统计',
          left: 'center'
        },
        label: {
          show: true,
          formatter: '{b}: {c} ({d}%)',
          // 设置标签内容格式，{b}为名称，{c}为值，{d}为百分比
          position: 'outside' // 将标签显示在外侧
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)',
          formatter: function formatter(res) {
            console.log('res', res);
            var tooltipContent = "".concat(res.name, "  : ").concat(res.value);
            // 追加百分比数据
            tooltipContent += " (".concat(res.percent, "%)");
            // 判断 total_amount 是否存在且不为空
            if (res.data.total_amount !== undefined && res.data.total_amount !== '') {
              tooltipContent += "<br>\u603B\u4EF7\u503C: ".concat(res.data.total_amount);
              if (res.data.nickName !== undefined && res.data.nickName !== '') {
                tooltipContent = tooltipContent.replace('总价值', res.data.nickName);
              }
            }
            // 单独处理已购买（卖出统计）
            if (res.dataIndex == 3) {
              tooltipContent += "<br>\u5356\u51FA\u8FDB\u8D27\u4EF7: ".concat(res.data.bought_price);
              tooltipContent += "<br>\u5356\u51FA\u6536\u5165: ".concat(res.data.bought_income);
            }
            return tooltipContent;
          }
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          // data: ['vehicle_stock', 'renting', 'returned', 'purchased'],
          data: ['车辆库存', '租用中', '已归还', '卖出车辆']
        },
        series: [{
          name: '车辆销售统计',
          type: 'pie',
          radius: '55%',
          center: ['50%', '60%'],
          data: [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      },
      option1: {
        title: {
          text: '租金到期付款比例',
          left: 'center'
        },
        color: ['#2DBF62', '#DE2E2E'],
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)',
          formatter: function formatter(res) {
            console.log('res', res);
            var tooltipContent = "".concat(res.name, "  : ").concat(res.value);
            // 追加百分比数据
            tooltipContent += " (".concat(res.percent, "%)");
            // 判断 total_amount 是否存在且不为空
            if (res.data.total_amount !== undefined && res.data.total_amount !== '') {
              tooltipContent += "<br>\u603B\u4EF7\u503C: ".concat(res.data.total_amount);
              if (res.data.nickName !== undefined && res.data.nickName !== '') {
                tooltipContent = tooltipContent.replace('总价值', res.data.nickName);
              }
            }
            return tooltipContent;
          }
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          data: ['租金到期已付', '租金到期未付']
        },
        series: [{
          name: '租金到期付款比例',
          type: 'pie',
          radius: '55%',
          center: ['50%', '60%'],
          data: [{
            value: 2,
            name: '租金到期已付'
          }, {
            value: 1,
            name: '租金到期未付'
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      },
      option2: {
        title: {
          text: '车辆维修信息统计',
          left: 'left'
        },
        label: {
          show: true,
          position: 'top' // 将标签显示在柱状图的顶部
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)',
          formatter: function formatter(res) {
            console.log('res', res);
            var legendMap = {
              total_purchase_price: '配件进货价',
              total_repair_price: '维修收入',
              repair_count: '维修数量'
            };
            var serName = res.seriesName;
            var tooltipContent = '';
            // tooltipContent = `${res.name}<br/>`
            // 根据seriesIndex判断显示哪个字段，分别是0，1，2
            tooltipContent += "".concat(legendMap[serName], ": ").concat(res.data[serName], "<br/>");
            return tooltipContent;
          }
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          // data: ['vehi
          formatter: function formatter(name) {
            var legendMap = {
              total_purchase_price: '进货价',
              total_repair_price: '维修价',
              repair_count: '维修数量'
            };
            return legendMap[name];
          }
        },
        dataset: {
          dimensions: ['name', 'repair_count', 'total_purchase_price', 'total_repair_price'],
          source: [{
            name: '维修统计',
            total_purchase_price: 43.3,
            total_repair_price: 85.8,
            repair_count: 2
          }]
        },
        xAxis: {
          type: 'category'
        },
        yAxis: [{
          type: 'value',
          name: '数量/次',
          max: 25
        },
        // 第一个 y 轴用于显示数量次数
        {
          type: 'value',
          name: '价格/元',
          max: 500
        } // 第二个 y 轴用于显示价格元
        ],
        series: [{
          type: 'bar',
          yAxisIndex: 0
        },
        // 关联第一个 y 轴，用于绘制与数量次数相关的柱状图
        {
          type: 'bar',
          yAxisIndex: 1
        },
        // 关联第二个 y 轴，用于绘制与价格元相关的柱状图
        {
          type: 'bar',
          yAxisIndex: 1
        } // 这里再添加一个与数量次数相关的柱状图示例
        ]
      },
      option3: {
        color: ['#4472C5', '#ED7C30', '#80FF80', '#FF8096', '#800080'],
        title: {
          text: '订单分佣统计',
          left: 'right'
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)',
          formatter: function formatter(res) {
            console.log('res', res);
            var legendMap = {
              total_parcel_num: '分佣订单数',
              total_parcel_amount: '分佣总总金额'
            };
            var serName = res.seriesName;
            var tooltipContent = '';
            // tooltipContent = `${res.name}<br/>`
            // 根据seriesIndex判断显示哪个字段，分别是0，1，2
            tooltipContent += "".concat(legendMap[serName], ": ").concat(res.data[serName], "<br/>");
            return tooltipContent;
          }
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          // data: ['vehi
          formatter: function formatter(name) {
            var legendMap = {
              total_parcel_num: '分佣订单数',
              total_parcel_amount: '分佣总总金额'
            };
            return legendMap[name];
          }
        },
        dataset: {
          dimensions: ['name', 'total_parcel_num', 'total_parcel_amount'],
          source: [{
            name: '分佣订单统计',
            total_parcel_num: 1,
            total_parcel_amount: '66.00'
          }]
        },
        xAxis: {
          type: 'category'
        },
        yAxis: [{
          type: 'value',
          name: '订单数量/比',
          max: 25
        },
        // 第一个 y 轴用于显示数量次数
        {
          type: 'value',
          name: '价格/元',
          max: 500
        } // 第二个 y 轴用于显示价格元
        ],
        series: [{
          type: 'bar',
          yAxisIndex: 0
        },
        // 关联第一个 y 轴，用于绘制与数量次数相关的柱状图
        {
          type: 'bar',
          yAxisIndex: 1
        } // 关联第二个 y 轴，用于绘制与价格元相关的柱状图
        ]
      },
      tableDataComp: '',
      tableDataRent: '',
      tableDataSell: '',
      tableDataRepair: '',
      tableDataParcel: '',
      page: 1,
      pageSize: 10
    };
  },
  watch: {
    createTime: function createTime(val) {
      if (val) {
        this.searchForm.createTime = val.join('~');
      } else {
        this.searchForm.createTime = '';
      }
    }
  },
  mounted: function mounted() {
    var chart = this.$refs.chart;
    console.log('chart', chart);
    this.initCreateTime();
    this.init();
  },
  methods: {
    jumpRoute: function jumpRoute(val) {
      var routeMap = {
        vel: '/inOutBound/in',
        part: '/inOutBound/part'
      };
      console.log('routeMap', routeMap[val]);
      this.$router.push({
        path: routeMap[val],
        query: {}
      });
    },
    initCreateTime: function initCreateTime() {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      var day = today.getDate();
      var startOfDay = "".concat(year, "-").concat(month.toString().padStart(2, '0'), "-").concat(day.toString().padStart(2, '0'), " 00:00:00");
      var endOfDay = "".concat(year, "-").concat(month.toString().padStart(2, '0'), "-").concat(day.toString().padStart(2, '0'), " 23:59:59");
      this.createTime = [startOfDay, endOfDay];
    },
    // 查询
    search: function search() {
      var _this = this;
      this.$nextTick(function () {
        _this.init();
      });
    },
    // 重置
    reset: function reset() {
      this.initCreateTime();
      this.init();
    },
    init: function init() {
      var _this2 = this;
      this.isShow = false;
      var page = this.page;
      var pageSize = this.pageSize;

      // 使用 Promise.all 发起并发请求
      Promise.all([(0, _inOutBound.totalVehicleInfo)({}), (0, _inOutBound.todayVehicleRent)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)), (0, _inOutBound.todayVehicleSell)({
        page: page,
        pageSize: pageSize,
        createTime: this.searchForm.createTime
      }), (0, _inOutBound.todayVehicleRepair)({
        page: page,
        pageSize: pageSize,
        createTime: this.searchForm.createTime
      }), (0, _inOutBound.todayVehicleParcel)({
        page: page,
        pageSize: pageSize,
        createTime: this.searchForm.createTime
      })]).then(function (_ref) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 5),
          compRes = _ref2[0],
          rentRes = _ref2[1],
          sellRes = _ref2[2],
          repairRes = _ref2[3],
          parcelRes = _ref2[4];
        console.log('总和统计', compRes);
        console.log('出租统计: ', rentRes);
        console.log('卖出统计: ', sellRes);
        console.log('维修统计: ', repairRes);
        console.log('分拥信息: ', parcelRes);
        _this2.tableDataComp = compRes;
        _this2.tableDataRent = rentRes;
        _this2.tableDataSell = sellRes;
        _this2.tableDataRepair = repairRes;
        _this2.tableDataParcel = parcelRes;
        // 处理统计图表数据
        var _this2$tableDataComp = _this2.tableDataComp,
          total_vehicle_count = _this2$tableDataComp.total_vehicle_count,
          veh_inventory_info = _this2$tableDataComp.veh_inventory_info;
        console.log('total_vehicle_count, veh_inventory_info', total_vehicle_count, veh_inventory_info);
        _this2.handleOptions1(_this2.tableDataComp);
        _this2.handleOptions2(_this2.tableDataRepair);
      }).catch(function (error) {
        console.error('Error fetching data:', error);
      }).finally(function () {
        _this2.isShow = true;
      });
    },
    handleOptions1: function handleOptions1(tableDataComp) {
      var total_vehicle_count = tableDataComp.total_vehicle_count,
        veh_inventory_info = tableDataComp.veh_inventory_info;
      this.option.series[0].data = [{
        value: veh_inventory_info.total_num,
        name: '车辆库存',
        total_amount: 100
      }, {
        value: total_vehicle_count.renting_count,
        name: '租用中',
        total_amount: veh_inventory_info.total_amount,
        nickName: '车辆收租金额'
      }, {
        value: total_vehicle_count.return_count,
        name: '已归还'
      }, {
        value: this.tableDataSell.bought_total,
        name: '卖出车辆',
        bought_price: this.tableDataSell.bought_price,
        bought_income: this.tableDataSell.bought_income
      }];
    },
    handleOptions2: function handleOptions2(tableDataRepair) {
      var repair_count = tableDataRepair.repair_count,
        total_repair_price = tableDataRepair.total_repair_price,
        total_purchase_price = tableDataRepair.total_purchase_price;
      this.option2.dataset.source[0].repair_count = repair_count;
      this.option2.dataset.source[0].total_repair_price = total_repair_price;
      this.option2.dataset.source[0].total_purchase_price = total_purchase_price;
    },
    jumpPage: function jumpPage(val) {
      // data: ['车辆库存', '租用中', '已归还', '已购买'],
      console.log('jumpPage', val);
      var routes = [{
        dataIndex: 0,
        path: '/inOutBound/in'
      }, {
        dataIndex: 1,
        path: '/inOutBound/out'
      }, {
        dataIndex: 2,
        path: '/inOutBound/out'
      }, {
        dataIndex: 3,
        path: '/inOutBound/out'
      }];
      var dataIndex = val.dataIndex;
      var route = routes.find(function (item) {
        return item.dataIndex === dataIndex;
      });
      if (route) {
        this.$router.push({
          path: route.path,
          query: {
            dataIndex: dataIndex
          }
        });
      } else {
        console.error('未找到对应的路由路径');
      }
    }
  }
};