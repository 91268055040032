"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editMemberInfo = editMemberInfo;
exports.editSupplier = editSupplier;
exports.editSupplierConfig = editSupplierConfig;
exports.getCompanyArea = getCompanyArea;
exports.getConfig = getConfig;
exports.getMemberList = getMemberList;
exports.getProvinceList = getProvinceList;
exports.getSupplierConfig = getSupplierConfig;
exports.getSupplierInfo = getSupplierInfo;
exports.getSupplierList = getSupplierList;
exports.saveMemberBreak = saveMemberBreak;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 用户管理-用户列表
 */
// 获取用户列表
function getMemberList(params) {
  return _fetch.default.post('/Supplier/Member/memberList', params);
}

/**
 * 供应商管理-供应商列表
 */
// 获取供应商列表
function getSupplierList(params) {
  return _fetch.default.post('/Supplier/Supplier/supplierList', params);
}
// 查看供应商信息
function getSupplierInfo(id) {
  return _fetch.default.get('/Supplier/Supplier/supplierInfoById', {
    id: id
  });
}
// 获取省份列表
function getProvinceList() {
  return _fetch.default.get('/Supplier/Supplier/getCompanyArea');
}
// 编辑供应商信息
function editSupplier(params) {
  return _fetch.default.post('/Supplier/Supplier/editSupplierById', params, true);
}
// 获取区域二级列表
function getCompanyArea(id) {
  return _fetch.default.get('/Supplier/Supplier/getSecondCompanyArea', {
    id: id
  });
}

// 获取供应商配置
function getSupplierConfig() {
  return _fetch.default.get('/Supplier/Supplier/getSupplierConfig');
}
// 编辑供应商配置
function editSupplierConfig(params) {
  return _fetch.default.post('/Supplier/Supplier/editSupplierConfig', params, true);
}
// 获取供应商配置详情
function getConfig(params) {
  return _fetch.default.get('/Supplier/Supplier/getConfig', params);
}

/**
 * 用户模块
 *
 */
// 用户拉黑或者取消拉黑
function saveMemberBreak(params) {
  return _fetch.default.post('/Supplier/Member/saveMemberBreak', params, true);
}
// 编辑用户信息
function editMemberInfo(params) {
  return _fetch.default.post('/Supplier/Member/editMemberInfo', params, true);
}