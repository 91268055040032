"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkCardId = checkCardId;
exports.checkCompanyId = checkCompanyId;
exports.checkEmail = checkEmail;
exports.checkMobile = checkMobile;
exports.checkName = checkName;
exports.checkPhone = checkPhone;
exports.checkString = checkString;
exports.checkUrl = checkUrl;
exports.isExternal = isExternal;
/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * 手机号码校验
 */
function checkMobile(val) {
  return /^1\d{10}$/.test(val);
}

/**
 * 工商注册号校验
 */
function checkCompanyId(val) {
  return /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(val);
}

/**
 * 身份证号校验
 */
function checkCardId(val) {
  return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);
}

/**
 * 固定电话校验
 */
function checkPhone(val) {
  // return /^0\d{2,3}-?\d{7,8}$/.test(val);
  // return /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/.test(val);
  // return /([0-9]{3,4}-)?[0-9]{7,8}/.test(val);
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(val) || /^400(-?\d{3,4}){2}$/.test(val);
}

/**
 * 校验Url
 */
function checkUrl(val) {
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(val);
}

/**
 * 校验特殊字符
 */
function checkString(val) {
  return /^[a-zA-Z0-9_\u4e00-\u9fa5\\s·]+$/.test(val);
}

/**
 * 校验姓名
 */
function checkName(val) {
  return /^[\u4e00-\u9fa5]{2,6}$/.test(val);
}
/**
 * 校验邮箱
 **/
function checkEmail(val) {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(val);
}