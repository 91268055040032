var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info_config_page" },
    [
      _c(
        "div",
        { staticClass: "flex align-center table-top-btns" },
        [
          _c(
            "h5",
            { staticClass: "flex align-center" },
            [
              _c("svg-icon", {
                staticStyle: { "margin-right": "15px" },
                attrs: { "icon-class": "list", size: 15 },
              }),
              _vm._v("数据列表 "),
            ],
            1
          ),
          _vm.$hasMethod("#add")
            ? _c(
                "el-button",
                {
                  staticClass: "add-btn",
                  attrs: { size: "small", icon: "el-icon-plus" },
                  on: { click: _vm.addCategorys },
                },
                [_vm._v("新增品类")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            "row-key": "id",
            border: "",
            "tree-props": { children: "children" },
            "default-expand-all": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "展开", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "排序", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !_vm.$hasMethod("#sort")
                      ? _c("span", [_vm._v(_vm._s(scope.row.displayorder))])
                      : _c("el-input", {
                          attrs: {
                            oninput: "value=value.replace(/[^\\d]/g,'')",
                            maxLength: "9",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeSort(
                                scope.row.id,
                                scope.row.displayorder
                              )
                            },
                          },
                          model: {
                            value: scope.row.displayorder,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "displayorder", $$v)
                            },
                            expression: "scope.row.displayorder",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "ID", width: "150", prop: "id" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "品类名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", [
                      !scope.row.children
                        ? _c(
                            "span",
                            { staticStyle: { margin: "0 5px 0 20px" } },
                            [_vm._v("├─")]
                          )
                        : _vm._e(),
                      _vm._v(_vm._s(scope.row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: scope.row.enabled == 1 ? "success" : "danger",
                        },
                      },
                      [_vm._v(_vm._s(scope.row.enabled == 1 ? "显示" : "隐藏"))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.children && _vm.$hasMethod("#add")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.addItemChild(scope.row)
                              },
                            },
                          },
                          [_vm._v("添加子菜单")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#enable")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "warning" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEnable(
                                  scope.row.id,
                                  scope.row.enabled
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(scope.row.enabled == 1 ? "隐藏" : "显示")
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.$hasMethod("#delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.removeItem(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("custom-page", {
        ref: "customPage",
        attrs: { total: _vm.total },
        on: { getList: _vm.getList },
      }),
      _c("add-category", {
        ref: "cateForm",
        attrs: {
          areaList: _vm.areaList,
          optionProps: _vm.optionProps,
          parentVisible: _vm.parentVisible,
          parentForm: _vm.parentForm,
          dialogTitle: _vm.dialogTitle,
        },
        on: {
          submitForm: _vm.submitForm,
          closeDialog: _vm.closeDialog,
          setUrl: _vm.setUrl,
        },
      }),
      _c("add-child", {
        attrs: {
          optionProps: _vm.optionProps,
          areaList: _vm.areaList,
          childVisible: _vm.childVisible,
          childForm: _vm.childForm,
          dialogTitle: _vm.dialogTitle,
          firstLevel: _vm.firstLevel,
          rent_list: _vm.rent_list,
          insurance_list: _vm.insurance_list,
          zlb_list: _vm.zlb_list,
          insure_list: _vm.insure_list,
        },
        on: {
          submitForm: _vm.submitChildForm,
          closeDialog: _vm.closeDialog,
          setUrl: _vm.setChildUrl,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }