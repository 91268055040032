"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
var _toConsumableArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
var _router = require("@/router");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
/**
 * @param routes
 * @param routeName
 */
function getRoute(routes, routeName) {
  var _iterator = _createForOfIteratorHelper(routes),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var item = _step.value;
      if (item.name === routeName) return _objectSpread({}, item);
      if (item.children && routeName.indexOf(item.name) > -1) {
        return getRoute(item.children, routeName);
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return null;
}

/***
 * 获取匹配到的路由数组
 */
function getRouteList(routes, menu) {
  var res = [];
  menu.forEach(function (item) {
    var obj = getRoute(routes, item.path);
    if (obj) {
      obj.meta.title = item.label;
      // 分配按钮权限
      if (item.children) {
        var mList = item.children.filter(function (k) {
          return k.path.indexOf("#") > -1;
        });
        if (mList.length > 0) {
          obj.meta.methods = mList.map(function (p) {
            return p.path;
          });
        }
      }
      res.push(obj);
    }
  });
  return res;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param menu
 */
function filterAsyncRoutes(routes, menu) {
  var res = [];
  menu.forEach(function (item) {
    if (item.path) {
      var obj = getRoute(routes, item.path);
      if (obj) {
        obj.meta.title = item.label;
        obj.children = getRouteList(routes, item.children);
        var _iterator2 = _createForOfIteratorHelper(item.children),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var child = _step2.value;
            if (child.children) {
              var result = getRouteList(routes, child.children);
              obj.children = [].concat((0, _toConsumableArray2.default)(obj.children), (0, _toConsumableArray2.default)(result));
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        var rdObj = obj.children.find(function (k) {
          return !k.hidden;
        });
        obj.redirect = obj.path + "/" + rdObj.path;
        res.push(obj);
      }
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, menu) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes = [];
      // 过滤掉第一级
      var menuArr = [];
      var _iterator3 = _createForOfIteratorHelper(menu),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;
          menuArr = menuArr.concat(item.children);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      accessedRoutes = filterAsyncRoutes(_router.asyncRoutes, menuArr);
      // 最后添加404
      accessedRoutes.push({
        path: "*",
        redirect: "/404",
        hidden: true
      });
      // console.log(accessedRoutes);
      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};