"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _finance = require("@/api/finance");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['id'],
  data: function data() {
    return {
      loading: true,
      rateList: [],
      rateListSelf: []
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      this.getRateConfig();
    },
    getRateConfig: function getRateConfig() {
      var _this = this;
      (0, _finance.getRateConfig)({
        type: 1
      }).then(function (res) {
        // console.log('getRateConfig', res)
        _this.rateList = res.rule_details;
        _this.rateListSelf = res.rule_details_self;
        _this.calcRate();
        _this.loading = false;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    calcRate: function calcRate() {
      this.rateList.forEach(function (item, index) {
        if (item.is_percentage) {
          if (item.value < 1 && item.value > 0) {
            item.value = item.value * 100;
          }
        }
      }), this.rateListSelf.forEach(function (item, index) {
        if (item.is_percentage) {
          if (item.value < 1 && item.value > 0) {
            item.value = item.value * 100;
          }
        }
      });
    },
    submitCalcRate: function submitCalcRate() {
      var arr = JSON.parse(JSON.stringify(this.rateList));
      arr.forEach(function (item, index) {
        if (item.is_percentage) {
          if (item.value > 1) {
            item.value = item.value / 100;
          }
        }
      });
      return arr;
    },
    submitCalcRateSelf: function submitCalcRateSelf() {
      var arr = JSON.parse(JSON.stringify(this.rateListSelf));
      arr.forEach(function (item, index) {
        if (item.is_percentage) {
          if (item.value > 1) {
            item.value = item.value / 100;
          }
        }
      });
      return arr;
    },
    calcRateUnit: function calcRateUnit(item) {
      return item.is_percentage ? '%' : '元';
    },
    submit: function submit() {
      var _this2 = this;
      this.loading = true;
      var rateList = this.submitCalcRate();
      var rateListSelf = this.submitCalcRateSelf();
      (0, _finance.editRateConfig)({
        type: 1,
        rule: rateList,
        rule_self: rateListSelf
      }).then(function (res) {
        _this2.$message.success("操作成功");
        _this2.getRateConfig();
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};