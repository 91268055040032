var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      ref: "appMain",
      staticClass: "app-main",
      on: { scroll: _vm.handleScroll },
    },
    [
      _c("transition", { attrs: { name: "fade-transform", mode: "out-in" } }, [
        _c(
          "div",
          {
            staticClass: "content_wrap",
            class: {
              "fix-footer": _vm.fixFooter,
              "no-shadow": _vm.noShadowList.indexOf(_vm.$route.name) > -1,
            },
          },
          [
            _vm.noTitleList.indexOf(_vm.$route.name) === -1
              ? _c("h3", { staticClass: "page_title" }, [
                  _vm._v(_vm._s(_vm.$route.meta.title)),
                ])
              : _vm._e(),
            _c(
              "keep-alive",
              [
                _vm.$route.meta.keepAlive
                  ? _c("router-view", {
                      key: _vm.key,
                      staticClass: "page_wrapper",
                      class: {
                        "no-padding":
                          _vm.noPaddingList.indexOf(_vm.$route.name) > -1,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            !_vm.$route.meta.keepAlive
              ? _c("router-view", {
                  key: _vm.key,
                  staticClass: "page_wrapper",
                  class: {
                    "no-padding":
                      _vm.noPaddingList.indexOf(_vm.$route.name) > -1,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }