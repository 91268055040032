var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.platformStatisList.length
    ? _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "白名单", prop: "reason" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择",
                        multiple: "",
                      },
                      model: {
                        value: _vm.form.platformWhiteList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "platformWhiteList", $$v)
                        },
                        expression: "form.platformWhiteList",
                      },
                    },
                    _vm._l(_vm.platformStatisList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "黑名单", prop: "reason2" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择",
                        multiple: "",
                      },
                      model: {
                        value: _vm.form.platformBlackList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "platformBlackList", $$v)
                        },
                        expression: "form.platformBlackList",
                      },
                    },
                    _vm._l(_vm.platformStatisList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.resetParams } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }