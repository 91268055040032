"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addActivity = addActivity;
exports.categoryList = categoryList;
exports.delShopUser = delShopUser;
exports.delUserInfo = delUserInfo;
exports.editGoodsData = editGoodsData;
exports.editGoodsSetmeal = editGoodsSetmeal;
exports.editShopUserById = editShopUserById;
exports.editShopUserStatus = editShopUserStatus;
exports.editUserIscheck = editUserIscheck;
exports.getActivityList = getActivityList;
exports.getCategory = getCategory;
exports.getExamineMobile = getExamineMobile;
exports.getGoodsAll = getGoodsAll;
exports.getGoodsAreas = getGoodsAreas;
exports.getGoodsCheckList = getGoodsCheckList;
exports.getGoodsData = getGoodsData;
exports.getGoodsInfo = getGoodsInfo;
exports.getGoodsParameter = getGoodsParameter;
exports.getGoodsRentData = getGoodsRentData;
exports.getGoodsSetmealInfo = getGoodsSetmealInfo;
exports.getGoodsSetmealList = getGoodsSetmealList;
exports.getGoodsShelfCheckList = getGoodsShelfCheckList;
exports.getMerchantExamineMobile = getMerchantExamineMobile;
exports.getParameterList = getParameterList;
exports.getShopCategory = getShopCategory;
exports.getSourceAndRate = getSourceAndRate;
exports.getSourceInfo = getSourceInfo;
exports.getUserByIdStr = getUserByIdStr;
exports.goodsCheckPass = goodsCheckPass;
exports.goodsCheckRefuse = goodsCheckRefuse;
exports.goodsGrounding = goodsGrounding;
exports.goodsShare = goodsShare;
exports.goodsShelfCheckPass = goodsShelfCheckPass;
exports.goodsShelfCheckRefuse = goodsShelfCheckRefuse;
exports.itemDownShelf = itemDownShelf;
exports.memberWithdrawalExamineList = memberWithdrawalExamineList;
exports.memberWithdrawalPass = memberWithdrawalPass;
exports.memberWithdrawalRefuse = memberWithdrawalRefuse;
exports.merchantWithdrawalExamineList = merchantWithdrawalExamineList;
exports.merchantWithdrawalExamineListExport = merchantWithdrawalExamineListExport;
exports.merchantWithdrawalPass = merchantWithdrawalPass;
exports.merchantWithdrawalRefuse = merchantWithdrawalRefuse;
exports.setRate = setRate;
exports.shopUserById = shopUserById;
exports.supplierCheckList = supplierCheckList;
exports.withdrawalExamineList = withdrawalExamineList;
exports.withdrawalExamineListExport = withdrawalExamineListExport;
exports.withdrawalPass = withdrawalPass;
exports.withdrawalRefuse = withdrawalRefuse;
var _fetch = _interopRequireDefault(require("@/utils/fetch"));
/**
 * 品类审核
 */
// 品类审核列表
function categoryList(params) {
  return _fetch.default.post("/Supplier/CategoryExamine/categoryExamineList", params);
}
// 根据id编辑商户分类信息的审核状态(通过、拒绝)
function editUserIscheck(params) {
  return _fetch.default.post("/Supplier/CategoryExamine/editCategoryUserIscheck", params, true);
}
// 根据id删除商户分类信息
function delUserInfo(params) {
  return _fetch.default.post("/Supplier/CategoryExamine/delCategoryUser", params, true);
}
// 根据member_id、category_id获取订单来源信息
function getSourceInfo(params) {
  return _fetch.default.get("/Supplier/CategoryExamine/getSource", params);
}
// 根据idStr获取商户分类信息
function getUserByIdStr(id) {
  return _fetch.default.get("/Supplier/CategoryExamine/categoryUserByIdStr?idStr=377,376", {
    id: id
  }, true);
}
// 根据member_id、category_id获取订单来源和费率信息
function getSourceAndRate(params) {
  return _fetch.default.get("/Supplier/CategoryExamine/getSourceAndRate", params);
}
// 设置费率
function setRate(params) {
  return _fetch.default.post("/Supplier/CategoryExamine/setRate", params, true);
}

/**
 * 商品审核
 */
// 商品审核列表
function getGoodsCheckList(params) {
  return _fetch.default.post("/Supplier/GoodsCheck/getGoodsCheckList", params);
}
// 商品审核通过
function goodsCheckPass(ids) {
  return _fetch.default.post("/Supplier/GoodsCheck/goodsCheckPass", {
    goods_id: ids
  }, true);
}
// 商品下架审核列表
function getGoodsShelfCheckList(params) {
  return _fetch.default.post("/Supplier/GoodsCheck/getGoodsShelfCheckList", params);
}
// 查看商品详情
function getGoodsInfo(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsInfo", {
    goods_id: id
  });
}
// 商品审核拒绝
function goodsCheckRefuse(params) {
  return _fetch.default.post("/Supplier/GoodsCheck/goodsCheckRefuse", params);
}
// 商品下架审核通过
function goodsShelfCheckPass(ids) {
  return _fetch.default.post("/Supplier/GoodsCheck/goodsShelfCheckPass", {
    goods_id: ids
  }, true);
}
// 商品审核拒绝
function goodsShelfCheckRefuse(params) {
  return _fetch.default.post("/Supplier/GoodsCheck/goodsShelfCheckRefuse", params);
}
// 商品列表
function getGoodsAll(params) {
  return _fetch.default.post("/Supplier/Goods/getGoodsAll", params);
}
// 获取设置活动
function getActivityList(id) {
  return _fetch.default.get("/Supplier/Goods/getActivityList", {
    goods_id: id
  });
}
// 设置活动
function addActivity(params) {
  return _fetch.default.post("/Supplier/Goods/addActivity", params);
}
// 商品分享
function goodsShare(id) {
  return _fetch.default.get("/Supplier/Goods/goodsShare", {
    goods_id: id
  });
}
// 商品下架
function itemDownShelf(params) {
  return _fetch.default.post("/Supplier/Goods/itemDownShelf", params, true);
}
// 商品上架
function goodsGrounding(id) {
  return _fetch.default.post("/Supplier/Goods/goodsGrounding", {
    goods_id: id
  }, true);
}
// 获取所有申请品类
function getShopCategory(id) {
  return _fetch.default.get("/Supplier/Goods/getShopCategory", {
    goods_id: id
  });
}
// 获取所有商品品类
function getCategory() {
  return _fetch.default.get("/Supplier/Goods/getCategory");
}
// 获取商品服务城市
function getGoodsAreas() {
  return _fetch.default.get("/Supplier/Goods/getGoodsAreas");
}
// 获取商品添加各参数
function getGoodsRentData() {
  return _fetch.default.get("/Supplier/Goods/getGoodsRentData");
}
// 编辑获取商品基础信息
function getGoodsData(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsData", {
    goods_id: id
  });
}
// 通过分类id获取商品参数
function getGoodsParameter(id) {
  return _fetch.default.post("/Supplier/Goods/getGoodsParameter", {
    cate_id: id
  });
}
// 编辑获取商品套餐列表
function getGoodsSetmealList(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsSetmealList", {
    goods_id: id
  });
}
// 套餐获取参数
function getParameterList() {
  return _fetch.default.get("/Supplier/Goods/getParameterList");
}
// 编辑获取套餐信息
function getGoodsSetmealInfo(id) {
  return _fetch.default.get("/Supplier/Goods/getGoodsSetmealInfo", {
    setmeal_id: id
  });
}
// 编辑商品基础信息
function editGoodsData(params) {
  return _fetch.default.post("/Supplier/Goods/editGoodsData", params);
}
// 编辑套餐信息
function editGoodsSetmeal(params) {
  return _fetch.default.post("/Supplier/Goods/editGoodsSetmeal", params);
}
// 提现审核列表
function withdrawalExamineList(params) {
  return _fetch.default.post("/Supplier/Withdrawal/withdrawalExamineList", params);
}
// 提现审核通过
function withdrawalPass(params) {
  return _fetch.default.post("/Supplier/Withdrawal/withdrawalPass", params, true);
}
// 获取提现审核验证码
function getExamineMobile() {
  return _fetch.default.post("/Supplier/Withdrawal/getExamineMobile");
}
// 提现审核拒绝
function withdrawalRefuse(params) {
  return _fetch.default.post("/Supplier/Withdrawal/withdrawalRefuse", params, true);
}
// 提现审核列表导出
function withdrawalExamineListExport(params) {
  return _fetch.default.export("/Supplier/Withdrawal/withdrawalExamineListExport", params, "提现审核列表");
}

/**
 * 商户提现审核
 */
// 商户提现审核列表
function merchantWithdrawalExamineList(params) {
  return _fetch.default.post("/Supplier/Withdrawal/merchantWithdrawalExamineList", params);
}
// 商户提现审核通过
function merchantWithdrawalPass(params) {
  return _fetch.default.post("/Supplier/Withdrawal/merchantWithdrawalPass", params, true);
}
// 获取商户提现审核验证码
function getMerchantExamineMobile() {
  return _fetch.default.post("/Supplier/Withdrawal/getMerchantExamineMobile");
}
// 商户提现审核拒绝
function merchantWithdrawalRefuse(params) {
  return _fetch.default.post("/Supplier/Withdrawal/merchantWithdrawalRefuse", params, true);
}
// 商户提现审核列表导出
function merchantWithdrawalExamineListExport(params) {
  return _fetch.default.export("/Supplier/Withdrawal/merchantWithdrawalExamineListExport", params, "商户提现审核列表");
}

/**
 * 用户提现审核
 */
// 用户提现审核列表
function memberWithdrawalExamineList(params) {
  return _fetch.default.get("/Supplier/Member/memberWithdrawal", params);
}
// 用户提现审核通过
function memberWithdrawalPass(params) {
  return _fetch.default.post("/Supplier/Member/memberWithdrawalSave", params, true);
}
// 用户提现审核拒绝
function memberWithdrawalRefuse(params) {
  return _fetch.default.post("/Supplier/Member/memberWithdrawalSave", params, true);
}

/**
 * 供应商审核
 */
// 供应商审核列表
function supplierCheckList(params) {
  return _fetch.default.post("/Supplier/SupplierCheck/supplierCheckList", params);
}
// 根据id查看供应商信息
function shopUserById(id) {
  return _fetch.default.get("/Supplier/SupplierCheck/shopUserById", {
    id: id
  });
}
// 根据id编辑供应商信息
function editShopUserById(params) {
  return _fetch.default.post("/Supplier/SupplierCheck/editShopUserById", params, true);
}
// 根据id编辑审核状态(通过)
function editShopUserStatus(id) {
  return _fetch.default.post("/Supplier/SupplierCheck/editShopUserStatus", {
    id: id,
    status: 1
  }, true);
}
// 根据id删除供应商信息
function delShopUser(id) {
  return _fetch.default.post("/Supplier/SupplierCheck/delShopUser", {
    id: id
  }, true);
}