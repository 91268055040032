"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _iconForm = _interopRequireDefault(require("./iconForm.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['imgWidth', 'imgHeight'],
  components: {
    iconForm: _iconForm.default
  },
  data: function data() {
    return {
      iconList: []
    };
  },
  methods: {
    // 删除商品
    deleteItem: function deleteItem(index) {
      // Check if the index is valid
      if (index < 0 || index >= this.iconList.length) {
        throw new Error('Invalid index');
      }

      // Remove the element at the specified index using splice
      this.iconList.splice(index, 1);
      this.$set(this.iconList);
    },
    addItem: function addItem(index) {
      this.iconList.push({
        img: '',
        title: ''
      });
      this.$set(this.iconList);
    },
    // 升序
    upSortItem: function upSortItem(index) {
      if (index === 0) {
        return;
      }
      var item = this.iconList[index];
      this.$set(this.iconList, index, this.iconList[index - 1]);
      this.$set(this.iconList, index - 1, item);
    },
    // 降序
    downSortItem: function downSortItem(index) {
      if (index === this.iconList.length - 1) {
        return;
      }
      var item = this.iconList[index];
      this.$set(this.iconList, index, this.iconList[index + 1]);
      this.$set(this.iconList, index + 1, item);
    },
    init: function init() {
      for (var i = 0; i < 10; i++) {
        this.iconList.push({
          img: '',
          title: '',
          jumpUrl: ''
        });
      }
    }
  },
  mounted: function mounted() {
    this.init();
    console.log('iconList', this.iconList);
  }
};