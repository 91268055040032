"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _merchant = require("@/api/merchant");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _add = _interopRequireDefault(require("./add"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    addDialog: _add.default
  },
  data: function data() {
    return {
      searchForm: {
        mobile: "",
        status: null
      },
      total: 0,
      tableData: [],
      editItem: null,
      roleList: []
    };
  },
  filters: {},
  mounted: function mounted() {
    this.getList();
    this.getRoleList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _merchant.getMerchantChildList)({
        page: page,
        pageSize: pageSize,
        status: this.searchForm.status === null ? -100 : this.searchForm.status,
        mobile: this.searchForm.mobile
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
      });
    },
    getRoleList: function getRoleList() {
      var _this2 = this;
      (0, _merchant.getMerchantGroupList)().then(function (res) {
        _this2.roleList = res.data || [];
      });
    },
    // 删除
    // removeItem(id) {
    // 	this.$confirm('您确定要删除吗?', '删除', {
    // 		confirmButtonText: '确定',
    // 		cancelButtonText: '取消',
    // 	}).then(() => {
    // 		delMerchant(id).then(res => {
    // 			this.getList(true);
    // 		});
    // 	}).catch(() => {});
    // },
    // 添加/编辑账号
    openAddDialog: function openAddDialog() {
      var val = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.editItem = val;
      this.$refs.addDialog.dialogVisible = true;
    },
    // 批量操作
    handleCommand: function handleCommand(command) {
      var list = this.$refs.multipleTable.selection;
      if (list.length === 0) {
        this.$message({
          message: "没有勾选项",
          type: "warning"
        });
        return;
      }
      var ids = list.map(function (item) {
        return item.id;
      }).join(',');
      switch (command) {
        case "delete":
          this.removeItem(ids);
          break;
        case "enable":
          this.changeStatus(ids, 0);
          break;
        case "disable":
          this.changeStatus(ids, 1);
          break;
        default:
          break;
      }
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = this.$options.data().searchForm;
      this.getList();
    },
    // 启用/禁用
    changeStatus: function changeStatus(ids, status) {
      var _this3 = this;
      var tip = "",
        title = "";
      if (status == 1) {
        tip = "您确定要禁用吗？";
        title = "禁用";
      } else {
        tip = "您确定要启用吗？";
        title = "启用";
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _merchant.setMerchantMember)({
          ids: ids,
          status: status == 1 ? 0 : 1
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    }
  }
};