"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem'],
  data: function data() {
    return {
      order_no: "",
      price: "获取中",
      status: -1,
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.submit();
      }
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;
      (0, _order.getMerchantTransByMoreServiceId)({
        moreServiceId: this.editItem.id
      }).then(function (res) {
        _this.price = res.money;
        _this.status = res.status;
      });
    },
    payOfflineMoreServer: function payOfflineMoreServer() {
      var _this2 = this;
      this.$confirm('确定已收到该笔金额？', '确认收款', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.moreServicePay)({
          id: _this2.editItem.id,
          amount: _this2.editItem.moreservice_price
        }).then(function (res) {
          _this2.$parent.getList(true);
        });
      }).catch(function () {});
    }
  }
};