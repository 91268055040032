"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.sub");
require("core-js/modules/es6.regexp.split");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _validate = require("@/utils/validate");
var _shop = require("@/api/shop");
var _index = require("@/api/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      infoData: {
        photo: ''
      },
      nameEdit: false,
      invoiceEdit: false,
      posEdit: false,
      contractEdit: false,
      signNumEdit: false,
      telEdit: false,
      DomainSupplierEdit: false,
      DomainShopEdit: false,
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      regionList: [],
      shopcity: ''
    };
  },
  methods: {
    changeNumberInput: function changeNumberInput(value) {
      // const pattern = /^[0-9][0-9]*$/ // 正整数的正则表达式
      // // 不符合正整数时
      // if (!pattern.test(value)) {
      //     // input 框绑定的内容为空
      //     this.infoData.mobile = checkPhone(value)
      // }
    },
    changeDomainSupplierInput: function changeDomainSupplierInput(value) {
      if (value && value.indexOf('supplier.') != 0) {
        this.$message({
          message: "供应商域名必须为 supplier. 开头",
          type: "warning"
        });
        return;
      }
    },
    changeDomainShopInput: function changeDomainShopInput(value) {
      if (value && value.indexOf('shop.') != 0) {
        this.$message({
          message: "商户域名必须为 shop. 开头",
          type: "warning"
        });
        return;
      }
    },
    saveTel: function saveTel() {
      var _this = this;
      if (this.telEdit) {
        if (!this.infoData.mobile) {
          this.$message({
            message: "店铺客服电话不能为空",
            type: "warning"
          });
          return;
        }
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          mobile: this.infoData.mobile
        }).then(function (res) {
          _this.telEdit = false;
        });
      } else {
        this.telEdit = true;
      }
    },
    saveDomainSupplier: function saveDomainSupplier() {
      var _this2 = this;
      if (this.DomainSupplierEdit) {
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          domain_supplier: this.infoData.domain_supplier
        }).then(function (res) {
          _this2.DomainSupplierEdit = false;
        });
      } else {
        this.DomainSupplierEdit = true;
      }
    },
    saveDomainShop: function saveDomainShop() {
      var _this3 = this;
      if (this.DomainShopEdit) {
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          domain_shop: this.infoData.domain_shop
        }).then(function (res) {
          _this3.DomainShopEdit = false;
        });
      } else {
        this.DomainShopEdit = true;
      }
    },
    saveSignNum: function saveSignNum() {
      var _this4 = this;
      if (this.signNumEdit) {
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          sign_num: this.infoData.sign_num
        }).then(function (res) {
          (0, _shop.shopBaseInfo)().then(function (res) {
            _this4.infoData.sign_num_name = res.sign_num_name;
            _this4.signNumEdit = false;
          });
        });
      } else {
        this.signNumEdit = true;
      }
    },
    saveContract: function saveContract() {
      var _this5 = this;
      if (this.contractEdit) {
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          contract_type: this.infoData.contract_type
        }).then(function (res) {
          (0, _shop.shopBaseInfo)().then(function (res) {
            _this5.infoData.contract_type_name = res.contract_type_name;
            _this5.contractEdit = false;
          });
        });
      } else {
        this.contractEdit = true;
      }
    },
    savePos: function savePos() {
      var _this6 = this;
      if (this.posEdit) {
        var shopcity = this.infoData.shopcity;
        shopcity = shopcity.join('-');
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          shopcity: shopcity
        }).then(function (res) {
          _this6.posEdit = false;
          _this6.shopcity = shopcity;
        });
      } else {
        this.posEdit = true;
      }
    },
    saveInvoice: function saveInvoice() {
      var _this7 = this;
      if (this.invoiceEdit) {
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          faptype: this.infoData.faptype
        }).then(function (res) {
          (0, _shop.shopBaseInfo)().then(function (res) {
            _this7.infoData.faptype_name = res.faptype_name;
            _this7.invoiceEdit = false;
          });
        });
      } else {
        this.invoiceEdit = true;
      }
    },
    saveName: function saveName() {
      var _this8 = this;
      if (this.nameEdit) {
        if (!this.infoData.shopname) {
          this.$message({
            message: "店铺名称不能为空",
            type: "warning"
          });
          return;
        }
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          shopname: this.infoData.shopname
        }).then(function (res) {
          _this8.nameEdit = false;
        });
      } else {
        this.nameEdit = true;
      }
    },
    imgChange: function imgChange(val) {
      var _this9 = this;
      if (val) {
        (0, _shop.editShopBaseInfoById)({
          id: this.infoData.id,
          photo: val
        }).then(function (res) {
          _this9.infoData.photo = val;
        });
      } else {
        this.infoData.photo = val;
      }
    },
    getData: function getData() {
      var _this10 = this;
      (0, _shop.shopBaseInfo)().then(function (res) {
        _this10.infoData = res || {};
        _this10.shopcity = _this10.infoData.shopcity;
        var cityArr = _this10.infoData.shopcity.split('-');
        _this10.infoData.shopcity = cityArr[0] && cityArr[1] && cityArr[2] ? cityArr : [];
      });
    },
    getProvice: function getProvice() {
      var _this11 = this;
      (0, _index.getRegion)().then(function (res) {
        _this11.regionList = res ? res[0].sub : [];
      });
    }
  },
  mounted: function mounted() {
    this.getData();
    this.getProvice();
  }
};