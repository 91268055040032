"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _shop = require("@/api/shop");
var _goodsLink = _interopRequireDefault(require("./goodsLink"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    goodsLink: _goodsLink.default
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: String,
      default: "1020px"
    },
    maxNum: {
      type: Number
    },
    showTypeSelect: {
      type: Boolean,
      default: false
    },
    pageSize: {
      type: Number,
      default: 8
    },
    showCheckedTool: {
      type: Boolean,
      default: false
    },
    showCate: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      searchForm: {
        title: "",
        cate_id: null,
        page: 1
      },
      selectItems: [],
      goodsList: [],
      total: 0,
      categoryList: []
    };
  },
  methods: {
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }
      var item = this.selectItems[index];
      this.$set(this.selectItems, index, this.selectItems[index - 1]);
      this.$set(this.selectItems, index - 1, item);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.selectItems.length - 1) {
        return;
      }
      var item = this.selectItems[index];
      this.$set(this.selectItems, index, this.selectItems[index + 1]);
      this.$set(this.selectItems, index + 1, item);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      this.selectItems.splice(index, 1);
    },
    // 获取商品列表
    getList: function getList() {
      var _this = this;
      (0, _shop.getGoods)(_objectSpread({
        pageSize: this.pageSize
      }, this.searchForm)).then(function (res) {
        console.log('getList', res.data);
        _this.goodsList = res.data;
        if (_this.goodsList && _this.goodsList.length) {
          _this.goodsList.forEach(function (item) {
            item.goods_id = item.id;
          });
        }
        _this.total = res.total;
      });
    },
    chkSelect: function chkSelect() {
      if (this.selectItems.length) {
        this.selectItems.forEach(function (item) {
          item.id = item.id ? item.id : item.goods_id;
          item.goods_id = item.goods_id ? item.goods_id : item.id;
        });
      }
    },
    submit: function submit() {
      this.$emit("setSelect", this.selectItems);
      this.dialogVisible = false;
    },
    // 点击选中
    handleSelect: function handleSelect(select) {
      console.log('select', select);
      var index = this.selectItems.findIndex(function (item) {
        return item.id == select.id;
      });
      if (index > -1) {
        // 已经选中取消选中
        this.selectItems.splice(index, 1);
      } else {
        // 选中
        if (this.multiple) {
          // 多选
          if (this.maxNum && this.selectItems.length >= this.maxNum) {
            this.$message({
              message: "数量已达到可选上限",
              type: "warning"
            });
          } else {
            this.selectItems.push(select);
          }
        } else {
          // 单选
          this.selectItems = [select];
        }
      }
    },
    // 判断是否选中
    isSelect: function isSelect(id) {
      return this.selectItems.findIndex(function (item) {
        return item.id == id;
      }) > -1;
    },
    // 页码切换
    handleCurrentChange: function handleCurrentChange(val) {
      this.searchForm.page = val;
      this.getList();
    },
    // 搜索商品
    search: function search() {
      if (this.searchForm.title || this.searchForm.cate_id) {
        this.searchForm.page = 1;
        this.getList();
      }
    },
    // 重置
    clearSearch: function clearSearch() {
      this.searchForm.title = '';
      this.searchForm.cate_id = '';
      this.searchForm.page = 1;
      this.getList();
    },
    // 清空数据
    reset: function reset() {
      this.searchForm = {
        title: "",
        cate_id: null,
        page: 1
      };
      this.selectItems = [];
    },
    // 获取二级分类列表
    getSelectList: function getSelectList() {
      var _this2 = this;
      (0, _shop.getCategoryList)().then(function (res) {
        _this2.categoryList = res;
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getList();
        this.chkSelect();
        if (this.showTypeSelect) {
          // this.getSelectList();
        }
      } else {
        // 重置
        this.reset();
      }
    }
  }
};