"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _bannerForm = _interopRequireDefault(require("../components/bannerForm"));
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    bannerForm: _bannerForm.default
  },
  data: function data() {
    return {
      banner: {
        img: '',
        jumpType: '',
        jumpUrl: '',
        goods: {}
      }
    };
  },
  methods: {}
};