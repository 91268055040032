var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "550px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "record" }, [
        _c("div", { staticClass: "form_item" }, [
          _c("label", [_vm._v("单价：")]),
          _c("p", { staticClass: "flex-1" }, [
            _vm._v(
              _vm._s(
                _vm.type == 1
                  ? _vm.infoData.contract
                  : _vm.type == 2
                  ? _vm.infoData.sms_amount
                  : _vm.infoData.risk_credit_price
              ) +
                "元/" +
                _vm._s(_vm.type != 3 ? "条" : "次") +
                "\n        "
            ),
            _c("span", [
              _vm._v(
                "（" +
                  _vm._s(
                    _vm.type == 1
                      ? "根据e签宝合同价格"
                      : _vm.type == 2
                      ? "远低于市场价格"
                      : "根据百融风控价格"
                  ) +
                  "）"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "form_item" }, [
          _c("label", [_vm._v("购买记录：")]),
          _c(
            "div",
            { staticClass: "flex-1 flex flex-wrap" },
            _vm._l(_vm.infoData.setMeal, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "pointer buy_item",
                  class: { active: _vm.selectId == item.id },
                  on: {
                    click: function ($event) {
                      _vm.selectId = item.id
                    },
                  },
                },
                [
                  _c("p", [
                    _vm._v(_vm._s(item.number)),
                    _c("span", { staticClass: "color-999" }, [
                      _vm._v(_vm._s(_vm.type != 3 ? "条" : "次")),
                    ]),
                  ]),
                  _c("p", [_vm._v("￥" + _vm._s(item.amount))]),
                ]
              )
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "form_item" }, [
          _c("label", [_vm._v("账户余额：")]),
          _c("div", { staticClass: "flex-1 flex" }, [
            _c("span", { staticStyle: { "margin-right": "20px" } }, [
              _vm._v(_vm._s(_vm.infoData.shop_balance) + "元"),
            ]),
            _c("p", [
              _vm._v("余额不足？"),
              _c(
                "span",
                {
                  staticClass: "blue-font pointer",
                  on: { click: _vm.goToRecharge },
                },
                [_vm._v("去充值")]
              ),
            ]),
          ]),
        ]),
        _c(
          "p",
          { staticStyle: { "padding-left": "50px", "margin-top": "20px" } },
          [
            _c(
              "el-checkbox",
              {
                class: { check_form: _vm.showError },
                on: {
                  change: function ($event) {
                    _vm.showError = false
                  },
                },
                model: {
                  value: _vm.checked,
                  callback: function ($$v) {
                    _vm.checked = $$v
                  },
                  expression: "checked",
                },
              },
              [
                _vm._v("我已阅读并同意"),
                _c("span", { staticClass: "blue-font" }, [
                  _vm._v("《增值服务营销软件服务使用规范》"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }