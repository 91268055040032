"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    goodsData: {
      type: Object,
      default: null
    },
    showSort: {
      type: Boolean,
      default: true
    },
    showTool: {
      type: Boolean,
      default: true
    },
    showCate: {
      type: Boolean,
      default: true
    }
  }
};