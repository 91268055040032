var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "office_index" }, [
    _c("div", { staticClass: "flex main" }, [
      _c("div", { staticClass: "flex-1 left" }, [
        _c(
          "div",
          { staticClass: "m-auto model_wrap" },
          [
            _c("img", {
              staticClass: "block w-100",
              attrs: { src: require("../../../assets/images/nine-header.jpg") },
            }),
            _vm._l(_vm.sectionList, function (item, index) {
              return [
                _c(
                  "div",
                  {
                    key: index,
                    staticClass: "section_wrap",
                    class: { active: index + 1 == _vm.activeNum },
                    on: {
                      click: function ($event) {
                        _vm.activeNum = index + 1
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "block w-100",
                      attrs: {
                        src: require("../../../assets/images/nine" +
                          item.imgName),
                        alt: item.title,
                      },
                    }),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "right" }, [
        _c("h5", { staticClass: "color-666 normal-font header" }, [
          _vm._v(_vm._s(_vm.sectionList[_vm.activeNum - 1].title)),
        ]),
        _c(
          "div",
          { staticClass: "main_form" },
          [
            _vm.sectionList[_vm.activeNum - 1].key == "first_level"
              ? _c(
                  "div",
                  { staticClass: "warn-box" },
                  [
                    _c("svg-icon", {
                      staticStyle: { "margin-right": "17px" },
                      attrs: { "icon-class": "warn", size: 20 },
                    }),
                    _c("p", [
                      _c("span", [_vm._v("图片要求！")]),
                      _c("br"),
                      _vm._v(
                        "图片宽度" +
                          _vm._s(_vm.imgWidth) +
                          "px，高度" +
                          _vm._s(_vm.imgHeight) +
                          "px。"
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _c("comp-form", {
              ref: "compForm",
              attrs: {
                "goods-num": _vm.sectionList[_vm.activeNum - 1].num,
                "level-name": _vm.sectionList[_vm.activeNum - 1].key,
                "comp-form-data": _vm.compFormData,
                imgWidth: _vm.imgWidth,
                imgHeight: _vm.imgHeight,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "text-center footer" },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "200px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.save },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "fix-footer-btns" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.submit },
          },
          [_vm._v("发布")]
        ),
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "" },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }