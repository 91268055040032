"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _category = require("@/api/category");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      applyList: [],
      notApplyList: [],
      checkAll: {
        parCheck: false,
        text: '全选'
      },
      checkedApply: []
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _category.getCategoryInfo)().then(function (res) {
        _this.applyList = res.applyCategory;
        var notData = res.notApplyCategory;
        notData.forEach(function (item) {
          _this.$set(item, 'parCheck', false);
          _this.$set(item, 'checkList', []);
        });
        _this.notApplyList = notData;
      });
    },
    checkedAll: function checkedAll(val) {
      var ids = [];
      if (val.parCheck) {
        val.children.forEach(function (item2) {
          ids.push(item2.id);
        });
        val.checkList = ids;
      } else {
        val.checkList = [];
      }
    },
    allSelect: function allSelect(value) {
      var checkedCount = value.length;
      this.checkAll.parCheck = checkedCount === this.notApplyList.length;
    },
    checkedItem: function checkedItem(item) {
      item.parCheck = false;
      if (item.checkList.length === item.children.length) {
        item.parCheck = true;
      }
    },
    returnList: function returnList() {
      this.$router.go(-1);
    },
    submitForm: function submitForm() {
      var _this2 = this;
      // console.log('notApplyList',this.notApplyList)
      var newStr = '',
        newList = [];
      this.notApplyList.forEach(function (item) {
        if (item.checkList.length > 0) {
          item.checkList.forEach(function (item1) {
            newList.push(item1);
          });
        }
        newStr = newList.join(',');
      });
      if (!newStr) {
        this.$message.warning('请选择品类');
        return false;
      }
      (0, _category.addCategory)({
        categoryIdStr: newStr
      }).then(function (res) {
        _this2.$router.push({
          name: 'goodsCategory'
        });
        _this2.getList();
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};