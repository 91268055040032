"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _system = require("@/api/system");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {},
  data: function data() {
    return {
      loading: false,
      formData: {
        white_list: "",
        status: "0",
        member_id: "1" //默认为供应商后台
      },
      options: [{
        value: "1",
        label: "供应商后台",
        disabled: ""
      }, {
        value: "2",
        label: "商户后台",
        disabled: ""
      }],
      platform: "1",
      rules: {
        white_list: [{
          required: false,
          message: "请输入IP地址",
          trigger: "blur"
        }, {
          validator: this.validateIPAddress,
          trigger: "change"
        }]
      }
    };
  },
  computed: {
    merchantId: function merchantId() {
      return (0, _auth.getMerchantId)();
    },
    memberId: function memberId() {
      return (0, _auth.getMemberId)();
      //  return '1673869050';//test
    },
    getPlatform: function getPlatform() {
      return this.platform == 1 ? "供应商后台" : "商户后台";
    }
  },
  mounted: function mounted() {
    this.getData();
  },
  methods: {
    validateIPAddress: function validateIPAddress(rule, value, callback) {
      // console.log("value: ", value);
      if (!value) {
        callback();
        return;
      }
      var addresses = value.split(",");
      for (var i = 0; i < addresses.length; i++) {
        var ip = addresses[i].trim();
        if (!/^((\d{1,3}\.){3}\d{1,3})$|^(?:(?:\d{1,3}\.){2})\*|(\d{1,3}\.\*)$|^[\u4E00-\u9FFF]+$/.test(ip) && !/^[\u4E00-\u9FFF]+$/.test(ip)) {
          callback(new Error("IP 地址格式不正确"));
        }
      }
      callback();
    },
    getData: function getData() {
      var _this = this;
      this.loading = true;
      this.formData.member_id = this.memberId;
      var params = {
        member_id: this.formData.member_id
      };
      if (this.merchantId) {
        this.platform = 2;
        params.member_id = this.merchantId;
      }
      console.log("params", params);
      (0, _system.getWhiteIpList)(params).then(function (res) {
        if (res) {
          // console.log("res", res);
          _this.formData = res;
        }
        _this.loading = false;
      });
    },
    submit: function submit() {
      var _this2 = this;
      // console.log("this.formData", this.formData);
      try {
        this.$refs.formData.validate(function (valid) {
          if (valid) {
            // console.log("校验成功");
            _this2.loading = true;
            console.log("this.formData", _this2.formData);
            (0, _system.saveWhiteIpList)(_this2.formData).then(function (res) {
              _this2.getData();
              _this2.loading = false;
            });
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
  }
};