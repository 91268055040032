"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog"));
var _validate = require("@/utils/validate");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    goodsDialog: _goodsDialog.default
  },
  props: ['editItem'],
  data: function data() {
    var _this = this;
    var validateUrl = function validateUrl(rule, value, callback) {
      // if (this.form.jumpType == 1 && !checkUrl(value)) {
      if (_this.form.jumpType == 1 && !value) {
        callback(new Error('文本链接不能为空'));
      } else {
        callback();
      }
    };
    return {
      form: {
        jumpType: 1,
        jumpUrl: "",
        goods: {}
      },
      rules: {
        jumpType: [{
          required: true,
          message: '请选择链接类型',
          trigger: 'change'
        }],
        jumpUrl: [{
          required: true,
          message: '链接不能为空',
          trigger: 'change'
        }, {
          validator: validateUrl,
          trigger: 'change'
        }]
      },
      dialogVisible: false
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;
      if (val) {
        this.$nextTick(function () {
          _this2.$refs['ruleForm'].resetFields();
          if (_this2.editItem) {
            _this2.form = _objectSpread({}, _this2.editItem);
          }
        });
      }
    }
  },
  methods: {
    resetUrl: function resetUrl() {
      this.form.jumpUrl = '';
      this.form.goods = {};
    },
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
    },
    // 设置选中的商品
    setSelect: function setSelect(goodsList) {
      this.form.goods = goodsList[0];
      this.form.jumpUrl = goodsList[0].goods_id;
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          _this3.$emit('setVal', _this3.form);
          _this3.dialogVisible = false;
        } else {
          _this3.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};