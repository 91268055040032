var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "查看风控详情(交互式风控)",
        visible: _vm.dialogVisible,
        size: "50%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticStyle: { width: "100%", height: "400px" },
      }),
      !_vm.loading
        ? _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "类型", prop: "curentPhase", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.getType(scope.row)) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1924262228
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "建议", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.getLevel(scope.row)) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3755661370
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "说明", width: "180" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.getTask(scope.row)) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1827022145
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }