"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog"));
var _shop = require("@/api/shop");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    goodsDialog: _goodsDialog.default,
    cropUpload: _cropUpload.default
  },
  props: ["editId"],
  data: function data() {
    return {
      dialogVisible: false,
      urlName: "",
      imgurl: '',
      form: {
        banner_img: "",
        goods_id: ""
      },
      rules: {
        banner_img: [{
          required: true,
          message: '请上传轮播图',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    setUrl: function setUrl(val) {
      this.form.banner_img = val;
    },
    // 提交
    submit: function submit() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var params = _objectSpread({}, _this.form);
          if (_this.editId) {
            params.id = _this.editId;
          }
          (0, _shop.addOrEdiShopBanner)(params).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit("getList");
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    // 设置选中的商品
    setSelect: function setSelect(goodsList) {
      var obj = goodsList[0];
      this.form.goods_id = obj.id;
      this.urlName = obj.title;
      this.imgurl = obj.imgurl;
    },
    // 打开商品选择对话框
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = this.form.goods_id ? [{
        id: this.form.goods_id,
        title: this.urlName,
        imgurl: this.imgurl
      }] : [];
    },
    reset: function reset() {
      var _this2 = this;
      this.urlName = "";
      this.imgurl = "";
      this.form = {
        banner_img: "",
        goods_id: ""
      };
      this.$nextTick(function () {
        _this2.$refs['form'].resetFields();
      });
    },
    // 获取轮播图数据
    getData: function getData() {
      var _this3 = this;
      (0, _shop.shopBannerById)(this.editId).then(function (res) {
        _this3.form.banner_img = res.banner_img;
        _this3.form.goods_id = res.goods_id;
        _this3.urlName = res.title;
        _this3.imgurl = res.imgurl;
      });
    }
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editId ? "编辑轮播图" : "添加轮播图";
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.reset();
        if (this.editId) {
          this.getData();
        }
      }
    }
  }
};