"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _system = require("@/api/system");
var _xcx = require("@/api/xcx");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  data: function data() {
    return {
      appid: '',
      pagePath: '',
      pageType: '办公设备',
      page_title: '',
      page_id: 16,
      formData: {
        level: 'first_level',
        main_title: '',
        background_image: '',
        background_color: '#FFFFFF',
        param_plus: []
      },
      param: {
        key: '',
        title: '',
        appId: '',
        path: 'pages/index/index',
        delay: 0,
        query: '',
        background_image: ''
      },
      imgWidth: 750,
      imgHeight: 1500,
      photoWidth: 100
    };
  },
  computed: {
    photoHeight: function photoHeight() {
      var height = this.photoWidth * this.imgHeight / this.imgWidth;
      return height;
    },
    alipayUrl: function alipayUrl() {
      var url = "alipays://platformapi/startapp?appId=2021004128647982&page=/nineClassify/pages/jump4/jump4?key=".concat(this.param.key, "&query=channel=").concat(this.param.key);
      return url;
    }
  },
  mounted: function mounted() {
    this.pageType = this.$route.meta.title;
    this.page_title = this.$route.meta.title;
    this.getAlipayAppid();
    this.getPagePath();
    this.getPageId();
    this.getData();
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.pageType = to.$route.meta.title;
    this.getData();
  },
  methods: {
    getAlipayAppid: function getAlipayAppid() {
      var _this = this;
      (0, _system.getAlipayAppid)().then(function (res) {
        if (res) {
          _this.appid = res;
        }
      });
    },
    getPagePath: function getPagePath() {
      this.pagePath = this.$route.path.split('/')[2];
    },
    getData: function getData() {
      var _this2 = this;
      (0, _xcx.getActivityPageById)(this.page_id).then(function (res) {
        var data = {};
        if (res.details) {
          var details = JSON.parse(res.details);
          data = details.first_level;
          _this2.formData.main_title = data.main_title;
          _this2.formData.background_color = data.background_color;
          _this2.formData.background_image = data.background_image;
        }
        if (data && data.param_plus) {
          _this2.formData.param_plus = data.param_plus;
        } else {
          _this2.formData.param_plus.push(_this2.$func.deepClone(_this2.param));
        }
      });
    },
    getPageId: function getPageId() {
      // console.log('this.$route.name', this.$route)
      switch (this.$route.name) {
        case 'activityJump1':
          this.page_id = 16;
          break;
        case 'activityJump2':
          this.page_id = 17;
          break;
        case 'activityJump3':
          this.page_id = 18;
          break;
        case 'activityJump4':
          this.page_id = 19;
          break;
        case 'activityJump5':
          this.page_id = 20;
          break;
        default:
          this.page_id = this.$route.name;
          break;
      }
    },
    getAlipayUrl: function getAlipayUrl(item) {
      var encode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var path = "/nineClassify/pages/".concat(this.pagePath, "/").concat(this.pagePath, "?key=").concat(item.key, "&query=channel=").concat(item.key);
      if (encode) {
        path = "alipays://platformapi/startapp?appId=".concat(this.appid, "&page=") + encodeURIComponent(path);
      } else {
        path = "alipays://platformapi/startapp?appId=".concat(this.appid, "&page=") + path;
      }
      return path;
    },
    setImage: function setImage(item, index, val) {
      item.background_image = val;
      if (index == 0) {
        this.formData.background_image = val;
      }
    },
    addParam: function addParam() {
      var param = this.$func.deepClone(this.param);
      this.formData.param_plus.push(param);
    },
    delParam: function delParam(index) {
      this.formData.param_plus.splice(index, 1);
    },
    // 保存
    save: function save() {
      var _this3 = this;
      var filter = this.formData.param_plus.filter(function (item) {
        return item.key == '';
      });
      if (filter.length) {
        this.$message.error('key不能为空');
        return;
      }
      (0, _xcx.editActivityPage)(_objectSpread({
        type: this.page_id,
        page_title: this.page_title
      }, this.formData)).then(function (res) {
        _this3.submit();
      });
    },
    // 发布
    submit: function submit() {
      var _this4 = this;
      (0, _xcx.release)(this.page_id).then(function (res) {
        _this4.getData();
      });
    },
    copySuccess: function copySuccess(e, item) {
      // console.log('copySuccess', e, item)
      if (item.key) {
        this.$message.success('已成功复制到粘贴板');
      } else {
        this.$message.error('统计标识key');
      }
    }
  }
};