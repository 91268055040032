var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.merchantId === 0
    ? _c(
        "el-form-item",
        { attrs: { label: "商户" } },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "全部", clearable: "" },
              on: { change: _vm.handleMerchantChange },
              model: {
                value: _vm.selectedMerchant,
                callback: function ($$v) {
                  _vm.selectedMerchant = $$v
                },
                expression: "selectedMerchant",
              },
            },
            _vm._l(_vm.merchantList, function (merchant) {
              return _c("el-option", {
                key: merchant.id,
                attrs: { label: merchant.username, value: merchant.id },
              })
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }