var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "查询公正信息",
        visible: _vm.dialogVisible,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "info-box",
        },
        [
          _c(
            "div",
            { staticClass: "order-info" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "130px", model: _vm.cert_info } },
                [
                  _c("el-form-item", { attrs: { label: "公证申请单号：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.cert_info.apply_No))]),
                  ]),
                  _c("el-form-item", { attrs: { label: "公证申请状态：" } }, [
                    _c("span", [_vm._v(_vm._s(_vm.cert_info.apply_Status))]),
                  ]),
                  _vm.cert_info.contract_Url
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "合同预览模板：" } },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.cert_info.contract_Url,
                                icon: "el-icon-view",
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.cert_info.notarization_Url
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "公正书链接：" } },
                        [
                          _c(
                            "el-link",
                            {
                              attrs: {
                                target: "_blank",
                                href: _vm.cert_info.notarization_Url,
                                icon: "el-icon-view",
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.cert_info.certification_Date
                    ? _c("el-form-item", { attrs: { label: "公正日期：" } }, [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.cert_info.certification_Date)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.cert_info.files_Signed
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "公正合同详情：" } },
                        _vm._l(
                          _vm.cert_info.files_Signed,
                          function (item, index) {
                            return _c(
                              "el-button",
                              {
                                key: index,
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  plain: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showDetail(item.file_Url)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.file_Name))]
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(" 关 闭 ")]
          ),
          _vm.form.cert_status == 1
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmOrderEquity },
                },
                [_vm._v("机构审核")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }