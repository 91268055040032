var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle,
        visible: _vm.dialogVisible,
        width: "550px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formDataRef",
          attrs: {
            "label-position": "left",
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: { placeholder: "请输入名称", clearable: "" },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "进货价格", prop: "purchase_price" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "240px" },
                attrs: { min: 0, placeholder: "请输入进货价格", clearable: "" },
                model: {
                  value: _vm.formData.purchase_price,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "purchase_price", $$v)
                  },
                  expression: "formData.purchase_price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否上牌", prop: "is_registered" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "全部", clearable: "" },
                  model: {
                    value: _vm.formData.is_registered,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "is_registered", $$v)
                    },
                    expression: "formData.is_registered",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "已上牌", value: 1 } }),
                  _c("el-option", { attrs: { label: "未上牌", value: 2 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车架号", prop: "chassis_number" } },
            [
              _c("el-input", {
                staticStyle: { width: "240px" },
                attrs: {
                  autosize: { minRows: 3 },
                  type: _vm.isTextarea,
                  placeholder: "请输入车架号,回车换行可批量输入",
                  clearable: "",
                },
                model: {
                  value: _vm.formData.chassis_number,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "chassis_number", $$v)
                  },
                  expression: "formData.chassis_number",
                },
              }),
            ],
            1
          ),
          this.formData.is_registered == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "车牌", prop: "license_plate" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入车牌", clearable: "" },
                    model: {
                      value: _vm.formData.license_plate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "license_plate", $$v)
                      },
                      expression: "formData.license_plate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 3, placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "remark", $$v)
                  },
                  expression: "formData.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.btn_disabled, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }