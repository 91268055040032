var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page" }, [
    _c(
      "div",
      { staticClass: "warn-box" },
      [
        _c("svg-icon", {
          staticStyle: { "margin-right": "17px" },
          attrs: { "icon-class": "warn", size: 20 },
        }),
        _vm._m(0),
      ],
      1
    ),
    _c("div", { staticClass: "mt15" }, [
      _c("h3", { staticClass: "h3" }, [_vm._v("已申请品类")]),
      _c("div", { staticClass: "beenOpenList" }, [
        _c(
          "ul",
          _vm._l(_vm.applyList, function (item, index) {
            return _c("li", { key: index, staticClass: "cate_li" }, [
              _vm._v("\n          " + _vm._s(item.name) + "\n        "),
            ])
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "mt15" },
      [
        _c("h3", { staticClass: "h3" }, [_vm._v("未申请品类")]),
        _vm._l(_vm.notApplyList, function (item) {
          return _c(
            "div",
            { key: item.id, staticClass: "noneOpenList" },
            [
              _c(
                "div",
                { staticClass: "checkTitle" },
                [
                  _c("span", { staticClass: "noneCate" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  item.children.length > 0
                    ? _c(
                        "el-checkbox",
                        {
                          attrs: { border: "", size: "mini" },
                          on: {
                            change: function ($event) {
                              return _vm.checkedAll(item)
                            },
                          },
                          model: {
                            value: item.parCheck,
                            callback: function ($$v) {
                              _vm.$set(item, "parCheck", $$v)
                            },
                            expression: "item.parCheck",
                          },
                        },
                        [_vm._v("选中全部")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-checkbox-group",
                {
                  staticClass: "checkboxGroup",
                  attrs: { size: "mini" },
                  on: { change: _vm.allSelect },
                  model: {
                    value: item.checkList,
                    callback: function ($$v) {
                      _vm.$set(item, "checkList", $$v)
                    },
                    expression: "item.checkList",
                  },
                },
                _vm._l(item.children, function (notItem) {
                  return _c(
                    "el-checkbox",
                    {
                      key: notItem.id,
                      staticClass: "itemcheckbox",
                      attrs: { border: "", label: notItem.id },
                      on: {
                        change: function ($event) {
                          return _vm.checkedItem(item)
                        },
                      },
                    },
                    [_vm._v(_vm._s(notItem.name))]
                  )
                }),
                1
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "bottomBtn" },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.returnList()
              },
            },
          },
          [_vm._v("返回")]
        ),
        _c(
          "el-button",
          {
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.submitForm()
              },
            },
          },
          [_vm._v("点击申请")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n      找不到想要的商品品类? 可点击联系"),
      _c("a", { staticStyle: { color: "#2892ff" }, attrs: { href: "#" } }, [
        _vm._v("官方客服"),
      ]),
      _vm._v("添加品类！\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }