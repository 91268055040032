"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _customPage = _interopRequireDefault(require("@/components/customPage"));
var _editService = _interopRequireDefault(require("./components/editService"));
var _goods = require("@/api/goods");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default,
    editService: _editService.default
  },
  data: function data() {
    return {
      loading: false,
      searchForm: {},
      total: 0,
      tableData: [],
      currItem: {}
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      (0, _goods.getServiceList)(_objectSpread({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        // console.log('getServiceList', res)
        _this.tableData = res.data;
        _this.total = res.total;
        _this.loading = false;
      });
    },
    edit: function edit() {
      var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currItem = item;
      this.$refs.editService.open();
    },
    // editSort(item, e) {
    // 	let params = {
    // 		setmeal_id: item.id,
    // 		sort: e,
    // 	}
    // 	this.editGoodsSetmealStatus(params)
    // },
    // editStatus(item) {
    // 	let status = item.meal_status == 0 ? 1 : 0
    // 	let params = {
    // 		setmeal_id: item.id,
    // 		meal_status: status,
    // 	}
    // 	this.editGoodsSetmealStatus(params)
    // },
    // editGoodsSetmealStatus(params) {
    // 	editGoodsSetmealStatus(params).then(res => {
    // 		this.getList()
    // 	}).catch()
    // },
    // 删除
    handleDelete: function handleDelete(id) {
      var _this2 = this;
      this.$confirm('您确定要删除该套餐信息吗？', '删除套餐信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _goods.delService)(id).then(function (res) {
          _this2.getList();
        });
      });
    }
  }
};