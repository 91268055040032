"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _goods = require("@/api/goods");
var _textEditor = _interopRequireDefault(require("@/components/textEditor"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    textEditor: _textEditor.default
  },
  props: {
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var checkNum = function checkNum(rule, value, callback) {
      var reg = /^[0-9.]*$/;
      if (!value && value !== 0) {
        callback(new Error("请输入数字"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正数"));
      } else {
        callback();
      }
    };
    return {
      id: 0,
      formData: {},
      formDefault: {
        id: 0,
        sort: 0,
        status: 1
      },
      dialogVisible: false,
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 10,
          message: '长度在 1 到 10 个字符',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入数字',
          trigger: 'blur'
        }, {
          validator: checkNum,
          trigger: 'blur'
        }],
        settle_percen: [{
          required: true,
          message: '请输入数字',
          trigger: 'blur'
        }, {
          validator: checkNum,
          trigger: 'blur'
        }],
        goods_ids: [{
          required: true,
          message: '请输入商品id',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return '增值服务 - ' + (this.id ? '编辑' : '添加');
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        if (this.item.id) {
          this.formData = this.$func.deepClone(this.item);
          this.formData.settle_percen *= 100;
        } else {
          this.formData = this.$func.deepClone(this.formDefault);
        }
      } else {
        this.$refs.form.clearValidate();
      }
    }
  },
  methods: {
    open: function open() {
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    chkPrice: function chkPrice(key) {
      this.formData[key] = this.$func.filterFloat(this.formData[key]);
    },
    onEditorChange: function onEditorChange(val) {
      this.formData.content = val;
    },
    submitForm: function submitForm() {
      var _this = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _this.$func.deepClone(_this.formData);
          param.settle_percen /= 100;
          (0, _goods.editServiceInfo)(_objectSpread({}, param)).then(function (res) {
            // console.log('submitForm', res)
            _this.$emit('getList');
            _this.close();
          });
        } else {
          return false;
        }
      });
    }
  }
};