"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  // components: { addDialog },
  props: ['id'],
  data: function data() {
    return {
      loading: true,
      supplierInfo: [],
      item: {
        name: 'name',
        value: 'value'
      },
      formData: [],
      editItem: null
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _user.getSupplierConfig)(this.id).then(function (res) {
        console.log('getSupplierConfig', res);
        _this.supplierInfo = res.info;
        _this.formData = res.config;
        _this.loading = false;
      });
    },
    switchLevel: function switchLevel(item) {
      item.show = Math.abs(item.show - 1);
    },
    submit: function submit() {
      var _this2 = this;
      this.loading = true;
      (0, _user.editSupplierConfig)({
        id: this.id,
        configData: JSON.stringify(this.formData)
      }).then(function (res) {
        _this2.getList();
      }).catch(function () {});
    }
  }
};