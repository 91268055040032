"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _audit = require("@/api/audit");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        activity: ""
      },
      rules: {
        activity: [{
          required: true,
          message: '请选择商品活动',
          trigger: 'change'
        }]
      },
      dialogVisible: false,
      goodsId: null,
      activityList: []
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['ruleForm'].validate(function (valid) {
        if (valid) {
          var params = {
            goods_id: _this.goodsId,
            activity_type: _this.form.activity.join(',')
          };
          (0, _audit.addActivity)(params).then(function (res) {
            _this.dialogVisible = false;
            _this.$emit('getList', true);
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;
      if (val) {
        (0, _audit.getActivityList)(this.goodsId).then(function (res) {
          _this2.activityList = [];
          for (var key in res.activity_list) {
            _this2.activityList.push({
              id: key,
              label: res.activity_list[key]
            });
          }
          ;
          if (res.activity_type) {
            _this2.form.activity = res.activity_type.split(',');
          }
        });
      }
    }
  }
};