var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "请填写分拥信息",
        visible: _vm.dialogVisible,
        width: "550px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分拥状态", prop: "parcel_status" } },
            [
              _c(
                "el-select",
                {
                  on: { change: _vm.orderStatusChange },
                  model: {
                    value: _vm.formData.parcel_status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "parcel_status", $$v)
                    },
                    expression: "formData.parcel_status",
                  },
                },
                _vm._l(_vm.optionsOrderStatList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.formData.parcel_status == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "分拥金额", prop: "parcel_amount" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      min: 0,
                      placeholder: "请输入分拥金额",
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.parcel_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "parcel_amount", $$v)
                      },
                      expression: "formData.parcel_amount",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.btn_disabled, type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", plain: "" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }