var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "add_dialog",
          attrs: {
            width: "600px",
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.editItem ? _vm.rules1 : _vm.rules,
                model: _vm.form,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号:", prop: "mobile" } },
                [
                  !_vm.editItem
                    ? [
                        _c("el-input", {
                          attrs: {
                            maxlength: "11",
                            placeholder: "请输入手机号",
                          },
                          on: { input: _vm.handleMobileChange },
                          model: {
                            value: _vm.form.mobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mobile", $$v)
                            },
                            expression: "form.mobile",
                          },
                        }),
                      ]
                    : _c("span", [_vm._v(_vm._s(_vm.form.mobile))]),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名/备注:", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", placeholder: "请输入用户名" },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码:", prop: "pwd" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: _vm.editItem
                        ? "(选填)留空则不修改"
                        : "请输入密码",
                      autocomplete: "new-password",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.form.pwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pwd", $$v)
                      },
                      expression: "form.pwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "重复密码:", prop: "confirmPwd" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: _vm.editItem
                        ? "(选填)留空则不修改"
                        : "请再次输入密码",
                      autocomplete: "new-password",
                      "show-password": "",
                    },
                    model: {
                      value: _vm.form.confirmPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "confirmPwd", $$v)
                      },
                      expression: "form.confirmPwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "角色:", prop: "role_id" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择角色" },
                      model: {
                        value: _vm.form.role_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "role_id", $$v)
                        },
                        expression: "form.role_id",
                      },
                    },
                    _vm._l(_vm.roleList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.title, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.submit },
                },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }