"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _customPage = _interopRequireDefault(require("@/components/customPage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        mobile: "",
        status: null
      },
      total: 0,
      tableData: []
    };
  },
  filters: {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;
      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
        page = _this$$refs$customPag.page,
        pageSize = _this$$refs$customPag.pageSize;
      if (!flag) {
        this.tableData = [];
      }
      (0, _order.getApplyCancelList)({
        page: page,
        pageSize: pageSize,
        status: this.searchForm.status,
        apply_type: 1
      }).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total;
        // console.log(this.tableData.length)
      });
    },
    // 发起扣款
    // withholdItem(row){
    //   console.log('row2',row)
    //   this.$confirm("您确定要发起扣款吗？", "发起扣款", {
    // 	confirmButtonText: '确定',
    // 	cancelButtonText: '取消'
    //   }).then(() => {
    // 	manualWithholding({ 
    // 	  orderId: row.order_id, 
    // 	  leaseId: row.id 
    // 	}).then(res => {
    // 	  this.getLease()
    // 	  setTimeout(() => {
    // 		this.$parent.getList(true);
    // 	  }, 1000);
    // 	})
    //   }).catch(() => { });
    // },
    // 通过审核
    passAudit: function passAudit(row) {
      var _this2 = this;
      console.log(row);
      this.$confirm('通过后订单为取消状态，涉及订单的金额和物品不做处理，需要客服独立处理，请确认?', '通过审核', {
        confirmButtonText: '通过',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.cancelOrderSave)({
          id: row.id,
          type: 1,
          //通过
          apply_type: 1 //通过申请完结
        }).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    // 拒绝审核
    rejectAudit: function rejectAudit(row) {
      var _this3 = this;
      this.$confirm('您确定要拒绝审核吗?', '拒绝审核', {
        confirmButtonText: '拒绝',
        cancelButtonText: '取消',
        confirmButtonClass: 'el-button--danger'
      }).then(function () {
        (0, _order.cancelOrderSave)({
          id: row.id,
          type: 2,
          //拒绝
          apply_type: 1
        }).then(function (res) {
          _this3.getList(true);
        });
      }).catch(function () {});
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      this.searchForm = {
        mobile: "",
        status: null
      };
      this.getList();
    },
    jumpOrderList: function jumpOrderList(trade_no) {
      this.$router.push({
        path: '/order/list?orderId=' + trade_no
      });
    }
  }
};