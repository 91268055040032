"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
var _merchant = require("@/api/merchant");
var _validate = require("@/utils/validate");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["editItem", "roleList"],
  data: function data() {
    var _this = this;
    var validateMobile = function validateMobile(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var validateConfirm = function validateConfirm(rule, value, callback) {
      if (value !== _this.form.pwd) {
        callback(new Error('两次输入密码不同！'));
      } else {
        callback();
      }
    };
    var validatePwd = function validatePwd(rule, value, callback) {
      var reg = /^[^\u4e00-\u9fa5]{0,}$/;
      if (value && value.length < 6) {
        callback(new Error('密码必须大于等于6个字符'));
      } else if (!reg.test(value)) {
        callback(new Error('密码不能包含汉字！'));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        id: 0,
        username: "",
        pwd: "",
        confirmPwd: "",
        group_id: "",
        mobile: "",
        status: 1
      },
      statusList: ['禁用', '启用'],
      rules: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '密码不能为空',
          trigger: 'blur'
        }, {
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          required: true,
          message: '重复密码不能为空',
          trigger: 'blur'
        }, {
          validator: validateConfirm,
          trigger: 'blur'
        }],
        group_id: [{
          required: true,
          message: '请选择成员分组',
          trigger: 'change'
        }]
      },
      rules1: {
        mobile: [{
          required: true,
          message: '手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        pwd: [{
          validator: validatePwd,
          trigger: 'blur'
        }],
        confirmPwd: [{
          validator: validateConfirm,
          trigger: 'blur'
        }],
        group_id: [{
          required: true,
          message: '请选择成员分组',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editItem ? "编辑账号" : "添加账号";
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;
      if (val) {
        this.$nextTick(function () {
          _this2.$refs['form'].resetFields();
          console.log('this.editItem', _this2.editItem);
          if (_this2.editItem) {
            console.log('this.editItem', _this2.editItem);
            // 编辑
            _this2.form = {
              id: _this2.editItem.id,
              username: _this2.editItem.username,
              mobile: _this2.editItem.mobile,
              group_id: _this2.editItem.group_id,
              status: _this2.editItem.status
            };
          }
        });
      } else {
        this.form = this.$options.data().form;
      }
    }
  },
  methods: {
    handleMobileChange: function handleMobileChange() {
      this.form.mobile = this.form.mobile.replace(/[^\d]/g, "");
    },
    submit: function submit() {
      var _this3 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this3.form.pwd = _this3.form.pwd ? _this3.$md5(_this3.form.pwd) : "";
          (0, _merchant.editMerchantMember)(_objectSpread({}, _this3.form)).then(function (res) {
            _this3.dialogVisible = false;
            _this3.$emit("getList", true);
          });
        } else {
          _this3.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    }
  }
};