"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _system = require("@/api/system");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    editItem: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      formData: {
        id: 0,
        is_default: 0,
        sort: 0,
        status: 1,
        tips: '',
        title: '',
        icon: '',
        key: ''
      },
      rules: {
        key: [{
          required: true,
          message: '请输入key',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '请输入支付名称',
          trigger: 'blur'
        }],
        tips: [{
          required: true,
          message: '请输入支付说明',
          trigger: 'blur'
        }]
        // icon: [
        //     { required: true, message: '请输上传图标', trigger: 'blur' },
        // ],
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return 'App版本管理 - ' + (this.formData.id == 0 ? '新增' : '编辑');
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this = this;
      if (val) {
        this.$nextTick(function () {
          if (_this.editItem) {
            _this.formData = Object.assign(_this.formData, _this.editItem);
            console.log('this.editItem', _objectSpread({}, _this.editItem));
          }
        });
      } else {
        this.formData = this.$options.data().formData;
        this.$refs.formData.clearValidate();
      }
    }
  },
  methods: {
    open: function open() {
      this.dialogVisible = true;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    changeDefault: function changeDefault(val) {
      this.formData.is_default = val;
    },
    changeStatus: function changeStatus(val) {
      this.formData.status = val;
    },
    submitForm: function submitForm() {
      console.log('submitForm');
      // this.$refs['formData'].validate((valid) => {
      //     if (valid) {
      //         editPayMent({
      //             form_data: this.formData
      //         }).then(res => {
      //             this.$emit('getList', true)
      //             this.close()
      //         })
      //     } else {
      //         return false;
      //     }
      // });
    }
  }
};