"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _linkDialog = _interopRequireDefault(require("./linkDialog"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default,
    linkDialog: _linkDialog.default
  },
  props: {
    itemIndex: {
      type: Number
    },
    showTool: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: {}
    },
    imgLimit: {
      type: Object,
      default: {}
    },
    type: {
      type: String
    }
  },
  computed: {
    linkVal: function linkVal() {
      return this.formData.jumpType == 1 ? this.formData.jumpUrl : this.formData.goods.title;
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    deleteLink: function deleteLink() {
      this.formData.jumpUrl = '';
      this.formData.goods = {};
    },
    setVal: function setVal(_ref) {
      var jumpType = _ref.jumpType,
        jumpUrl = _ref.jumpUrl,
        goods = _ref.goods;
      this.formData.jumpType = jumpType;
      this.formData.jumpUrl = jumpUrl;
      this.formData.goods = jumpType == 2 ? goods : {};
    },
    addLink: function addLink() {
      this.$refs.linkDialog.dialogVisible = true;
    }
  }
};