var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "editor_container" },
    [
      _c("quill-editor", {
        ref: "myTextEditor",
        staticStyle: { height: "200px" },
        attrs: { content: _vm.content, options: _vm.editorOption },
        on: {
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
        },
      }),
      _c("span", { staticClass: "text_length" }, [
        _vm._v(_vm._s(_vm.textLength) + "字"),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "ql-editor",
          domProps: { innerHTML: _vm._s(_vm.str) },
        },
        [_vm._v("\n    " + _vm._s(_vm.str) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }