"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find-index");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.string.sub");
require("core-js/modules/es6.function.name");
var _index = require("@/api/index");
var _validate = require("@/utils/validate");
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
var _index2 = require("@/utils/index");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  data: function data() {
    var validateName = function validateName(rule, value, callback) {
      if (!(0, _validate.checkName)(value)) {
        callback(new Error('姓名格式错误'));
      } else {
        callback();
      }
    };
    var validateMobile = function validateMobile(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var validateCompanyId = function validateCompanyId(rule, value, callback) {
      if (!(0, _validate.checkCompanyId)(value)) {
        callback(new Error('工商注册号格式错误'));
      } else {
        callback();
      }
    };
    var validateIdCard = function validateIdCard(rule, value, callback) {
      if (!(0, _validate.checkCardId)(value)) {
        callback(new Error('身份证号格式错误'));
      } else {
        callback();
      }
    };
    return {
      activeStep: 1,
      shopForm: {
        shopname: '',
        photo: '',
        shoptype: '',
        people: '',
        contact_tel: '',
        faptype: [1],
        mechanism: '',
        companyid: '',
        company_area: '',
        company: '',
        shopcity: '',
        shopcard: '',
        doorphoto: '',
        seal_url: '',
        faname: '',
        idcard: '',
        faidcard: '',
        faidcard_back: ''
      },
      rules: {
        shopname: [{
          required: true,
          message: '店铺名称不能为空',
          trigger: 'blur'
        }],
        photo: [{
          required: true,
          message: '店铺头像不能为空',
          trigger: 'change'
        }],
        shoptype: [{
          required: true,
          message: '请选择经营项目',
          trigger: 'change'
        }],
        people: [{
          required: true,
          message: '店铺联系人不能为空',
          trigger: 'blur'
        }, {
          validator: validateName,
          trigger: 'blur'
        }],
        contact_tel: [{
          required: true,
          message: '联系人手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        faptype: [{
          required: true,
          message: '请选择公司发票类型',
          trigger: 'change'
        }],
        mechanism: [{
          required: true,
          message: '公司/机构名称不能为空',
          trigger: 'blur'
        }],
        companyid: [{
          required: true,
          message: '工商注册号不能为空',
          trigger: 'blur'
        }, {
          validator: validateCompanyId,
          trigger: 'blur'
        }],
        company_area: [{
          required: true,
          message: '请选择公司所在地区',
          trigger: 'change'
        }],
        company: [{
          required: true,
          message: '公司详细地址不能为空',
          trigger: 'blur'
        }],
        shopcity: [{
          required: true,
          message: '请选择当前经营位置',
          trigger: 'change'
        }],
        shopcard: [{
          required: true,
          message: '请上传营业执照图片',
          trigger: 'change'
        }],
        doorphoto: [{
          required: true,
          message: '请上传公司门头照',
          trigger: 'change'
        }],
        seal_url: [{
          required: true,
          message: '请上传公章图片',
          trigger: 'change'
        }],
        faname: [{
          required: true,
          message: '公司法人姓名不能为空',
          trigger: 'blur'
        }, {
          validator: validateName,
          trigger: 'blur'
        }],
        idcard: [{
          required: true,
          message: '公司法人身份证号不能为空',
          trigger: 'blur'
        }, {
          validator: validateIdCard,
          trigger: 'blur'
        }],
        faidcard: [{
          required: true,
          message: '请上传身份证正面',
          trigger: 'change'
        }],
        faidcard_back: [{
          required: true,
          message: '请上传身份证背面',
          trigger: 'change'
        }]
      },
      typeList: [],
      agreeChecked: false,
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      areaProps: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      regionList: [],
      areaList: [],
      currentDate: '',
      relationId: ''
    };
  },
  methods: {
    getAreaStr: function getAreaStr(arr) {
      var str = '';
      var _iterator = _createForOfIteratorHelper(this.areaList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var p = _step.value;
          if (p.id == arr[0]) {
            str += p.name;
            if (p.sub) {
              var _iterator2 = _createForOfIteratorHelper(p.sub),
                _step2;
              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var c = _step2.value;
                  if (c.id == arr[1]) {
                    str += '-' + c.name;
                  }
                  if (c.sub) {
                    var _iterator3 = _createForOfIteratorHelper(c.sub),
                      _step3;
                    try {
                      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                        var ct = _step3.value;
                        if (ct.id == arr[2]) {
                          str += '-' + ct.name;
                        }
                      }
                    } catch (err) {
                      _iterator3.e(err);
                    } finally {
                      _iterator3.f();
                    }
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return str;
    },
    submit: function submit() {
      var _this = this;
      if (!this.agreeChecked) {
        this.$message({
          message: "请勾选同意协议！",
          type: "warning"
        });
        return;
      }
      this.$refs['shopForm'].validate(function (valid) {
        if (valid) {
          var params = _objectSpread({}, _this.shopForm);
          params.company_area = _this.getAreaStr(_this.shopForm.company_area);
          params.area_id = _this.shopForm.company_area[1];
          params.shopcity = params.shopcity.join('-');
          params.faptype = params.faptype.join(',');
          params.relationId = _this.relationId || '';
          (0, _index.addSupplier)(params).then(function (res) {
            _this.currentDate = (0, _index2.formatDate)(new Date(), 'yyyy-MM-dd hh:mm');
            _this.activeStep = 3;
          });
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });
          return false;
        }
      });
    },
    fpTypeChange: function fpTypeChange(val) {
      var index = this.shopForm.faptype.findIndex(function (item) {
        return val == item;
      });
      if (index > -1) {
        this.shopForm.faptype.splice(index, 1);
      } else {
        this.shopForm.faptype.push(val);
      }
    },
    setUrl: function setUrl(key, value) {
      this.shopForm[key] = value;
    },
    getSelect: function getSelect() {
      var _this2 = this;
      (0, _index.getParentCategory)().then(function (res) {
        _this2.typeList = res || [];
      });
      (0, _index.getRegion)().then(function (res) {
        _this2.regionList = res ? res[0].sub : [];
        _this2.areaList = res ? (0, _toConsumableArray2.default)(res[0].sub) : [];
        var index = _this2.areaList.findIndex(function (k) {
          return k.id == 7459;
        });
        _this2.areaList.splice(index, 1);
      });
    }
  },
  mounted: function mounted() {
    if (this.$route.query && this.$route.query.relationId) {
      this.relationId = this.$route.query.relationId;
    }
    this.getSelect();
  }
};