"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _xcx = require("@/api/xcx");
var _compForm = _interopRequireDefault(require("./components/compForm"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    compForm: _compForm.default
  },
  data: function data() {
    return {
      activeNum: 1,
      pageId: '',
      configData: (0, _defineProperty2.default)((0, _defineProperty2.default)({
        first_level: {
          goods: [],
          main_title: '',
          is_nineGrid: false
        },
        second_level: {
          goods: [],
          main_title: ''
        },
        third_level: {
          goods: [],
          main_title: ''
        }
      }, "third_level", {
        goods: [],
        main_title: ''
      }), "fourth_level", {
        main_title: '',
        exam_list: [],
        is_editShopList: false
      }),
      pageTitle: '办公设备',
      pageType: 0,
      compFormData: {},
      imgWidth: 750,
      imgHeight: 810,
      shopId: ''
    };
  },
  computed: {
    sectionList: function sectionList() {
      var sectionList = [];
      switch (this.pageTitle) {
        case '办公设备':
          sectionList = [{
            title: '商品（1+3）推荐位',
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '商户配置':
          sectionList = [{
            title: '顶部轮播图商品推荐位',
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 3
          }, {
            title: '商品（4）推荐位',
            key: 'third_level',
            imgName: '9.jpg',
            num: 4
          }, {
            title: '商品（6）推荐位',
            key: 'fourth_level',
            imgName: '8.jpg',
            num: 6
          }];
          break;
        case '店铺列表':
          sectionList = [{
            title: '顶部标题背景',
            key: 'first_level',
            imgName: '4.jpg'
          }, {
            title: '店铺列表',
            key: 'second_level',
            imgName: '8.jpg'
          }];
          break;
        case '电脑平板':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '5.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '7.jpg',
            num: 6
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '健康理疗':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '品牌手机':
          sectionList = [{
            title: '商品（1+4）推荐位',
            key: 'first_level',
            imgName: '6.jpg',
            num: 5
          }, {
            title: '商品（1+4）推荐位',
            key: 'second_level',
            imgName: '6.jpg',
            num: 5
          }];
          break;
        case '能源设备':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '开店赋能':
          sectionList = [{
            title: '商品（1+3）推荐位',
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '数码摄影':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '5.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '7.jpg',
            num: 6
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '游戏设备':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '智能家居':
          sectionList = [{
            title: '商品（1+3）推荐位',
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '无人机相机':
        case '二手精选':
          sectionList = [{
            title: '商品（2）推荐位',
            key: 'first_level',
            imgName: '10.jpg',
            num: 2
          }, {
            title: '商品（9）推荐位',
            key: 'second_level',
            imgName: '3.jpg',
            num: 9
          }, {
            title: '商品（2）推荐位',
            key: 'third_level',
            imgName: '10.jpg',
            num: 2
          }, {
            title: '商品（9）推荐位',
            key: 'fourth_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '花呗专区':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '热租爆品':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '短租精选':
          sectionList = [{
            title: '商品（3）推荐位',
            key: 'first_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '租完即送':
          sectionList = [{
            title: '商品（1+3）推荐位',
            key: 'first_level',
            imgName: '1.jpg',
            num: 4
          }, {
            title: '商品（3+4）推荐位',
            key: 'second_level',
            imgName: '2.jpg',
            num: 7
          }, {
            title: '商品（9）推荐位',
            key: 'third_level',
            imgName: '3.jpg',
            num: 9
          }];
          break;
        case '一元租机':
          sectionList = [{
            title: '商品（4）推荐位',
            key: 'first_level',
            imgName: '9.jpg',
            num: 4
          }, {
            title: '商品（3）推荐位',
            key: 'second_level',
            imgName: '4.jpg',
            num: 3
          }, {
            title: '商品（6）推荐位',
            key: 'third_level',
            imgName: '8.jpg',
            num: 6
          }, {
            title: '商品（6）推荐位',
            key: 'fourth_level',
            imgName: '8.jpg',
            num: 6
          }];
          break;
      }
      return sectionList;
    }
  },
  watch: {
    activeNum: function activeNum(val) {
      if (val) {
        this.setData(val);
      }
    }
  },
  mounted: function mounted() {
    this.pageTitle = this.$route.meta.title;
    this.shopId = this.$route.params.id;
    switch (this.pageTitle) {
      case '办公设备':
        this.pageType = 1;
        break;
      case '商户配置':
        this.pageType = 'shop' + this.shopId;
        this.imgHeight = 340;
        this.imgWidth = 750;
        break;
      case '店铺列表':
        this.pageType = 'shopList';
        this.imgHeight = 163;
        this.imgWidth = 163;
        break;
      case '电脑平板':
        this.pageType = 2;
        break;
      case '健康理疗':
        this.pageType = 3;
        break;
      case '品牌手机':
        this.pageType = 4;
        break;
      case '能源设备':
        this.pageType = 5;
        break;
      case '开店赋能':
        this.pageType = 6;
        break;
      case '数码摄影':
        this.pageType = 7;
        break;
      case '游戏设备':
        this.pageType = 8;
        break;
      case '智能家居':
        this.pageType = 9;
        break;
      case '花呗专区':
        this.pageType = 10;
        break;
      case '热租爆品':
        this.pageType = 11;
        break;
      case '短租精选':
        this.pageType = 12;
        break;
      case '租完即送':
        this.pageType = 13;
        break;
      case '一元租机':
        this.pageType = 14;
        this.imgHeight = 320;
        break;
      case '无人机相机':
        this.pageType = 30;
        this.imgHeight = 320;
        break;
      case '二手精选':
        this.pageType = 31;
        this.imgHeight = 320;
        break;
    }
    this.getData();
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.activeNum = 1;
    this.pageTitle = to.$route.meta.title;
    this.getData();
  },
  methods: {
    submit: function submit() {
      (0, _xcx.release)(this.pageType).then(function (res) {});
    },
    setData: function setData(pos) {
      var key = this.sectionList[pos - 1].key;
      var data = _objectSpread({}, this.configData[key]);
      data.goods = data.goods ? data.goods : [];
      if (data.goods.length > 0) {
        data.goods.forEach(function (item) {
          item.id = item.id ? item.id : item.goods_id;
          item.goods_id = item.goods_id ? item.goods_id : item.id;
        });
      }
      this.compFormData = data;
      if (this.shopId && this.pageTitle == '商户配置') {
        this.compFormData.is_nineGrid = true;
        if (!this.compFormData.hasOwnProperty('background_image')) {
          this.compFormData.background_image = ',';
        }
      }
      if (this.shopId && this.pageTitle == '店铺列表') this.compFormData.is_editShopList = true;
      console.log('compFormData', this.compFormData);
      // this.$nextTick(() => {
      // 	this.$refs.compForm.formData = data;
      // })
      console.log('图片数据', this.configData.first_level);
    },
    getData: function getData() {
      var _this = this;
      (0, _xcx.getActivityPageByType)(this.pageType).then(function (result) {
        // console.log('getActivityPageByType', result)
        var data = result && result.details ? JSON.parse(result.details) : false;
        _this.setConfigData(data);
        _this.setData(_this.activeNum);
      });
    },
    setConfigData: function setConfigData() {
      var _this2 = this;
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.sectionList.forEach(function (item, index) {
        var key = 'first_level';
        switch (index + 1) {
          case 1:
            key = 'first_level';
            break;
          case 2:
            key = 'second_level';
            break;
          case 3:
            key = 'third_level';
            break;
          case 4:
            key = 'fourth_level';
            break;
        }
        _this2.configData[key] = data && data[key] ? data[key] : {
          goods: [],
          main_title: ''
        };
      });
    },
    save: function save() {
      var _this3 = this;
      var params = this.$refs.compForm.getSubmitData();
      console.log('params', params);
      // return;
      if (params) {
        (0, _xcx.editActivityPage)(_objectSpread({
          type: this.pageType,
          page_title: this.pageTitle
        }, params)).then(function (res) {
          _this3.getData();
        });
      }
    },
    preview: function preview() {}
  }
};