"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/defineProperty.js"));
var _orderEC = require("@/api/orderEC");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['editItem', 'selfItem'],
  data: function data() {
    return {
      form: {
        id: 0,
        order_id: 0,
        name: "",
        relation: "",
        phone: "",
        is_wechar: 0,
        is_alipay: 0
      },
      tradeNo: '',
      rules: {
        name: [{
          required: true,
          message: '名字不能为空'
        }],
        relation: [{
          required: true,
          message: '关系不能为空'
        }],
        phone: [{
          required: true,
          message: '联系方式'
        }]
      },
      dialogVisible: false
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;
      if (this.selectedId != null) {
        this.form.id = this.selectedId;
      }
      console.log(this.form);
      (0, _orderEC.saveOrderEmeCon)(_objectSpread({}, this.form)).then(function (res) {
        _this.dialogVisible = false;
        _this.$emit('success', true);
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.tradeNo = this.editItem.trade_no;
        if (this.selfItem != null) {
          this.form = _objectSpread({}, this.selfItem);
        } else {
          this.form = this.$options.data().form;
        }
        this.form.order_id = this.editItem.id;
      } else {
        this.$refs['ruleForm'].resetFields();
      }
    }
  }
};