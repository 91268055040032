var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": "",
        title: "线下支付——扫码付",
        visible: _vm.dialogVisible,
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "pay_code" }, [
        _c("div", { staticClass: "top" }, [
          _c("h5", { staticClass: "bold-font" }, [_vm._v("支付金额")]),
          _c(
            "p",
            {
              staticClass: "red-font bold-font",
              staticStyle: { "font-size": "35px", margin: "20px 0" },
            },
            [_vm._v("￥" + _vm._s(_vm.formData.amount))]
          ),
          _c("div", { staticClass: "flex" }, [
            _c("p", { staticClass: "flex-1 color-666" }, [
              _c("label", { staticClass: "bold-font" }, [_vm._v("交易类型：")]),
              _vm._v("线下扫码支付"),
            ]),
            _c("p", { staticClass: "flex-1 color-666" }, [
              _c("label", { staticClass: "bold-font" }, [_vm._v("操作主体：")]),
              _vm._v(_vm._s(_vm.userName)),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "text-center bottom" },
          [
            _c(
              "el-statistic",
              {
                attrs: {
                  value: _vm.deadline2,
                  "time-indices": "",
                  title: "🎉支付倒计时：🎉",
                },
                on: { finish: _vm.hilarity },
              },
              [_c("template", { slot: "suffix" })],
              2
            ),
            _c(
              "div",
              { staticClass: "code_wrap" },
              [
                _c(
                  "el-image",
                  {
                    staticStyle: { width: "250px", height: "250px" },
                    attrs: { src: _vm.code_url },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot",
                        attrs: { slot: "error" },
                        slot: "error",
                      },
                      [_c("i", { staticClass: "el-icon-picture-outline" })]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex align-center",
                    staticStyle: { padding: "4px 30px 24px" },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "42px",
                        height: "42px",
                        "margin-right": "25px",
                      },
                      attrs: {
                        src: require("@/assets/images/scan.png"),
                        alt: "扫码",
                      },
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "line-height": "16px",
                        },
                      },
                      [_vm._v("打开手机支付宝扫一扫继续付款")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }