var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    { attrs: { "wrap-class": "scrollbar-wrapper" } },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "default-active": _vm.activeMenu,
            collapse: false,
            "unique-opened": false,
            "active-text-color": _vm.variables.menuActiveText,
            "collapse-transition": false,
            mode: "vertical",
          },
        },
        [
          _vm._l(_vm.permission_routes, function (route) {
            return [
              _vm.hasRoutes.includes(route.name)
                ? _c("sidebar-item", {
                    key: route.path,
                    attrs: { item: route, "base-path": route.path },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }