"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: {
    dialogTitle: {
      type: String
    },
    handleDialog: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    sourceForm: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    var checkCode = function checkCode(rule, value, callback) {
      var reg = /^(?!\d+$)[\da-zA-Z]+$/;
      if (!value) {
        callback(new Error("请输入来源标识"));
      } else if (!reg.test(value) || value.length > 20) {
        callback(new Error("请输入20位以内英文或加数字组合"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        source_name: [{
          required: true,
          message: '请输入订单来源名称',
          trigger: 'blur'
        }, {
          min: 1,
          max: 10,
          message: '长度在 1 到 10 个字符',
          trigger: 'blur'
        }],
        source_code: [{
          required: true,
          message: '请输入来源标识',
          trigger: 'blur'
        }, {
          validator: checkCode,
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      }
    };
  },
  methods: {
    closeDialog: function closeDialog(param) {
      this.$emit("closeDialog", param);
    },
    setUrl: function setUrl(val) {
      this.$emit("setUrl", val);
    },
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.$emit("submitForm", true);
        } else {
          return false;
        }
      });
    }
  }
};