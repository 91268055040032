"use strict";

var _interopRequireDefault = require("D:/project/xingxingzu/multi_merchant_web/supplier/node_modules/_@babel_runtime@7.24.8@@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _order = require("@/api/order");
var _finance = require("@/api/finance");
var _deductLog = _interopRequireDefault(require("@/components/dialogs/deductLog"));
var _setWithhold = _interopRequireDefault(require("./setWithhold"));
var _mayi = require("@/api/mayi");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ['orderId', 'orderNo'],
  components: {
    deductLog: _deductLog.default,
    setWithhold: _setWithhold.default
  },
  data: function data() {
    return {
      loading: false,
      dialogVisible: false,
      tableData: [],
      selectedItem: {}
    };
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      if (val) {
        this.getLease();
      }
    }
  },
  methods: {
    getLease: function getLease() {
      var _this = this;
      console.log(this.orderId);
      this.loading = true;
      this.tableData = [];
      (0, _order.orderShareList)(this.orderId).then(function (res) {
        _this.tableData = res || [];
        _this.loading = false;
      });
    },
    getTagType: function getTagType(status) {
      var type = '未知';
      if (status == 0) {
        type = '未结算';
      } else if (status == 1) {
        type = '已结算';
      } else if (status == 2) {
        type = '已退回';
      } else if (status == 3) {
        type = '已作废';
      }
      return type;
    },
    refresh: function refresh() {
      var _this2 = this;
      this.getLease();
      setTimeout(function () {
        _this2.$parent.getList(true);
      }, 1000);
    }
  }
};