"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dateFormat = dateFormat;
exports.formatDate = formatDate;
exports.param2Obj = param2Obj;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.string.pad-start");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}
function formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  var o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds()
  };
  for (var k in o) {
    if (new RegExp("(".concat(k, ")")).test(fmt)) {
      var str = o[k] + "";
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

// 导出时间格式化
// "YYYY-mm-dd-时间戳"
function dateFormat(date) {
  var ret,
    fmt = "YYYY-mm-dd";
  var opt = {
    "Y+": date.getFullYear().toString(),
    // 年
    "m+": (date.getMonth() + 1).toString(),
    // 月
    "d+": date.getDate().toString() // 日
  };
  for (var k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"));
    }
  }
  return fmt + "-" + countDown(date);
}

// 时分秒转为时间戳
function countDown(date) {
  var s = 0;
  var hour = date.getHours();
  var min = date.getMinutes();
  var sec = date.getSeconds();
  s = Number(hour * 3600) + Number(min * 60) + Number(sec);
  return s;
}