var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common-uploader", class: { flex: _vm.multiple } },
    [
      _vm.imgUrl
        ? _c(
            "div",
            {
              staticClass: "img_wrapper",
              style: { width: _vm.wrapperWidth, height: _vm.wrapperHeight },
            },
            [
              _vm.imgUrl
                ? _c("img", {
                    staticClass: "w-100 h-100",
                    attrs: { src: _vm.$globalObj.imgBaseUrl + _vm.imgUrl },
                  })
                : _vm._e(),
              _vm.showViewer
                ? _c("el-image-viewer", {
                    attrs: {
                      "on-close": _vm.closeViewer,
                      "url-list": [_vm.$globalObj.imgBaseUrl + _vm.imgUrl],
                      "z-index": 9000,
                    },
                  })
                : _vm._e(),
              _c(
                "p",
                { staticClass: "mask_tool" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "flex-1 text-center pointer",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.showViewer = true
                        },
                      },
                    },
                    [_vm._v("查看")]
                  ),
                  !_vm.hideDelete
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  !_vm.hideDelete
                    ? _c(
                        "span",
                        {
                          staticClass: "flex-1 text-center pointer",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.delImg($event)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.multiple
        ? _c(
            "div",
            { staticClass: "flex flex-wrap mul_list" },
            [
              _vm.fileList.length > 0
                ? [
                    _vm.showViewer
                      ? _c("el-image-viewer", {
                          attrs: {
                            "on-close": _vm.closeViewer,
                            "url-list": [
                              _vm.$globalObj.imgBaseUrl + _vm.showUrl,
                            ],
                            "z-index": 9000,
                          },
                        })
                      : _vm._e(),
                    !_vm.sort
                      ? _vm._l(_vm.fileList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item,
                              staticClass: "inline-block img_wrapper",
                              style: {
                                width: _vm.wrapperWidth,
                                height: _vm.wrapperHeight,
                              },
                            },
                            [
                              item
                                ? _c("img", {
                                    staticClass: "w-100 h-100",
                                    attrs: {
                                      src: _vm.$globalObj.imgBaseUrl + item,
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "p",
                                { staticClass: "mask_tool" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "flex-1 text-center pointer",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.previewImg(item)
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  !_vm.hideDelete
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  !_vm.hideDelete
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "flex-1 text-center pointer",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.delImg(index)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        })
                      : _c(
                          "vuedraggable",
                          {
                            staticClass: "draggable-container",
                            model: {
                              value: _vm.sortList,
                              callback: function ($$v) {
                                _vm.sortList = $$v
                              },
                              expression: "sortList",
                            },
                          },
                          [
                            _c(
                              "transition-group",
                              _vm._l(_vm.sortList, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item,
                                    staticClass: "inline-block img_wrapper",
                                    style: {
                                      width: _vm.wrapperWidth,
                                      height: _vm.wrapperHeight,
                                    },
                                  },
                                  [
                                    item
                                      ? _c("img", {
                                          staticClass: "w-100 h-100",
                                          attrs: {
                                            src:
                                              _vm.$globalObj.imgBaseUrl + item,
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "p",
                                      { staticClass: "mask_tool" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "flex-1 text-center pointer",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.previewImg(item)
                                              },
                                            },
                                          },
                                          [_vm._v("查看")]
                                        ),
                                        !_vm.hideDelete
                                          ? _c("el-divider", {
                                              attrs: { direction: "vertical" },
                                            })
                                          : _vm._e(),
                                        !_vm.hideDelete
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex-1 text-center pointer",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delImg(index)
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                  ]
                : _vm._e(),
              _vm.fileList.length < _vm.maxNum
                ? _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "string",
                        accept: _vm.accept ? _vm.accept : "image/*",
                        "show-file-list": false,
                        "http-request": _vm.httpRequest,
                        "before-upload": _vm.beforeUpload,
                      },
                    },
                    [
                      _vm.showType === "button"
                        ? _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          )
                        : _vm._e(),
                      _vm.showType === "photo"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-column align-center justify-center photo_wrapper",
                              style: {
                                width: _vm.photoWidth + "px",
                                height: _vm.photoHeight + "px",
                              },
                            },
                            [
                              _c("i", { staticClass: "el-icon-plus" }),
                              _c("span", [_vm._v("上传图片")]),
                            ]
                          )
                        : _vm._e(),
                      _vm.tip
                        ? _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(_vm._s(_vm.tip)),
                              _vm.subTip ? _c("br") : _vm._e(),
                              _vm._v(_vm._s(_vm.subTip)),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      !_vm.multiple && !_vm.imgUrl
        ? _c(
            "el-upload",
            {
              ref: "upload",
              attrs: {
                action: "string",
                accept: _vm.accept ? _vm.accept : "image/*",
                "show-file-list": false,
                "http-request": _vm.httpRequest,
                "before-upload": _vm.beforeUpload,
              },
            },
            [
              _vm.showType === "button"
                ? _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  )
                : _vm._e(),
              _vm.showType === "photo"
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-column align-center justify-center photo_wrapper",
                      style: {
                        width: _vm.photoWidth + "px",
                        height: _vm.photoHeight + "px",
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _c("span", [_vm._v("上传图片")]),
                    ]
                  )
                : _vm._e(),
              _vm.tip
                ? _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(_vm._s(_vm.tip)),
                      _vm.subTip ? _c("br") : _vm._e(),
                      _vm._v(_vm._s(_vm.subTip)),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            width: _vm.dialogWidth,
            title: "图片剪裁",
            visible: _vm.dialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "m-auto cropper",
              style: { width: _vm.cropperWidth, height: _vm.cropperHeight },
            },
            [
              _c("vueCropper", {
                ref: "cropper",
                attrs: {
                  img: _vm.option.img,
                  outputType: _vm.outputType,
                  outputSize: _vm.option.outputSize,
                  info: _vm.option.info,
                  canScale: _vm.option.canScale,
                  autoCrop: _vm.option.autoCrop,
                  autoCropWidth: _vm.autoCropWidth,
                  autoCropHeight: _vm.autoCropHeight,
                  fixed: _vm.fixScale,
                  fixedBox: _vm.fixedBox,
                  fixedNumber: _vm.option.fixedNumber,
                  enlarge: _vm.enlarge,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.cancelCrop },
                },
                [_vm._v("直接上传")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.finish },
                },
                [_vm._v("裁剪")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }